import React from 'react';
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import {SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import List from "@mui/material/List";
import PropTypes from 'prop-types';
import CREDITS from "../../../app/assets/creditis.png"

const JumboVerticalNavbar = ({items}) => {
    const {sidebarOptions} = useJumboLayoutSidebar();

    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);

    return (
        <>
       
        <List 
       
            disablePadding
            sx={{
                mr: isMiniAndClosed ? 0 : 0,
                pb: 2,
                fontSize:"19px",
                fontWeight:"600",
                mt: 2
            }}
        >
            {
                items.map((item, index) => (
                    <JumboNavIdentifier translate item={item} key={index} />
                ))
            }
        </List>
        <div className='text-center pb-20 pt-20 border-top-grey credits_label'>
            <img height={26} width={137} src={CREDITS} alt='credits' />


        </div>
       

        </>
    );
};

JumboVerticalNavbar.defaultProps = {
    items: PropTypes.array,
    translate: false
};

export default JumboVerticalNavbar;