import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AdminSVg, AppSvgLogon } from "app/assets/svg/Svg";
import CREDITS from "../../../../assets/nsdl_logo.png";
import { SucessGifLogin } from "app/components/SucessGif/SucessGif";

const AdminSuccessMessage = () => {
  const navigate = useNavigate();
  function handleClick(event) {
    event.preventDefault();
    navigate("/admin", { replace: true });
  }

  return (
    <div>
    <div className="session_page ">
       <Grid className="h-full session_grid" container>
          <Grid
          className="session_image_content"
          item
          lg={7}
          style={{ alignSelf: "center" }}
          >
          <div className="session_content">
             <div className="session_img">
                <AdminSVg />
             </div>
          </div>
       </Grid>
       <Grid item lg={5} style={{ alignSelf: "center" }}>
       <div className="session_card flex_container_col justify-between">
          <div className="session_wrapper mb-10">
             <div className="session_form">
                <div className="admin_form">
              
                   <div className="text-center">
                      <AppSvgLogon />
                   </div>
                   <div >
                      <Grid
                         container
                         justifyContent="center"
                         alignItems="center"
                         >
                           <div className='login_gif'><SucessGifLogin /></div>
                      </Grid>
                      <p className="sucess_greeting text-center">
                         You have successfully reset your password. Please use
                         your new password when logging in.
                      </p>
                      <LoadingButton
                      className="login_btn"
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                      onClick={handleClick}
                      >
                      Go to Login
                      </LoadingButton>
                   </div>
                
             </div>
          </div>
       </div>
       <div className="credits_logo mb-30  text-center">
          <p>Powered by</p>
          <img width={98} height={40} src={CREDITS} alt="credits" />
       </div>
    </div>
    </Grid>
    </Grid>
 </div>
 </div>
  );
};

export default AdminSuccessMessage;
