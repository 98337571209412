import {
    Autocomplete,
    Box,
    Button,
    Drawer,
    FormControl,
    FormControlLabel,
    Grid,
    LinearProgress,
    Radio,
    RadioGroup,
    Step,
    StepLabel,
    Stepper,
    TextField,
    ThemeProvider,
    Typography,
    createTheme,
  } from "@mui/material";
  import React, { useContext, useState } from "react";
  import ClearIcon from "@mui/icons-material/Clear";
  import Div from "@jumbo/shared/Div";
    import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
    import { DatePicker } from "@mui/x-date-pickers/DatePicker";
    import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
    import dayjs from "dayjs";
import { CurrentContext } from "../../Context/CurrentUserContext";

  
  const steps = [
    "Organisation Details",
    "Customer Profile",
    "Nominee",
    "Application",
    "Authorised Signatory ",
    "Credit Facilities",
    "Declaration",
    "Annexure 5",
    "Standing Instruction Request",
    "FATCA /CRS Declaration",
    "Customer Declaration",
  ];
  
  const theme = createTheme({
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#333", // Change the color of the unselected radio button
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: "#666666", // Change the color of the radio label
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
    },
  });

const Nominee = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [checked, setChecked] = useState(false);
    const [selectedValue, setSelectedValue] = useState("option1");
    const [selectedValueTwo, setSelectedValueTwo] = useState("option1");
    const [value, setValue] = useState(dayjs("2022-04-17"));
    const state = [
      {
        label: "Tamil nadu",
        value: "Tamil nadu",
      },
      {
        label: "Tamil nadu",
        value: "Tamil nadu",
      },
      {
        label: "Tamil nadu",
        value: "Tamil nadu",
      },
    ];
    const country = [
      {
        label: "India",
        value: "India",
      },
      {
        label: "India",
        value: "India",
      },
      {
        label: "India",
        value: "India",
      },
    ];
    const Year = [
      { label: "5" },
      {
        label: "4",
      },
      {
        label: "6",
      },
    ];
    const Month = [
      { label: "5" },
      {
        label: "4",
      },
      {
        label: "6",
      },
    ];
    const city = [
      {
        label: "Madurai",
        value: "madurai",
      },
      {
        label: "Chennai",
        value: "chennai",
      },
      {
        label: "Theni",
        value: "theni",
      },
    ];
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleChangeRadio = (event) => {
      setSelectedValue(event.target.value);
    };
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
      };
      const handleChangeTwo = (event) => {
        setSelectedValueTwo(event.target.value);
      };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const isLastStep = activeStep === steps.length - 1;
  
    const handleStepClick = (stepIndex) => {
      if (stepIndex < activeStep) {
        setActiveStep(stepIndex);
      }
    };

    const { Nomineeformik } = useContext(CurrentContext);

  return (
    <>
    <div className="create_form_width">
      <p className="nominee_text">
        To Be Filled For Sole Proprietorship Accounts Only
      </p>
      <Div sx={{ mt: 3 }}>
        <p className="lette_space_normal">
          The Bank recommends that all depositors avail the
          nomination facility. The nominee, in the event of
          death of the depositors would receive the balance
          outstanding in the account as a trustee of legal
          affairs, thus helping in quick and easy
          settlement.
        </p>
        <ThemeProvider theme={theme}>
        
        

        <FormControl>
            <RadioGroup
              row
              name="nomnieeType"
              value={Nomineeformik.values.nomnieeType}
              onChange={Nomineeformik.handleChange}
              onBlur={Nomineeformik.handleBlur}
            >
              <FormControlLabel
                value="No"
                className="radio_form"
                control={<Radio />}
                label="I do not wish to nominate anyone as the beneficiary of my Saving Bank Account with NSDL Payments Bank"
              />
              <FormControlLabel
                value="Yes"
                className="radio_form mt-10"
                control={<Radio />}
                label="I nominate the following person to whom in the event of my death the amount of deposits in my account, may be returned by NSDL Payments Bank. The nomination will be applicable for all my accounts held with the bank"
              />
            </RadioGroup>
          </FormControl>

      </ThemeProvider>
       </Div>
      <Div sx={{ mt: 5 }}>
          <Typography className="from_label">
            Name of the Nominee
          </Typography>
          <TextField
            className="form_new_style"
            id="outlined-basic"
            variant="outlined"
            fullWidth
            name="nomnieeName"
            {...Nomineeformik.getFieldProps("nomnieeName")}
          />
        </Div>
        <Div sx={{ mt: 4 }}>
        <Typography className="from_label">
        Date of Birth
          </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="w-100"
                value={Nomineeformik.values.nomineeDOB}
                onChange={(value) => Nomineeformik.setFieldValue("nomineeDOB", value)}
                format="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                   
                    value={
                      Nomineeformik.values.nomineeDOB
                        ? dayjs(Nomineeformik.values.nomineeDOB).format("DD/MM/YYYY")
                        : ""
                    }
                    onChange={(event) => {
                      Nomineeformik.setFieldValue(
                        "nomineeDOB",
                        event.target.value
                          ? dayjs(event.target.value, "DD/MM/YYYY").toDate()
                          : null
                      );
                    }}
                  />
                )}
              />
            </LocalizationProvider>
        </Div> 
        <Div sx={{ mt: 4 }}>
          <Typography className="from_label">
           
           Relationship with A/C Holder
          </Typography>
          <TextField
            className="form_new_style"
            id="outlined-basic"
            variant="outlined"
            fullWidth
            name="nomineeRelation"
            {...Nomineeformik.getFieldProps("nomineeRelation")}
          />
        </Div>
        <Div sx={{ mt: 4 }}>
          <Typography className="from_label">
          Mobile No
          </Typography>
          <TextField
            className="form_new_style"
            id="outlined-basic"
            variant="outlined"
            fullWidth
            name="nomineeMobileNo"
            {...Nomineeformik.getFieldProps("nomineeMobileNo")}
          />
        </Div>
        <Div sx={{mt:4}}> 
        <div  className="year_of_busness">
        <Typography className="from_label">
        Nominee Address
          </Typography>
          <ThemeProvider theme={theme}>
          <FormControl>
          <RadioGroup    row
                        name="nomnieeType"
                        value={Nomineeformik.values.nomnieeType}
                        onChange={Nomineeformik.handleChange}
                        onBlur={Nomineeformik.handleBlur}

          >
        <FormControlLabel   className="radio_form" value="same" control={<Radio />} label="Same as applicants address mentioned above." />
        <FormControlLabel    className="radio_form" value="new" control={<Radio />} label="Other Address" />
      
      </RadioGroup>
      </FormControl>
       </ThemeProvider>
      {Nomineeformik.values.nomnieeType === 'new' && (
        <div>
            <Typography sx={{mt:1}} className="lette_space_normal">
      If different, fill the details mentioned below
          </Typography>
         <Div sx={{ mt: 3 }}>
          <Typography className="from_label">
           Address
          </Typography>
          <textarea
            className="w-100"
            name="nomineeOtherAddress"
            {...Nomineeformik.getFieldProps("nomineeOtherAddress")}
            id=""
            rows="5"
          ></textarea>
        </Div>
        <Div sx={{ mt: 3 }}>
          <Typography className="from_label">
          LANDMARK
          </Typography>
          <textarea
            className="w-100"
            name="nomineeOtherLandmark"
            {...Nomineeformik.getFieldProps("nomineeOtherLandmark")}
            id=""
            rows="5"
          ></textarea>
        </Div>
        <Div sx={{ mt: 3 }}>
        <Grid container gap={"24px"}>
              <Grid item sm={6}>
                <Typography className="from_label">
                  City
                </Typography>
                <Autocomplete
                    options={city}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) =>
                      option.value === value?.value
                    }
                    value={
                      city.find(
                        (opt) => opt.value === Nomineeformik.values.nomineeOtherCity
                      ) || null
                    }
                    onChange={(_, value) =>
                      Nomineeformik.setFieldValue(
                        "nomineeOtherCity",
                        value?.value || null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
              </Grid>
              <Grid item sm={5.5}>
                <Typography className="from_label">
                  Pin Code
                </Typography>
                
                    <TextField  className="form_new_style"
            id="outlined-basic"
            variant="outlined"
            fullWidth 
            name="nomineeOtherLandmark"
            {...Nomineeformik.getFieldProps("nomineeOtherPincode")}
            />
                 
                
              </Grid>
            </Grid> 
        </Div>
        <Div sx={{ mt: 3 }}>
        <Grid container gap={"24px"}>
              <Grid item sm={6}>
                <Typography className="from_label">
                  Sate
                </Typography>
                <Autocomplete
                    options={state}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) =>
                      option.value === value?.value
                    }
                    value={
                      state.find(
                        (opt) => opt.value === Nomineeformik.values.nomineeOtherSate
                      ) || null
                    }
                    onChange={(_, value) =>
                      Nomineeformik.setFieldValue(
                        "nomineeOtherSate",
                        value?.value || null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
              </Grid>
              <Grid item sm={5.5}>
                <Typography className="from_label">
                 Country
                </Typography>
                <Autocomplete
                    options={country}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) =>
                      option.value === value?.value
                    }
                    value={
                      country.find(
                        (opt) => opt.value === Nomineeformik.values.nomineeOtherCountry
                      ) || null
                    }
                    onChange={(_, value) =>
                      Nomineeformik.setFieldValue(
                        "nomineeOtherCountry",
                        value?.value || null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                 
                
              </Grid>
            </Grid> 
        </Div>


        </div>
      )}

     
      

        </div>
        </Div>
        <Div sx={{mt:4}}>
        <div  className="year_of_busness">
        <Typography className="lette_space_normal">
        Guardian
          </Typography>
          <Typography sx={{mt:3}} className="lette_space_normal">
          As the nominee is a minor on this date, I appoint  the guardian to receive the amount of deposit in the account on behalf of the nominee in the event of my death during the minority of the nominee.
          </Typography>

          <Div sx={{ mt: 4 }}>
          <Typography className="from_label">
          
          Guradian Name (If Nominee Is a Minor)
          </Typography>
          <TextField
            className="form_new_style"
            id="outlined-basic"
            variant="outlined"
            fullWidth
          />
        </Div>
        <Div sx={{ mt: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography className="from_label">
             
              Date of Birth of Guradian
            </Typography>
            <DatePicker
              className="w-100"
              value={value}
              format="DD/MM/YYYY"
              onChange={(newValue) => setValue(newValue)}
            />
          </LocalizationProvider>
        </Div> 
        <Div sx={{ mt: 4 }}>
        <Typography className="from_label">
        Nominee Address
          </Typography>
          <ThemeProvider theme={theme}>
          <RadioGroup value={selectedValueTwo} onChange={handleChangeTwo} row>
        <FormControlLabel   className="radio_form" value="option1" control={<Radio />} label="Same as applicants address mentioned above." />
        <FormControlLabel    className="radio_form" value="option2" control={<Radio />} label="Other Address" />
      
      </RadioGroup>
      </ThemeProvider>

        </Div>
        <Div sx={{mt:4}}>
        {selectedValueTwo === 'option2' && (
        <div>
            <Typography sx={{mt:1}} className="lette_space_normal">
      If different, fill the details mentioned below
          </Typography>
         <Div sx={{ mt: 3 }}>
          <Typography className="from_label">
           
           Address
          </Typography>
          <textarea
            className="w-100"
            name=""
            id=""
            rows="5"
          ></textarea>
        </Div>
        <Div sx={{ mt: 3 }}>
          <Typography className="from_label">
          Landmark
          </Typography>
          <textarea
            className="w-100"
            name=""
            id=""
            rows="5"
          ></textarea>
        </Div>
        <Div sx={{ mt: 3 }}>
        <Grid container gap={"24px"}>
              <Grid item sm={6}>
                <Typography className="from_label">
                  City
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={city}
                  renderInput={(params) => (
                    <TextField
                      className="mr-10"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={5.5}>
                <Typography className="from_label">
                  Pin Code
                </Typography>
                
                    <TextField  className="form_new_style"
            id="outlined-basic"
            variant="outlined"
            fullWidth />
                 
                
              </Grid>
            </Grid> 
        </Div>
        <Div sx={{ mt: 3 }}>
        <Grid container gap={"24px"}>
              <Grid item sm={6}>
                <Typography className="from_label">
                  State
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={state}
                  renderInput={(params) => (
                    <TextField
                      className="mr-10"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={5.5}>
                <Typography className="from_label">
                 Country
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={country}
                  renderInput={(params) => (
                    <TextField
                      className="mr-10"
                      fullWidth
                      {...params}
                    />
                  )}
                />
                 
                
              </Grid>
            </Grid> 
        </Div>


        </div>
      )}

        </Div>


        </div>

        </Div>
        
      
    </div>
  </>
  )
}

export default Nominee