import React, { useRef } from 'react'
import {  Box } from "@mui/system";
import { Button, Grid, TextField } from "@mui/material";
import Modal from '@mui/material/Modal';
import { ClearIcon } from '@mui/x-date-pickers';
import * as yup from "yup";
import {useFormik } from "formik";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    outline: 'none'
    
  };
  const otpValidation = yup
  .array()
  .of(
    yup
      .string()
      .matches(/^\d+$/, 'Each PIN digit should be a digit')
      .length(1, 'Each PIN digit must be exactly 1 digit')
  )
  .required('PIN is required')
  .test('len', 'PIN must be exactly 6 digits', (val) => val && val.join('').length === 6);

  const validationSchema = yup.object({

    otp: otpValidation,
    
  });
const VerifyOtp = ({ open, handleClose }) => {

    const inputRefs = [
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
      ];

    const handleInputChange = (e, index, formik, fieldName, valueArray, refs) => {
        const inputValue = e.target.value;
        const newArray = [...valueArray];
      
        if (inputValue.match(/^\d+$/)) {
          newArray[index] = inputValue;
          formik.setFieldValue(fieldName, newArray);
          if (index < 5 && inputValue) {
            refs[index + 1].current.focus();
          }
        } else if (inputValue === '' && index > 0) {
          newArray[index] = inputValue;
          formik.setFieldValue(fieldName, newArray);
          refs[index - 1].current.focus();
        } else if (inputValue === '' && index === 0) {
          newArray[index] = inputValue;
          formik.setFieldValue(fieldName, newArray);
        }
      };

      const formik = useFormik({
        initialValues: {
           otp: Array.from({ length: 6 }, () => ''),
           
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
      
         
        },
      });

  return (
    <div>
     
    <Modal
     className="Otp_popup"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <div className='model_header_otp dlfex-justify-between-align-center '>
            <p>Enter OTP</p>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleClose} />

        </div>
        <div className='model_content'>
            <div className='pt-20 pb-20'>
            <div className="Otp_input_box emailcode responisve">
                      {formik.values.otp.map((_, index) => (
                      <div key={index} className="otp_input small">
                         <TextField name={`otp[${index}]`} variant="outlined" margin="normal" type="text" inputProps={{ maxLength: 1,}}
                         inputRef={inputRefs[index]}
                         error={formik.touched.otp && formik.errors.otp ? true : false}
                         onChange={(e) => handleInputChange(e, index, formik, 'otp', formik.values.otp, inputRefs)} />
                      </div>
                      ))}
             </div>
            </div>

       
        <Grid container spacing={3}>
          <Grid alignSelf={'center'} item lg={6} >
            <Button
              className="popup_cancel "
              fullWidth
              type="submit"
              variant="contained"
              size="small"
              
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>

          <Grid alignSelf={'center'}  item lg={6} >
            <Button
              className="primary_btn mt-0 mb-0 "
              fullWidth
              type="submit"
              variant="contained"
              size="small"
              
            >
              Verify
            </Button>
          </Grid>
        </Grid>
        </div>
      </Box>
    </Modal>
  </div>
  )
}

export default VerifyOtp