import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Drawer } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";

const MiniStatement = ({
  handleDrawerToggled,
  mobileOpens,
  handleDrawerClosed,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpens}
        onClose={handleDrawerToggled}
      >
        <Box className="sidenave_box" sx={{ width: "520px" }}>
          <div className="sidebar_header">
            <h1>Get Mini Statement</h1>
            <ClearIcon
              sx={{ cursor: "pointer" }}
              onClick={handleDrawerClosed}
            />
          </div>

          <div className="beneficarty_add_details">
            <table className="w-100 beneficary_details">
              <tr>
                <td className="table_label">Account Name</td>
                <td className="table_value">Vignesh M</td>
              </tr>
              <tr>
                <td className="table_label">Account Bank</td>
                <td className="table_value">Axis Bank</td>
              </tr>
              <tr>
                <td className="table_label">Account Number</td>
                <td className="table_value">5010007865490</td>
              </tr>
              <tr>
                <td className="table_label">IFSC Code</td>
                <td className="table_value">AXIS0987182</td>
              </tr>
              <tr>
                <td className="table_label">Account Type</td>
                <td className="table_value">Current</td>
              </tr>
            </table>
          </div>
        </Box>
        <Div className="pob-20" sx={{ ml:2.6, mt:1}}>
          <h2 className="from_label">
            You Can Able See Last 10 Transactions
          </h2>
          <div className="d-flex items-center gap mt-15">
                  <Button className="confrm_btn" >
                    Download
                  </Button>
                     <Button
                      className="outline_btn" >
                          Cancel
                    </Button>
                </div>
        </Div>
      </Drawer>
    </div>
  );
};

export default MiniStatement;
