import Div from '@jumbo/shared/Div'
import { Button, FormControlLabel, Grid, Radio, RadioGroup, TextField, ThemeProvider, Typography, createTheme } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { FaCircleCheck } from "react-icons/fa6";
import BeneficailOwnership from './BeneficailOwnership';
import { SignatureUpload } from 'app/assets/svg/Svg';

const theme = createTheme({
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#333", // Change the color of the unselected radio button
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: "#666666", // Change the color of the radio label
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
    },
  });
const Annexure = () => {
    const [selectedValue, setSelectedValue] = useState("option1");

    const [ createdraw, setCreatedraw] = useState(false);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
      };
      const handleDrawerToggle =() => {
        setCreatedraw(!createdraw);
      }
      const  handleDrawerClose = () => {
        setCreatedraw(false);
      }
  return (
    <div>
        <div className="create_form_width">
        <Div >
          <Typography className="from_label">
            
            Name of the Company
          </Typography>
          <TextField
            className="form_new_style"
            id="outlined-basic"
            variant="outlined"
            fullWidth
          />
        </Div>
        <Div sx={{mt:3}}>
          <Typography className="from_label">
            
            Registration Number
          </Typography>
          <TextField
            className="form_new_style"
            id="outlined-basic"
            variant="outlined"
            fullWidth
          />
        </Div>
        <Div sx={{ mt: 3 }}>
          <Typography className="from_label">
          
          Registered Address
          </Typography>
          <textarea
            className="w-100"
            name=""
            id=""
            rows="5"
          ></textarea>
        </Div>
        </div>
        <div>
        <Div sx={{ mt: 3 }}>
        <Typography className="lette_space_normal">
        The Company as stated above hereby confirms and that on the below date:</Typography>
        <p className='m-0'><strong>(Please tick the correct box - One input is Mandatory)</strong></p>

        <Div sx={{mt:3}}>
        <ThemeProvider theme={theme}>
          <RadioGroup value={selectedValue} onChange={handleChange} >

        <FormControlLabel   className="radio_form mb-20" value="option1" control={<Radio />} label="
        We hereby declare that no natural person is holding 10 % or more shares/exercise control in the company/Trust or 15% or more shares/exercises control in case of Partnership/LLP/AOP/Society/Club/University/Institution as stated above. Therefore details of natural person(s) holding the position of directors/senior management in the company are given in the above table
        " />
        <FormControlLabel    className="radio_form mb-20" value="option2" control={<Radio />} label="Details of ultimate natural persons ultimately holding 10 % or more shares or exercising ultimate control over the company/ Trust or 15 % or more shares or exercising ultimate control over Partnership/LLP/AOP/Society/Club/University/Institution" />
        <FormControlLabel    className="radio_form" value="option3" control={<Radio />} label="The Company is listed on (Name of the Stock Exchange)" />
      
      </RadioGroup>
       </ThemeProvider>
        </Div>
        
     </Div>
     {
        selectedValue === "option2" ? 
    <>
        
        <Div sx={{mt:3}}>
        <h2 className='info_text'>If you have ticked any of the above, please complete table before signing the declaration</h2>

    </Div>
    <Div className='d-flex items-center' sx={{mt:3}}>
    <Button onClick={handleDrawerToggle} className='beneficial_own_btn' variant="outlined" endIcon={<IoIosArrowForward />}>Fill Beneficial Ownership Table</Button>
    <h3 className='m-0 beneficial_text'> <FaCircleCheck className='check_icon' />  Beneficial Ownership Updated</h3>

    </Div>
    </> : ""
     }
     {
          selectedValue === "option3" ? 
          <div>
          <div className='create_form_width'>
            <Div>
           
          <TextField
          placeholder='Enter Name of the Stock Exchange'
            className="form_new_style"
            id="outlined-basic"
            variant="outlined"
            fullWidth
          />
          <p className='company_details'>or is a majorly owned subsidiary of <b>(Name of the listed Company)</b> </p>

            </Div>
            <Div>
           
           <TextField
           placeholder='Name of the listed Company'
             className="form_new_style"
             id="outlined-basic"
             variant="outlined"
             fullWidth
           />
           <p className='company_details'>listed on  <b>(Name of the Stock Exchange)</b> </p>
 
             </Div>
             <Div>
           
           <TextField
           placeholder='Enter Name of the Stock Exchange'
             className="form_new_style"
             id="outlined-basic"
             variant="outlined"
             fullWidth
           />
           <p className='company_details'>The Company undertakes that the facts stated above are true and correct.</p>
 
             </Div>

             <div className='year_of_busness'>
                <Typography className='lette_space_normal'>
                The Company undertakes and agrees that it will notify NSDL Payment Bank without delay of any changes to the controlling shareholders, person exercising control or having controlling ownership interest in the Company, as declared in the table above.

                </Typography>
                <Div sx={{mt:4}}
                >
                     <Typography className="from_label">
           Name of the Company
          </Typography>
          <TextField
            className="form_new_style"
            id="outlined-basic"
            variant="outlined"
            fullWidth
          />

                </Div>

             </div>
             <div className='mt-20'>
        <Typography  className='lette_space_normal' >Signature of the Authorized Official with Organization Stamp:
(to be signed by the official authorized to sign the Board Resolution)</Typography>
 </div> 
   <Grid container className='mt-20'>
    <Grid item sm={6}>
    <div className='signatur_upload'>
                <div className='sinature_uploader'>
               <SignatureUpload />
                   

                </div>
                <div className='signatur_upload_details'>
             
          <h2 className='upload_image'>  Upload Signature With Stamp </h2>
          <p className='format'>  Use a JPG, PNG (250kb)</p>
          
                </div>



            </div>

    </Grid>
   </Grid>
   

 

            

          </div>


          </div> 
          : ""
     }

  

      

        </div>



        <BeneficailOwnership handleDrawerClose={handleDrawerClose}
        createdraw={createdraw}
        handleDrawerToggle={handleDrawerToggle} />
    </div>
  )
}

export default Annexure