import React, { useState } from "react";
import {
Card,
CardContent,
Grid,
IconButton,
Popover,
Table,
TableBody,
TableCell,
TableFooter,
TableHead,
TablePagination,
TableRow,
TableSortLabel,
Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Div from "@jumbo/shared/Div";
import Search from "../../component/search";
import NoRecordsFound from "app/pages/app-pages/component/noRecordsFound";
import SkeletonTableLoader from "../../component/skeleton_loader/skeletonTableLoader";
import DyanamicDatePicker from "app/components/DatePicker/DyanamicDatePicker";
import { FaReceipt } from "react-icons/fa6";
import { FileIcon, FilterIcon, RefreshIcon } from "app/assets/svg/Svg";
import TransactionReceipt from "../TransactionReceipt";


const TransactionList = () => {
   const TableData = [
      {
        sno: "01",
        transactionId: "6301957864",
        name: "Siva Ramar",
        remarks: "Friends and Family",
        dateTime: "31/05/23",
        time:"09:20 AM",
        amount: "1,500.00 ",
        balance: "2,15,589.00",
        sender: "Jayshri Tiwari",
        mobile: "78945 61230",
        status: 1,
        method: "IMPS",
        transAmt:"12,000.00"
      },
      {
        sno: "02",
        transactionId: "2730175548",
        name: "Vishwas Patel",
        remarks: "Entertainment",
        dateTime: "01/06/23",
        time:"09:20 AM",
        amount: "1,100.00",
        balance: "2,00,589.00",
        sender: "Jayshri Tiwari",
        mobile: "78945 61230",
        status: 1,
        method: "RTGS",
        transAmt:"12,000.00"
      },
      {
        sno: "03",
        transactionId: "1696086371",
        name: "Navjot Kaur",
        remarks: "Investment",
        dateTime: "1/06/23",
        time:"09:20 AM",
        amount: "1,200.00 ",
        balance: "1,89,589.00",
        sender: "Siva Ramar",
        mobile: "78945 61230",
        status: 1,
        method: "IMPS",
        transAmt:"12,000.00"
      },
      {
        sno: "04",
        transactionId: "3954212189",
        name: "mamta Lodhi",
        remarks: "Food",
        dateTime: "03/06/23",
        time:"09:20 AM",
        amount: "3,460.00 ",
        balance: "2,01,589.00",
        sender: "Vishwas Patel",
        mobile: "78945 61230",
        status: 0,
        method: "IMPS",
        transAmt:"12,000.00"
      },
      {
        sno: "05",
        transactionId: "0500042814",
        name: "Paartho Ghosh",
        remarks: "Entertainment",
        dateTime: "03/06/23",
        time:"09:20 AM",
        amount: "1,260.00",
        balance: "1,66,989.00",
        sender: "Navjot Kaur",
        mobile: "78945 61230",
        status: 2,
        method: "IMPS",
        transAmt:"12,000.00"
      },
      {
        sno: "06",
        transactionId: "7725068610",
        name: "Bhavna Goyal",
        remarks: "Food",
        dateTime: "31/05/23",
        time:"09:20 AM",
        amount: "909.12",
        balance: "1,66,080.00",
        sender: "Jayshri Tiwari",
        mobile: "78945 61230",
        status: 0,
        method: "RTGS",
        transAmt:"12,000.00"
      },
      {
        sno: "07",
        transactionId: "1501341233",
        name: "Jayshri Tiwari",
        remarks: "Entertainment",
        dateTime: "05/06/23",
        time:"09:20 AM",
        amount: "765.54",
        balance: "1,65,315.00",
        sender: "mamta Lodhi",
        mobile: "78945 61230",
        status: 0,
        method: "NEFT",
        transAmt:"12,000.00"
      },
      {
        sno: "08",
        transactionId: "3910793817",
        name: "Ramesh Gupta",
        remarks: "Savings",
        dateTime: "02/06/23",
        time:"09:20 AM",
        amount: "899.00",
        balance: "1,66,214.00",
        sender: "Paartho Ghosh",
        mobile: "78945 61230",
        status: 2,
        method: "IMPS",
        transAmt:"12,000.00"
      },
      {
        sno: "09",
        transactionId: "1501341233",
        name: "Jayshri Tiwari",
        remarks: "Entertainment",
        dateTime: "05/06/23",
        time:"09:20 AM",
        amount: "765.54",
        balance: "1,65,315.00",
        sender: "Ramesh Gupta",
        mobile: "78945 61230",
        status: 0,
        method: "IMPS",
        transAmt:"12,000.00"
      },
      {
        sno: "10",
        transactionId: "3910793817",
        name: "Ramesh Gupta pandey sharma",
        remarks: "Savings for the gold investments",
        dateTime: "02/06/23",
        time:"09:20 AM",
        amount: "899.00",
        balance: "1,66,214.00",
        sender: "Jayshri Tiwari",
        mobile: "78945 61230",
        status: 1,
        method: "IMPS",
        transAmt:"12,000.00"
      },
    ];

const terms = ["name", "remarks", "transactionId"];

// useSate
const [filteredData, setFilteredData] = useState(TableData);
const [loading, setLoading] = useState(false);
const [method, setMethod] = useState("Method");
const [type, setType] = useState("type");
const [anchorEl, setAnchorEl] = React.useState(null);
const [selectedIndex, setSelectedIndex] = useState(null);
const [mobileOpen, setMobileOpen] = React.useState(false);
// functions
const handleSearch = (filteredData, loading) => {
setLoading(loading);
setFilteredData(filteredData);
};
const handleClick = (event) => {
setAnchorEl(event.currentTarget);
};
const handleClose = () => {
setAnchorEl(null);
};
const open = Boolean(anchorEl);
const id = open ? "popover" : undefined;
const handleChangeMethod = (event) => {
setMethod(event.target.value);
};
const handleChangeType = (event) => {
   setType(event.target.value);
   };
const handleClickedRecipt = (event, i) => {
   setSelectedIndex(i);
   setMobileOpen(!mobileOpen);
 };
 function handleDrawerClose() {
   setMobileOpen(false);
 }

return (
<section>
   <Card className="responsivecard">
      <CardContent>
         <Grid className="common_header grid current_user" container>
            <Grid lg={4} className="custom_grid">
               <Grid item>
                  <div className="d-flex items-center">
                     <h2>Transactions (12)</h2>
                  </div>
               </Grid>
            </Grid>
            <Grid lg={8} className="custom_grid">
               <Grid container spacing={1}>
                  <Grid item lg={3.7} md={6}>
                     <Search data={TableData} onSearch={handleSearch} terms={terms} />
                  </Grid>
                  <Grid item lg={3.7} md={6}>
                     <DyanamicDatePicker />
                  </Grid>
                  <Grid item lg={0.8}>
                     <Tooltip title="Filter">
                        <IconButton onClick={handleClick} className="icon_btn " variant="outlined">
                           <FilterIcon />
                        </IconButton>
                     </Tooltip>
                     {/* filter popover */}
                     <Popover className="date_popover" id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
                        <div className="date_popover_inner">
                        <Select className={type == "type" ? "select-box mb-20" : 'select-box-select mb-20'}
                          value={type}
                              onChange={handleChangeType} fullWidth>
                              <MenuItem selected disabled value={"type"}>
                              Filter by Transaction Type
                              </MenuItem>
                              <MenuItem value={0}>IMPS</MenuItem>
                              <MenuItem value={1}>NEFT</MenuItem>
                              <MenuItem value={2}>RTGS</MenuItem>
                           </Select>
                           <Select className={method == "Method" ? "select-box mb-20" : 'select-box-select mb-20'}
                            value={method}
                              onChange={handleChangeMethod} fullWidth>
                              <MenuItem selected disabled value={"Method"}>
                              Filter by Status
                              </MenuItem>
                              <MenuItem value={0}>Success</MenuItem>
                              <MenuItem value={1}>Failed</MenuItem>
                              <MenuItem value={2}>Pending</MenuItem>
                           </Select>
                           <div>
                              <Button className="datefilter_btn" variant="outlined" onClick={handleClose}>
                                 Submit
                              </Button>
                           </div>
                        </div>
                     </Popover>
                  </Grid>
                  <Grid item lg={0.8}>
                     <Tooltip title="Refresh">
                        <IconButton className="icon_btn" variant="outlined">
                           <RefreshIcon />
                        </IconButton>
                     </Tooltip>
                  </Grid>
                  <Grid item lg={2.8} md={3.9}>
                     <Button startIcon={<FileIcon />}
                     className="export_btn w-100"
                     variant="outlined"
                     >
                     Export as Excel
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
         <Div>
            <Table className="common_table">
            <TableHead>
                <TableRow>
                  <TableCell>Txn.Id </TableCell>
                  <TableCell><TableSortLabel>Created On </TableSortLabel>   </TableCell>
                  <TableCell><TableSortLabel>Recipient</TableSortLabel></TableCell>
                  <TableCell><TableSortLabel>Mobile No</TableSortLabel></TableCell>
                  <TableCell><TableSortLabel>Txn.Type</TableSortLabel>  </TableCell>
                  <TableCell className="amt_head"> Txn.Amt (₹)</TableCell>
                  <TableCell className="amt_head"> Convenience Fee (₹) </TableCell>
                  <TableCell className="amt_head">Total Amt (₹) </TableCell>
                   <TableCell> Status </TableCell>
                  <TableCell> Action </TableCell>
                </TableRow>
              </TableHead>
               <TableBody>
                  {loading ? (
                  <SkeletonTableLoader tablecell="7" row="8" />
                  ) : filteredData.length === 0 ? (
                  <NoRecordsFound tablecell="7" />
                  ) : (
                  filteredData.map((item, i) => {
                  return (
                     <TableRow key={i}>
                     <TableCell>{item?.transactionId}</TableCell>
                     <TableCell>{item?.dateTime} {item?.time} </TableCell>
                     <TableCell className="truncate">
                     <Tooltip title={item?.sender}>
                       {item?.sender}
                       </Tooltip>
                     </TableCell>
                     <TableCell>{item?.mobile}</TableCell>
                     <TableCell>{item?.method}</TableCell>
                     <TableCell  className="amt_cell">{item?.transAmt}</TableCell>
                     <TableCell className="amt_cell">
                       {item?.amount}
                     </TableCell>
                     <TableCell className="amt_cell">
                       {item?.balance}
                     </TableCell>
                     

                     <TableCell>
                       {item?.status == 1 ? (
                         <span className="admin_active_status">
                           <span className="active_dot"></span> Success
                         </span>
                       ) : item?.status == 2 ? (
                         <span className="admin_inactive_status">
                           <span className="inactive_dot"></span> Failure
                         </span>
                       ) : (
                         <span className="admin_pending_status">
                           <span className="pending_dot"></span> Pending
                         </span>
                       )}
                     </TableCell>
                     <TableCell>
                     <Tooltip title="Receipt">
                     <span className="receipt_icon"  onClick={(event) => handleClickedRecipt(event, i)}><FaReceipt /></span>

                        </Tooltip>
                     </TableCell>
                   </TableRow>
                  ); 
                  })
                  )}
               </TableBody>
               <TableFooter>
                  <TablePagination rowsPerPageOptions={[5, 10, 25]} count={15} rowsPerPage={5} page={1} />
               </TableFooter>
            </Table>
         </Div>
      </CardContent>
   </Card>
   <TransactionReceipt handleDrawerClose={handleDrawerClose} mobileOpen={mobileOpen} handleDrawerToggle={handleClickedRecipt}/>
</section>
);
};

export default TransactionList;