import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Drawer,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Div from "@jumbo/shared/Div";


const SavingsUserView = ({
  handleDrawerClose,
  mobileOpen
}) => {
    const status=1;
    const kyc=1;
  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpen}
        onClose={handleDrawerClose}
      >
        <Box sx={{ width: "520px" }}>
          <div className="sidebar_header">
            <h1>View Saving User</h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>

          <div >
            <div className="info_title">
            <p className="profile_header">Personal Info</p>
            </div>
            <div>
              <div className="d-flex items-center profile_info w-100">
                <div className="profile_info_name_details w-100">
                  <h2>John Rio - 3696086371</h2>
                  <h3>
                    {" "}
                    {status == 1 ? (
                            <span className="admin_active_status">
                              <span className="active_dot"></span> Active
                            </span>
                          ) : (
                            <span className="admin_inactive_status">
                              <span className="inactive_dot"></span> Inactive
                            </span>
                          )}
                  </h3>
                </div>
              </div>
              <Div>
                <Table className="common_table my_profile">
                  <TableBody>
                    <TableRow>
                      <TableCell>Mobile No</TableCell>
                      <TableCell className="bold">95667 89650</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Alternate Mobile No
                      </TableCell>
                      <TableCell className="bold">92845 02080</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Email Id
                      </TableCell>
                      <TableCell className="bold">johnrio@gmail.com</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ verticalAlign: "top" }}
                        className="font-thin"
                      >
                        Address
                      </TableCell>
                      <TableCell className="bold">
                        324/7A, Varatharaja Perumal Street, Mumbai, Maharashtra
                        6250007
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Div>
            <div className="info_title">
            <p className="profile_header">Account Info</p>
            </div>
              <Div>
                <Table className="common_table my_profile">
                  <TableBody>
                        <TableRow>
                          <TableCell>Account No</TableCell>
                          <TableCell className="bold">543210987654321</TableCell>
                        </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ verticalAlign: "top" }}
                        className="font-thin"
                      >
                        IFSC Code
                      </TableCell>
                      <TableCell className="bold">
                        HDFCB000K12
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Div>
              <div className="info_title">
              <div>
            <p className="profile_header">KYC Status</p>
             </div>
               <div>
                  <h3>
                  {" "}
                  {kyc == 1 ? (
                            <span className="verified_txt">Verified</span>
                          ) : (
                            <span className="pending_txt">Pending</span>
                          )}
                  </h3>
                   </div>
                </div>
              </div>
                  {kyc == 1 ? (
                     <Div>
                     <Table className="common_table my_profile">
                       <TableBody>
                    <TableRow>
                          <TableCell>PAN No</TableCell>
                          <TableCell className="bold">CJKPG5960P</TableCell>
                        </TableRow>
                    <TableRow>
                          <TableCell>Aadhar No</TableCell>
                          <TableCell className="bold">1212 3247 9874</TableCell>
                        </TableRow>
                        </TableBody>
                </Table>
              </Div>
                     ) : (
                <Div>
                    <Table className="common_table my_profile">
                       <TableBody> 
                        <TableRow>
                          <TableCell>PAN No</TableCell>
                          <TableCell className="bold">Not Updated</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Aadhar No</TableCell>
                          <TableCell className="bold">Not Updated</TableCell>
                        </TableRow>
                        </TableBody>
                </Table>
              </Div>
                        )}
            </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default SavingsUserView;
