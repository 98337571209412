import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";
import { useContext } from 'react';
import {  TextField, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import {useFormik} from "formik";
import * as yup from 'yup';
import Div from "@jumbo/shared/Div";
import { AuthenticationContext } from 'app/utils/constants/AuthContext';
import useApp from 'app/hooks/useApp';
import { AppSvgLogon, LoginBGSVG, LOGINBGSVG1 } from 'app/assets/svg/Svg';
import CREDITS from '../../../assets/nsdl_logo.png'

const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
  });

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const appData = useApp();
    const {  login} = useContext(AuthenticationContext)

    const formik = useFormik({
        validateOnChange: true,
        initialValues: {
          email: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          console.log("Form submitted:", values);
          navigate('/current-user/reset-password') 
        },
      });


  
    return (
    <Div className='current_user_session '>
        <div className='h-100'>
           <div className='app_logo_svg '>
              <AppSvgLogon  />
           </div>
           <div className='current_user_session_card'>
              <form className='h-100'  onSubmit={formik.handleSubmit}  noValidate autoComplete="off">
                 <div className="current_session_form justify-between h-100">
                    <div className='w-100 '>
                       <Typography  sx={{textAlign:"center",mt:1}} className='login_greeting'>Forgot Password?</Typography>
                       <Typography textAlign={"center"}>
                          No worries, we’ll send you reset instructions.
                        </Typography>
                       <Div sx={{ mt: 4 }}>
                       <Typography className="login_label">
                          Email Address <span className="required">*</span>
                       </Typography>
                       <TextField fullWidth  name="email" placeholder="Enter Email Address"   type="email"  variant="outlined"
                     onChange={formik.handleChange}
                     error={  formik.touched.email &&  Boolean(formik.errors.email) }
                    helperText={ formik.touched.email && formik.errors.email } />
                </Div>
         </div>
           <Div  className='w-100'>
           <LoadingButton  className="login_btn"  fullWidth  type="submit"  variant="contained"  size="large" sx={{ mb: 3 }}>
           Reset Password
           </LoadingButton>
           <Typography textAlign={"center"}>
             <Link className="back_to_login"  underline="none" href="/current-user/login" > Back to Login</Link>
            </Typography>
           </Div>
        </div>
        </form>
     </div>
     <div className="credits_logo mt-25" >
        <p>Powered by</p>
        <img width={98} height={40} src={CREDITS} alt="credits" />
     </div>
     </div>
     <div className='charcter_svg'>
        <LOGINBGSVG1 />
     </div>
     <div className='mobile_svg'>
        <LoginBGSVG />
     </div>
     </Div>
      
    );
};

export default ForgotPassword;

