import React from 'react';
import ReactApexChart from 'react-apexcharts';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CircleIcon from '@mui/icons-material/Circle';

const CurrentUserChart = () => {
  const activeUsers = 279;
  const inactiveUsers = 118;

  const active = '#28982675';
  const inactive = '#ed232375';
  const totalUsers = activeUsers + inactiveUsers;

  const options = {
    chart: {
      id: 'donut-chart',
    },
    labels: ['Active', 'Inactive'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 150,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
          size: '75%', // Adjust the size of the donut
          background: 'transparent',
          strokeWidth: '8%', // Adjust the thickness of the circular bar
        },
        title: {
          text: totalUsers.toString(),
          offsetY: 20, // Adjust the vertical position inside the donut
          style: {
            fontSize: '20px',
            color: '#000000', // Adjust the color of the total users count
          },
        },
      },
    },
    legend: {
      show: false,
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: '14px',
      markers: {
        width: 10,
        height: 10,
      },
    },
    colors: [active, inactive],
  };

  const series = [activeUsers, inactiveUsers];

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ position: 'relative' }}>
        <ReactApexChart options={options} series={series} type="donut" height={200} />
        <div
          style={{
            position: 'absolute',
            top: '45%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
          }}
        >
          <div style={{ fontSize: '32px', color: '#000000', fontWeight: '700', fontFamily: "Inter" }}>{totalUsers}</div>
          <div>Total Users</div>
        </div>
      </div>
      <div style={{ position: 'relative', marginLeft: '20px',marginTop:'30px' }}>
        <div style={{ textAlign: 'left' }}>
          <div style={{ display: 'inline-block' }}>
            <p style={{ color: active, marginLeft: '5px', fontWeight: '600' }}><CircleIcon sx={{ height: '12px' }} /> Active - {activeUsers}</p>
          </div>
          <div style={{ display: 'inline-block' }}>
            <p style={{ color: inactive, marginLeft: '5px', fontWeight: '600',marginTop:'0' }}><CircleIcon sx={{ height: '12px' }} /> Inactive - {inactiveUsers}</p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default CurrentUserChart;