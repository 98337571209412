import Div from '@jumbo/shared/Div'
import { Autocomplete, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField, ThemeProvider, Typography, createTheme } from '@mui/material'
import React from 'react'
import { useState } from 'react';

const theme = createTheme({
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#333", // Change the color of the unselected radio button
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: "#666666", // Change the color of the radio label
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
    },
  });
const StandingInstruct = () => {

    const [selectedValue, setSelectedValue] = useState("option1");
    const [age, setAge] = useState(10);
    const SelecthandleChange = (event) => {
        setAge(event.target.value);
      };

    const Bank = [
        {
            label:"HDFC Bank"
        }, 
        {
            label:"SBI Bank"
        }, 
        {
            label:"IOB"
        }, 
    ]
  return (
    <div>
        <Div>
        <Typography className="lette_space_normal">
        We would be moving funds out of the NSDL Payment Bank account every day into our Current Account maintained with to meet our day to day related obligations/ expenses.
          </Typography>
          <Typography sx={{mt:1}} className="lette_space_normal">
          However in the scenario , we are not able to do so for any reason, technical or other unavoidable circumstances not related to normal course of the process, we request and hereby authorize NSDL Payment Bank to move such quantum of funds as specified in the annexure from our accounts in the NSDLPB bank to the following account:
          </Typography>

        </Div>
        <div className='create_form_width'>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
         
        Name of the Bank
      </Typography>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={Bank}
        renderInput={(params) => (
          <TextField fullWidth {...params} />
        )}
      />

        </Div>

        <Div sx={{mt:3}}>
        <Typography className="from_label">
       
       Account No
      </Typography>
      <TextField
        className="form_new_style"
        id="outlined-basic"
        variant="outlined"
        fullWidth
      />
        </Div>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
       Name of the Account Holder
      </Typography>
      <TextField
        className="form_new_style"
        id="outlined-basic"
        variant="outlined"
        fullWidth
      />
        </Div>
        <Div sx={{ mt: 3 }}>
      <ThemeProvider theme={theme}>
        <Typography className="from_label">
          Account Type
        </Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="Current"
            className="radio_form"
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="Current"
          />
          <FormControlLabel
            value="Savings"
            className="radio_form "
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="Savings"
          />
          <FormControlLabel
            value="Others"
            className="radio_form "
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="Others"
          />
         
        </RadioGroup>
      </ThemeProvider>
    </Div>  
    <Div sx={{mt:3}}>
        <Typography className="from_label">
      
      Other Account type
      </Typography>
      <TextField
        className="form_new_style"
        id="outlined-basic"
        variant="outlined"
        fullWidth
      />
        </Div>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
      IFSC Code
      
      </Typography>
      <TextField
        className="form_new_style"
        id="outlined-basic"
        variant="outlined"
        fullWidth
      />
        </Div>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
        
        Branch Name
      </Typography>
      <TextField
        className="form_new_style"
        id="outlined-basic"
        variant="outlined"
        fullWidth
      />
        </Div>

        </div>
        <Div sx={{mt:3}}>
        <Typography  className="lette_space_normal mb-20">
        We would also request that this transfer be affected at the time as specified in the annexure in case due to the reasons stated above, we have not been able to move the funds out. The purpose stated above is to ensure our smooth daily operations. We are also enclosing herewith a cancelled cheque leaf of the abovesaid account.
        </Typography>
        <Typography sx={{mt:2}} className="lette_space_normal">
        We wish to set-up / already have a Standing Instruction for our account/’s held with your bank. We hereby give consent to the Bank to set-up / modify the Standing Instructions and threshold limits as mentioned below.
        </Typography>
        </Div>
        <Div  sx={{mt:3}}>
            <div className="d-flex items-center" style={{justifyContent:"flex-end"}}>
                <p>No of rows</p>
                <Select className="ml-10" style={{width:"70px"}} labelId="demo-simple-select-label"id="demo-simple-select"value={age}onChange={SelecthandleChange}>
                    <MenuItem value={10}>2</MenuItem>
                    <MenuItem value={20}>4</MenuItem>
                    <MenuItem value={30}>6</MenuItem>
                    </Select>
            </div>
        </Div>
        <Div sx={{mt:3}}>
        <table className="w-100 dyanmic_table">
            <thead>
                <tr>
                    <td>S.No</td>
                    <td>Account Number</td>
                    <td>Threshold</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>
                    <TextField  className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Account Number" />
                    </td>
                    <td>
                    <TextField  className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Threshold" />
                    </td>
                </tr>
            </tbody>




        </table>
        </Div>
        <Div sx={{mt:3}}>
            <h3 className='info_med_text'><strong>Timing for Transfer:</strong>  After 10 PM and at EOD</h3>
        </Div>
        <Div sx={{mt:3}}>
        <Typography  className="lette_space_normal mb-20">
        Bank should ensure at all times, that the threshold amount mentioned against each account should be maintained in our respective account/’s at EOD post transfer of funds. This arrangement will be active till customer advises the Bank to Terminate the service or Bank decides to terminate the same due to suspected fraud/unauthorized activities
        </Typography>
        </Div>
      


    </div>
  )
}

export default StandingInstruct