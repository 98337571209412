import { useState } from "react";
import Button from "@mui/material/Button";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Popover,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { MobileDatePicker } from "@mui/x-date-pickers";
import { FiCalendar } from "react-icons/fi";
import { ToastServices } from "app/services/toastServices";

const DyanamicDatePicker = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [error, setError] = useState('');
    const [formattedDates, setFormattedDates] = useState('');
    const handleFromDateChange = (date) => {
      setFromDate(date);
      validateDates(date, toDate);
    };
  
    const handleToDateChange = (date) => {
      setToDate(date);
      validateDates(fromDate, date);
    };
  
    const validateDates = (from, to) => {
      if (from && to && dayjs(to).isBefore(from)) {
        setError('To date cannot be smaller than From date');
      } else {
        setError('');
      }
    };
  
    const handlePostApi = () => {
  
      if (error) {
       
        
        ToastServices.ErroToast(error)
      } 
      else {
        console.log('From Date:', dayjs(fromDate).format('DD/MM/YYYY')   );
        console.log('To Date:', dayjs(toDate).format('DD/MM/YYYY'));
        setFormattedDates(
          `${fromDate ? dayjs(fromDate).format('DD/MM/YYYY') : '--'} to ${
            toDate ? dayjs(toDate).format('DD/MM/YYYY') : '---'
          }`
        );
        handleClose()
      }
  };


  return (
     
    <div className="marginsettings">
     

      <Popover
       className="date_popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        
      
      >
       <div  className="date_popover_inner">
        
         <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="date_input">
            <MobileDatePicker
          label="From Date"
          format="DD/MM/YYYY"
          
          value={fromDate}
          onChange={handleFromDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
         <FiCalendar className="icon date-picker-icon__icon" />
            </div>
            <div className="date_input">
            <MobileDatePicker
          label="To Date"
          format="DD/MM/YYYY"
          value={toDate}
          onChange={handleToDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
          {error && <small style={{ color: 'red' }}>{error}</small>}
          <FiCalendar className="icon date-picker-icon dteicon" />
            </div>
       </LocalizationProvider>
     
    <div > 
    <Button className="datefilter_btn" variant="outlined" onClick={handlePostApi}> Submit</Button>
    </div>
       </div>
      </Popover>
      <FormControl  className="w-100"  variant="outlined">
            
            <OutlinedInput
              onClick={handleClick}
              placeholder=" Start Date - End Date"
              id="outlined-adornment-password"
              type={"text"}
              className="bg-white password_input widthsettings"
              readOnly
              value={formattedDates}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClick}
                    edge="end"
                  >
                  <FiCalendar className="icon" />
                  </IconButton>
                </InputAdornment>
              }
            
            />
          </FormControl>
    
    </div>
  )
}


export default DyanamicDatePicker