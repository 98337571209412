import React, { useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { useFormik } from "formik";
import ClearIcon from "@mui/icons-material/Clear";

const pinValidation = yup
  .array()
  .of(
    yup
      .string()
      .matches(/^\d+$/, "Each PIN digit should be a digit")
      .length(1, "Each PIN digit must be exactly 1 digit")
  )
  .required("PIN is required")
  .test(
    "len",
    "PIN must be exactly 4 digits",
    (val) => val && val.join("").length === 4
  );
const validationSchema = yup.object({
  pin: pinValidation,
  cpin: pinValidation.test(
    "match",
    "PIN and Confirm PIN must match",
    function (value) {
      return value.join("") === this.parent.pin.join("");
    }
  ),
  security: yup.object().shape({
    label: yup.string().required("Security question is required"),
  }),
  answer: yup.string().trim().required("Security answers is required"),
});
// Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} />;
};
const ManagePins = ({ managePinsOpen, handleDrawerClose }) => {
  const Questions = [
    { label: "What is your pet dog name?" },
    { label: "what is your favourite food?" },
    { label: "what is your favourite Sport?" },
  ];
  const formik = useFormik({
    initialValues: {
      pin: Array.from({ length: 4 }, () => ""),
      cpin: Array.from({ length: 4 }, () => ""),
      security: null,
      answer: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
      console.log("Form submitted:", values);
    },
  });
  const Setformik = useFormik({
    initialValues: {
      pin: Array.from({ length: 4 }, () => ""),
      cpin: Array.from({ length: 4 }, () => ""),
      security: null,
      answer: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
      console.log("Form submitted:", values);
    },
  });
  // useState
  const [value, setValue] = useState(0);
  // functions

  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const Ref = [useRef(), useRef(), useRef(), useRef()];
  const handleInputChange = (e, index, formik, fieldName, valueArray, refs) => {
    const inputValue = e.target.value;
    const newArray = [...valueArray];
    if (inputValue.match(/^\d+$/)) {
      newArray[index] = inputValue;
      formik.setFieldValue(fieldName, newArray);
      if (index < 3 && inputValue) {
        refs[index + 1].current.focus();
      }
    } else if (inputValue === "" && index > 0) {
      newArray[index] = inputValue;
      formik.setFieldValue(fieldName, newArray);
      refs[index - 1].current.focus();
    } else if (inputValue === "" && index === 0) {
      newArray[index] = inputValue;
      formik.setFieldValue(fieldName, newArray);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={managePinsOpen}
        onClose={handleDrawerClose}
      >
        <Box sx={{ width: "520px" }}>
          <div className="sidebar_header">
            <h1>Manage PIN</h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>
          <div className="sideform_container pt-0">
            <Box>
              <Tabs
                disableRipple
                className="tabs_section pin"
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  disableRipple
                  className="tab_title"
                  label="Set New PIN"
                  {...a11yProps(0)}
                />
                <Tab
                  disableRipple
                  className="tab_title ml-50"
                  label="Change PIN"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <form
                onSubmit={formik.handleSubmit}
                noValidate
                autoComplete="off"
              >
                <Div>
                  <Typography className="from_label mb-0">
                    Set PIN <span className="required">*</span>
                  </Typography>
                  <div className="Otp_input_box small">
                    {formik.values.pin.map((_, index) => (
                      <div key={index} className="otp_input small">
                        <TextField
                          name={`pin[${index}]`}
                          variant="outlined"
                          margin="normal"
                          type="text"
                          inputProps={{
                            maxLength: 1,
                          }}
                          inputRef={inputRefs[index]}
                          error={
                            formik.touched.pin && formik.errors.pin
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              index,
                              formik,
                              "pin",
                              formik.values.pin,
                              inputRefs
                            )
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {formik.touched.pin && formik.errors.pin && (
                    <div className="error_text text-danger">{formik.errors.pin}</div>
                  )}
                </Div>
                <Div sx={{ mt: 1 }}>
                  <Typography className="from_label mb-0">
                    Confirm PIN <span className="required">*</span>
                  </Typography>
                  <div className="Otp_input_box small">
                    {formik.values.cpin.map((_, index) => (
                      <div key={index} className="otp_input small">
                        <TextField
                          name={`cpin[${index}]`}
                          variant="outlined"
                          margin="normal"
                          type="text"
                          inputProps={{ maxLength: 1 }}
                          inputRef={Ref[index]}
                          error={
                            formik.touched.cpin && formik.errors.cpin
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleInputChange(
                              e,
                              index,
                              formik,
                              "cpin",
                              formik.values.cpin,
                              Ref
                            )
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {formik.touched.cpin && formik.errors.cpin && (
                    <div className="error_text text-danger">
                      {formik.errors.cpin}
                    </div>
                  )}
                </Div>
                <Typography
                  className="pin_text"
                  sx={{ mt: 2 }}
                  variant={"h5"}
                  fontWeight={"bold"}
                >
                  Answer the security questions to reset your PIN
                </Typography>
                <Div sx={{ mt: 3 }}>
                  <Typography className="from_label">
                     Security Question <span className="required">*</span>
                  </Typography>
                  <Autocomplete
                    disablePortal
                    name="security"
                    options={Questions}
                    getOptionLabel={(option) => option.label}
                    PaperComponent={CustomPaper}
                    fullWidth
                    value={formik.values.security}
                    onChange={(_, newValue) =>
                      formik.setFieldValue("security", newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                      placeholder="Select Security Question"
                        className="form_new_style form_selct"
                        error={
                          formik.touched.security &&
                          Boolean(formik.errors.security)
                        }
                        helperText={
                          formik.touched.security &&
                          formik.errors.security?.label
                        }
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                   {formik.touched.security && formik.errors.security && (
                    <div className="error_text text-danger">
                      security cannot be null </div>
                  )}
                </Div>
                <Div sx={{ mt: 3 }}>
                  <Typography className="from_label">
                     Security Answer <span className="required">*</span>
                  </Typography>
                  <textarea
                    name="answer"
                    className="w-100"
                    onChange={formik.handleChange}
                    rows="2"
                    placeholder="Enter Your Answer"
                  />
                  {formik.touched.answer && formik.errors.answer && (
                    <div className="error_text text-danger">
                      {formik.errors.answer}
                    </div>
                  )}
                </Div>
                <Div>
                  <Button
                    className="primary_btn w-200 "
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                  >
                    Set PIN
                  </Button>
                </Div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <div>
                <form  onSubmit={Setformik.handleSubmit}>
                  <Div>
                    <Typography className="from_label mb-0">
                      Old PIN <span className="required">*</span>
                    </Typography>
                    <div className="Otp_input_box small">
                      {Setformik.values.pin.map((_, index) => (
                        <div key={index} className="otp_input small">
                          <TextField
                            name={`pin[${index}]`}
                            variant="outlined"
                            margin="normal"
                            type="text"
                            inputProps={{
                              maxLength: 1,
                            }}
                            inputRef={inputRefs[index]}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                Setformik,
                                "pin",
                                Setformik.values.pin,
                                inputRefs
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </Div>
                  <Div sx={{ mt: 1 }}>
                    <Typography className="from_label mb-0">
                      Enter PIN <span className="required">*</span>
                    </Typography>
                    <div className="Otp_input_box small">
                      {Setformik.values.cpin.map((_, index) => (
                        <div key={index} className="otp_input small">
                          <TextField
                            name={`cpin[${index}]`}
                            variant="outlined"
                            margin="normal"
                            type="text"
                            inputProps={{
                              maxLength: 1,
                            }}
                            inputRef={Ref[index]}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                Setformik,
                                "cpin",
                                Setformik.values.cpin,
                                Ref
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </Div>
                  <Div sx={{ mt: 1 }}>
                    <Typography className="from_label mb-0">
                      Confirm PIN <span className="required">*</span>
                    </Typography>
                    <div className="Otp_input_box small">
                      {Setformik.values.cpin.map((_, index) => (
                        <div key={index} className="otp_input small">
                          <TextField
                            name={`cpin[${index}]`}
                            variant="outlined"
                            margin="normal"
                            type="text"
                            inputProps={{
                              maxLength: 1,
                            }}
                            inputRef={Ref[index]}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                index,
                                Setformik,
                                "cpin",
                                Setformik.values.cpin,
                                Ref
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </Div>
                  <Div>
                    <Button
                      className="primary_btn w-200 "
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                    >
                      Change PIN
                    </Button>
                  </Div>
                </form>
              </div>
            </CustomTabPanel>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default ManagePins;
