// import axios from 'axios'
// import jwtdecode from "jwt-decode";
// import dayjs from 'dayjs'


// const baseURL = 'http://192.168.43.41:4000'

// let authTokens = sessionStorage.getItem('refershtoken') ;


// const axiosInstance = axios.create({
//   baseURL,
//   headers:{Authorization: `Bearer ${authTokens}`}
// });

// axiosInstance.interceptors.request.use(async req => {

//   if(!authTokens){
//       authTokens = localStorage.getItem('refershtoken') 
//       req.headers.Authorization = `Bearer ${authTokens}`
//   }

//   const user = jwtdecode(authTokens)
//   console.log("user", user)
//   const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;



//   if(!isExpired) return req 

 

//   const response = await axios.post(`${baseURL}/current-user/refresh-token`, {
//     refresh_token: authTokens
//     });

//   sessionStorage.setItem('refershtoken', response.data.refreshToken)
//   req.headers.Authorization = `Bearer ${response.data.refreshToken}`
//   console.log("......................................................................",response.data.message)

//   return req
// })




// export default axiosInstance;



import axios from 'axios'
import jwtdecode from "jwt-decode";
import dayjs from 'dayjs'


const apiBaseUrl = 'https://api.example.com';



const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
 


});

let tokenRefreshTimer;


const refershtokenapi = () =>{


  return axiosInstance.post('/current-user/refresh-token').then((response)=>{
    const newAccessToken = response.data.refreshToken;
    const newExpiryTime = jwtdecode(response.data.refreshToken).exp

      sessionStorage.setItem('refreshToken', newAccessToken);
      sessionStorage.setItem('newExpiryTime', newExpiryTime);

      setupTokenRefreshTimer(newExpiryTime);


      return newAccessToken;



  }).catch((refreshError) => {
    // Handle token refresh error
    console.error('Token refresh failed:', refreshError);
    // You might want to redirect to the login page or handle this in another way
    throw refreshError;
  });


                     
} 


const setupTokenRefreshTimer = (expiryTime) => {
  // Calculate the time until the token expiration
  const timeUntilRefresh = expiryTime - Date.now() - 5000; // Refresh 5 seconds before expiry

  // Clear the existing timer, if any
  if (tokenRefreshTimer) {
    clearTimeout(tokenRefreshTimer);
  }

  // Set up a new timer to refresh the token
  tokenRefreshTimer = setTimeout(() => {
    refershtokenapi();
  }, timeUntilRefresh);
};


axiosInstance.interceptors.request.use(
  (config) => {
    // Check if the access token is present and not expired
    const accessToken = sessionStorage.getItem('refreshToken');
    const expiryTime = localStorage.getItem('access_token_expiry');

    if (accessToken && expiryTime && Date.now() < parseInt(expiryTime, 10)) {
      // Set the Authorization header with the access token
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      // Access token expired or not present, initiate token refresh
      return refershtokenapi().then((newAccessToken) => {
        // Update the Authorization header with the new access token
        config.headers.Authorization = `Bearer ${newAccessToken}`;
        return config;
      });
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Unauthorized, refresh the token
      return refershtokenapi().then((newAccessToken) => {
        // Update the original request with the new access token
        error.config.headers.Authorization = `Bearer ${newAccessToken}`;
        // Retry the original request
        return axiosInstance(error.config);
      });
    }

    return Promise.reject(error);
  }
);


const initialExpiryTime = sessionStorage.getItem('access_token_expiry');
if (initialExpiryTime) {
  setupTokenRefreshTimer(parseInt(initialExpiryTime, 10));
}

export default axiosInstance;