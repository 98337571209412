import React, { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Drawer,
  Grid,
  Card,
  Avatar,
  Button,
  MenuItem,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IoIosArrowForward } from "react-icons/io";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import { GoBell } from "react-icons/go";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Div from "@jumbo/shared/Div";
import MarkunreadIcon from '@mui/icons-material/Markunread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { MdMarkunread } from "react-icons/md";
import { orange } from '@mui/material/colors';

const NotificationsDropdownAdmin = () => {
  const [staticNotifications, setStaticNotifications] = useState([
    {
      id: 1,
      time: "43 Days Ago",
      body: "Commission Received",
      img: "https://www.shutterstock.com/image-vector/discount-percentage-line-icon-pixel-260nw-357646325.jpg",
      read: false,
    },
    {
      id: 2,
      time: "43 Days Ago",
      body: "Transactions Success",
      img: "https://www.shutterstock.com/image-illustration/indian-rupee-currency-symbol-inr-260nw-2287502777.jpg",
      read: false,
    },
    {
      id: 3,
      time: "46 Days Ago",
      body: "Commission Received",
      img: "https://www.shutterstock.com/image-vector/discount-percentage-line-icon-pixel-260nw-357646325.jpg",
      read: true,
    },
    {
      id: 4,
      time: "46 Days Ago",
      body: "Transactions Success",
      img: "https://www.shutterstock.com/image-illustration/indian-rupee-currency-symbol-inr-260nw-2287502777.jpg",
      read: true,
    },
    {
      id: 5,
      time: "46 Days Ago",
      body: "Commission Received",
      img: "https://www.shutterstock.com/image-vector/discount-percentage-line-icon-pixel-260nw-357646325.jpg",
      read: false,
    },
  ]);

  const [mobileOpens, setMobileOpens] = React.useState(false);
  const [UnreadCount, setUnreadCount] = React.useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setUnreadCount(staticNotifications.filter(notification => !notification.read).length);
  }, [staticNotifications]);

  const navigate = useNavigate();

  const handleView = () => {
    navigate("/notifications");
    handleDrawerClosed();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDrawerToggled() {
    setMobileOpens(!mobileOpens);
  }
  function handleDrawerClosed() {
    setMobileOpens(false);
  }

  const handleMarkReadToggle = (notificationId) => {
    const updatedNotifications = staticNotifications.map(notification =>
      notification.id === notificationId ? { ...notification, read: !notification.read } : notification
    );
    setStaticNotifications(updatedNotifications);
  };

  return (
    <div>
      <Div sx={{ width: "35px", height: "20px", cursor: "pointer", marginRight: '12px' }}>
        <Badge
          color="secondary"
          badgeContent={UnreadCount}
          max={99}
          onClick={handleDrawerToggled}
          sx={{ width: "25px", height: "20px", cursor: "pointer" }}
        >
          <GoBell className="notifi_bell" style={{ width: "25px", height: "25px" }} color="action" />
        </Badge>
      </Div>

      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpens}
        onClose={handleDrawerToggled}
      >
        <Box className="sidenave_box" sx={{ width: "520px" }}>
          <div className="sidebar_header">
            <h1>Notifications</h1>
            <ClearIcon
              sx={{ cursor: "pointer" }}
              onClick={handleDrawerClosed}
            />
          </div>
          <Grid item lg={5} xs={12}>
            <Card
              className=""
              sx={{ height: "785px", padding: "0px 10px", borderRadius: '0 !important' }}
            >
              <div className="recent_search_result">
                {staticNotifications.map((notification) => (
                  <div key={notification.id} className="main_results">
                    <div className="result_card space-between d-flex items-start not_div">
                      <div className="d-flex items-center not_div">
                        <div className="result_logo_bg">
                          <Avatar
                            alt=""
                            src={notification.img}
                            sx={{ width: 55, height: 55 }}
                          />
                        </div>
                        <div className="ml-15 resultcard_details">
                          <p>{notification.time}</p>
                          <h4>{notification.body}</h4>
                        </div>
                      </div>
                      <IconButton onClick={() => handleMarkReadToggle(notification.id)} color="primary">
                        {notification.read ? <MarkEmailReadIcon sx={{ color: orange[800] }} /> : <MarkunreadIcon sx={{ color: orange[500] }} />}
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>

              <Div className="view_not">
                <Typography
                  onClick={handleView}
                  fontSize={17}
                  sx={{
                    "&:hover": {
                      color: "gray",
                      "& .MuiIconButton-root": {
                        color: "gray",
                      },
                    },
                  }}
                >
                  View all Notifications{" "}
                  <IconButton sx={{ color: "#2168F0" }}>
                    <IoIosArrowForward />
                  </IconButton>
                </Typography>
              </Div>
            </Card>
          </Grid>
        </Box>
      </Drawer>
    </div>
  );
};

export default NotificationsDropdownAdmin;