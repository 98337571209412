import React, { useState, useEffect } from "react";
import {
  CardContent,
  Card,
  Grid,
  TableFooter,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  TableSortLabel,
  Button,
  MenuItem,
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";
import { useSelector } from "react-redux";
import { config } from "app/config/main";
import moment from "moment";
import DyanamicDatePicker from "app/components/DatePicker/DyanamicDatePicker";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import MarkunreadIcon from '@mui/icons-material/Markunread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { MdMarkunread } from "react-icons/md";
import { orange } from '@mui/material/colors';

const NotificationList = () => {
  const token = useSelector((state) => state.auth?.accessToken);
  const notifyData = useSelector((state) => state.notification?.data);
  const [data, setData] = useState(notifyData);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [markAsReadVisible, setMarkAsReadVisible] = useState(true);

  const handleMarkasRead = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    await fetch(`${config.apiUrl}/push/mark-all-read`, options)
      .then((res) => res.json())
      .then(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
  };

  useEffect(() => {
    handleMarkasRead();
  }, []);

  const staticNotifications = [
    {
      id: 1,
      body: "Commission Received",
      status: 1,
      description: `Commission ₹ 0.15 received for transaction. The Transaction ID is 123456789`,
    },
    {
      id: 2,
      body: "Transactions Success",
      status: 0,
      description: `Sabari has just completed the transaction. The Transaction ID is 123456789`,
    },
    {
      id: 3,
      body: "Commission Received",
      status: 1,
      description: `Commission ₹ 0.75 received for transaction. The Transaction ID is 123456789`,
    },
    {
      id: 4,
      body: "Transactions Success",
      status: 0,
      description: `Ganesh has just completed the transaction. The Transaction ID is 123456789`,
    },
    {
      id: 5,
      body: "Commission Received",
      status: 1,
      description: `Commission ₹ 0.15 received for transaction. The Transaction ID is 123456789`,
    },
    {
      id: 6,
      body: "Transactions Success",
      status: 0,
      description: `Rahul has just completed the transaction. The Transaction ID is 123456789`,
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Toggle the visibility of "Mark All as Read" button based on the selected tab
    setMarkAsReadVisible(newValue === 0);
  };

  const handleView = () => {
    navigate("/transactionlist");
  };

  // Tabs
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  //for tooltip
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return (
    <Card className="responsivecard">
      <CardContent>
        <Grid
          className="common_header grid current_user"
          container
          spacing={2}
          sx={{ paddingBottom: "8px" }}
        >
          <Grid item lg={4} className="custom_grid">
            <Grid item>
              <div className="d-flex items-center">
                <h2> Notifications (46)</h2>
              </div>
            </Grid>
          </Grid>
          <Grid item lg={8} className="custom_grid">
          <Grid container spacing={1.5} justifyContent="flex-end">
  <Grid item xs={4} lg={4} md={6}>
    <DyanamicDatePicker dateRange={false} />
  </Grid>
  <Grid item xs={1} lg={0.8}>
    <Tooltip title="Refresh">
      <IconButton className="icon_btn" variant="outlined">
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  </Grid>
  <Grid item xs={4} lg={2.7} md={3.9} style={{ display: markAsReadVisible ? 'block' : 'none' }}>
    <Button className="export_btn w-100" variant="outlined">
      Mark All as Read
    </Button>
  </Grid>
</Grid>
          </Grid>
        </Grid>


        <Grid
          item
          lg={2}
          className="custom_grid"
          sx={{ width: "300px", marginBottom: "0px" }}
        >
          <Tabs
            disableRipple
            className="tabs_section pin"
            value={value}
            sx={{ marginTop: "0" }}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              disableRipple
              className="tab_title tab_Unread"
              label="Unread(44)"
              sx={{ marginTop: "0", padding: "0" }}
              {...a11yProps(0)}
            />
            <Tab
              disableRipple
              className="tab_title ml-50 tab_read"
              label="Read(2)"
              sx={{ marginTop: "0", padding: "0" }}
              {...a11yProps(1)}
            />
          </Tabs>
        </Grid>
        <CustomTabPanel value={value} index={0} >
          <Table className="common_table" id="tablePopover" >
            <TableHead sx={{paddingTop:'0px !important'}}>
              <TableRow>
                <TableCell >
                  <TableSortLabel>Received Date</TableSortLabel>
                </TableCell>
                <TableCell>
                  Title
                </TableCell>
                <TableCell> Description 
                </TableCell>
                <TableCell align="center" sx={{ marginLeft: "20px" }}>
                    Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staticNotifications.map((notification) => (
                <TableRow key={notification.id}>
                  <TableCell sx={{ width: "10px" }}>
                    {moment().format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>{notification.body}</TableCell>
                  <TableCell>{notification.description}</TableCell>
                  <TableCell sx={{ width: "10px" }} align="center">
                    <IconButton>
                    <MarkunreadIcon sx={{color: orange[300],padding:'0' }}/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={15}
                rowsPerPage={5}
                page={1}
              />
            </TableFooter>
          </Table>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Table className="common_table" id="tablePopover">
            <TableHead>
              <TableRow>
              <TableCell>
                  <TableSortLabel>Received Date</TableSortLabel>
                </TableCell>
                <TableCell>
                  Title
                </TableCell>
                <TableCell> Description 
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staticNotifications.map((notification) => (
                <TableRow key={notification.id}>
                  <TableCell>{moment().format("DD/MM/YYYY")}</TableCell>
                  <TableCell>{notification.body}</TableCell>
                  <TableCell>{notification.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={15}
                rowsPerPage={5}
                page={1}
              />
            </TableFooter>
          </Table>
        </CustomTabPanel>
      </CardContent>
    </Card>
  );
};

export default NotificationList;
