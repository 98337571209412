import React, { useState } from "react";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Select,
  Popover,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Search from "app/pages/app-pages/component/search";
import SkeletonTableLoader from "app/pages/app-pages/component/skeleton_loader/skeletonTableLoader";
import NoRecordsFound from "app/pages/app-pages/component/noRecordsFound";
import {
  ExpoIcon,
  FileIcon,
  FilterIcon,
  RefreshIcon,
  TrashIcon,
  UpdateIcon,
} from "app/assets/svg/Svg";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DyanamicDatePicker from "app/components/DatePicker/DyanamicDatePicker";
import Filter from "app/components/Filter/Filter";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import SavingsUserView from "./SavingsUserView";
const ManageSavingsUser = () => {
  const List = [
    {
      userid: "1696086371",
      name: "Alex",
      account_number: "987654321098765",
      mobile_no: "85555 26464",
      place: "Madurai",
      mab: "25,000.00",
      create_on: "10/06/2023",
      time: "08:30 AM",
      status: 1,
      email: "alex@gmail.com",
      kyc: 0,
    },
    {
      userid: "1696086371",
      name: "Benot Lawrence",
      account_number: "876543210987654",
      mobile_no: "95555 37474",
      place: "Chennai",
      mab: "30,000.00",
      create_on: "12/06/2023",
      time: "08:30 AM",
      status: 0,
      email: "benotlawrence@gmail.com",
      kyc: 1,
    },
    {
      userid: "1696086371",
      name: "Gamma ",
      account_number: "765432109876543",
      mobile_no: "75555 48484",
      place: "Bangalore",
      mab: "20,000.00",
      create_on: "15/06/2023",
      time: "08:30 AM",
      status: 1,
      email: "gamma@gmail.com",
      kyc: 1,
    },
    {
      userid: "5696086371",
      name: "Depthi",
      account_number: "654321098765432",
      mobile_no: "65555 59494",
      place: "Hyderabad",
      mab: "28,000.00",
      create_on: "18/06/2023",
      time: "08:30 AM",
      status: 0,
      email: "depthi@gmail.com",
      kyc: 1,
    },
    {
      userid: "3696086371",
      name: "John Rio",
      account_number: "543210987654321",
      mobile_no: "85555 70404",
      place: "Mumbai",
      mab: "22,000.00",
      create_on: "20/06/2023",
      time: "08:30 AM",
      status: 1,
      email: "johnrio@gmail.com",
      kyc: 0,
    },
    {
      userid: "3954212189",
      name: "farzana ",
      account_number: "432109876543210",
      mobile_no: "95555 81414",
      place: "Delhi",
      mab: "35,000.00",
      create_on: "22/06/2023",
      time: "08:30 AM",
      status: 1,
      email: "farzana@gmail.com",
      kyc: 0,
    },
    {
      userid: "2396086371",
      name: "kavitha",
      account_number: "321098765432109",
      mobile_no: "75555 92424",
      place: "Kolkata",
      mab: "18,000.00",
      create_on: "25/06/2023",
      time: "08:30 AM",
      status: 0,
      email: "kavitha@gmail.com",
      kyc: 1,
    },
    {
      userid: "3954212189",
      name: "Nancy Rajan",
      account_number: "210987654321098",
      mobile_no: "65555 03434",
      place: "Pune",
      mab: "26,000.00",
      create_on: "28/06/2023",
      time: "08:30 AM",
      status: 1,
      email: "nancy@gmail.com",
      kyc: 0,
    },
    {
      userid: "1696086371",
      name: "Jenifer ",
      account_number: "109876543210987",
      mobile_no: "85555 14444",
      place: "Jaipur",
      mab: "24,000.00",
      create_on: "30/06/2023",
      time: "08:30 AM",
      status: 0,
      email: "jenifer@gmail.com",
      kyc: 1,
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [createdraw, setCreatedraw] = useState(false);
  const [updatedraw, setUpdatedraw] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [method, setMethod] = useState("Method");
  const [filteredData, setFilteredData] = useState(List);
  const [status, setStatus] = useState("Status");
  const [loading, setLoading] = useState(false);
  const [fillter, setFillter] = useState(null);
  const openFilter = Boolean(fillter);
  const id = open ? "simple-popover" : undefined;
  const terms = ["company_name", "account_number", "mobile_no", "place"];

  const [type, setType] = useState("Type");
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDrawerToggle() {
    setMobileOpen(true);
  }

  function handleDrawerClose() {
    setMobileOpen(false);
  }

  const handleSearch = (filteredData, loading) => {
    setLoading(loading);
    setFilteredData(filteredData);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleFilter = (event) => {
    setFillter(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFillter(null);
  };
  const handleChangeMethod = (event) => {
    setMethod(event.target.value);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ShortenedText = ({ text, maxLength }) => {
    const displayText =
      text.length > maxLength ? text.slice(0, maxLength) + "..." : text;

    return displayText;
  };

  return (
    <section>
      <Card className="responsivecard">
        <CardContent>
          <div className="common_header page_header_option beneficiary d-flex mb-0 mt-0 space-between">
            <div className="d-flex items-center">
              <h2>Saving Users (112) </h2>
            </div>

            <div className="d-flex items-center">
              <div className="ml-20 ">
                {" "}
                <Search
                  data={List}
                  onSearch={handleSearch}
                  terms={terms}
                />{" "}
              </div>

              <div className="ml-20 mr-20">
                <DyanamicDatePicker />
              </div>

              <div>
                <Tooltip title="Filter">
                  <IconButton
                    onClick={handleFilter}
                    className="icon_btn "
                    variant="outlined"
                  >
                    <FilterIcon />
                  </IconButton>
                </Tooltip>
                {/* filter popover  */}
                <Popover
                  className="date_popover"
                  id={id}
                  open={openFilter}
                  anchorEl={fillter}
                  onClose={handleFilterClose}
                >
                  <div className="date_popover_inner">
                    <Select
                      className={status == "Status" ? "select-box mb-20" : 'select-box-select mb-20'}
                      value={status}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem selected value={"Status"}>
                        Filter by Status
                      </MenuItem>
                      <MenuItem value={0}>Success</MenuItem>
                      <MenuItem value={1}>Pending</MenuItem>
                      <MenuItem value={2}>Failed</MenuItem>
                    </Select>
                    <Select
                      className={method == "Method" ? "select-box mb-20" : 'select-box-select mb-20'}
                      value={method}
                      onChange={handleChangeMethod}
                      fullWidth
                    >
                      <MenuItem selected value={"Method"}>
                        Filter by City
                      </MenuItem>
                      <MenuItem value={0}>Madurai</MenuItem>
                      <MenuItem value={1}>Chennai</MenuItem>
                    </Select>
                    <div>
                      <Button
                        className="datefilter_btn"
                        variant="outlined"
                        onClick={handleFilterClose}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>

              <div>
                <Tooltip title="Refresh">
                  <IconButton className="ml-10 icon_btn" variant="outlined">
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="ml-20">
                <Button
                  startIcon={<FileIcon />}
                  className="export_btn width-settings"
                  variant="outlined"
                >
                  {" "}
                  Export as Excel
                </Button>
              </div>
            </div>
          </div>
          <div>
            <Table className="common_table" id="tablePopover">
              <TableHead>
                <TableRow>
                  <TableCell>Created on</TableCell>
                  <TableCell>Customer Id </TableCell>
                  <TableCell>Customer Name </TableCell>
                  <TableCell>Mobile No</TableCell>
                  <TableCell>Email Id</TableCell>
                  <TableCell>Account Number</TableCell>

                  <TableCell>City</TableCell>
                  <TableCell>KYC Status</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  //Display the skeleton loader while data is loading
                  <SkeletonTableLoader tablecell="9" row="8" />
                ) : // Display the actual table content when data is loaded
                filteredData.length == 0 ? (
                  <NoRecordsFound tablecell="9" />
                ) : (
                  filteredData.map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          {item?.create_on} {item?.time}
                        </TableCell>
                        <TableCell
                          className="Link"
                          onClick={handleDrawerToggle}
                        >
                          {item?.userid}
                        </TableCell>
                        <TableCell>
                          {item?.name.length > 15 ? (
                            <Tooltip title={item?.name}>
                              <span className="cursor">{`${item?.name.slice(
                                0,
                                14
                              )}...`}</span>
                            </Tooltip>
                          ) : (
                            <span>{item?.name}</span>
                          )}
                        </TableCell>
                        <TableCell>{item?.mobile_no}</TableCell>
                        <TableCell>
                          {item?.email.length > 20 ? (
                            <Tooltip title={item?.email}>
                              <span className="cursor">{`${item?.email.slice(
                                0,
                                20
                              )}...`}</span>
                            </Tooltip>
                          ) : (
                            <span>{item?.email}</span>
                          )}
                        </TableCell>
                        <TableCell>{item?.account_number}</TableCell>
                        <TableCell>{item?.place}</TableCell>

                        <TableCell>
                          {item?.kyc == 1 ? (
                            <span className="verified_txt">Verified</span>
                          ) : (
                            <span className="pending_txt">Pending</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {item?.status == 1 ? (
                            <span className="admin_active_status">
                              <span className="active_dot"></span> Active
                            </span>
                          ) : (
                            <span className="admin_inactive_status">
                              <span className="inactive_dot"></span> Inactive
                            </span>
                          )}
                        </TableCell>

                        <TableCell align="center">
                          <IconButton
                            aria-label=" View "
                            className="icon"
                            onClick={handleDrawerToggle}
                          >
                            <RemoveRedEyeRoundedIcon className="icon" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
              <TableFooter>
                <TablePagination
                  count={count}
                  page={page}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableFooter>
            </Table>
          </div>
        </CardContent>
      </Card>
      <SavingsUserView
        handleDrawerClose={handleDrawerClose}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
    </section>
  );
};

export default ManageSavingsUser;
