import React from "react";
import { Box, Button, Drawer, TextField, Typography } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import Div from "@jumbo/shared/Div";

const Verify = ({ handleDrawerToggle, mobileOpen, handleDrawerClose }) => {
  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <Box className="sidenave_box" sx={{ width: "520px" }}>
          <div className="sidebar_header">
            <h1>Verify Bank Account</h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>
          <div className="sideform_container">
            <form noValidate autoComplete="off">
              <Div sx={{ mt: 0 }}>
                <Typography className="from_label">
                  Account No <span className="required">*</span>
                </Typography>
                <TextField
                  name="bname"
                  placeholder="Enter Account No"
                  className="form_new_style"
                  variant="outlined"
                  fullWidth
                />
              </Div>
              <Div sx={{ mt: 2 }}>
                <Typography className="from_label">
                  IFSC <span className="required">*</span>
                </Typography>
                <TextField
                  name="bname"
                  placeholder="Enter IFSC"
                  className="form_new_style"
                  variant="outlined"
                  fullWidth
                />
              </Div>
              <Div sx={{ mt: 2 }}>
                <Typography className="from_label">
                  Account Holder Name
                </Typography>
                <TextField
                  name="bname"
                  placeholder="Enter Account Holder Name"
                  className="form_new_style"
                  variant="outlined"
                  fullWidth
                />
              </Div>
              <Div sx={{ mt: 2 }}>
                <Typography className="from_label">Mobile No</Typography>
                <TextField
                  name="bname"
                  placeholder="Enter Mobile No"
                  className="form_new_style"
                  variant="outlined"
                  fullWidth
                />
              </Div>
              <Div className="verify_note" sx={{ mt: 2 }}>
                <p>₹2.50 will be deducted from your available balance</p>
              </Div>

              <div className="d-flex items-center confirmation_buttons gap">
                <Button className="confrm_btn" onClick={handleDrawerClose}>
                  Verify
                </Button>
                <Button className="outline_btn" onClick={handleDrawerClose}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default Verify;
