import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {Autocomplete,Box,Button, Drawer, Paper, TextField, Typography, Table, TableCell, TableRow, TableBody,} from "@mui/material";
import Div from "@jumbo/shared/Div";
const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} />;
};
const FindIfsc = ({ handleDrawerToggle, ifscOpen, handleDrawerClose, beneForm }) => {
  // useSate 
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false)
  const Bank = [
    { label: "HDFC Back" },
    { label: "SBI Bank" },
    { label: "IOB Bank" },
    { label: "Canara Bank" },
  ];
  const State = [
    { label: "Tamil Nadu" },
    { label: "Andhra Pradesh" },
    { label: "Arunachal Pradesh" },
    { label: "Assam" },
    { label: "Bihar" },
    { label: "Chhattisgarh" },
  ];
  const District = [
    { label: "Madurai" },
    { label: "Chennai" },
    { label: "Coimbatore" },
    { label: "Trichy" },
  ];
  const Branch = [
    { label: "Madurai Main Branch" },
    { label: "Kalavasal Branch" },
    { label: "Chinthamani Branch" },
    { label: "Iyer bunglow Branch" },
    { label: "Keelavasal Branch" },
  ];
  // functions 

  const findCode = () =>{
    setLoading(true)
    
    setTimeout(()=>{
     setLoading(false)
     setResult(true)
    },1000)
   }
const openFirst = () =>{
  beneForm()
  handleDrawerToggle()
}

  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={ifscOpen}
        onClose={handleDrawerToggle}>
        <Box sx={{ width: "520px" }}>
          <div className="sidebar_header">
            <h1>Find IFSC Code</h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>
          <div className="sideform_container">
            <form >
              <Div >
                <Typography className="from_label">  Bank <span className="required">*</span> </Typography>
                <Autocomplete
                  disablePortal
                  options={Bank}
                  PaperComponent={CustomPaper}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                    placeholder="Select Bank"
                      className="form_new_style form_selct"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </Div>
              <Div sx={{ mt: 2 }}>
                <Typography className="from_label"> State <span className="required">*</span></Typography>
                <Autocomplete
                  disablePortal
                  options={State}
                  PaperComponent={CustomPaper}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                    placeholder="Select State"
                      className="form_new_style form_selct"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </Div>
              <Div sx={{ mt: 2 }}>
                <Typography className="from_label"> District <span className="required">*</span></Typography>
                <Autocomplete
                  disablePortal
                  options={District}
                  PaperComponent={CustomPaper}
                  fullWidth
                  placeholder="Select District"
                  renderInput={(params) => (
                    <TextField
                    placeholder="Select District"
                      className="form_new_style form_selct"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </Div>
              <Div sx={{ mt: 2 }}>
                <Typography className="from_label"> Branch <span className="required">*</span></Typography>
                <Autocomplete
                  disablePortal
                  options={Branch}
                  PaperComponent={CustomPaper}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                    placeholder="Select Branch"
                      className="form_new_style form_selct"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </Div>
              {
                result === true ? 
              <Div sx={{mt:4}}>
                <Table
                  className="common_table find_ifsc ">
                  <TableBody>
                    <TableRow>
                      <TableCell>IFSC Code</TableCell>
                      <TableCell className="ifsc_code"><b>HDFC0001068</b> </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Bank</TableCell>
                      <TableCell>HDFC Bank</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>MICR code</TableCell>
                      <TableCell>MICR Not Provided</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Branch Code</TableCell>
                      <TableCell>001670</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Div>
              : ''
               }
               <Div sx={{ mt: 3, mb:2}}>
                {
                   result === true ? 
                   <Button
                    className="outline_btn" onClick={openFirst} > Cancel </Button>
                    :
                 <Button
                  className="primary_btn w-200"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }} onClick={findCode}>
                  {loading === true ? `Finding...` : `Find IFSC`}
                </Button>
}
              </Div>
            </form>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};
export default FindIfsc;