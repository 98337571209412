import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import Div from "@jumbo/shared/Div";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import OrganisationDetails from "./FormModule/OrganisationDetails";
import CustomerProfile from "./FormModule/CustomerProfile";
import Nominee from "./FormModule/Nominee";
import AuthorizedForm from "./FormModule/AuthorizedForm";
import CreditFac from "./FormModule/CreditFac";
import Declaration from "./FormModule/Declaration";
import Annexure from "./FormModule/Annexure";
import StandingInstruct from "./FormModule/StandingInstruct";
import CrsDecalartion from "./FormModule/CrsDecalartion";
import CutsomerDecl from "./FormModule/CutsomerDecl";
import DocumentsUpload from "./FormModule/DocumentsUpload";
import * as yup from 'yup';
import { CurrentContext } from "../Context/CurrentUserContext";
import { ToastServices } from "app/services/toastServices";
import { useEffect } from "react";
const steps = [
  "Organisation Details",
  "Customer Profile",
  "Nominee",
 
  "Authorised Signatory ",
  "Credit Facilities",
  "Declaration",
  "Annexure 5",
  "Standing Instruction Request",
  "FATCA /CRS Declaration",
  "Customer Declaration",
  "Documents Upload"
];






const CreateCurrentUser = ({
  handleDrawerToggle,
  createdraw,
  handleDrawerClose,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [checked, setChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState("option1");
  const [value, setValue] = useState(dayjs("2022-04-17"));
  
  const {formik, Customerformik , Nomineeformik} = useContext(CurrentContext)
 

  const enitity = [
    { label: "Proprietorship" },
    {
      label: "Proprietorship 1",
    },
    {
      label: "Proprietorship 2",
    },
  ];
  const NatureofBusiness = [
    { label: "Others" },
    {
      label: "Others 1",
    },
    {
      label: "Others 2",
    },
  ];
  const Year = [
    { label: "5" },
    {
      label: "4",
    },
    {
      label: "6",
    },
  ];
  const Month = [
    { label: "5" },
    {
      label: "4",
    },
    {
      label: "6",
    },
  ];
  const city = [
    {
      label: "Madurai",
    },
    {
      label: "Chennai",
    },
    {
      label: "Theni",
    },
  ];


  const { errors, touched } = formik;
  const { errors: customerErrors, touched: customerTouched } = Customerformik;
  const { errors: nomineeErrors, touched: nomineeTouched } = Nomineeformik;
  const nameOftheOrgError = errors.nameOftheOrg && touched.nameOftheOrg ? errors.nameOftheOrg : null
  const handleNext = () => {

    switch (activeStep) {
      case 0:
        console.log(Object.keys(errors), formik.values)
        const valuesArray = Object.values(formik.values);

// Check if any value is an empty string or null
const countNullAndEmpty = valuesArray.filter(value => value === "" || value === null).length;
if(countNullAndEmpty === 9){
  ToastServices.ErroToast("please fill the form values");
} 
if (Object.keys(errors).length === 0) {
  setActiveStep(activeStep + 1);
  formik.handleSubmit();
  console.log("No errors", formik.values);
}
         
        break;
    
      // Add more cases as needed
      case 1:
        if (Object.keys(customerErrors).length === 0) {
          setActiveStep(activeStep + 1);
          Customerformik.handleSubmit();
          console.log("No errors");
          const concatenatedObject = Object.assign({},  formik.values, Customerformik.values);
          console.log(" concatenatedObject  >>>", concatenatedObject);
        } else {
          ToastServices.ErroToast("fill all the required fields");
        }

       
        break;

        case 2:
        
        if (Object.keys(nomineeErrors).length === 0) {
          setActiveStep(activeStep + 1);
          Nomineeformik.handleSubmit();
          console.log("No errors");
          
        
        } else {
          ToastServices.ErroToast("Please fill the required field");
        }
       
        break;
        case 3:
        
        setActiveStep(activeStep + 1);
     
      break;
      case 4:
        
      setActiveStep(activeStep + 1);
   
    break;
    case 5:
        
    setActiveStep(activeStep + 1);
 
  break;
  case 6:
        
  setActiveStep(activeStep + 1);

break;

case 7:
        
setActiveStep(activeStep + 1);

break;
case 8:
        
setActiveStep(activeStep + 1);

break;

case 9:
        
setActiveStep(activeStep + 1);

break;
case 10:
        
setActiveStep(activeStep + 1);

break;

        
    
      
        
    
      default:
        // Default case
        break;
    }



    // if (activeStep == 0 && Object.keys(errors).length == 0){

       
    //    setActiveStep(activeStep  + 1);
    //    formik.handleSubmit()
    //    console.log( "errors",errors)
    //   } else{
    //     ToastServices.ErroToast("12121111")
    //   }
    
    // if( !nameOftheOrgError && !nameOftheOrgError !== ""){
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);

    //   formik.handleSubmit()

    //   console.log(nameOftheOrgError, errors,"``````````````````````````````````````")
    // }
    // else{
    //   ToastServices.ErroToast(nameOftheOrgError)
    //   console.log(nameOftheOrgError, errors,"``````````````````````````````````````")
    // }

   
    
   
  };
  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isLastStep = activeStep === steps.length - 1;

  const handleStepClick = (stepIndex) => {
    if (stepIndex < activeStep) {
      setActiveStep(stepIndex);
    }
  };

  const progress = (activeStep / (steps.length - 1)) * 100;
  return (
    <div className="currentuser_draw">
      <Drawer
        className="currentuser_draw"
        variant="temporary"
        anchor={"right"}
        open={createdraw}
        onClose={handleDrawerClose}
      >
        <Box className="sidenave_box">
          <div className="sidebar_header">
            <h1>Add New Current Account</h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>
          <div className="curre_acc_create_container">
            <Grid container>
              <Grid item xl={4}>
                <div>
                  <Stepper className="resposive_steppper" activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                      <Step key={label} onClick={() => handleStepClick(index)}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </Grid>
              <Grid item xl={8}>
                {activeStep === steps.length ? (
                  <div className="modal_footer">
                    <div
                      className="d-flex w-100 items-center space-between"
                      style={{ padding: "15px 30px" }}
                    >
                      <div>
                        <LinearProgress variant="determinate" value={100} />
                        <Typography className="completed_stus">{`100% Completed`}</Typography>
                      </div>

                      <div className="d-flex gap">
                        <Button
                          variant="contained"
                          color="primary"
                          className="submit_btn"
                        >
                          Save
                        </Button>
                        <Button
                          className="back_btn"
                          onClick={() => setActiveStep(0)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="current_user_details_form">
                    {activeStep === 0 && (
                     <OrganisationDetails />
                    )}
                    {activeStep === 1 && (
                      <CustomerProfile />
                    )}
                    {activeStep === 2 && (
                     <Nominee />
                    )}
                    {
                      activeStep === 3 && (
                        <AuthorizedForm />
                      )
                    }
                    {
                      activeStep === 4 && (
                        <CreditFac />
                      )
                    }
                    {
                      activeStep === 5 && (
                        <Declaration />
                      )
                    }
                    {
                      activeStep === 6 && (
                        <Annexure />
                      )
                    }
                    {
                      activeStep === 7 && (
                        <StandingInstruct />
                      )
                    }
                    {
                      activeStep == 8 && (
                        <CrsDecalartion />
                      )
                    }{
                      activeStep === 9 &&(
                        <CutsomerDecl />
                      )
                    }
                    {
                      activeStep === 10 && (
                        <DocumentsUpload />
                      )
                    }

                    <div className="modal_footer">
                      <div
                        className="d-flex w-100 items-center space-between"
                        style={{ padding: "15px 30px" }}
                      >
                        <div>
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                          />
                          <Typography className="completed_stus">{`${progress.toFixed(
                            0
                          )}% Completed`}</Typography>
                        </div>

                        <div className="d-flex gap">
                          <Button
                            variant="contained"
                            color="primary"
                            className="submit_btn"
                            onClick={handleNext}
                          >
                            {isLastStep ? "Finish" : "Next"}
                          </Button>

                         
                          <Button
                            className="back_btn"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            Back
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default CreateCurrentUser;
