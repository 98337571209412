import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BaneficiaryCreate from "../BaneficiaryCreate/BaneficiaryCreate";
import { TableSortLabel } from "@mui/material";
import DyanamicDatePicker from "app/components/DatePicker/DyanamicDatePicker";
import Div from "@jumbo/shared/Div";
import DeletePopup from "../../component/deleteModal";
import BackdropLoader from "../../component/loader";
import SkeletonTableLoader from "../../component/skeleton_loader/skeletonTableLoader";
import Search from "../../component/search";
import NoRecordsFound from "../../component/noRecordsFound";
import {
  ExpoIcon,
  FileIcon,
  FilterIcon,
  RefreshIcon,
  TrashIcon,
  UpdateIcon,
} from "app/assets/svg/Svg";
import { LuEye } from "react-icons/lu";
import "../Benificiary.css";
import BeneficiaryView from "../BeneficiaryView/BeneficiaryView";

const BeneficiariesList = () => {
  const TableData = [
    {
      id: "BENE0001",
      createdonDate: "24/01/2024",
      createdTime: "09:30 AM",
      name: "Gowtham K",
      mobile: " 75555 27960",
      bank: "HDFC Bank",
      acno: "5010007865490",
      noTrans: "121",
      status: 1,
    },
    {
      id: "BENE0002",
      createdonDate: "27/01/2024",
      createdTime: "11:30 AM",
      name: "Kamesh",
      mobile: " 98755 5632",
      bank: "Canare Bank",
      acno: "18210007865490",
      noTrans: "11",
      status: 1,
    },
    {
      id: "BENE0003",
      createdonDate: "25/01/2024",
      createdTime: "11:30 AM",
      name: "Sachin",
      mobile: " 99955 27960",
      bank: "Indian Bank",
      acno: "16210007865490",
      noTrans: "12",
      status: 1,
    },
    {
      id: "BENE0004",
      createdonDate: "01/02/2024",
      createdTime: "08:30 AM",
      name: "Rahul",
      mobile: " 98755 27960",
      bank: "HDFC Bank",
      acno: "18210007865490",
      noTrans: "21",
      status: 0,
    },
    {
      id: "BENE0005",
      createdonDate: "02/02/2024",
      createdTime: "11:30 AM",
      name: "Husmathulla",
      mobile: " 69555 27960",
      bank: "HDFC Bank",
      acno: "18210007865490",
      noTrans: "121",
      status: 1,
    },
  ];

  // useState
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mobileOpens, setMobileOpens] = React.useState(false);
  const [age, setAge] = React.useState("20");
  const [filteredData, setFilteredData] = useState(TableData);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [method, setMethod] = useState("Method");
  const [bank, setBank] = useState("Bank");
  const [fillter, setFillter] = useState(null);
  const openFilter = Boolean(fillter);
  const [color, setColor] = useState(false)
  const id = open ? "simple-popover" : undefined;
  const terms = ["name", "bank", "acno", "ifsc", "mobile", "actype"];
  // Functions
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  function handleDrawerToggled() {
    setMobileOpens(!mobileOpens);
    setAnchorEl(null);
  }
  function handleDrawerClose() {
    setMobileOpen(false);
  }
  function handleDrawerClosed() {
    setMobileOpens(false);
  }
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleSearch = (filteredData, loading) => {
    // Do something with the filtered data
    setFilteredData(filteredData);
    setLoading(loading);
  };
  // filler
  const handleFilter = (event) => {
    setFillter(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFillter(null);
  };
  const handleChangeMethod = (event) => {
    setMethod(event.target.value);
  };
  const handleChangeBank = (e) => {
    setBank(e.target.value);
    setColor(true)
  };
  return (
    <section className="current_beneficiaty">
      <DeletePopup open={isModalOpen} handleClose={handleCloseModal} />
      <Card className="responsivecard">
        <CardContent>
          <Grid className="common_header grid current_user" container>
            <Grid lg={4} className="custom_grid">
              <Grid item>
                <div className="d-flex items-center">
                  <h2>Beneficiaries (12)</h2>
                  <Typography
                    className="create_btn"
                    variant={"h2"}
                    mb={5}
                    onClick={handleDrawerToggle}
                  >
                    {" "}
                    + Add new
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid lg={8} className="custom_grid">
              <Grid container spacing={1}>
                <Grid item lg={3.7} md={6}>
                  <Search
                    data={TableData}
                    onSearch={handleSearch}
                    terms={terms}
                  />
                </Grid>
                <Grid item lg={3.7} md={6}>
                  <DyanamicDatePicker />
                </Grid>
                <Grid item lg={0.8}>
                  <Tooltip title="Filter">
                    <IconButton
                      onClick={handleFilter}
                      className="icon_btn "
                      variant="outlined"
                    >
                      <FilterIcon />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    className="date_popover"
                    id={id}
                    open={openFilter}
                    anchorEl={fillter}
                    onClose={handleFilterClose}
                  >
                    <div className="date_popover_inner">
                      <Select
                        className= {bank == "Bank" ? "select-box mb-20" : 'select-box-select mb-20'}
                        value={bank}
                        onChange={handleChangeBank}
                        fullWidth
                      >
                        <MenuItem selected disabled value={"Bank"} >
                          Filter by Bank
                        </MenuItem>
                        <MenuItem value={0}>HDFC Bank</MenuItem>
                        <MenuItem value={1}>Indian Bank</MenuItem>
                        <MenuItem value={1}>Punjab National Bank</MenuItem>
                      </Select>
                      <Select
                        className={method == "Method" ? "select-box mb-20" : 'select-box-select mb-20'}
                        value={method}
                        onChange={handleChangeMethod}
                        fullWidth
                      >
                        <MenuItem  value={"Method"}>
                          Filter by Status
                        </MenuItem>
                        <MenuItem value={0}>Active</MenuItem>
                        <MenuItem value={1}>Inactive</MenuItem>
                      </Select>
                      <div>
                        <Button
                          className="datefilter_btn"
                          variant="outlined"
                          onClick={handleFilterClose}
                        >
                          {" "}
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </Grid>
                <Grid item lg={0.8}>
                  <Tooltip title="Refresh">
                    <IconButton className="icon_btn" variant="outlined">
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item lg={2.8} md={3.9}>
                  <Button
                    startIcon={<FileIcon />}
                    className="export_btn w-100"
                    variant="outlined"
                  >
                    Export as Excel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Div>
            <Table className="common_table" id="tablePopover">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel>Bene.Id</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Created On</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel> Name </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Mobile No </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Bank Name</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    Account No
                  </TableCell>
                  <TableCell style={{ width: "10%" }}>
                    No.of Transaction
                  </TableCell>
                  <TableCell>
                    Status
                  </TableCell>
                  <TableCell>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <SkeletonTableLoader tablecell="7" row="8" />
                ) : filteredData.length == 0 ? (
                  <NoRecordsFound tablecell="7" />
                ) : (
                  filteredData.map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell
                          className="table_link"
                          onClick={handleDrawerToggled}
                        >
                          {item?.id}
                        </TableCell>
                        <TableCell>
                          {item?.createdonDate} 
                          {/* <br />
                           {item?.createdTime}  */}
                        </TableCell>
                        <TableCell
                          className="table_link"
                          onClick={handleDrawerToggled}
                        >
                          {item?.name}
                        </TableCell>
                        <TableCell>{item?.mobile}</TableCell>
                        <TableCell>{item?.bank}</TableCell>
                        <TableCell>{item?.acno}</TableCell>
                        <TableCell className="text-center">
                          {item?.noTrans}
                        </TableCell>
                        <TableCell>
                          {item?.status == 1 ? (
                            <span className="admin_active_status">
                              <span className="active_dot"></span> Active
                            </span>
                          ) : (
                            <span className="admin_inactive_status">
                              <span className="inactive_dot"></span> Inactive
                            </span>
                          )}
                        </TableCell>
                        <TableCell sx={{ width: "20px" }}>
                          <IconButton
                            className="table_icon_btn"
                            aria-controls={
                              open ? "demo-positioned-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            className="custom_popover"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              className="menuItem"
                              onClick={handleDrawerToggled}
                            >
                              <LuEye />
                              <span className="ml-10"> View </span>
                            </MenuItem>
                            <MenuItem
                              className="menuItem"
                              onClick={handleClose}
                            >
                              <ExpoIcon />
                              <span className="ml-10">
                                {item?.status === 1
                                  ? "Mark as Inactive"
                                  : "Mark as Active"}
                              </span>
                            </MenuItem>

                            <MenuItem
                              className="menuItem"
                              onClick={handleDelete}
                            >
                              <TrashIcon />
                              <span className="ml-10"> Delete </span>
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={15}
                  rowsPerPage={5}
                  page={1}
                />
              </TableFooter>
            </Table>
          </Div>
        </CardContent>
      </Card>
      <BaneficiaryCreate
        handleDrawerClose={handleDrawerClose}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <BeneficiaryView
        handleDrawerClosed={handleDrawerClosed}
        mobileOpens={mobileOpens}
        handleDrawerToggled={handleDrawerToggled}
      />
      {loader == true ? <BackdropLoader /> : ""}
    </section>
  );
};

export default BeneficiariesList;
