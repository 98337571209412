import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { logoutAction } from "app/redux/actions/authAction";
import { useSelector } from "react-redux";
import { ASSET_AVATARS } from "app/utils/constants/paths";
import { getAssetPath } from "app/utils/appHelpers";
import useApp from "app/hooks/useApp";
import MyProfile from "app/pages/app-pages/Profile/myProfile";
import ManagePins from "app/pages/app-pages/Profile/managePins";
import ChangePassword from "app/pages/app-pages/Profile/changePassword";
import { SlLock } from "react-icons/sl";
import { AiOutlineLogin } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";

const UserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const { setAuthToken } = useJumboAuth();
  const userData = useSelector((state) => state?.user?.userData);
  const user = useApp();
  console.log(user, "1321564654");

  // const [anchorE1, setanchorEl] = useState(null)
  const [mobileOpen, setMobileOpen] = useState(false);
  const [managePinsOpen, setManagePinsOpen] = useState(false);
  const [changePassOpen, setChangePassOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  let isOpen = Boolean(anchorEl);

  function handleDrawerToggle() {
    setMobileOpen(true);
    setManagePinsOpen(false);
    setChangePassOpen(false);
    setAnchorEl(null);
  }

  function handleDrawerClose() {
    setMobileOpen(false);
    setAnchorEl(null);
  }

  function handleManagePinsToggle() {
    setManagePinsOpen(true);
    setMobileOpen(false);
    setChangePassOpen(false);
    setAnchorEl(null);
  }

  function handleManagePinsClose() {
    setManagePinsOpen(false);
    setAnchorEl(null);
  }

  function handleChangePassToggle() {
    setChangePassOpen(true);
    setManagePinsOpen(false);
    setMobileOpen(false);
    setAnchorEl(null);
  }

  function handleChangePassClose() {
    setChangePassOpen(false);
    setAnchorEl(null);
  }

  // const userData = user.userData
  const profile_pic = getAssetPath(`${ASSET_AVATARS}/avatar.png`, `60x60`);
  const onLogout = () => {
    logoutAction();
    window.location.reload(false);
  };

  const handleProfile = () => {
    navigate("/change-profile");
  };

  /*menu popover */

  const popuphandleClick = React.useCallback((event) => {
    //isOpen = Boolean(anchorEl);

    setAnchorEl(event.currentTarget);
  }, []);

  const popuphandleClose = React.useCallback(() => {
    setAnchorEl(null);
  });
return (
    <div>
      <ThemeProvider theme={theme}>
        <JumboDdPopover
          handleClick={popuphandleClick}
          handleClose={popuphandleClose}
          isOpen={isOpen}
          anchorEl={anchorEl}
          triggerButton={
            <div className="d-flex items-center avater" style={{ cursor: "pointer" }}>
              <Avatar
                src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
               
                
              />
              <h2 className="m-0 mb-0 ml-10 username_top">Vignesh M </h2>
              <IoIosArrowDown
                style={{ fontSize: "26px", marginLeft: "20px" }}
              />
            </div>
          }
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              p: (theme) => theme.spacing(0),
            }}
          ></Div>

          <nav>
            <List
              className="user_list"
              style={{ minHeight: "100px", minWidth: "200px" }}
            >
              

              

              <ListItemButton>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <SlLock style={{ fontSize: "22px" }} />
                </ListItemIcon>
                <ListItemText
                  onClick={handleChangePassToggle}
                  primary="Change Password"
                  sx={{ my: 0, fontSize: "18px" }}
                />
              </ListItemButton>
                  <ListItemButton onClick={onLogout}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <AiOutlineLogin
                    style={{
                      fontSize: "22px",
                      transform: "rotate(175deg)",
                      color: "#e41919",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Logout"
                  sx={{ my: 0, color: "#e41919", fontSize: "18px" }}
                />
              </ListItemButton>
            </List>
          </nav>
        </JumboDdPopover>

        <MyProfile
          handleDrawerClose={handleDrawerClose}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <ManagePins
          handleDrawerClose={handleManagePinsClose}
          managePinsOpen={managePinsOpen}
          handleDrawerToggle={handleManagePinsToggle}
        />
        <ChangePassword
          handleDrawerClose={handleChangePassClose}
          changePassOpen={changePassOpen}
          handleDrawerToggle={handleChangePassToggle}
        />
      </ThemeProvider>
    </div>
  );
};

export default UserDropdown;
