import React, { useState } from 'react';
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from 'app/shared/widgets/AuthUserDropdown';
import {IconButton, useMediaQuery} from "@mui/material";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { DashLogo } from 'app/assets/svg/Svg';
import { GoBell } from "react-icons/go";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import UserDropdown from './User/User';


const SavingsUserHeader = () => {


    return (
        <div className='d-flex items-center space-between w-95 ' style={{padding:'10px 8px', backgroundColor:'#fff',
        position: "sticky",
                top: "0px",
                left: "0px",
                width:'100%',
        }}>
            <div className='d-flex items-center h-full savings_navbar'>
                <DashLogo  />
                <Divider orientation="vertical" flexItem sx={{marginLeft:'20px !important'}} />
                <Typography variant='h3'  fontWeight={600} fontSize={'25px'} marginLeft={2} paddingLeft={2}>Hi Vignesh Kumar!</Typography>
            </div>
            <Stack direction="row" alignItems="center" spacing={1.25} sx={{ml: "auto"}}>
                
                <UserDropdown/>
            </Stack>
        </div>
    );
};

export default SavingsUserHeader;
