import React, { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Typography, CardContent, Divider } from "@mui/material";
import {
  Box,
  Drawer,
  Grid,
  Card,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { TextareaAutosize } from "@mui/material";
import { DatePicker } from "@mui/lab";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Div from "@jumbo/shared/Div";
import profile from "app/assets/user_img.jpg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
   FormControlLabel,
   Radio,
   RadioGroup,
 } from "@mui/material";
 import userIMG from "app/assets/savinguser img.png";
 import { InputAdornment } from '@mui/material';


const EditInfo = ({ handleDrawerToggled, handleDrawerClosed, mobileOpens }) => {
  
  const [step,setSteps] = useState(1);  
  const steps = ["Personal Details", "Other Details", "Nominee Details"];
  const [selectedValue, setSelectedValue] = useState("salary");

  const Cities = [
    { label: "Madurai" },
    { label: "Chennai" },
    { label: "Theni" },
  ];

  const State = [
    { label: "Tamil Nadu" },
    { label: "Kerala" },
    { label: "Andhra" },
  ];


  function handleUpdate(){
    setSteps(step+1)
  }

  function handleCancel(){
    setSteps(step-1)
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const theme = createTheme({
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#a19b9b",
            "&.Mui-checked": {
              color: "#000",
            },
          },
        },
      },
    },
  });
  

  return (
    <div>
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpens}
        onClose={handleDrawerToggled}
      >
        <Box className="sidenave_box" sx={{ width: "850px" }}>
          <div
            className="sidebar_header"
            style={{ backgroundColor: "#F6F2EB" }}
          >
            <Typography variant="p" fontWeight={700} fontSize={"24px"}>
              Edit Account Opening Form
            </Typography>
            <ClearIcon
              sx={{ cursor: "pointer" }}
              onClick={handleDrawerClosed}
            />
          </div>
          <Grid item lg={5} xs={12}>
            <Card
              className=""
              sx={{
                height: "100%",
                padding: "0px 20px",
                borderRadius: "0 !important",
              }}
            >
                <div style={{ padding: "0px 0px 0px 15px" }}> 
              <Div sx={{ width: "100%", marginTop: "5px" }}>
                <Stepper activeStep={step-1} alternativeLabel sx={{border:'none  !important'}} className="resposive_steppper">
                    {steps.map((label) => (
                      <Step key={label} sx={{border:'none  !important'}} >
                        <StepLabel sx={{border:'none  !important'}}>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
              </Div>
               
              {step === 1 ? (
              <Div>

               
                <div
                  className=" d-flex"
                  style={{ gap: "20px" , marginTop:'25px'}}
                >

                
                  <div
                  style={{
                    width: "120px",
                    height: "120px",
                    overflow: "hidden",
                  }}>
                    <img
                       style={{ maxWidth: "100%", maxHeight: "100%" }}
                      src={userIMG}
                      alt="profilephoto"
                    />
                  </div>
                  <div className="mt-5">
                    <Typography
                      variant="p"
                      fontWeight={500}
                      color={"#666666"}
                      fontSize={"18px"}
                    >
                      Recent Profile Photo
                    </Typography>
                    <br />
                    <Div sx={{display:'flex', alignItems:'center',gap:'15px'}}>
                    <Button
                      className="outline_btn width_fit mt-15"
                      type="file"
                      variant="contained"
                      sx={{
                        height: "40px !important",
                        width: "120px !important",
                        fontSize:'16px !important'

                      }}
                    >
                      Change
                    </Button>{" "}
                    <Typography
                      variant="p"
                      fontWeight={400}
                      color={"#666666"}
                      fontSize={"15px"}
                      className="mt-15"
                    >
                      Use a JPEG, JPG, PNG (250 kb)
                    </Typography>
                    </Div>
                  </div>
                </div>
                <form>
                  <Grid container spacing={0} marginTop={"15px"} marginBottom={"100px"} gap={'20px'}>
                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <Typography className="from_label">
                        First Name <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter First Name"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <>
                      <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <Typography className="from_label">
                          Last Name <span className="required">*</span>
                          </Typography>
                          <TextField
                            placeholder="Enter Last Name"
                            name=""
                            type="text"
                            className="form_new_style"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </Grid>
                      <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <div className="dlfex-justify-between-align-center">
                            <Typography className="from_label" textTransform={'none !important'}>
                            Date of Birth
                            </Typography>
                          </div>
                          <TextField
                            name=""
                            placeholder="dd/mm/yyyy"
                            type="date"
                            className="form_new_style"
                            variant="outlined"
                            fullWidth
                            sx={{fontSize:'12px',fontWeight:'300'}}
                            InputProps={{
                              style: { color: '#969696' } 
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <div className="dlfex-justify-between-align-center">
                            <Typography className="from_label">
                            Email Id<span className="required">*</span>
                            </Typography>
                          </div>
                          <TextField
                            placeholder="Enter Email Id"
                            name=""
                            type="text"
                            className="form_new_style"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </Grid>
                      <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <Typography className="from_label">
                          Mobile No <span className="required">*</span>
                          </Typography>
                          <TextField
                            placeholder="Enter  Mobile Number"
                            name=""
                            type="number"
                            className="form_new_style"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </Grid>
                    </>
                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <Typography className="from_label text-normal">
                        Alternative Mobile No
                        </Typography>
                        <TextField
                          placeholder="Enter Alternative Mobile Number"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                          Address Line 1 <span className="required">*</span>
                          </Typography>
                        </div>
                        <textarea
                          placeholder="Enter Address line -1"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          style={{width:'100%'}}
                          
                        />
                      </div>
                    </Grid>
                    <>
                      <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <Typography className="from_label">
                          Address Line 2
                          </Typography>
                          <textarea
                            placeholder="Enter Address line -2"
                            name=""
                            type="text"
                            className="form_new_style"
                            variant="outlined"
                            style={{width:'100%'}}
                          />
                        </div>
                      </Grid>
                    </>
                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                            State <span className="required">*</span>
                          </Typography>
                        </div>
                        <Autocomplete
                          disablePortal
                          name="bbank"
                          options={State}
                          renderInput={(params) => (
                            <TextField
                              placeholder="Select State"
                              className="form_new_style form_selct"
                              fullWidth
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                            City <span className="required">*</span>
                          </Typography>
                        </div>
                        <Autocomplete
                          disablePortal
                          name="bbank"
                          options={Cities}
                          renderInput={(params) => (
                            <TextField
                              placeholder="Select City"
                              className="form_new_style form_selct"
                              fullWidth
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <Typography className="from_label">
                          Pincode <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Pincode"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    lg={12}
                    spacing={2}
                    justifyContent={"flex-end"}
                    marginTop={'20px'}
                    sx={{
                      position:'fixed',
                      right:'0',
                      bottom:'0',
                      background: "white",
                      width: "850px",
                      padding: "5px 50px 10px 5px",
                    }}
                  >
                    <Grid
                      lg={3}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <Button
                        className="outline_btn w-100 admin"
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        onClick={handleDrawerClosed}
                        
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      lg={3}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <Button
                        className="primary_btn admin"
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        onClick={handleUpdate}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                </Div>):(null)}









                {step === 2 ? (
              <Div>

<form>
                  <Grid container spacing={0} marginTop={"15px"} marginBottom={'120px'}  gap={'20px'}>
                    <Grid 
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <Typography className="from_label">
                        Marital Status <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Marital Status"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <>
                      <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <Typography className="from_label">
                          Father / Spouse Name <span className="required">*</span>
                          </Typography>
                          <TextField
                            placeholder="Enter Father / Spouse Name"
                            name=""
                            type="text"
                            className="form_new_style"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </Grid>
                      
                    </>

                    <Grid lg={5.5} item className="custom_grid">
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography className="from_label">
                        Employee Type<span className="required">*</span>
                      </Typography>
                      <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        value={selectedValue}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="salary"
                          className="radio_form"
                          control={<Radio />}
                          label="Salaried"
                          sx={{color:'#666666 !important'}}
                        />
                        <FormControlLabel
                          value="self Employe"
                          className="radio_form ml-30"
                          control={<Radio />}
                          label="Self Employed"
                          sx={{color:'#666666 !important'}}
                        />
                      </RadioGroup>
                    </ThemeProvider>
                  </div>
                </Grid>


                <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      ></Grid>

                <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <Typography className="from_label">
                          Company Name <span className="required">*</span>
                          </Typography>
                          <TextField
                            placeholder="Enter Comapny Name"
                            name=""
                            type="number"
                            className="form_new_style"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </Grid>

                   

                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <Typography className="from_label text-normal">
                        Company Experience{" "}
                          <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Experience"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>

                    <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <Typography className="from_label">
                          Location  <span className="required">*</span>
                          </Typography>
                          <TextField
                            placeholder="Enter Location "
                            name=""
                            type="number"
                            className="form_new_style"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </Grid>

                      <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <Typography className="from_label">
                          Income(₹)<span className="required">*</span>
                          </Typography>
                          <TextField
                            placeholder="Enter Income"
                            name=""
                            type="number"
                            className="form_new_style amt_input"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </Grid>
                    
                    
                    
                  </Grid>

                  <Grid
                    container
                    lg={12}
                    spacing={2}
                    justifyContent={"flex-end"}
                    sx={{
                      position:'fixed',
                      right:'0',
                      bottom:'0',
                      width: "850px",
                      padding: "5px 50px 10px 5px",
                      backgroundColor:'white'
                    }}
                  >
                    <Grid
                      lg={3}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <Button
                        className="outline_btn w-100 admin"
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      lg={3}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <Button
                        className="primary_btn admin"
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        onClick={handleUpdate}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </form>

              </Div>):(null)}







              {step === 3 ? (
              <Div>

               
                <div
                  className=" d-flex"
                  style={{ gap: "20px" , marginTop:'25px'}}
                >

                
                  <div
                  style={{
                    width: "120px",
                    height: "120px",
                    overflow: "hidden",
                  }}>
                    <img
                       style={{ maxWidth: "100%", maxHeight: "100%" }}
                      src={userIMG}
                      alt="profilephoto"
                    />
                  </div>
                  <div className="mt-5">
                    <Typography
                      variant="p"
                      fontWeight={500}
                      color={"#666666"}
                      fontSize={"18px"}
                    >
                      Recent Profile Photo
                    </Typography>
                    <br />
                    <Div sx={{display:'flex', alignItems:'center',gap:'15px'}}>
                    <Button
                      className="outline_btn width_fit mt-15"
                      type="file"
                      variant="contained"
                      sx={{
                        height: "40px !important",
                        width: "120px !important",
                        fontSize:'16px !important'

                      }}
                    >
                      Change
                    </Button>{" "}
                    <Typography
                      variant="p"
                      fontWeight={400}
                      color={"#666666"}
                      fontSize={"15px"}
                      className="mt-15"
                    >
                      Use a JPEG, JPG, PNG (250 kb)
                    </Typography>
                    </Div>
                  </div>
                </div>
                <form>
                  <Grid container spacing={0} marginTop={"15px"} marginBottom={'100px'} gap={'20px'}>
                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <Typography className="from_label">
                        Nominee First Name <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Nominee First Name"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <>
                      <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <Typography className="from_label">
                          Nominee Last Name <span className="required">*</span>
                          </Typography>
                          <TextField
                            placeholder="Enter Nominee Last Name"
                            name=""
                            type="text"
                            className="form_new_style"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </Grid>
                      <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <div className="dlfex-justify-between-align-center">
                            <Typography className="from_label" textTransform={'none !important'}>
                            Nominee Date of Birth <span className="required">*</span>
                            </Typography>
                          </div>
                          <TextField
                            name=""
                            placeholder="dd/mm/yyyy"
                            type="date"
                            className="form_new_style"
                            variant="outlined"
                            fullWidth
                            sx={{fontSize:'12px',fontWeight:'300'}}
                            InputProps={{
                              style: { color: '#969696' } 
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <div className="dlfex-justify-between-align-center">
                            <Typography className="from_label">
                            Nominee Email Id<span className="required">*</span>
                            </Typography>
                          </div>
                          <TextField
                            placeholder="Enter Email Id"
                            name=""
                            type="number"
                            className="form_new_style"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </Grid>
                      <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <Typography className="from_label">
                          Mobile No <span className="required">*</span>
                          </Typography>
                          <TextField
                            placeholder="Enter  Mobile Number"
                            name=""
                            type="number"
                            className="form_new_style"
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </Grid>
                    </>
                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <Typography className="from_label text-normal">
                        Alternative Mobile No{" "}
                          <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Alternative Mobile Number"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                          Address line 1 <span className="required">*</span>
                          </Typography>
                        </div>
                        <textarea
                          placeholder="Enter Address line -1"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          style={{width:'100%'}}
                        />
                      </div>
                    </Grid>
                    <>
                      <Grid
                        lg={5.5}
                        item
                        className="custom_grid custom_grid-saving"
                      >
                        <div className="">
                          <Typography className="from_label">
                          Address line 2
                          </Typography>
                          <textarea
                            placeholder="Enter Address line -2"
                            name=""
                            type="text"
                            className="form_new_style"
                            variant="outlined"
                            style={{width:'100%'}}
                          />
                        </div>
                      </Grid>
                    </>
                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                          State <span className="required">*</span>
                          </Typography>
                        </div>
                        <Autocomplete
                          disablePortal
                          name="bbank"
                          options={State}
                          renderInput={(params) => (
                            <TextField
                              placeholder="Select State"
                              className="form_new_style form_selct"
                              fullWidth
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                          City <span className="required">*</span>
                          </Typography>
                        </div>
                        <Autocomplete
                          disablePortal
                          name="bbank"
                          options={Cities}
                          renderInput={(params) => (
                            <TextField
                              placeholder="Select City"
                              className="form_new_style form_selct"
                              fullWidth
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid
                      lg={5.5}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <div className="">
                        <Typography className="from_label">
                        Pincode <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Pincode"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    lg={12}
                    spacing={2}
                    justifyContent={"flex-end"}
                    marginTop={'20px'}
                    sx={{
                      position:'fixed',
                      right:'0',
                      bottom:'0',
                      background: "white",
                      width: "850px",
                      padding: "5px 50px 10px 15px",
                    }}
                  >
                    <Grid
                      lg={3}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <Button
                        className="outline_btn w-100 admin"
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      lg={3}
                      item
                      className="custom_grid custom_grid-saving"
                    >
                      <Button
                        className="primary_btn admin"
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        onClick={handleDrawerClosed}
                      >
                        submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                </Div>):(null)}

               </div> 
               
            </Card>
          </Grid>
        </Box>
      </Drawer>
    </div>
  );
};

export default EditInfo;
