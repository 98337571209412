import React, { useEffect, useRef, useState, useContext } from "react";
import {TextField,Typography,} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from "yup";
import {useFormik } from "formik";
import Div from "@jumbo/shared/Div";
import useApp from "app/hooks/useApp";
import { AuthenticationContext } from "app/utils/constants/AuthContext";
import { AppSvgLogon, LOGINBGSVG1, LoginBGSVG } from "app/assets/svg/Svg";
import CREDITS from "../../../assets/nsdl_logo.png";


const otpValidation = yup
  .array()
  .of(
    yup
      .string()
      .matches(/^\d+$/, 'Each PIN digit should be a digit')
      .length(1, 'Each PIN digit must be exactly 1 digit')
  )
  .required('PIN is required')
  .test('len', 'PIN must be exactly 6 digits', (val) => val && val.join('').length === 6);

  const validationSchema = yup.object({

    otp: otpValidation,
    
  });

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const appData = useApp();
  const { login } = useContext(AuthenticationContext);

  
 
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
 
  const handleInputChange = (e, index, formik, fieldName, valueArray, refs) => {
    const inputValue = e.target.value;
    const newArray = [...valueArray];
  
    if (inputValue.match(/^\d+$/)) {
      newArray[index] = inputValue;
      formik.setFieldValue(fieldName, newArray);
      if (index < 5 && inputValue) {
        refs[index + 1].current.focus();
      }
    } else if (inputValue === '' && index > 0) {
      newArray[index] = inputValue;
      formik.setFieldValue(fieldName, newArray);
      refs[index - 1].current.focus();
    } else if (inputValue === '' && index === 0) {
      newArray[index] = inputValue;
      formik.setFieldValue(fieldName, newArray);
    }
  };
  
  const formik = useFormik({
    initialValues: {
       otp: Array.from({ length: 6 }, () => ''),
       
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
  
      console.log('Form submitted:', values);
      navigate("/current-user/set-password", { replace: true });
    },
  });
  const [counter, setCounter] = useState(10);
  useEffect(() => {
    if (counter > 0) {
        const timer = setTimeout(() => setCounter(counter - 1), 1000);
        return () => clearTimeout(timer);
    }
}, [counter]);
  return (
    <Div className='current_user_session '>
    <div className='h-100'>
       <div className='app_logo_svg '>
          <AppSvgLogon  />
       </div>
       <div className='current_user_session_card'>
          <form className='h-100'  onSubmit={formik.handleSubmit}  noValidate autoComplete="off">
             <div className="current_session_form justify-between h-100">
                <div className='w-100 '>
                   <Typography  sx={{textAlign:"center",mt:1}} className='login_greeting'>Reset Password</Typography>
                   <Typography textAlign={"center"}> We sent a code to {"sivar@gmail.com"} </Typography>
                   <Div sx={{ mt: 4 }}>
                   <Typography className="login_label">
                      Enter Code <span className="required">*</span>
                   </Typography>
                   <div className="Otp_input_box emailcode responisve">
                      {formik.values.otp.map((_, index) => (
                      <div key={index} className="otp_input small">
                         <TextField name={`otp[${index}]`} variant="outlined" margin="normal" type="text" inputProps={{ maxLength: 1,}}
                         inputRef={inputRefs[index]}
                         error={formik.touched.otp && formik.errors.otp ? true : false}
                         onChange={(e) => handleInputChange(e, index, formik, 'otp', formik.values.otp, inputRefs)} />
                      </div>
                      ))}
                   </div>
                   {formik.touched.otp && formik.errors.otp && (<small className="error text-danger">{formik.errors.otp}</small>)}
                </Div>
                {counter != 0 ?
                <div >
                   {counter < 10 ?
                   <Typography className="pt-10" textAlign={"right"} >
                      {" "}
                      <Link className="timer" underline="none">
                      Request a New Code in 00:0{counter}
                      </Link>
                   </Typography>
                   :
                   <Typography className="pt-10" textAlign={"right"} >
                      {" "}
                      <Link className="timer" underline="none">
                      Request a New Code in 00:{counter}
                      </Link>
                   </Typography>
                   }
                </div>
                :  
                <Typography className="pt-10" textAlign={"right"} onClick={()=>
                   setCounter(10)}>
                   <Link className="forget_link cursor-pointer" underline="none">
                   Resend Code </Link>
                </Typography>
                }
             </div>
             <Div  className='w-100'>
                <LoadingButton  className="login_btn"  fullWidth  type="submit"  variant="contained"  size="large" sx={{ mb: 3 }}>
                Continue
                </LoadingButton>
                <Typography textAlign={"center"}>
                   <Link className="back_to_login"  underline="none" href="/current-user/login" >
                   Back to Login</Link>
                </Typography>
             </Div>
       </div>
       </form>
    </div>
    <div className="credits_logo mt-25" >
       <p>Powered by</p>
       <img width={98} height={40} src={CREDITS} alt="credits" />
    </div>
 </div>
 <div className='charcter_svg'>
    <LOGINBGSVG1 />
 </div>
 <div className='mobile_svg'>
    <LoginBGSVG />
 </div>
 </Div>
 
  );
};

export default ResetPassword;

