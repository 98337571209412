import "./style.css";
import Div from "@jumbo/shared/Div";
import {
  Card,
  CardContent,
  MenuItem,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Search from "app/pages/app-pages/component/search";
import SkeletonTableLoader from "app/pages/app-pages/component/skeleton_loader/skeletonTableLoader";
import NoRecordsFound from "app/pages/app-pages/component/noRecordsFound";
import DyanamicDatePicker from "app/components/DatePicker/DyanamicDatePicker";
import Filter from "app/components/Filter/Filter";
import { FileIcon, FilterIcon, RefreshIcon } from "app/assets/svg/Svg";
import TransactionReceiptAdmin from "./TransactionReceiptAdmin";
import { FaReceipt } from "react-icons/fa6";


const ManageTransactions = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
 const [mobileOpen, setMobileOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const TableData = [
    {
      sno: "01",
      transactionId: "6301957864",
      name: "Siva Ramar",
      remarks: "Friends and Family",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "1,500.00 ",
      balance: "2,15,589.00",
      sender: "Jayshri Tiwari",
      mobile: "78945 61230",
      status: 1,
      method: "IMPS",
    },
    {
      sno: "02",
      transactionId: "2730175548",
      name: "Vishwas Patel",
      remarks: "Entertainment",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "1,100.00",
      balance: "2,00,589.00",
      sender: "Jayshri Tiwari",
      mobile: "78945 61230",
      status: 1,
      method: "NEFT",
    },
    {
      sno: "03",
      transactionId: "1696086371",
      name: "Navjot Kaur",
      remarks: "Investment",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "1,200.00 ",
      balance: "1,89,589.00",
      sender: "Siva Ramar",
      mobile: "78945 61230",
      status: 1,
      method: "IMPS",
    },
    {
      sno: "04",
      transactionId: "3954212189",
      name: "mamta Lodhi",
      remarks: "Food",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "3,460.00 ",
      balance: "2,01,589.00",
      sender: "Vishwas Patel",
      mobile: "78945 61230",
      status: 0,
      method: "IMPS",
    },
    {
      sno: "05",
      transactionId: "0500042814",
      name: "Paartho Ghosh",
      remarks: "Entertainment",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "1,260.00",
      balance: "1,66,989.00",
      sender: "Navjot Kaur",
      mobile: "78945 61230",
      status: 2,
      method: "IMPS",
    },
    {
      sno: "06",
      transactionId: "7725068610",
      name: "Bhavna Goyal",
      remarks: "Food",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "909.12",
      balance: "1,66,080.00",
      sender: "Jayshri Tiwari",
      mobile: "78945 61230",
      status: 0,
      method: "NEFT",
    },
    {
      sno: "07",
      transactionId: "1501341233",
      name: "Jayshri Tiwari",
      remarks: "Entertainment",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "765.54",
      balance: "1,65,315.00",
      sender: "mamta Lodhi",
      mobile: "78945 61230",
      status: 0,
      method: "NEFT",
    },
    {
      sno: "08",
      transactionId: "3910793817",
      name: "Ramesh Gupta",
      remarks: "Savings",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "899.00",
      balance: "1,66,214.00",
      sender: "Paartho Ghosh",
      mobile: "78945 61230",
      status: 2,
      method: "IMPS",
    },
    {
      sno: "09",
      transactionId: "1501341233",
      name: "Jayshri Tiwari",
      remarks: "Entertainment",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "765.54",
      balance: "1,65,315.00",
      sender: "Ramesh Gupta pandey sharma",
      mobile: "78945 61230",
      status: 0,
      method: "IMPS",
    },
    {
      sno: "10",
      transactionId: "3910793817",
      name: "Ramesh Gupta pandey sharma",
      remarks: "Savings for the gold investments",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "899.00",
      balance: "1,66,214.00",
      sender: "Jayshri Tiwari",
      mobile: "78945 61230",
      status: 1,
      method: "IMPS",
    },
  ];

  const terms = ["name", "remarks", "transactionId"];
  const [filteredData, setFilteredData] = useState(TableData);
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState("Method");
  const [type, setType] = useState("type");
  const [fillteranchorEl, setFillterAnchorEl] = React.useState(null);

  const fillterOpen = Boolean(fillteranchorEl);
const isid = fillterOpen ? "popover" : undefined;
  const handleSearch = (filteredData, loading) => {
    // Do something with the filtered data
    setLoading(loading);
    setFilteredData(filteredData);
  };
  const handleClickedRecipt = (event, i) => {
    setSelectedIndex(i);
    setMobileOpen(!mobileOpen);
  };
  function handleDrawerClose() {
    setMobileOpen(false);
  }
  const handleChangeMethod = (event) => {
    setMethod(event.target.value);
    };
    const handleChangeType = (event) => {
       setType(event.target.value);
       };
       const handleClose = () => {
        setFillterAnchorEl(null);
        };
        const handleClick = (event) => {
          setFillterAnchorEl(event.currentTarget);
          };
  return (
    <section>
      <Card className="responsivecard">
        <CardContent>
          <div
            className="common_header d-flex mb-0 mt-0 space-between "
            style={{ alignItems: "baseline" }}
          >
            <div className="d-flex items-center">
              <h2>Transactions (112) </h2>
              <div className="ml-20">{/** search customisable */}</div>
            </div>

            <div className="d-flex items-center">
              <div className="ml-20 ">
                {" "}
                <Search
                  data={TableData}
                  onSearch={handleSearch}
                  terms={terms}
                />{" "}
              </div>

              <div className="ml-20 mr-20">
                <DyanamicDatePicker />
              </div>

              <div>
              <Tooltip title="Filter">
                        <IconButton onClick={handleClick} className="icon_btn " variant="outlined">
                           <FilterIcon />
                        </IconButton>
                     </Tooltip>
                     {/* filter popover */}
                     <Popover className="date_popover" id={isid} open={fillterOpen} anchorEl={fillteranchorEl} onClose={handleClose}>
                        <div className="date_popover_inner">
                        <Select className={type == "type" ? "select-box mb-20" : 'select-box-select mb-20'}
                          value={type}
                              onChange={handleChangeType} fullWidth>
                              <MenuItem selected disabled value={"type"}>
                              Filter by Transaction Type
                              </MenuItem>
                              <MenuItem value={0}>IMPS</MenuItem>
                              <MenuItem value={1}>NEFT</MenuItem>
                              <MenuItem value={2}>RTGS</MenuItem>
                           </Select>
                           <Select className={method == "Method" ? "select-box mb-20" : 'select-box-select mb-20'}  value={method}
                              onChange={handleChangeMethod} fullWidth>
                              <MenuItem selected disabled value={"Method"}>
                              Filter by Status
                              </MenuItem>
                              <MenuItem value={0}>Pending</MenuItem>
                              <MenuItem value={1}>Active</MenuItem>
                              <MenuItem value={2}>Inactive</MenuItem>
                           </Select>
                           <div>
                              <Button className="datefilter_btn" variant="outlined" onClick={handleClose}>
                                 Submit
                              </Button>
                           </div>
                        </div>
                     </Popover>
              </div>

              <div>
                <Tooltip title="SVG icon Refresh">
                  <IconButton className="ml-10 icon_btn" variant="outlined">
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="ml-20">
                <Button
                  startIcon={<FileIcon />}
                  className="export_btn width-settings"
                  variant="outlined"
                >
                  {" "}
                  Export as Excel
                </Button>
              </div>
            </div>
          </div>

          <Div>
            <Table className="common_table ">
              <TableHead>
                <TableRow>
                  <TableCell> <TableSortLabel>Created On </TableSortLabel></TableCell>
                  <TableCell > Txn.Id </TableCell>
                  <TableCell> <TableSortLabel>Sender </TableSortLabel></TableCell>
                  <TableCell> <TableSortLabel>Mobile No </TableSortLabel> </TableCell>
                  <TableCell> <TableSortLabel>Recipient </TableSortLabel></TableCell>
                  <TableCell> Txn.Type </TableCell>
                  <TableCell className="amt_head"> Txn.Amount (₹) </TableCell>
                  <TableCell className="amt_head"> Convenience Fee (₹) </TableCell>
                  <TableCell className="amt_head">Total Amount (₹) </TableCell>
                  <TableCell> Status </TableCell>
                  <TableCell> Action </TableCell>  
              </TableRow>
              </TableHead> 
              <TableBody>
                {loading ? (
                  // Display the skeleton loader while data is loading
                  <SkeletonTableLoader tablecell="7" row="8" />
                ) : // Display the actual table content when data is loaded
                filteredData.length == 0 ? (
                  <NoRecordsFound tablecell="7" />
                ) : (
                  filteredData.map((item, i) => {
                    return (
                      <TableRow key={i} className="transactions">
                        
                        <TableCell>{item?.create_on} {item?.time} </TableCell>
                        <TableCell className="Link" >{item?.transactionId}</TableCell>
                        <TableCell >
                        {item?.sender.length > 15 ? (
        <Tooltip title={item?.sender}>
          <span>{`${item?.sender.slice(0, 13)}...`}</span>
       </Tooltip>
      ) : (
        <span>{item?.sender}</span>
      )}  
                        </TableCell>
                        <TableCell>{item?.mobile}</TableCell>
                        <TableCell >
                        {item?.name.length > 15 ? (
        <Tooltip title={item?.name}>
          <span>{`${item?.name.slice(0, 13)}...`}</span>
        </Tooltip>
      ) : (
        <span>{item?.name}</span>
      )}  
                        </TableCell>
                        <TableCell>{item?.method}</TableCell>
                        <TableCell className="amt_cell">
                          {item?.amount}
                        </TableCell>
                        <TableCell className="amt_cell" >
                          {item?.amount}
                        </TableCell>
                        <TableCell className="amt_cell" >
                          {item?.balance}
                        </TableCell>
                       

                        <TableCell>
                          {item?.status == 1 ? (
                            <span className="admin_active_status">
                              <span className="active_dot"></span> Success
                            </span>
                          ) : item?.status == 2 ? (
                            <span className="admin_inactive_status">
                              <span className="inactive_dot"></span> Failure
                            </span>
                          ) : (
                            <span className="admin_pending_status">
                              <span className="pending_dot"></span> Pending
                            </span>
                          )}
                        </TableCell>
                        <TableCell className="p-0">
                        <Tooltip title="Receipt">
                   {/**      <FaReceipt className="p-0" onClick={(event) => handleClickedRecipt(event, i)}/> */}
                    <span className="receipt_icon"  onClick={(event) => handleClickedRecipt(event, i)}> <FaReceipt /></span>   

                        </Tooltip>
                    
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={15}
                  rowsPerPage={5}
                  page={1}
                />
              </TableFooter>
            </Table>
          </Div>
        </CardContent>
      </Card>
      <TransactionReceiptAdmin handleDrawerClose={handleDrawerClose} mobileOpen={mobileOpen} handleDrawerToggle={handleClickedRecipt}/>
    </section>
  );
};

export default ManageTransactions;
