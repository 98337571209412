import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Drawer,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Div from "@jumbo/shared/Div";

const MyProfile = ({ handleDrawerClose, mobileOpen }) => {
  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpen}
        onClose={handleDrawerClose}
      >
        <Box sx={{ width: "520px" }}>
          <div className="sidebar_header">
            <h1>My Profile</h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>

          <div className="sideform_container" style={{ padding: "0" }}>
            <p className="profile_header">Company Details</p>
            <div className="d-flex items-center profile_info ">
              <div className="profile_info_name_details">
                <h2>Sree Selvas Diamonds Export And Import Private Limited</h2>
                <h3 className="partner">
                  {" "}
                  <span></span> Partnership
                </h3>
              </div>
            </div>
            <Div>
              <Table className="common_table my_profile">
                <TableBody>
                  <TableRow>
                    <TableCell>PAN No</TableCell>
                    <TableCell className="bold">AAR7645LTY</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      Aadhaar No
                    </TableCell>
                    <TableCell className="bold">6622-2350-9284</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>GST</TableCell>
                    <TableCell className="bold">HSG019213</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ verticalAlign: "top" }}
                      className="font-thin"
                    >
                      Address
                    </TableCell>
                    <TableCell className="bold">
                      324/7A, Varatharaja Perumal Street, Madurai, Tamil Nadu
                      6250007
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Div>
            <Div style={{ display: "none" }}>
              <Table className="common_table my_profile">
                <TableBody>
                  <TableRow>
                    <TableCell>Year of Gap </TableCell>
                    <TableCell className="bold">10</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Company Name</TableCell>
                    <TableCell className="bold">
                      Alpha - Alpha Enterprise{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Contact Person Name</TableCell>
                    <TableCell className="bold">Govinda Raj </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Business Email Id</TableCell>
                    <TableCell className="bold">govind123@gmail.com </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mobile No</TableCell>
                    <TableCell className="bold">9874561230</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Alterate Mobile No</TableCell>
                    <TableCell className="bold">9874561230</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>PAN No</TableCell>
                    <TableCell className="bold">AAR7645LTY</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      Aadhaar No
                    </TableCell>
                    <TableCell className="bold">6622-2350-9284</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>GST</TableCell>
                    <TableCell className="bold">HSG019213</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-thin">Address</TableCell>
                    <TableCell className="bold">
                      324/7A, Varatharaja Perumal Street,{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>State</TableCell>
                    <TableCell className="bold">Tamil Nadu</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>City</TableCell>
                    <TableCell className="bold">Madurai</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Pin Code</TableCell>
                    <TableCell className="bold">6250007</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Div>
            <div className="mt-20">
              <p className="profile_header">Partner Details</p>
              <div>
                <div className="d-flex items-center profile_info w-100">
                  <div className="profile_info_name_details w-100">
                    <h2>Ajay Krishna</h2>
                    <h3 className="partner">
                      {" "}
                      <span></span> Partner I
                    </h3>
                  </div>
                </div>
                <div className="partnerdetails1">
                  <Table className="common_table my_profile">
                    <TableBody>
                      <TableRow>
                        <TableCell>Mobile No</TableCell>
                        <TableCell className="bold">778945 23654</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Alterate Mobile No</TableCell>
                        <TableCell className="bold">89745 23654</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>PAN No</TableCell>
                        <TableCell className="bold">AAR7645LTY</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Aadhaar No
                        </TableCell>
                        <TableCell className="bold">6622-2350-9284</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
              <div className="mt-30">
                <div className="d-flex items-center profile_info w-100">
                  <div className="profile_info_name_details w-100">
                    <h2>Vijay Krishna</h2>
                    <h3 className="partner">
                      {" "}
                      <span></span> Partner II
                    </h3>
                  </div>
                </div>
                <div className="partnerdetails1">
                  <Table className="common_table my_profile">
                    <TableBody>
                      <TableRow>
                        <TableCell>Mobile No</TableCell>
                        <TableCell className="bold">99945 23654</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Alterate Mobile No</TableCell>
                        <TableCell className="bold">89965 23654</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>PAN No</TableCell>
                        <TableCell className="bold">ASS7645LTY</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Aadhaar No
                        </TableCell>
                        <TableCell className="bold">6622-2350-9256</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div> 
            <Div className="d-flex items-center" sx={{ ml: 2.6, mt: 1 }}>
              {/* <Button
                className="primary_btn w-200 "
                fullWidth
                type="submit"
                variant="contained"
                size="large"
              >
                Edit Profile
              </Button> */}
            </Div>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default MyProfile;
