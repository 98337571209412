import React, { useRef, useState } from "react";
import {Autocomplete,Box,Button,Drawer,Paper,TextField,Typography,} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { ToastServices } from "app/services/toastServices";
import ClearIcon from "@mui/icons-material/Clear";
import Div from "@jumbo/shared/Div";
import FindIfsc from "./findIfsc";
import { Verified } from "app/assets/svg/Svg";
import { SucessGif } from "app/components/SucessGif/SucessGif";


const validationSchema = yup.object({
  bname: yup.string().required("Benificiary name is required"),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(/^[6-9]\d{9}$/, "Invalid mobile number"),
  bbank: yup.object().shape({
    label: yup.string().required("benificiary bank is required"),
  }),
 ifsc: yup.string().required("IFSC code is required"),
  acc_no: yup
    .string()
    .required("Account number is required")
    .matches(/^\d{10}$/, "Account number must be 10 digits"),
  c_acc_no: yup
    .string()
    .required("Confirm account number is required")
    .oneOf(
      [yup.ref("acc_no")],
      "Confirm account number & account number must match"
    ),
});

const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} />;
};

const BaneficiaryCreate = ({handleDrawerToggle,mobileOpen,handleDrawerClose,}) => {
  const Bank = [
    { label: "HDFC Bank" },
    { label: "SBI Bank" },
    { label: "IOB Bank" },
    { label: "Canara Bank" },
  ];
  const formik = useFormik({
    initialValues: {
      bname: "",
      mobile: "",
      bbank: null,
      acc_type: null,
      ifsc: "",
      c_acc_no: "",
      acc_no: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Form submitted:", values);
      setOtppage(true);
      setFormentry(false);
    },
  });
// usesate 
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [formentery, setFormentry] = useState(true);
  const [showOtppage, setOtppage] = useState(false);
  const [showOtpenter, setOtpenter] = useState(true);
  const [showSucess, setSucess] = useState(false);
  const [verify, setVerify] = useState(false);
  const [ifscOpen, setIfscOpen] = useState(false);
// functions 
 const handleOtpChange = (e, index) => {
    if (e.target.value.match(/^\d+$/)) {
      const newOtp = [...otp];
      newOtp[index] = e.target.value;
      setOtp(newOtp);

      if (index < 3 && e.target.value) {
        inputRefs[index + 1].current.focus();
      }
    }

    if (e.target.value === "" && index > 0) {
      const newOtp = [...otp];
      newOtp[index] = e.target.value;
      setOtp(newOtp);
      inputRefs[index - 1].current.focus();
    }
    if (e.target.value === "" && index === 0) {
      const newOtp = [...otp];
      newOtp[index] = e.target.value;
      setOtp(newOtp);
    }
  };
  const sucess = (e) => {
    e.preventDefault();
    const isFilled = otp.every((value) => value !== "");

    if (isFilled) {
      ToastServices.SucessToast("OTP successfully verified");
      setOtpenter(false);
      setSucess(true);
    } else {
      ToastServices.ErroToast("Invalid OTP or fill all the fields");

      console.log("Please fill all OTP fields");
    }
  };
function handleIfscToggle() {
    setIfscOpen(!ifscOpen);
    handleDrawerClose();
  }
  function handleIfscClose() {
    setIfscOpen(false);
  }
  const Verification = () => {
    setVerify(!verify);
  };
  const backtoList = () =>{
    setFormentry(true);
    setOtppage(false);
  }

  return (
    <div>
      <Drawer  variant="temporary"anchor={"right"}open={mobileOpen}onClose={handleDrawerToggle}>
        <Box className="sidenave_box" sx={{ width: "520px" }}>
          <div className="sidebar_header">
            <h1>Add New Beneficiary</h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>
          {/* Beneficiary form  */}
          {formentery === true ? (
            <div className="sideform_container">
              <form onSubmit={formik.handleSubmit}noValidate autoComplete="off">
                <Div sx={{ mt: 0 }}>
                  <Typography className="from_label">
                    Beneficiary Name <span className="required">*</span>
                  </Typography>
                  <TextField name="bname"placeholder="Enter Beneficiary Name"onChange={formik.handleChange}error={formik.touched.bname && Boolean(formik.errors.bname)}helperText={formik.touched.bname && formik.errors.bname}
                    className="form_new_style"variant="outlined"fullWidth/>
                </Div>
                <Div sx={{ mt: 2 }}>
                  <Typography className="from_label"> Mobile No <span className="required">*</span></Typography>
                  <TextField name="mobile" placeholder="Enter Mobile No"onChange={formik.handleChange}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                    helperText={formik.touched.mobile && formik.errors.mobile}
                    className="form_new_style"
                    variant="outlined"
                    fullWidth
                  />
                </Div>
                <Div sx={{ mt: 2 }}>
                  <Typography className="from_label">
                    Benificiary Bank <span className="required">*</span>
                  </Typography>
                  <Autocomplete disablePortal name="bbank"options={Bank}getOptionLabel={(option) => option.label}PaperComponent={CustomPaper}fullWidthvalue={formik.values.bbank}
                    onChange={(_, newValue) =>
                      formik.setFieldValue("bbank", newValue)
                    }
                    renderInput={(params) => (
                      <TextField placeholder="Select Benificiary Bank"className="form_new_style form_selct"
                        error={formik.touched.bbank && Boolean(formik.errors.bbank)}
                        helperText={formik.touched.bbank && formik.errors.bbank?.label}
                        fullWidth {...params}/>
                    )}
                  />
                </Div>
                <Div sx={{ mt: 2 }}>
                  <Typography className="from_label">Account No <span className="required">*</span></Typography>
                  <TextField name="acc_no"placeholder="Enter Account No"onChange={formik.handleChange}
                    error={formik.touched.acc_no && Boolean(formik.errors.acc_no)}
                    helperText={formik.touched.acc_no && formik.errors.acc_no}
                    className="form_new_style"
                    variant="outlined"
                    fullWidth
                  />
                </Div>
                <Div sx={{ mt: 2 }}>
                  <Div className="dlfex-justify-between-align-center">
                    <Typography className="from_label">
                      Confirm Account No <span className="required">*</span>
                    </Typography>
                    <Typography className="findifce" onClick={Verification}>
                     {verify === true ? (
                     <p className="verified d-flex items-center gap-5">
                          <Verified /> Verified
                        </p>
                      ) : (
                        "Verify"
                      )}
                    </Typography>
                  </Div>
                  <TextField
                    name="c_acc_no"
                    placeholder="Enter Confirm Account No"
                    onChange={formik.handleChange}
                    error={formik.touched.c_acc_no && Boolean(formik.errors.c_acc_no)}
                    helperText={formik.touched.c_acc_no && formik.errors.c_acc_no}
                    className="form_new_style"
                   variant="outlined"
                    fullWidth/>
                </Div>
                <Div sx={{ mt: 2 }}>
                  <Div className="dlfex-justify-between-align-center">
                    <Typography className="from_label">
                      IFSC Code <span className="required">*</span>
                    </Typography>
                    <Typography onClick={handleIfscToggle} className="findifce">
                      Find IFSC
                    </Typography>
                  </Div>
                  <TextField
                    name="ifsc"
                    placeholder="Enter IFSC Code"
                    onChange={formik.handleChange}
                    error={formik.touched.ifsc && Boolean(formik.errors.ifsc)}
                    helperText={formik.touched.ifsc && formik.errors.ifsc}
                    className="form_new_style"
                    variant="outlined"
                    fullWidth
                  />
                </Div>
                <Div sx={{ mt: 0 }}>
                  <Button
                    className="primary_btn w-360"
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    >
                    Add Beneficiary
                  </Button>
                </Div>
              </form>
            </div>
          ) : (
            ""
          )}
          {/* OTP form  */}
          {showOtppage === true ? (
            <div className="beneficarty_add_details">
              <table className="w-100 beneficary_details">
                <tr>
                  <td className="table_label">Beneficiary Name</td>
                  <td className="table_value">Roger Binny</td>
                </tr>
                <tr>
                  <td className="table_label">Mobile No</td>
                  <td className="table_value"> 76255 45612</td>
                </tr>
                <tr>
                  <td className="table_label">Beneficiary Bank</td>
                  <td className="table_value">HDFC Bank</td>
                </tr>
                <tr>
                  <td className="table_label">Account No</td>
                  <td className="table_value">998745123652</td>
                </tr>
                <tr>
                  <td className="table_label">IFSC Code</td>
                  <td className="table_value">HDFC0987182</td>
                </tr>
                </table>
              {showOtpenter === true ? (
                <form noValidate autoComplete="off">
                  <div className="verify_otp_verification">
                    <p className="verify_otp_verification_title">
                      Verify OTP to add beneficiary
                    </p>
                    <p className="verify_otp_verification_subtext">
                      Enter OTP Code sent to <span> 89*** ***90</span>
                    </p>
                    <div className="Otp_input_box">
                      {otp.map((value, index) => (
                        <div key={index} className="otp_input">
                          <TextField
                            variant="standard"
                            margin="normal"
                            type="text"
                            inputProps={{
                              maxLength: 1,
                            }}
                            value={value}
                            onChange={(e) => handleOtpChange(e, index)}
                            inputRef={inputRefs[index]}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="dlfex-justify-between-align-center otp_actions">
                      <p>Didn’t receive OTP Code?</p>
                      <h6>Resend Code</h6>
                    </div>
                    <div className="d-flex items-center confirmation_buttons gap">
                      <Button
                        type="submit"
                        className="confrm_btn"
                        onClick={(e) => sucess(e)}
                      >
                        Confirm OTP
                      </Button>
                      <Button
                        className="outline_btn" onClick={backtoList} >
                          Cancel
                          </Button>
                    </div>
                  </div>
                </form>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {/* Success Page */}
          {showSucess === true ? (
            <div className="verification_sucess">
              <div className="gif_cont">
               <SucessGif />
              </div>
              <p className="verify_greet text-center">
                Above beneficiary details has been <br />  added successfully
              </p>
              <div>
                <p
                  className="back_bene text-center"
                  onClick={handleDrawerClose}
                >
                  Go to Beneficiary list
                </p>
              </div>
              
            </div>
          ) : (
            ""
          )}
        </Box>
      </Drawer>
      <FindIfsc beneForm={handleDrawerToggle} handleDrawerClose={handleIfscClose} ifscOpen={ifscOpen} handleDrawerToggle={handleIfscToggle}/>
    </div>
  );
};

export default BaneficiaryCreate;
