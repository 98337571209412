import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {
  Card,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { CiSearch } from "react-icons/ci";
import { IoReceiptOutline } from "react-icons/io5";
import MiniStatement from "app/pages/app-pages/home/MiniStatement/MiniStatement";
import { Currency, UserSwitch } from "../../../assets/svg/Svg";
import NO_RECORD_FOUND from "../../../assets/no_records_found.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CurrentUserChart from "app/pages/admin-pages/dashboard/chart/CurrentUsersChart";
import YearLineChart from "app/pages/admin-pages/dashboard/chart/YearLineChart";
import MonthLineChart from "app/pages/admin-pages/dashboard/chart/MonthLineChart";
import WeekLineChart from "app/pages/admin-pages/dashboard/chart/WeekLineChart";


const formatAmount = (value) => {
  if (value < 100000) {
    return (value / 1000).toFixed(0) + "k";
  } else if (value < 10000000) {
    return (value / 100000).toFixed(1) + "L";
  } else {
    return (value / 10000000).toFixed(1) + "cr";
  }
};


const AdminDashboard = () => {
  const navigate = useNavigate();
  const Logo = `https://tse2.mm.bing.net/th?id=OIP.HLCFd2lEK2PDX2wc4BJpMAAAAA&pid=Api&P=0&h=180`;
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const activeColor = "#ffc275";
  const disabledColor = "#ffc275";
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("thisWeek");
  const [mobileOpens, setMobileOpens] = useState(false);

  const currentDate1 = new Date();
  const currentYear = currentDate1.getFullYear();
  const currentMonth1 = currentDate1.getMonth();
  const startDate = new Date(currentYear, currentMonth, 1);
  const endDate = new Date(currentYear, currentMonth + 1, 0);
  const [commissionFilter, setcommissionFilter] = useState("week");

  const labels = [];
  const data = [];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthName = months[currentDate1.getMonth()];
  for (let day = 1; day <= 30; day++) {
    const date = new Date(currentYear, currentMonth1, day);
    if (date >= startDate && date <= endDate) {
      labels.push(
        date.toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      );
      data.push(Math.floor(Math.random() * 100000) + 50000);
    }
  }

  const handleCommissionFilter = (event) => {
    setcommissionFilter(event.target.value);
  };

  const dataForOptions = {
    thisWeek: {
      title: "Wee",
      labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      data: [5000, 4000, 3000, 4000, 1000, 2000, 3000],
    },
    thisMonth: {
      title: "Month",
      labels: Array.from({ length: 30 }, (_, i) => ` ${i + 1}`),
      data: Array.from(
        { length: 30 },
        (_, i) => Math.floor(Math.random() * 100000) + 50000
      ),
    },
    thisYear: {
      title: "Year",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      data: [200000, 220000, 210000, 230000, 240000, 220000, 250000],
    },
  };
  // thisMonth: { title: 'Month',  labels:labels,  data: data   },

  function getWeekLabels() {
    const today = new Date();
    const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
    const lastDay = new Date(
      today.setDate(today.getDate() - today.getDay() + 6)
    );
    const startDate = formatDate(firstDay);
    const endDate = formatDate(lastDay);
    return [`Days (${startDate} - ${endDate})`];
  }

  function formatDate(date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  const getXAxisTitle = (option) => {
    switch (option) {
      case "thisWeek":
        return getWeekLabels();
      case "thisMonth":
        return `Month( ${currentMonthName} ${currentYear} )`;
      case "thisYear":
        return `Year(${new Date().getFullYear().toString()})`; // Set x-axis title to current year when 'This Year' is selected
      default:
        return "";
    }
  };
  const getCategoryColor = (index) => {
    return index <= currentMonth ? activeColor : disabledColor;
  };
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getBorderRadius = (option) => {
    switch (option) {
      case "thisWeek":
        return 20;
      case "thisMonth":
        return 5;
      case "thisYear":
        return 12;
      default:
        return 20;
    }
  };

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: getBorderRadius(selectedOption),
        colors: {
          backgroundBarColors: ["#ffc275"],
          backgroundBarOpacity: 0.2,
        },
      },
      colors: ["#ffc275"],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: dataForOptions[selectedOption]?.labels || [],
      title: {
        text: getXAxisTitle(selectedOption),
      },

      labels: {
        style: {
          fontSize: "14px",
          fontWeight: "medium",
          fontFamily: "Inter, sans-serif",
        },
      },
    },
    yaxis: {
      title: {
        text: "Amount (₹)",
      },

      labels: {
        formatter: function (val) {
          return formatAmount(val);
        },
        style: {
          fontSize: "14px",
          fontWeight: "medium",
          fontFamily: "Inter, sans-serif",
        },
      },
    },
    fill: {
      opacity: 1,

      colors: Array.from({ length: 12 }, (_, i) => getCategoryColor(i)),
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        if (selectedOption === "thisMonth") {
          const label = w.config.xaxis.categories[dataPointIndex];
          const currentDate = new Date();
          currentDate.setDate(label);
          const formattedDate = formatDate(currentDate);
          series = series[seriesIndex][dataPointIndex];
          const roundedSeries = Number(series).toFixed(2);
          return `  <div class="custom-tooltip">
          <span class="labels">${formattedDate}</span>
          <div class="border-line">
            <div class="value"><span class="amount">₹ ${roundedSeries} </span></div>
          </div>
        </div>`;
        } else {
          const label = w.config.xaxis.categories[dataPointIndex];
          series = series[seriesIndex][dataPointIndex];
          const roundedSeries = Number(series).toFixed(2);
          return `  <div class="custom-tooltip">
       <span class="labels">${label}</span>
       <div class="border-line">
         <div class="value"><span class="amount">₹ ${roundedSeries} </span></div>
       </div>
     </div>`;
        }
      },
      x: {
        show: true,
        formatter: function (val) {
          return val;
        },
      },
      y: {
        formatter: function (val) {
          return val.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        },
      },
      style: {
        borderRadius: getBorderRadius(selectedOption),
        backgroundColor: "#FFA500", // Change background color to orange
        color: "#000", // Change text color to black
      },
    },
    noData: {
      text: "No Data Found",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: -20,
      style: {
        color: "black",
        fontSize: "16px",
        fontFamily: "Inter, sans-serif",
      },
    },
  };
  const series = [
    {
      name: "Amount",
      data: dataForOptions[selectedOption]?.data || [],
    },
  ];

  function handleDrawerToggled() {
    setMobileOpens(!mobileOpens);
    setAnchorEl(null);
  }
  function handleDrawerClosed() {
    setMobileOpens(false);
  }
  const handleCurrentUser = () => {
    navigate("/current-user");
  };

  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={7} xs={12} spacing={2}>
            <Grid container spacing={2}>
              <Grid container spacing={2}></Grid>
              <Grid container spacing={2} lg={12}>
                <Grid item xs={12} sm={6}>
                  <Card sx={{ height: "270px", mt: 2 , marginLeft:'16px'}}>
                    <div className="accont_info">
                      <Grid container lg={12}>
                        <Grid item lg={12}>
                          <p className="mt-7"> Current Users </p>
                        </Grid>
                      </Grid>
                    </div>
                    {/* <Grid
                      container
                      xs={12}
                      sx={{ borderBottom: "1.4px solid #E7E6EC" }}
                    >
                      <Grid item xs={6}>
                        <div className="statement_info">
                          <Typography className="from_label">
                            <span className={"circle1"}></span>
                            Active{" "}
                          </Typography>
                          <p>
                            279{" "}
                            <span className={"text-color1"}>
                              <ArrowDownwardIcon sx={{ fontSize: "11px" }} />{" "}
                              14%{" "}
                            </span>
                            <ArrowForwardIosIcon
                              className="arrow"
                              onClick={handleCurrentUser}
                            />
                          </p>
                        </div>
                      </Grid> */}
                      {/* <Grid
                        item
                        xs={6}
                        sx={{ borderLeft: "1.4px solid #E7E6EC" }}
                      >
                        <div className="statement_info">
                          <Typography className="from_label">
                            Inactive <span className={"circle2"}></span>
                          </Typography>
                          <p>
                            118{" "}
                            <span className={"text-color2"}>
                              <ArrowUpwardIcon sx={{ fontSize: "11px" }} />{" "}
                              12.5%{" "}
                            </span>
                            <ArrowForwardIosIcon
                              className="arrow"
                              onClick={handleCurrentUser}
                            />
                          </p>
                        </div>
                      </Grid> 
                    </Grid>*/}
                    <div className="mt-20"> </div>
                    <CurrentUserChart />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card sx={{ height: "270px", mt: 2 }}>
                    <div className="accont_info">
                      <Grid container xs={12}>
                        <Grid item xs={12}>
                          <p className="mt-7"> Saving Users </p>
                        </Grid>
                      </Grid>
                    </div>
                    {/* <Grid
                      container
                      xs={12}
                      sx={{ borderBottom: "1.4px solid #E7E6EC" }}
                    >
                      <Grid item xs={6}>
                        <div className="statement_info">
                          <Typography className="from_label">
                            <span className={"circle1"}></span>
                            Active{" "}
                          </Typography>
                          <p>
                            279{" "}
                            <span className={"text-color1"}>
                              <ArrowDownwardIcon sx={{ fontSize: "11px" }} />{" "}
                              14%{" "}
                            </span>
                            <ArrowForwardIosIcon
                              className="arrow"
                              onClick={handleCurrentUser}
                            />
                          </p>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{ borderLeft: "1.4px solid #E7E6EC" }}
                      >
                        <div className="statement_info">
                          <Typography className="from_label">
                            Inactive <span className={"circle2"}></span>
                          </Typography>
                          <p>
                            118{" "}
                            <span className={"text-color2"}>
                              <ArrowUpwardIcon sx={{ fontSize: "11px" }} />{" "}
                              12.5%{" "}
                            </span>
                            <ArrowForwardIosIcon
                              className="arrow"
                              onClick={handleCurrentUser}
                            />
                          </p>
                        </div>
                      </Grid>
                    </Grid> */}
                    <div className="mt-20"> </div>
                    <CurrentUserChart />
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={11.8} xs={12} marginTop={1.5}>
              <Card sx={{ height: "610px" }}>
                <div className="accont_info">
                  <Grid container>
                    <Grid item xs={8.1}>
                      <p className="mt-7"> Commission Analytics </p>
                    </Grid>
                    <Grid item xs={3.2}>
                      <div>
                        <Select
                          sx={{ minWidth: "200px" }}
                          className="select-box-select"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={commissionFilter}
                          onChange={handleCommissionFilter}
                        >
                          <MenuItem value={"week"}> This Week </MenuItem>
                          <MenuItem value={"month"}> This Month </MenuItem>
                          <MenuItem value={"year"}> This Year </MenuItem>
                        </Select>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="pl-20 mt-20">
                  {commissionFilter === "year" && <YearLineChart />}
                  {commissionFilter === "month" && <MonthLineChart />}
                  {commissionFilter === "week" && <WeekLineChart />}
                </div>
              </Card>
            </Grid>
          </Grid>
          <Grid item lg={5} xs={12}>
            <Card className="" sx={{ height: "891px" }}>
              <div className=" p-22 ">
                <div className="recent_transaction_header">
                  <p>Recent Transactions</p>
                  <h6
                    onClick={() => {
                      navigate("/transactionlist");
                    }}
                  >
                    See all
                  </h6>
                </div>
                <div className="recent_transaction_header mt-10">
                  <OutlinedInput
                    className="form_new_style w-100"
                    id="outlined-adornment-password"
                    placeholder="Search"
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start">
                          <CiSearch />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
              </div>
              <div className="recent_search_result">
                {/* <div className="chart_section_nodata">
                      <img  src={NO_RECORD_FOUND} alt="your-image-alt-text" />
                      <Typography  variant={'h2'} className={'text-center no_records'} sx={{alignItems: 'center'}}> No Records Found </Typography> 
               </div> */}
                <div>
                  <div className="recent_months_div">
                    <p>September 2023</p>
                  </div>
                  <div className="main_results">
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Sathya Jyothi</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 420.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Gowtham K</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 420.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Vijay Kumar</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 120.50 </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="recent_months_div">
                    <p>August 2023 </p>
                  </div>
                  <div className="main_results">
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Sathya Jyothi</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 420.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Gowtham K</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 420.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Vijay Kumar</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 120.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Vijay Kumar</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 120.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Vijay Kumar</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 120.50 </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <MiniStatement
        handleDrawerClosed={handleDrawerClosed}
        mobileOpens={mobileOpens}
        handleDrawerToggled={handleDrawerToggled}
      />
    </div>
  );
};

export default AdminDashboard;


