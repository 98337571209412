
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, TextField, Typography, Link } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import {useFormik } from "formik";
import Div from "@jumbo/shared/Div";
import { AdminSVg, AppSvgLogon } from "app/assets/svg/Svg";
import CREDITS from '../../../../assets/nsdl_logo.png'


const otpValidation = yup
  .array()
  .of(
    yup
      .string()
      .matches(/^\d+$/, 'Each PIN digit should be a digit')
      .length(1, 'Each PIN digit must be exactly 1 digit')
  )
  .required('PIN is required')
  .test('len', 'PIN must be exactly 6 digits', (val) => val && val.join('').length === 6);

  const validationSchema = yup.object({

    otp: otpValidation,
    
  });

const AdminResetPassword = () => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ]; 
  const [counter, setCounter] = useState(10);


  const handleInputChange = (e, index, formik, fieldName, valueArray, refs) => {
    const inputValue = e.target.value;
    const newArray = [...valueArray];
  
    if (inputValue.match(/^\d+$/)) {
      newArray[index] = inputValue;
      formik.setFieldValue(fieldName, newArray);
      if (index < 5 && inputValue) {
        refs[index + 1].current.focus();
      }
    } else if (inputValue === '' && index > 0) {
      newArray[index] = inputValue;
      formik.setFieldValue(fieldName, newArray);
      refs[index - 1].current.focus();
    } else if (inputValue === '' && index === 0) {
      newArray[index] = inputValue;
      formik.setFieldValue(fieldName, newArray);
    }
  };

  
  const formik = useFormik({
    initialValues: {
       otp: Array.from({ length: 6 }, () => ''),
       
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
      console.log('Form submitted:', values);
      navigate("/set-password", { replace: true });
    },
  });
  useEffect(() => {
    if (counter > 0) {
        const timer = setTimeout(() => setCounter(counter - 1), 1000);
        return () => clearTimeout(timer);
    }
}, [counter]);
  

  return (
    <div>
    <div className="session_page ">
       <Grid className="h-full session_grid" container>
          <Grid
          className="session_image_content"
          item
          lg={7}
          style={{ alignSelf: "center" }}
          >
          <div className="session_content">
             <div className="session_img">
                <AdminSVg />
             </div>
          </div>
       </Grid>
       <Grid item lg={5} style={{ alignSelf: "center" }}>
       <div className="session_card flex_container_col justify-between">
          <div className="session_wrapper mb-10">
             <div className="session_form">
                <div className="session_title mt-20 text-center">
                   <AppSvgLogon />
                </div>
                <div className="admin_form">
                <form onSubmit={formik.handleSubmit} noValidate autoComplete='off'>
                   <div className="text-center">
                      <h2 className="admin_greetings">Reset Password</h2>
                      <Typography   textAlign={"center"} > We sent a code to {"sivar@gmail.com"}</Typography>
                   </div>
                   <div >
                      <Div>
                         <Typography
                         className="from_label"
                         sx={{ mb: "0 !important", mt: 4 }}
                         >
                         Enter Code <span className="required">*</span>{" "}
                         </Typography>
                         <div className="Otp_input_box small">
                            {formik.values.otp.map((_, index) => (
                            <div key={index} className="otp_input small">
                               <TextField
                               name={`otp[${index}]`}
                               variant="outlined"
                               margin="normal"
                               type="text"
                               inputProps={{
                               maxLength: 1,
                               }}
                               inputRef={inputRefs[index]}
                               error={formik.touched.otp && formik.errors.otp ? true : false}
                               onChange={(e) => handleInputChange(e, index, formik, 'otp', formik.values.otp, inputRefs)}
                               />
                            </div>
                            ))}
                         </div>
                         {formik.touched.otp && formik.errors.otp && (
                         <Div className="error text-danger">{formik.errors.otp}</Div>
                         )}
                      </Div>
                      {counter != 0 ?
                      <div >
                         {counter < 10 ?
                         <Typography className="pt-10" textAlign={"right"} >
                            {" "}
                            <Link className="timer" underline="none">
                            Request a New Code in 00:0{counter}
                            </Link>
                         </Typography>
                         :
                         <Typography className="pt-10" textAlign={"right"} >
                            {" "}
                            <Link className="timer" underline="none">
                            Request a New Code in 00:{counter}
                            </Link>
                         </Typography>
                         }
                      </div>
                      :  
                      <Typography className="pt-10" textAlign={"right"} onClick={()=>
                         setCounter(10)}>
                         {" "}
                         <Link className="forget_link cursor-pointer" underline="none">
                         {" "}
                         Resend Code{" "}
                         </Link>
                      </Typography>
                      }
                      <LoadingButton
                      className="login_btn"
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                      //  onClick={handleClick}
                      >
                      Continue
                      </LoadingButton>
                      <Typography textAlign={"center"}>
                         <Link
                            className="back_to_login"
                            underline="none"
                            href="/admin"
                            >
                         {" "}
                         Back to Login{" "}
                         </Link>
                      </Typography>
                   </div>
                </form>
             </div>
          </div>
       </div>
       <div className="credits_logo mb-30  text-center" >
          <p>Powered by</p>
          <img width={98} height={40} src={CREDITS} alt="credits" />
       </div>
    </div>
    </Grid>
    </Grid>
 </div>
 </div>
  );
};

export default AdminResetPassword;
