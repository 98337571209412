import React from "react";
import JumboLayout from "@jumbo/components/JumboLayout";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import layoutConfig from "./layoutConfig";

import { AuthenticationContext } from "app/utils/constants/AuthContext";
import { useContext } from "react";

import AdminHeader from "app/pages/admin-pages/admin-header/AdminHeader";

const AdminLayout = ({children}) => {
    const {setJumboLayoutOptions} = useJumboLayout();
    const { isAuthenticated } = useContext (AuthenticationContext)

    React.useEffect(() => {
        setJumboLayoutOptions(layoutConfig);
        
       

    }, []);

    return (

        <JumboLayout>
          
          <AdminHeader />
            
                     
                
           
           
           


            
           
           <div style={{padding: "16px"}}>
           {children}
           </div>

            
        </JumboLayout>
    );
};

export default AdminLayout;
