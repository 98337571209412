import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SavingsUserHeader from "./Header";
import Div from "@jumbo/shared/Div";
import { Grid, Typography, Card, CardContent, Divider, Button } from "@mui/material";
import BorderColorTwoToneIcon from "@mui/icons-material/BorderColorTwoTone";
import userIMG from "app/assets/savinguser img.png";
import piggybank from "app/assets/Piggy bank-bro 1.png";
import { Link } from "react-router-dom";
import EditInfo from "./EditInfo";
import Backdrop from '@mui/material/Backdrop';
import { SucessGif } from "app/components/SucessGif/SucessGif";

const SavingsUserHome = () => {
  const navigate = useNavigate();
  const [kyc, setKyc] = useState(false);

  const handleKYC = () => {
    setKyc(true);
  };

  const [mobileOpens, setMobileOpens] = React.useState(false);

  function handleDrawerToggled() {
    setMobileOpens(!mobileOpens);
  }
  function handleDrawerClosed() {
    setMobileOpens(false);
  }


    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
      setOpen(false);
    };
    const handleOpen = () => {
      setOpen(true);
    };
  

  return (
    <Div>
      <SavingsUserHeader />
      <Card className="responsivecard" sx={{ margin: "25px" }}>
        <CardContent className="d-flex">

        <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <div className="gif_cont" style={{
          backgroundColor:'white',
          borderRadius:'20px' ,
          width:'350px', 
          height:'300px',
          display:'flex',
          flexWrap:'wrap',
          justifyContent:'center',
          alignItems:'center',
          padding:'25px'}}>

             <div style={{width:'150px'}}>
          <SucessGif  />
              </div>
              <Typography variant="p" fontWeight={400} fontSize={"15px"} color={'black'} textAlign={'center'}>
               Your account has been successfully created! 
                    </Typography>
               <Button className='login_session' >Continue</Button>     
          
          </div>
              
      </Backdrop>
    </div>
          
          <Div>
            <div className="d-flex items-center">
              {!kyc ? (
                <div className="d-flex items-center">
                  <Typography className="kyc_waiting_card">
                    Waiting for KYC Verification
                  </Typography>
                  <Typography className="kyc_verify" onClick={handleKYC}>
                    Click & Verify KYC
                  </Typography>
                </div>
              ) : (
                <Typography className="kyc_active_card">Active</Typography>
              )}
            </div>

            <Grid item lg={5} xs={12}>
              <Card className="" sx={{ width: "650px", marginTop: "25px" }}>
                <div className=" p-10 " style={{ backgroundColor: "#F9F6EF" }}>
                  <div
                    className="recent_transaction_header"
                    style={{ padding: "5px 10px" }}
                  >
                    <Typography variant="p" fontWeight={600} fontSize={"20px"}>
                      User Info
                    </Typography>
                    <Typography
                      variant="p"
                      fontWeight={400}
                      fontSize={"18px"}
                      color={"#D16513"}
                      sx={{ cursor: "pointer" }}
                      onClick={handleDrawerToggled}
                      className="edit_info_savings_view"
                    >
                      <BorderColorTwoToneIcon sx={{ fontSize: "19px" }} /> Edit
                      Info
                    </Typography>
                  </div>
                </div>
                <div className="personal_details_savings">
                  <Typography variant="p" fontWeight={600} fontSize={"18px"}>
                    Personal Details
                  </Typography>
                  <Grid container spacing={1} lg={12} xs={12} marginTop={3}>
                    <Grid item xs={4}>
                      <div
                        style={{
                          width: "200px",
                          height: "170px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={userIMG}
                          alt="img"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        <Typography
                          variant="p"
                          fontWeight={600}
                          fontSize={"22px"}
                          display={"block"}
                        >
                          Vignesh Kumar M
                        </Typography>
                        <Typography
                          variant="p"
                          fontWeight={600}
                          fontSize={"16px"}
                          display={"block"}
                          color={"#107100"}
                        >
                          Id: CUSS08
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={7.5} marginLeft={1}>
                      <div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Phone Number
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            +91 83456 34536
                          </Typography>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Email Id
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            vigneshkumar1234@gmail.com
                          </Typography>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Date of Birth
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            23/04/1995
                          </Typography>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Address
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            13604 89ths StrGokhale Road, Chinna Chokkikulam,
                            Madurai, Tamil Nadu 625002
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={8}
                    item
                    sx={{
                      backgroundColor: "#C1C1C1",
                      height: "0.5px",
                      marginTop: "30px",
                    }}
                  ></Grid>
                </div>

                <div className="personal_details_savings">
                <Typography variant="p" fontWeight={600} fontSize={"18px"}>
                    Other Details
                  </Typography>
                  <Grid container spacing={1} lg={12} xs={12} marginTop={3}>
                    <Grid item xs={5.5}>
                      <div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Marital Status
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            Single
                          </Typography>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Employee Type
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            Salaried
                          </Typography>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Company Experience
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            3 Years
                          </Typography>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Location
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            Vasanthanagar, near petrol bulk, madurai 625002.
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={5.5} marginLeft={2}>
                      <div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Father / Spouse Name
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            Manikandan S
                          </Typography>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Company Name
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            Technogenesis
                          </Typography>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Income(₹)
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            2 LPA
                          </Typography>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={8}
                    item
                    sx={{
                      backgroundColor: "#C1C1C1",
                      height: "0.5px",
                      marginTop: "30px",
                    }}
                  ></Grid>
                </div>

                <div className="nominee_details_savings">
                <Typography variant="p" fontWeight={600} fontSize={"18px"}>
                    Nominee Details
                  </Typography>
                  <Grid container spacing={1} lg={12} xs={12} marginTop={3}>
                    <Grid item xs={4}>
                      <div
                        style={{
                          width: "200px",
                          height: "170px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={userIMG}
                          alt="img"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        <Typography
                          variant="p"
                          fontWeight={600}
                          fontSize={"22px"}
                          display={"block"}
                        >
                          Vinoth V
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={7.5} marginLeft={1}>
                      <div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Phone Number
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            +91 92563 57489
                          </Typography>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Email Id
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            vinothr1234@gmail.com
                          </Typography>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Date of Birth
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            12/03/1996
                          </Typography>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="p"
                            fontWeight={500}
                            fontSize={"15px"}
                            display={"block"}
                            color={"#777676"}
                          >
                            Address
                          </Typography>
                          <Typography
                            variant="p"
                            fontWeight={600}
                            fontSize={"16px"}
                            display={"block"}
                          >
                            13604 89ths StrGokhale Road, Chinna Chokkikulam,
                            Madurai, Tamil Nadu 625002
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Div>

          <Div>
            <div
              style={{
                position: "fixed",
                top: "100px",
                right: "100px",
                width: "550px",
                height: "550px",
              }}
            >
              <img
                src={piggybank}
                alt="img"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
              <Typography
                variant="p"
                fontWeight={600}
                fontSize={"24px"}
                textAlign={"center"}
                display={"block"}
                color={"#A1A1A1"}
              >
                Saving Account
              </Typography>
            </div>
          </Div>
          <EditInfo
           handleDrawerToggled={handleDrawerToggled} 
           handleDrawerClosed={handleDrawerClosed} 
           mobileOpens={mobileOpens} />
        </CardContent>
      </Card>
    </Div>
  );
};

export default SavingsUserHome;
