import { Typography } from '@mui/material';
import  ERR_IMG  from 'app/assets/error.png';
import React from 'react';
 

const NotFound = () => {
  return (
    <div className={'bg-white'}  >
        
      <div className="err" >
        <img width='400px' height='400px' src={ERR_IMG}></img>
      </div>
    </div>
  );
};

export default NotFound;