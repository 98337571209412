import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Drawer, Typography, Button, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Div from "@jumbo/shared/Div";
import { SucessGif } from "app/components/SucessGif/SucessGif";
import { useFormik } from "formik";
import * as yup from "yup";

const FundTranferForgotpin = ({ handleDrawerClose }) => {
  const [mobileOpens, setMobileOpens] = useState(false);
  const [section, setSection] = useState(false);
  const [showSuccessGif, setShowSuccessGif] = useState(false);
  const [button, setButton] = useState("inline-block");

  const [Questions] = useState([
    { label: "What is your pet dog name?" },
    { label: "what is your favourite food?" },
    { label: "what is your favourite Sport?" },
  ]);

  const validationSchema = yup.object({
    pin: yup
      .array()
      .of(
        yup
          .string()
          .matches(/^\d*$/, "Please enter only digits")
          .length(1, "PIN must be 1 digit")
      ),
    cpin: yup
      .array()
      .of(
        yup
          .string()
          .matches(/^\d*$/, "Please enter only digits")
          .length(1, "PIN must be 1 digit")
      ),
    security: yup.object().required("Security question is required"),
    answer: yup.string().required("Security answer is required"),
  });

  const formik = useFormik({
    initialValues: {
      pin: Array.from({ length: 4 }, () => ""),
      cpin: Array.from({ length: 4 }, () => ""),
      security: null,
      answer: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Form submitted:", values);
    },
  });

  const handleDrawerToggled = () => {
    setMobileOpens(!mobileOpens);
  };

  const FPhandleDrawerClosed = () => {
    setMobileOpens(false);
  };

  const Click = () => {
    setSection(true);
  };

  const handleFormSubmit = () => {
    Click();
  };
  const handlePINSubmit = () => {
    setShowSuccessGif(true);
    setButton("none");
  };
  return (
    <div>
      <div className="dlfex-justify-between-align-center otp_actions fundtransfer_div">
        <h6 onClick={handleDrawerToggled}>Forgot PIN?</h6>
      </div>

      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpens}
        onClose={handleDrawerToggled}
      >
        <Box className="sidenave_box" sx={{ width: "550px" }}>
          <div className="sidebar_header">
            <h1>Forgot PIN</h1>
            <ClearIcon
              sx={{ cursor: "pointer" }}
              onClick={FPhandleDrawerClosed}
            />
          </div>

          <Div sx={{ padding: "10px 25px" }}>
            {section ? (
              <Div sx={{ padding: "10px 0px" }}>
                <form
                  onSubmit={formik.handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <Div>
                    <Typography className="from_label mb-0">
                      Set PIN <span className="required">*</span>
                    </Typography>
                    <div className="Otp_input_box small">
                      {formik.values.pin.map((_, index) => (
                        <div key={index} className="otp_input small">
                          <TextField
                            name={`pin[${index}]`}
                            variant="outlined"
                            margin="normal"
                            type="text"
                            inputProps={{
                              maxLength: 1,
                            }}
                            error={
                              formik.touched.pin &&
                              formik.errors.pin &&
                              formik.errors.pin[index]
                            }
                            helperText={
                              formik.touched.pin &&
                              formik.errors.pin &&
                              formik.errors.pin[index]
                            }
                            onChange={formik.handleChange}
                            value={formik.values.pin[index]}
                          />
                        </div>
                      ))}
                    </div>
                  </Div>
                  <Div sx={{ mt: 1 }}>
                    <Typography className="from_label mb-0">
                      Confirm PIN <span className="required">*</span>
                    </Typography>
                    <div className="Otp_input_box small">
                      {formik.values.cpin.map((_, index) => (
                        <div key={index} className="otp_input small">
                          <TextField
                            name={`cpin[${index}]`}
                            variant="outlined"
                            margin="normal"
                            type="text"
                            inputProps={{
                              maxLength: 1,
                            }}
                            error={
                              formik.touched.cpin &&
                              formik.errors.cpin &&
                              formik.errors.cpin[index]
                            }
                            helperText={
                              formik.touched.cpin &&
                              formik.errors.cpin &&
                              formik.errors.cpin[index]
                            }
                            onChange={formik.handleChange}
                            value={formik.values.cpin[index]}
                          />
                        </div>
                      ))}
                    </div>
                  </Div>

                  <Div>
                    <Button
                      className="primary_btn w-200 "
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      onClick={handlePINSubmit}
                      style={{ display: button }}
                    >
                      Set PIN
                    </Button>
                  </Div>
                </form>
              </Div>
            ) : (
              <React.Fragment>
                <div sx={{ marginTop: "10px" }}>
                  <Typography
                    className="pin_text"
                    sx={{ mt: 2 }}
                    variant="h5"
                    fontWeight="bold"
                  >
                    Answer the security questions to reset your PIN
                  </Typography>
                </div>
                <div>
                  <Typography className="from_label" sx={{ marginTop: "20px" }}>
                    Security Question <span className="required">*</span>
                  </Typography>
                  <Autocomplete
                    disablePortal
                    name="security"
                    options={Questions}
                    getOptionLabel={(option) => option.label}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="form_new_style form_selct"
                        error={false}
                        helperText=""
                        fullWidth
                        placeholder="Select Security Question"
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography className="from_label" sx={{ marginTop: "20px" }}>
                    Answer security question <span className="required">*</span>
                  </Typography>
                  <textarea
                    name="answer"
                    className="w-100"
                    rows="5"
                    placeholder="Enter Security Answer"
                  />
                </div>
                <Button
                  className="primary_btn w-200"
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  onClick={handleFormSubmit}
                >
                  submit
                </Button>
              </React.Fragment>
            )}
          </Div>

          {showSuccessGif ? (
            <Div
              className="verification_sucess"
              sx={{ borderTop: "0.5px solid #d9d9d9", marginTop: "0" }}
            >
              <div className="gif_cont">
                <SucessGif />
              </div>
              <p className="verify_greet text-center">
                PIN has been <br /> Changed successfully
              </p>
              <div>
                <p
                  className="back_bene text-center"
                  onClick={FPhandleDrawerClosed}
                >
                  Go to Fund Transfer
                </p>
              </div>
            </Div>
          ) : null}
        </Box>
      </Drawer>
    </div>
  );
};

export default FundTranferForgotpin;
