import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const WeekLineChart = () => {
  const [chart, setChart] = useState(0);
 
  const formatAmount = (value) => {
    if (value < 100000) {
      return (value / 1000).toFixed(0) + "k";
    } else if (value < 10000000) {
      return (value / 100000).toFixed(1) + "L";
    } else {
      return (value / 10000000).toFixed(1) + "cr";
    }
  };

  const currentDate = new Date();
  const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

// Calculate the difference between the current day and Sunday (0)
const daysSinceSunday = currentDay === 0 ? 0 : currentDay;

// Calculate the start date of the week (Sunday)
const startOfWeek = new Date(currentDate);
startOfWeek.setDate(currentDate.getDate() - daysSinceSunday);

// Create an array of dates for the current week (Sunday to Saturday)
const currentWeekDates = Array.from({ length: 7 }, (_, index) => {
  const date = new Date(startOfWeek);
  date.setDate(startOfWeek.getDate() + index);
  return date;
});
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();
// Format the output as "3 Sun - 9 Sat"
const formattedOutput = `${(currentWeekDates[0].getDate() + '').padStart(2, '0')}/${(currentMonth + 1 + '').padStart(2, '0')}/${currentYear} - ${ (currentWeekDates[6].getDate() + '').padStart(2, '0')}/${(currentMonth + 1 + '').padStart(2, '0')}/${currentYear}`;
// Format the output as "03 - 09"
 

  const currentDays = '';
  const activeColor = "#FFC275";
  const disabledColor = "#ACE9F0";

  const getCategoryColor = (index) => {
    return index <= currentMonth ? activeColor : disabledColor;
  };

  const handleChangeChart = (e) => {
    setChart(e.target.value);
  };

  const options = {
    chart: {
      type: "bar",
      height: 400,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 15,
        colors: {
          backgroundBarColors: ["#FFC275"],
          backgroundBarOpacity: 0.2,
        },
      },
      colors: ["#7CDFD3"],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      title: {
        text: `Days ( ${formattedOutput} )`,
        style: {
          fontSize: "16px", // Change the font size of the y-axis title
          fontWeight: "500", // Change the font weight of the y-axis title
          fontFamily: "Inter", // Change the font family of the y-axis title
        },
      },
      labels: {
        style: {
          fontSize: "12px", // Change the font size of the y-axis labels
          fontWeight: "500", // Change the font weight of the y-axis labels
          fontFamily: "Inter", // Change the font family of the y-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Amount (₹)",
        style: {
          fontSize: "16px", // Change the font size of the y-axis title
          fontWeight: "500", // Change the font weight of the y-axis title
          fontFamily: "Inter", // Change the font family of the y-axis title
        },
      },
      min: 28000,
      max: 300000,
      labels: {
        formatter: function (val) {
          return formatAmount(val);
        },
        style: {
          fontSize: "12px", // Change the font size of the y-axis labels
          fontWeight: "500", // Change the font weight of the y-axis labels
          fontFamily: "Inter", // Change the font family of the y-axis labels
        },
      },
    },
    fill: {
      opacity: 1,
      colors: Array.from({ length: 12 }, (_, i) => getCategoryColor(i)),
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {

        const label = w.config.xaxis.categories[dataPointIndex];
        series = series[seriesIndex][dataPointIndex];
       const roundedSeries = Number(series).toFixed(2);
       return `  <div class="custom-tooltip">
       <span class="labels">${label}</span>
       <div class="border-line">
         <div class="value"><span class="amount">₹ ${roundedSeries} </span></div>
       </div>
     </div>`
      }
  },
  };

  const series = [
    {
      name: "",
      data: [300000, 150000, 120000, 180000, 200000, 160000, 220000],
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={500}
    />
  );
};

export default WeekLineChart;
