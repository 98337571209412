import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Search from "app/pages/app-pages/component/search";
import NoRecordsFound from "app/pages/app-pages/component/noRecordsFound";
import SkeletonTableLoader from "app/pages/app-pages/component/skeleton_loader/skeletonTableLoader";
import { FaReceipt } from "react-icons/fa6";
import TransactionReceipt from "app/pages/app-pages/transactions/TransactionReceipt";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";

const BeneTransactionList = () => {
  const TableData = [
    {
      sno: "01",
      transactionId: "6301957864",
      name: "Siva Ramar",
      remarks: "Friends and Family",
      dateTime: "31/05/23",
      time:"09:20 AM",
      amount: "1,500.00 ",
      balance: "2,15,589.00",
      sender: "Jayshri Tiwari",
      mobile: "78945 61230",
      status: 1,
      method: "IMPS",
      transAmt:"12,000.00"
    },
    {
      sno: "02",
      transactionId: "2730175548",
      name: "Vishwas Patel",
      remarks: "Entertainment",
      dateTime: "01/06/23",
      time:"09:20 AM",
      amount: "1,100.00",
      balance: "2,00,589.00",
      sender: "Jayshri Tiwari",
      mobile: "78945 61230",
      status: 1,
      method: "RTGS",
      transAmt:"12,000.00"
    },
    {
      sno: "03",
      transactionId: "1696086371",
      name: "Navjot Kaur",
      remarks: "Investment",
      dateTime: "1/06/23",
      time:"09:20 AM",
      amount: "1,200.00 ",
      balance: "1,89,589.00",
      sender: "Siva Ramar",
      mobile: "78945 61230",
      status: 1,
      method: "IMPS",
      transAmt:"12,000.00"
    },
    {
      sno: "04",
      transactionId: "3954212189",
      name: "mamta Lodhi",
      remarks: "Food",
      dateTime: "03/06/23",
      time:"09:20 AM",
      amount: "3,460.00 ",
      balance: "2,01,589.00",
      sender: "Vishwas Patel",
      mobile: "78945 61230",
      status: 0,
      method: "IMPS",
      transAmt:"12,000.00"
    },
    {
      sno: "05",
      transactionId: "0500042814",
      name: "Paartho Ghosh",
      remarks: "Entertainment",
      dateTime: "03/06/23",
      time:"09:20 AM",
      amount: "1,260.00",
      balance: "1,66,989.00",
      sender: "Navjot Kaur",
      mobile: "78945 61230",
      status: 2,
      method: "IMPS",
      transAmt:"12,000.00"
    },
    {
      sno: "06",
      transactionId: "7725068610",
      name: "Bhavna Goyal",
      remarks: "Food",
      dateTime: "31/05/23",
      time:"09:20 AM",
      amount: "909.12",
      balance: "1,66,080.00",
      sender: "Jayshri Tiwari",
      mobile: "78945 61230",
      status: 0,
      method: "RTGS",
      transAmt:"12,000.00"
    },
    {
      sno: "07",
      transactionId: "1501341233",
      name: "Jayshri Tiwari",
      remarks: "Entertainment",
      dateTime: "05/06/23",
      time:"09:20 AM",
      amount: "765.54",
      balance: "1,65,315.00",
      sender: "mamta Lodhi",
      mobile: "78945 61230",
      status: 0,
      method: "NEFT",
      transAmt:"12,000.00"
    },
    {
      sno: "08",
      transactionId: "3910793817",
      name: "Ramesh Gupta",
      remarks: "Savings",
      dateTime: "02/06/23",
      time:"09:20 AM",
      amount: "899.00",
      balance: "1,66,214.00",
      sender: "Paartho Ghosh",
      mobile: "78945 61230",
      status: 2,
      method: "IMPS",
      transAmt:"12,000.00"
    },
    {
      sno: "09",
      transactionId: "1501341233",
      name: "Jayshri Tiwari",
      remarks: "Entertainment",
      dateTime: "05/06/23",
      time:"09:20 AM",
      amount: "765.54",
      balance: "1,65,315.00",
      sender: "Ramesh Gupta",
      mobile: "78945 61230",
      status: 0,
      method: "IMPS",
      transAmt:"12,000.00"
    },
    {
      sno: "10",
      transactionId: "3910793817",
      name: "Ramesh Gupta pandey sharma",
      remarks: "Savings for the gold investments",
      dateTime: "02/06/23",
      time:"09:20 AM",
      amount: "899.00",
      balance: "1,66,214.00",
      sender: "Jayshri Tiwari",
      mobile: "78945 61230",
      status: 1,
      method: "IMPS",
      transAmt:"12,000.00"
    },
  ];

  const terms = ["name", "remarks", "transactionId"];

  const navigate = useNavigate();

  // useSate
  const [filteredData, setFilteredData] = useState(TableData);
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState("Method");
  const [type, setType] = useState("type");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // functions
  const handleSearch = (filteredData, loading) => {
    setLoading(loading);
    setFilteredData(filteredData);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;
  const handleChangeMethod = (event) => {
    setMethod(event.target.value);
  };
  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const handleClickedRecipt = (event, i) => {
    setSelectedIndex(i);
    setMobileOpen(!mobileOpen);
  };
  function handleDrawerClose() {
    setMobileOpen(false);
  }
  const handleList = () => {
    navigate("/current-user/view/1");
  };

  return (
    <section>
      <Card className="responsivecard">
        <CardContent>
          <Grid className="common_header grid current_user" container>
            <Grid lg={8} item className="custom_grid">
              <div className="d-flex items-center">
                <h2>Bene.Transactions List (12)</h2>
              </div>
            </Grid>
            <Grid lg={4} item className="custom_grid">
              <div className="d-flex items-center justify-end">
                <Button
                  startIcon={<ListOutlinedIcon />}
                  className="export_btn width-settings"
                  variant="outlined"
                  onClick={handleList}
                >
                  Back to List
                </Button>
              </div>
            </Grid>
          </Grid>

          <Grid className="common_header grid current_user" container>
            <Grid item lg={9} className="custom_grid">
              <Div sx={{ textAlign: "end" }}>
                <div className="bene_tranction_name">
                  <h3>
                    Siva Ramar - <span>BENE #008</span>
                  </h3>
                </div>
              </Div>
            </Grid>
            <Grid item lg={3} className="custom_grid">
              <Search data={TableData} onSearch={handleSearch} terms={terms} />
            </Grid>
          </Grid>

          <Div>
          <Table className="common_table">
            <TableHead>
                <TableRow>
                  <TableCell>Txn.Id </TableCell>
                  <TableCell><TableSortLabel>Created On </TableSortLabel>   </TableCell>
                  <TableCell><TableSortLabel>Recipient</TableSortLabel></TableCell>
                  <TableCell><TableSortLabel>Mobile No</TableSortLabel></TableCell>
                  <TableCell><TableSortLabel>Txn.Type</TableSortLabel>  </TableCell>
                  <TableCell className="amt_head"> Txn.Amt (₹)</TableCell>
                  <TableCell className="amt_head"> Commission (₹) </TableCell>
                  <TableCell className="amt_head">Total Amt (₹) </TableCell>
                   <TableCell> Status </TableCell>
                  <TableCell> Action </TableCell>
                </TableRow>
              </TableHead>
               <TableBody>
                  {loading ? (
                  <SkeletonTableLoader tablecell="7" row="8" />
                  ) : filteredData.length === 0 ? (
                  <NoRecordsFound tablecell="7" />
                  ) : (
                  filteredData.map((item, i) => {
                  return (
                     <TableRow key={i}>
                     <TableCell>{item?.transactionId}</TableCell>
                     <TableCell>{item?.dateTime} {item?.time} </TableCell>
                     <TableCell className="truncate">
                     <Tooltip title={item?.sender}>
                       {item?.sender}
                       </Tooltip>
                     </TableCell>
                     <TableCell>{item?.mobile}</TableCell>
                     <TableCell>{item?.method}</TableCell>
                     <TableCell  className="amt_cell">{item?.transAmt}</TableCell>
                     <TableCell className="amt_cell">
                       {item?.amount}
                     </TableCell>
                     <TableCell className="amt_cell">
                       {item?.balance}
                     </TableCell>
                     

                     <TableCell>
                       {item?.status == 1 ? (
                         <span className="admin_active_status">
                           <span className="active_dot"></span> Success
                         </span>
                       ) : item?.status == 2 ? (
                         <span className="admin_inactive_status">
                           <span className="inactive_dot"></span> Failure
                         </span>
                       ) : (
                         <span className="admin_pending_status">
                           <span className="pending_dot"></span> Pending
                         </span>
                       )}
                     </TableCell>
                     <TableCell>
                     <Tooltip title="Receipt">
                     <span className="receipt_icon"  onClick={(event) => handleClickedRecipt(event, i)}><FaReceipt /></span>

                        </Tooltip>
                     </TableCell>
                   </TableRow>
                  ); 
                  })
                  )}
               </TableBody>
               <TableFooter>
                  <TablePagination rowsPerPageOptions={[5, 10, 25]} count={15} rowsPerPage={5} page={1} />
               </TableFooter>
            </Table>
          </Div>
        </CardContent>
      </Card>
      <TransactionReceipt
        handleDrawerClose={handleDrawerClose}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleClickedRecipt}
      />
    </section>
  );
};

export default BeneTransactionList;
