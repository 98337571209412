import { createContext, useEffect, useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";

export const CurrentContext = createContext();

// const [origanisationvalid, setOrganisationvalid] = useState ( false)

export const CurrentContextProvider = ({ children }) => {
    const [origanisationvalid, setOrganisationvalid] = useState ( false)

    const formik = useFormik({
        initialValues: {
       nameOftheOrg: "",
       accountTitle: "",
       accountType: "",
       pan: "",
       dateOfReg: null,
       registrationNo: "",
       cityOfIncroporation: "",
       annualTurnover:"",
       selectedOption: null,
     },
     validationSchema: Yup.object({
       nameOftheOrg: Yup.string().required("Name of org is required"),
       accountTitle: Yup.string().required("Account title is required"),
       accountType: Yup.string().required("Account Type is required"),
       pan: Yup.string().required("Account Type is required"),
       dateOfReg: Yup.date().required("Date of Birth is required"),
       registrationNo: Yup.string().required("registrationNo is required"),
       cityOfIncroporation: Yup.string().required(
         "cityOfIncroporation is required"
       ),
       annualTurnover: Yup.string().required("Account Type is required"),
       // selectedOption: Yup.object().nullable().required('Option is required'),
    
     }),
     onSubmit: (values) => {

        if(values){
            setOrganisationvalid(true)
        }

       // Handle form submission logic here
       console.log("Form submitted with values:", values);

     },
     
   });
   const Customerformik = useFormik({
    initialValues: {
  NatureofBusiness: null,
   OtherBusiness: "",
   networth :"",
   iecnumber:"",
   yearinBussiness:'',
   monthinBussiness:"",
   officeaddressSince :"",
   officeaddressLine1 : "",
   officeaddressLine2 : "",
   officelandMark : "",
   officecity : "",
   officepinCode : "",
   officestate : "",
   officecountry:"",
   officeType:"",
   telNumber:"",
   faxNumber:"",
   conatctPersonBirth :"",
   contactPersonName:"",
   contactTelnumber:"",
   contactMobileNumber:"",
   contactEmailId:"",
   contactCity:"",



  
 },
 validationSchema: Yup.object({
    OtherBusiness: Yup.string().required("other business is required"),
    networth : Yup.string().required("netwoth is required"),
    officeType : Yup.string().required("office type is required"),
    conatctPersonBirth: Yup.string().required("Contact person mobile  is required"),
   // selectedOption: Yup.object().nullable().required('Option is required'),

 }),
 onSubmit: (values) => {

    if(values){
        setOrganisationvalid(true)
    }

   // Handle form submission logic here
   console.log("Form submitted with values:", values);

 },
 
});

const Nomineeformik = useFormik({
  initialValues: {
   nomnieeType :"",
   nomnieeName :"",
   nomineeDOB:"",
   nomineeRelation :"",
   nomineeMobileNo:"",
   nomineeAddress: "",
   nomineeOtherAddress:"",
   nomineeOtherLandmark :"",
   nomineeOtherCity :"",
   nomineeOtherPincode :"",
   nomineeOtherSate :"",
   nomineeOtherCountry :"",
   guradianName :"",
   guradianDOB :"",
   guradianDOB :"",
   guradianOtherAddress:"",
   guradianOtherLandmark :"",
   guradianOtherCity :"",
   guradianOtherPincode :"",
   guradianOtherSate :"",
   guradianOtherCountry :"",
  },

  validationSchema: Yup.object({



  }),
  onSubmit: (values) => {

    

   // Handle form submission logic here
   console.log("Form submitted with values:", values);

 },



})



    return (
        <CurrentContext.Provider value={{formik, origanisationvalid , Customerformik , Nomineeformik}}>
          {children}
        </CurrentContext.Provider>
      );


}