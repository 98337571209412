import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import Div from "@jumbo/shared/Div";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CurrentContext } from "../../Context/CurrentUserContext";

const steps = [
  "Organisation Details",
  "Customer Profile",
  "Nominee",
  "Application",
  "Authorised Signatory ",
  "Credit Facilities",
  "Declaration",
  "Annexure 5",
  "Standing Instruction Request",
  "FATCA /CRS Declaration",
  "Customer Declaration",
];

const theme = createTheme({
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#333", // Change the color of the unselected radio button
        },
        checked: {
          color: "green", // Change the color of the selected radio button
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: "#666666", // Change the color of the radio label
        },
        checked: {
          color: "green", // Change the color of the selected radio button
        },
      },
    },
  },
});

const CustomerProfile = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [checked, setChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState("option1");
  const [value, setValue] = useState(dayjs("2022-04-17"));
  const enitity = [
    { label: "Proprietorship" },
    {
      label: "Proprietorship 1",
    },
    {
      label: "Proprietorship 2",
    },
  ];
  const options = [
    { label: "Others", value: "Others" },
    {
      label: "Others 1",
      value: "Others 1",
    },
    {
      label: "Others 2",
      value: "Others 2",
    },
  ];
  const Year = [
    { label: "5", value: "5" },
    {
      label: "4",
      value: "4",
    },
    {
      label: "6",
      value: "6",
    },
  ];
  const Month = [
    { label: "5", value: "5" },
    {
      label: "4",
      value: "4",
    },
    {
      label: "6",
      value: "6",
    },
  ];
  const city = [
    {
      label: "Madurai",
      value: "madurai",
    },
    {
      label: "Chennai",
      value: "chennai",
    },
    {
      label: "Theni",
      value: "theni",
    },
  ];
  const pincode = [
    {
      label: "625009",
      value: "625009",
    },
    {
      label: "625001",
      value: "625001",
    },
    {
      label: "625012",
      value: "625012",
    },
  ];

  const state = [
    {
      label: "Tamil nadu",
      value: "Tamil nadu",
    },
    {
      label: "Tamil nadu",
      value: "Tamil nadu",
    },
    {
      label: "Tamil nadu",
      value: "Tamil nadu",
    },
  ];
  const country = [
    {
      label: "India",
      value: "India",
    },
    {
      label: "India",
      value: "India",
    },
    {
      label: "India",
      value: "India",
    },
  ];

  const handleStepClick = (stepIndex) => {
    if (stepIndex < activeStep) {
      setActiveStep(stepIndex);
    }
  };
  const { Customerformik } = useContext(CurrentContext);

  return (
    <div className="create_form_width">
      <Div sx={{ mt: 0 }}>
        <Typography className="from_label">
           Name of Business <span>*</span>
        </Typography>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.value === value?.value}
          value={
            options.find(
              (opt) => opt.value === Customerformik.values.NatureofBusiness
            ) || null
          }
          onChange={(_, value) =>
            Customerformik.setFieldValue(
              "NatureofBusiness",
              value?.value || null
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={
                Customerformik.touched.NatureofBusiness &&
                Customerformik.errors.NatureofBusiness
              }
            />
          )}
        />
      </Div>
      <Div sx={{ mt: 3 }}>
        <Typography className="from_label">Other Business</Typography>
        <TextField
          className="form_new_style"
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="OtherBusiness"
          {...Customerformik.getFieldProps("OtherBusiness")}
          error={
            Customerformik.touched.OtherBusiness &&
            Boolean(Customerformik.errors.OtherBusiness)
          }
          helperText={
            Customerformik.touched.OtherBusiness &&
            Customerformik.errors.OtherBusiness
          }
        />
      </Div>
      <Div sx={{ mt: 3 }}>
        <ThemeProvider theme={theme}>
          <Typography className="from_label">
            Net Worth <span>*</span>
          </Typography>
          <FormControl>
            <RadioGroup
              row
              name="networth"
              value={Customerformik.values.networth}
              onChange={Customerformik.handleChange}
              onBlur={Customerformik.handleBlur}
            >
              <FormControlLabel value="<5cr" control={<Radio />} label="<5cr" />
              <FormControlLabel
                value="5-25cr"
                control={<Radio />}
                label="5-25cr"
              />
              <FormControlLabel
                value=">25-50cr"
                control={<Radio />}
                label=">25-50cr"
              />
              <FormControlLabel
                value=">50-100cr"
                control={<Radio />}
                label=">50-100cr"
              />
              <FormControlLabel
                value=">100-150cr"
                control={<Radio />}
                label=">100-150cr"
              />
              <FormControlLabel
                value=">150-250cr"
                control={<Radio />}
                label=">150-250cr"
              />
              <FormControlLabel
                value=">250-500cr"
                control={<Radio />}
                label=">250-500cr"
              />
              <FormControlLabel
                value=">500-750cr"
                control={<Radio />}
                label=">500-750cr"
              />
              <FormControlLabel
                value=">750cr"
                control={<Radio />}
                label=">750cr"
              />
            </RadioGroup>

            {Customerformik.touched.networth &&
              Customerformik.errors.networth && (
                <div style={{ color: "red" }}>
                  {Customerformik.errors.networth}
                </div>
              )}
          </FormControl>
        </ThemeProvider>
      </Div>
      <Div sx={{ mt: 3 }}>
        <Typography className="from_label">IEC Number</Typography>
        <TextField
          className="form_new_style"
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="OtherBusiness"
          {...Customerformik.getFieldProps("iecnumber")}
        />
      </Div>
      <Div sx={{ mt: 3 }}>
        <div className="year_of_busness">
          <Typography className="from_label">
            No. of Years in Business <span>*</span>
          </Typography>
          <Grid container gap={"24px"}>
            <Grid item sm={6}>
              <Typography className="from_label">Years</Typography>
              <Autocomplete
                options={Year}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  option.value === value?.value
                }
                value={
                  Year.find(
                    (opt) => opt.value === Customerformik.values.yearinBussiness
                  ) || null
                }
                onChange={(_, value) =>
                  Customerformik.setFieldValue(
                    "yearinBussiness",
                    value?.value || null
                  )
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item sm={5.5}>
              <Typography className="from_label">Months</Typography>
              <Autocomplete
                options={Month}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  option.value === value?.value
                }
                value={
                  Month.find(
                    (opt) =>
                      opt.value === Customerformik.values.monthinBussiness
                  ) || null
                }
                onChange={(_, value) =>
                  Customerformik.setFieldValue(
                    "monthinBussiness",
                    value?.value || null
                  )
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </div>
      </Div>
      <Div sx={{ mt: 3 }}>
        <div className="address_details">
          <Typography className="from_label">
            Registered Office Address <span>*</span>
          </Typography>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">Since</Typography>
            <textarea
              className="w-100"
              name="officeaddressSince"
              {...Customerformik.getFieldProps("officeaddressSince")}
              id=""
              rows="5"
            ></textarea>
          </Div>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label"> Address Line 1</Typography>
            <textarea
              className="w-100"
              id=""
              rows="5"
              name="officeaddressLine1"
              {...Customerformik.getFieldProps("officeaddressLine1")}
            ></textarea>
          </Div>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">Address Line 2</Typography>
            <textarea
              className="w-100"
              id=""
              rows="5"
              name="officeaddressLine2"
              {...Customerformik.getFieldProps("officeaddressLine2")}
            ></textarea>
          </Div>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">Landmark</Typography>
            <textarea
              className="w-100"
              id=""
              rows="5"
              name="officelandMark"
              {...Customerformik.getFieldProps("officelandMark")}
            ></textarea>

            <Div sx={{ mt: 3 }}>
              <Grid container gap={"24px"}>
                <Grid item sm={6}>
                  <Typography className="from_label">City</Typography>
                  <Autocomplete
                    options={city}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) =>
                      option.value === value?.value
                    }
                    value={
                      city.find(
                        (opt) => opt.value === Customerformik.values.officecity
                      ) || null
                    }
                    onChange={(_, value) =>
                      Customerformik.setFieldValue(
                        "officecity",
                        value?.value || null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid item sm={5.5}>
                  <Typography className="from_label">Pin Code</Typography>
                  <Autocomplete
                    options={pincode}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) =>
                      option.value === value?.value
                    }
                    value={
                      pincode.find(
                        (opt) =>
                          opt.value === Customerformik.values.officepinCode
                      ) || null
                    }
                    onChange={(_, value) =>
                      Customerformik.setFieldValue(
                        "officepinCode",
                        value?.value || null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </Grid>
            </Div>
            <Div sx={{ mt: 4 }}>
              <Grid container gap={"24px"}>
                <Grid item sm={6}>
                  <Typography className="from_label">State</Typography>
                  <Autocomplete
                    options={state}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) =>
                      option.value === value?.value
                    }
                    value={
                      state.find(
                        (opt) => opt.value === Customerformik.values.officestate
                      ) || null
                    }
                    onChange={(_, value) =>
                      Customerformik.setFieldValue(
                        "officestate",
                        value?.value || null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid item sm={5.5}>
                  <Typography className="from_label">Country</Typography>
                  <Autocomplete
                    options={country}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) =>
                      option.value === value?.value
                    }
                    value={
                      country.find(
                        (opt) =>
                          opt.value === Customerformik.values.officecountry
                      ) || null
                    }
                    onChange={(_, value) =>
                      Customerformik.setFieldValue(
                        "officecountry",
                        value?.value || null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </Grid>
            </Div>
          </Div>
        </div>
      </Div>
      <Div sx={{ mt: 3 }}>
        <ThemeProvider theme={theme}>
          <Typography className="from_label">
            Office <span>*</span>
          </Typography>
          <FormControl>
            <RadioGroup
              row
              name="officeType"
              value={Customerformik.values.officeType}
              onChange={Customerformik.handleChange}
              onBlur={Customerformik.handleBlur}
            >
              <FormControlLabel
                value="Owned"
                className="radio_form"
                control={<Radio />}
                label="Owned"
              />
              <FormControlLabel
                value="Rented"
                className="radio_form "
                control={<Radio />}
                label="Rented / Leased"
              />
            </RadioGroup>
          </FormControl>
        </ThemeProvider>
        {Customerformik.touched.officeType &&
          Customerformik.errors.officeType && (
            <div style={{ color: "red" }}>
              {Customerformik.errors.officeType}
            </div>
          )}
      </Div>

      <Div sx={{ mt: 3 }}>
        <Typography className="from_label">Tel Number</Typography>
        <TextField
          className="form_new_style"
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="telNumber"
          {...Customerformik.getFieldProps("telNumber")}
        />
      </Div>
      <Div sx={{ mt: 3 }}>
        <Typography className="from_label">Fax Number</Typography>
        <TextField
          className="form_new_style"
          id="outlined-basic"
          variant="outlined"
          fullWidth
          name="faxNumber"
          {...Customerformik.getFieldProps("faxNumber")}
        />
      </Div>
      <Div sx={{ mt: 3 }}>
        <div className="year_of_busness">
          <Typography className="from_label mb-0">
            Contact Person <span>*</span>
          </Typography>
          <Typography className="from_label">
            (Any one of the authorised signatories for this account){" "}
            <span>*</span>
          </Typography>
          <Div sx={{ mt: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="w-100"
                value={Customerformik.values.conatctPersonBirth}
                onChange={(value) => Customerformik.setFieldValue("conatctPersonBirth", value)}
                format="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      Customerformik.touched.conatctPersonBirth && Customerformik.errors.conatctPersonBirth
                    }
                    value={
                      Customerformik.values.conatctPersonBirth
                        ? dayjs(Customerformik.values.conatctPersonBirth).format("DD/MM/YYYY")
                        : ""
                    }
                    onChange={(event) => {
                      Customerformik.setFieldValue(
                        "conatctPersonBirth",
                        event.target.value
                          ? dayjs(event.target.value, "DD/MM/YYYY").toDate()
                          : null
                      );
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Div>

          {Customerformik.touched.conatctPersonBirth &&
          Customerformik.errors.conatctPersonBirth && (
            <div style={{ color: "red" }}>
              {Customerformik.errors.conatctPersonBirth}
            </div>
          )}
        </div>
      </Div>
      <Div sx={{ mt: 3 }}>
        <div className="year_of_busness">
          <Typography className=" mb-0 lette_space_normal">
            For all communication related to this current account will be sent
            to the below mentioned contact person. In case of any change in
            future please inform the bank about the same.
          </Typography>

          <Div sx={{ mt: 5 }}>
            <Typography className="from_label">Name of the Person</Typography>
            <TextField
              className="form_new_style"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              name="contactPersonName"
              {...Customerformik.getFieldProps("contactPersonName")}
            />
          </Div>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">Tel Number</Typography>
            <TextField
              className="form_new_style"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              name="contactTelnumber"
              {...Customerformik.getFieldProps("contactTelnumber")}
            />
          </Div>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">Mobile Number</Typography>
            <TextField
              className="form_new_style"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              name="contactMobileNumber"
              {...Customerformik.getFieldProps("contactMobileNumber")}
            />
          </Div>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">Email Id</Typography>
            <TextField
              className="form_new_style"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              name="contactEmailId"
              {...Customerformik.getFieldProps("contactEmailId")}

            />
          </Div>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">City</Typography>
            <Autocomplete
                    options={city}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) =>
                      option.value === value?.value
                    }
                    value={
                      city.find(
                        (opt) => opt.value === Customerformik.values.contactCity
                      ) || null
                    }
                    onChange={(_, value) =>
                      Customerformik.setFieldValue(
                        "contactCity",
                        value?.value || null
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
          </Div>
        </div>
      </Div>
    </div>
  );
};

export default CustomerProfile;
