import React, { useState } from "react";
import { Button, Typography, OutlinedInput, Card, Grid, CardContent,InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as yup from "yup";
import { useFormik } from "formik";
import Div from "@jumbo/shared/Div";

const validationSchema = yup.object({
  oldPass: yup
    .string()
    .required("Old password is required")
    .min(6, "Old password must be at least 6 characters"),
  newPass: yup
    .string()
    .required("New password is required")
    .min(6, "New password must be at least 6 characters")
    .matches(
      /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/,
      "Must contain at least one digit, one uppercase letter, and one symbol"
    ),
  confirmPass: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPass"), null], "Passwords must match"),
});

const AdminChangePassword = () => {
  // useSate 
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [conpassword, setConPassword] = useState("");

// function 
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityNewPass = () => {
    setShowNewPassword(!showNewPassword);
  };

  const togglePasswordVisibilityConPass = () => {
    setShowConPassword(!showConPassword);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  // const handleChangeNewPassword = (e) => {
  //   setNewPassword(e.target.value);
  // };

  // const handleChangeNewPassword = (e) => {
  //   setNewPassword(e.target.value);
  // };
  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };
  const handleChangeConPassword = (e) => {
    setConPassword(e.target.value);
  };

  const formik = useFormik({
    initialValues: {
      oldPass: "",
      newPass: "",
      confirmPass: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
      console.log("Form submitted:", values);
    },
  });
  return (
    <div>
      <Card className="admin">
        <Grid className="common_header grid" container>
          <Grid lg={12} className="custom_grid">
            <Grid item>
              <h2 className=" admin_head">Change Password</h2>
            </Grid>
          </Grid>
        </Grid>
        <CardContent style={{paddingTop:"12px"}}>
          <Grid container>
            <Grid item lg={6}>
          <section className="forms_section">
          <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
              <Div>
                <Typography className="from_label">Old Password <span className="required">*</span></Typography>
                <OutlinedInput
                  name="oldPass"
                  placeholder="Enter Old Password"
                  className="form_new_style w-100 password_input"
                  
                  type={showPassword ? "text" : "password"}
                  value={formik.values.oldPass}
                  error={
                    formik.touched.oldPass && Boolean(formik.errors.oldPass)
                  }
                  helperText={formik.touched.oldPass && formik.errors.oldPass}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChangePassword(e);
                  }}
                  onBlur={formik.handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibility}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formik.touched.oldPass && formik.errors.oldPass && (
                  <Div className="error text-danger">
                    {formik.errors.oldPass}
                  </Div>
                )}
              </Div>

              <Div sx={{ mt: 3 }}>
                <Typography className="from_label">New Password <span className="required">*</span></Typography>
                <OutlinedInput
                  name="newPass"
                  placeholder="Enter New Password"
                  className="form_new_style w-100 password_input"
                  
                  type={showNewPassword ? "text" : "password"}
                  value={formik.values.newPass}
                  error={
                    formik.touched.newPass && Boolean(formik.errors.newPass)
                  }
                  helperText={formik.touched.newPass && formik.errors.newPass}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChangeNewPassword(e);
                  }}
                  onBlur={formik.handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibilityNewPass}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formik.touched.newPass && formik.errors.newPass && (
                  <Div className="error text-danger">
                    {formik.errors.newPass}
                  </Div>
                )}
              </Div>
              <Div sx={{ mt: 3 }}>
                <Typography className="from_label">Confirm Password <span className="required">*</span></Typography>
                <OutlinedInput
                  name="confirmPass"
                  placeholder="Enter Confirm Password "
                  className="form_new_style w-100 password_input"
                  type={showConPassword ? "text" : "password"}
                  value={formik.values.confirmPass}
                  error={
                    formik.touched.confirmPass &&
                    Boolean(formik.errors.confirmPass)
                  }
                  helperText={
                    formik.touched.confirmPass && formik.errors.confirmPass
                  }
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChangeConPassword(e);
                  }}
                  onBlur={formik.handleBlur}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibilityConPass}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showConPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                {formik.touched.confirmPass && formik.errors.confirmPass && (
                  <Div className="error text-danger">
                    {formik.errors.confirmPass}
                  </Div>
                )}
              </Div>
               <Div>
                <Button
                  className="primary_btn  "
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3, width: "fit-content" }}
                >
                  Change Password
                </Button>
              </Div>
            </form>
          </section>
          </Grid>
          </Grid>
        </CardContent>
      </Card>
     
     
    </div>
  );
};

export default AdminChangePassword;
