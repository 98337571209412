import React from 'react'
import Lottie from "lottie-react";
import  Success from './success_ani.json';
import  SuccessLoginGif from './login_ani.json';
export const SucessGif = () => {
  return (
    <Lottie  speed={0.5}   animationData={Success} />
  )
}

export const SucessGifLogin = () => {
    return (
      <Lottie  speed={0.5}   animationData={SuccessLoginGif} />
    )
  }
  
