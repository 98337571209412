import React, { useState } from "react";
import {Box,Card,CardContent,Grid,Typography,} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PropTypes from "prop-types";
import Div from "@jumbo/shared/Div";


function handleClick(event) {
event.preventDefault();
console.info("You clicked a breadcrumb.");
}
function TabPanel(props) {
const { children, value, index, ...other } = props;

return (
<div role="tabpanel" hidden={value !==index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`}
  {...other}>
  {value === index && (
  <Box sx={{ p: 3 }}>
    <Typography>{children}</Typography>
  </Box>
  )}
</div>
);
}
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};
function a11yProps(index) {
return {
id: `vertical-tab-${index}`,
"aria-controls": `vertical-tabpanel-${index}`,
};
}
const TermsConditions = () => {
// useState
const [value, setValue] = useState(0);
// function
const handleChange = (event, newValue) => {
setValue(newValue);
};

return (
<section className="terms">
<Card >
   <CardContent>
   <Grid className="common_header grid pb-5" container>
            <Grid className="custom_grid p-0">
              <Grid item>
                <div className="d-flex items-center">
                  <h2>Terms & Conditions</h2>
                  </div>
              </Grid>
            </Grid>
            </Grid>
    <div className="mt-20">
    <Typography className="terms_content_heaer" variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>
          Introduction
        </Typography>
        <div className="terms_content_content">
          <p> I agree and undertake to ensure that there would be sufficient
            funds/cleared balance/pre-arranged credit facilities in my account
            for effecting transactions. I agree that the Bank shall not be
            liable for any consequences arising out of non-compliance by the
            Bank of my instructions due to inadequacy of funds and the Bank can
            at its sole discretion decide to carry out the instructions
            notwithstanding the inadequacy of funds. The Bank may do the
            aforesaid without prior approval from or notice to me and I shall be
            liable to repay with interest the resulting advance, overdraft or
            credit thereby created and all related charges arising thereby @
            Prime Lending rate +5% p.a. compounded quarterly.</p>
          <p>I agree that the Bank shall not be liable
            for any damages, losses (direct or indirect) whatsoever, due to
            disruption or non availability of any of services/facility/s due to
            technical fault/error or any failure in telecommunication network or
            any error in any software or hardware systems.</p>
          <p> I agree that in case of a dispute, the
            matter will be settled by arbitration as per the rules of Indian
            Arbitration and Conciliation Act 1996. Further, the Managing
            Director of nsdl Bank Ltd. or any other person nominated by him will
            be the sole arbitrator and that the place of arbitration will be
            Mumbai.</p>
          <p> I agree that the Bank may disclose
            customer information, in strict confidence, to any of its agent/s
            and/or contractors with whom the Bank enters or has entered into any
            arrangement in connection with providing of services.</p>
        </div>

    </div>
    <div className="mt-40">
    <Typography className="terms_content_heaer" variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>
    Net Banking Access
        </Typography>
        <div className="terms_content_content">
          <p> I agree and undertake to ensure that there would be sufficient
            funds/cleared balance/pre-arranged credit facilities in my account
            for effecting transactions. I agree that the Bank shall not be
            liable for any consequences arising out of non-compliance by the
            Bank of my instructions due to inadequacy of funds and the Bank can
            at its sole discretion decide to carry out the instructions
            notwithstanding the inadequacy of funds. The Bank may do the
            aforesaid without prior approval from or notice to me and I shall be
            liable to repay with interest the resulting advance, overdraft or
            credit thereby created and all related charges arising thereby @
            Prime Lending rate +5% p.a. compounded quarterly.</p>
          <p>I agree that the Bank shall not be liable
            for any damages, losses (direct or indirect) whatsoever, due to
            disruption or non availability of any of services/facility/s due to
            technical fault/error or any failure in telecommunication network or
            any error in any software or hardware systems.</p>
          <p> I agree that in case of a dispute, the
            matter will be settled by arbitration as per the rules of Indian
            Arbitration and Conciliation Act 1996. Further, the Managing
            Director of nsdl Bank Ltd. or any other person nominated by him will
            be the sole arbitrator and that the place of arbitration will be
            Mumbai.</p>
          <p> I agree that the Bank may disclose
            customer information, in strict confidence, to any of its agent/s
            and/or contractors with whom the Bank enters or has entered into any
            arrangement in connection with providing of services.</p>
        </div>

    </div>
    <div className="mt-40">
    <Typography className="terms_content_heaer" variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>
    Current Accounts
        </Typography>
        <div className="terms_content_content">
          <p> I agree and undertake to ensure that there would be sufficient
            funds/cleared balance/pre-arranged credit facilities in my account
            for effecting transactions. I agree that the Bank shall not be
            liable for any consequences arising out of non-compliance by the
            Bank of my instructions due to inadequacy of funds and the Bank can
            at its sole discretion decide to carry out the instructions
            notwithstanding the inadequacy of funds. The Bank may do the
            aforesaid without prior approval from or notice to me and I shall be
            liable to repay with interest the resulting advance, overdraft or
            credit thereby created and all related charges arising thereby @
            Prime Lending rate +5% p.a. compounded quarterly.</p>
          <p>I agree that the Bank shall not be liable
            for any damages, losses (direct or indirect) whatsoever, due to
            disruption or non availability of any of services/facility/s due to
            technical fault/error or any failure in telecommunication network or
            any error in any software or hardware systems.</p>
          <p> I agree that in case of a dispute, the
            matter will be settled by arbitration as per the rules of Indian
            Arbitration and Conciliation Act 1996. Further, the Managing
            Director of nsdl Bank Ltd. or any other person nominated by him will
            be the sole arbitrator and that the place of arbitration will be
            Mumbai.</p>
          <p> I agree that the Bank may disclose
            customer information, in strict confidence, to any of its agent/s
            and/or contractors with whom the Bank enters or has entered into any
            arrangement in connection with providing of services.</p>
        </div>

    </div>
    <div className="mt-40">
    <Typography className="terms_content_heaer" variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>
    Application
        </Typography>
        <div className="terms_content_content">
          <p> I agree and undertake to ensure that there would be sufficient
            funds/cleared balance/pre-arranged credit facilities in my account
            for effecting transactions. I agree that the Bank shall not be
            liable for any consequences arising out of non-compliance by the
            Bank of my instructions due to inadequacy of funds and the Bank can
            at its sole discretion decide to carry out the instructions
            notwithstanding the inadequacy of funds. The Bank may do the
            aforesaid without prior approval from or notice to me and I shall be
            liable to repay with interest the resulting advance, overdraft or
            credit thereby created and all related charges arising thereby @
            Prime Lending rate +5% p.a. compounded quarterly.</p>
         
        </div>

    </div>
    <div className="mt-40">
    <Typography className="terms_content_heaer" variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>
    Account Holders
        </Typography>
        <div className="terms_content_content">
          <p> I agree and undertake to ensure that there would be sufficient
            funds/cleared balance/pre-arranged credit facilities in my account
            for effecting transactions. I agree that the Bank shall not be
            liable for any consequences arising out of non-compliance by the
            Bank of my instructions due to inadequacy of funds and the Bank can
            at its sole discretion decide to carry out the instructions
            notwithstanding the inadequacy of funds. The Bank may do the
            aforesaid without prior approval from or notice to me and I shall be
            liable to repay with interest the resulting advance, overdraft or
            credit thereby created and all related charges arising thereby @
            Prime Lending rate +5% p.a. compounded quarterly.</p>
         
        </div>

    </div>
    <div className="mt-40">
    <Typography className="terms_content_heaer" variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>
    Password
        </Typography>
        <div className="terms_content_content">
          <p> I agree and undertake to ensure that there would be sufficient
            funds/cleared balance/pre-arranged credit facilities in my account
            for effecting transactions. I agree that the Bank shall not be
            liable for any consequences arising out of non-compliance by the
            Bank of my instructions due to inadequacy of funds and the Bank can
            at its sole discretion decide to carry out the instructions
            notwithstanding the inadequacy of funds. The Bank may do the
            aforesaid without prior approval from or notice to me and I shall be
            liable to repay with interest the resulting advance, overdraft or
            credit thereby created and all related charges arising thereby @
            Prime Lending rate +5% p.a. compounded quarterly.</p>
         
        </div>

    </div>
    <div className="mt-40">
    <Typography className="terms_content_heaer" variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>
    Transaction Policy
        </Typography>
        <div className="terms_content_content">
          <p> I agree and undertake to ensure that there would be sufficient
            funds/cleared balance/pre-arranged credit facilities in my account
            for effecting transactions. I agree that the Bank shall not be
            liable for any consequences arising out of non-compliance by the
            Bank of my instructions due to inadequacy of funds and the Bank can
            at its sole discretion decide to carry out the instructions
            notwithstanding the inadequacy of funds. The Bank may do the
            aforesaid without prior approval from or notice to me and I shall be
            liable to repay with interest the resulting advance, overdraft or
            credit thereby created and all related charges arising thereby @
            Prime Lending rate +5% p.a. compounded quarterly.</p>
         
        </div>

    </div>
    <div className="mt-40">
    <Typography className="terms_content_heaer" variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>
    Internet Frauds
        </Typography>
        <div className="terms_content_content">
          <p> I agree and undertake to ensure that there would be sufficient
            funds/cleared balance/pre-arranged credit facilities in my account
            for effecting transactions. I agree that the Bank shall not be
            liable for any consequences arising out of non-compliance by the
            Bank of my instructions due to inadequacy of funds and the Bank can
            at its sole discretion decide to carry out the instructions
            notwithstanding the inadequacy of funds. The Bank may do the
            aforesaid without prior approval from or notice to me and I shall be
            liable to repay with interest the resulting advance, overdraft or
            credit thereby created and all related charges arising thereby @
            Prime Lending rate +5% p.a. compounded quarterly.</p>
         
        </div>

    </div>
    <div className="mt-40">
    <Typography className="terms_content_heaer" variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>
    Technology Risks
        </Typography>
        <div className="terms_content_content">
          <p> I agree and undertake to ensure that there would be sufficient
            funds/cleared balance/pre-arranged credit facilities in my account
            for effecting transactions. I agree that the Bank shall not be
            liable for any consequences arising out of non-compliance by the
            Bank of my instructions due to inadequacy of funds and the Bank can
            at its sole discretion decide to carry out the instructions
            notwithstanding the inadequacy of funds. The Bank may do the
            aforesaid without prior approval from or notice to me and I shall be
            liable to repay with interest the resulting advance, overdraft or
            credit thereby created and all related charges arising thereby @
            Prime Lending rate +5% p.a. compounded quarterly.</p>
         
        </div>

    </div>
   



   </CardContent>


    </Card>
        

</section>
);
};

export default TermsConditions;