import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Grid,
  Box,
  TableSortLabel,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import Div from "@jumbo/shared/Div";
import Search from "app/pages/app-pages/component/search";
import SkeletonTableLoader from "app/pages/app-pages/component/skeleton_loader/skeletonTableLoader";
import NoRecordsFound from "app/pages/app-pages/component/noRecordsFound";
import InactiveModal from "app/pages/app-pages/component/InactiveModal";
import CurrentUserDetailedView from "./CurrentUserDetailedView";
import "./style.css";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { TrashIcon } from "app/assets/svg/Svg";



const CurrentUserDetailedList = () => {
  const index = useParams("id");
  console.log(index?.id);
  const TableData = [
    {
      id: "BENE0001",
      createdonDate: "24/01/2024",
      createdTime: "09:30 AM",
      name: "Gowtham K",
      mobile: " 7555527960",
      bank: "HDFC Bank",
      acno: "5010007865490",
      noTrans: "121",
      status: 1,
    },
    {
      id: "BENE0002",
      createdonDate: "27/01/2024",
      createdTime: "11:30 AM",
      name: "Kamesh",
      mobile: " 9877845632",
      bank: "Canare Bank",
      acno: "18210007865490",
      noTrans: "11",
      status: 1,
    },
    {
      id: "BENE0003",
      createdonDate: "25/01/2024",
      createdTime: "11:30 AM",
      name: "Sachin",
      mobile: " 9995527960",
      bank: "Indian Bank",
      acno: "16210007865490",
      noTrans: "12",
      status: 1,
    },
    {
      id: "BENE0004",
      createdonDate: "01/02/2024",
      createdTime: "08:30 AM",
      name: "Rahul",
      mobile: " 9875527960",
      bank: "HDFC Bank",
      acno: "18210007865490",
      noTrans: "21",
      status: 0,
    },
    {
      id: "BENE0005",
      createdonDate: "02/02/2024",
      createdTime: "11:30 AM",
      name: "Husmathulla",
      mobile: " 6955527960",
      bank: "HDFC Bank",
      acno: "18210007865490",
      noTrans: "121",
      status: 1,
    },
  ];

  const terms = ["name", "acno", "mobile"];
  const navigate = useNavigate();

  // usestate
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState(TableData);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Status");
  const [method, setMethod] = useState("Method");
  const [fillter, setFillter] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const openFilter = Boolean(fillter);
  const id = open ? "simple-popover" : undefined;
 
  const handleDelete = () => {
    setAnchorEl(null);
    setModalOpen(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearch = (filteredData, loading) => {
    // Do something with the filtered data
    setLoading(loading);
    setFilteredData(filteredData);
  };
  
  const handleClick = (event, i) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(i);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };
  const handleFilter = (event) => {
    setFillter(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFillter(null);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const handleChangeMethod = (event) => {
    setMethod(event.target.value);
  };
  
  function handleDrawerToggle() {
    setMobileOpen(true);
  }

  function handleDrawerClose() {
    setMobileOpen(false);
  }

  function handlecurrentpage() {
    navigate("/current-user");
  }
  const handleView = () => {
    handleDrawerToggle(); 
    handleClose()
   } 
  return (
    <section>
      <Card className="responsivecard">
        <InactiveModal open={isModalOpen} handleClose={handleCloseModal} />
        <CardContent sx={{ padding: "10px 20px 0px" }}>
          <Div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div className="d-flex items-center">
              <h2 style={{ margin: "2px 0px" }}>View Current User</h2>
            </div>
            <div>
              <Grid item lg={2.6} md={3.9}>
                <Button
                  startIcon={<ListOutlinedIcon />}
                  className="export_btn width-settings"
                  variant="outlined"
                  onClick={handlecurrentpage}
                >
                  Back to List
                </Button>
              </Grid>
            </div>
          </Div>
          <Grid className="common_header grid mb-0 pb-0" container>
            <Grid lg={12} item className="custom_grid">
              <Div className="d-flex items-center">
                <Div
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "10px",
                    borderTop: "0.5px solid #cccccc",
                    paddingTop: "20px",
                  }}
                >
                  <Typography
                    variant="h1"
                    fontSize="26"
                    fontWeight="600"
                    className="mb-0"
                  >
                     Alpha - Alpha Enterprise - CUSOO1 
                  </Typography>

                  {index?.id == 1 && (
                    <Typography
                      variant="h6"
                      fontSize="20"
                      fontWeight="500"
                      className="mb-0"
                      color="#EC8605"
                      sx={{
                        backgroundColor: "#ffeecc",
                        padding: "10px 20px",
                        borderRadius: "25px",
                      }}
                    >
                      {" "}
                      Partnership{" "}
                    </Typography>
                  )}
                  {index?.id == 2 && (
                    <Typography
                      variant="h6"
                      fontSize="20"
                      fontWeight="500"
                      className="mb-0"
                      color="#EC8605"
                      sx={{
                        backgroundColor: "#ffeecc",
                        padding: "10px 20px",
                        borderRadius: "25px",
                      }}
                    >
                      {" "}
                      Proprietorship{" "}
                    </Typography>
                  )}
                </Div>
              </Div>
            </Grid>
          </Grid>
        </CardContent>

        
          <Grid>
            <Grid item lg={6}>
              <CardContent className="info_card">
                <Typography
                  variant="h3"
                  fontWeight="550"
                  className="mb-0 p-0 m-0"
                >
                  Company Info
                </Typography>

                <Div className="user_data_company">
                <Grid className="inside_box" container spacing={1}>
  {index?.id == 2 && (
    <>
    <Grid item xs={12} lg={2.2} className="info_div_company">
      <div>
        <Typography className="user_data_typography_2"> Contact Person Name </Typography>
        <Typography className="user_data_typography_1"> Siva Ramar </Typography>
      </div>
    </Grid></>
  )}

  <Grid item xs={12}  lg={2.2} className="info_div_company">
    <div>
      <Typography className="user_data_typography_2"> Mobile No </Typography>
      <Typography className="user_data_typography_1"> 99406 68010 </Typography>
    </div>
  </Grid>

  {index?.id == 2 && (
    <Grid item xs={12}  lg={2.2} className="info_div_company">
      <div>
        <Typography className="user_data_typography_2"> Alternate Mobile No </Typography>
        <Typography className="user_data_typography_1"> 78945 61231 </Typography>
      </div>
    </Grid>
  )}
     
  <Grid item xs={12} sm={6} md={3} lg={2.5} className="info_div_company">
    <div>
      <Typography className="user_data_typography_2"> Email Id </Typography>
      <Typography className="user_data_typography_1"> sabarirahulm2001@gmail.com </Typography>
    </div>
  </Grid>

  {index?.id == 2 && (
    <Grid item xs={12} sm={6} md={3} lg={2.2} className="info_div_company">
      <div>
        <Typography className="user_data_typography_2"> Years of Gap </Typography>
        <Typography className="user_data_typography_1"> 5 </Typography>
      </div>
    </Grid>
  )}

  <Grid item xs={12}  lg={2.2} className="info_div_company">
    <div>
      <Typography className="user_data_typography_2"> Pan No </Typography>
      <Typography className="user_data_typography_1"> 9940ABCDEFR70 </Typography>
    </div>
  </Grid>

  <Grid item xs={12}  lg={2.2} className="info_div_company">
    <div>
      <Typography className="user_data_typography_2"> Aadhar No </Typography>
      <Typography className="user_data_typography_1"> 9940 6680 1170 </Typography>
    </div>
  </Grid>

  <Grid item xs={12}  lg={2.2} className="info_div_company">
  
    <div>
      <Typography className="user_data_typography_2"> GST </Typography>
      <Typography className="user_data_typography_1"> 12345678 </Typography>
    </div>
  </Grid>

  <Grid item xs={12}  lg={4.2} className="info_div_company_Address">
    <div>
      <Typography className="user_data_typography_2"> Address </Typography>
      <Typography className="user_data_typography_1"> 15/1 Akalya apartments, Tamil Nadu, Madurai - 625009 </Typography>
    </div>
  </Grid>
</Grid>



                  
                </Div>
              </CardContent>
           

              {index?.id == 1 && (
              <Div className="Overall_curusr_div">
                <CardContent className="">
                  <Typography
                    variant="h3"
                    fontWeight="550"
                    className="mb-0 p-0 m-0"
                  >
                    Partner I Info
                  </Typography>

                  <Box className="info-card">
                    <table>
                      <tbody>
                        <tr className="">
                          <td>
                            <Typography className="user_data_typography_2">
                              {" "}
                              Contact Person Name{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight="bold"
                              marginLeft={2}
                              className="user_data_typography_1"
                            >
                              {" "}
                              Sabari Rahul Murali
                            </Typography>
                          </td>
                        </tr>
                        <tr className="">
                          <td>
                            <Typography className="user_data_typography_2">
                              {" "}
                              Mobile No{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight="bold"
                              marginLeft={2}
                              className="user_data_typography_1"
                            >
                              {" "}
                              99406 68010{" "}
                            </Typography>
                          </td>
                        </tr>
                        <tr className="">
                          <td>
                            <Typography
                              width={"250px"}
                              className="user_data_typography_2"
                            >
                              {" "}
                              Alternate Mobile No{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight="bold"
                              marginLeft={2}
                              className="user_data_typography_1"
                            >
                              {" "}
                              99406 68010{" "}
                            </Typography>
                          </td>
                        </tr>
                        <tr className="">
                          <td>
                            <Typography className="user_data_typography_2">
                              {" "}
                              Email Id{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight="bold"
                              marginLeft={2}
                              className="user_data_typography_1"
                            >
                              {" "}
                              sabarirahulm25032001@gmail.com
                            </Typography>
                          </td>
                        </tr>
                        <tr className="">
                          <td>
                            <Typography className="user_data_typography_2">
                              {" "}
                              Pan No{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight="bold"
                              marginLeft={2}
                              className="user_data_typography_1"
                            >
                              {" "}
                              9940ABCDEFR70{" "}
                            </Typography>
                          </td>
                        </tr>
                        <tr className="">
                          <td>
                            <Typography className="user_data_typography_2">
                              {" "}
                              Aadhar No{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight="bold"
                              marginLeft={2}
                              className="user_data_typography_1"
                            >
                              {" "}
                              9940 6680 1170{" "}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Box>
                </CardContent>

                <CardContent className="">
                  <Typography
                    variant="h3"
                    fontWeight="550"
                    className="mb-0 p-0 m-0"
                  >
                    Partner II Info
                  </Typography>

                  <Box className="info-card">
                    <table>
                      <tbody>
                        <tr className="">
                          <td>
                            <Typography className="user_data_typography_2">
                              {" "}
                              Contact Person Name{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight="bold"
                              marginLeft={2}
                              className="user_data_typography_1"
                            >
                              {" "}
                              Sabari Rahul Murali
                            </Typography>
                          </td>
                        </tr>
                        <tr className="">
                          <td>
                            <Typography className="user_data_typography_2">
                              {" "}
                              Mobile No{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight="bold"
                              marginLeft={2}
                              className="user_data_typography_1"
                            >
                              {" "}
                              99406 68010{" "}
                            </Typography>
                          </td>
                        </tr>
                        <tr className="">
                          <td>
                            <Typography
                              width={"250px"}
                              className="user_data_typography_2"
                            >
                              {" "}
                              Alternate Mobile No{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight="bold"
                              marginLeft={2}
                              className="user_data_typography_1"
                            >
                              {" "}
                              99406 68010{" "}
                            </Typography>
                          </td>
                        </tr>
                        <tr className="">
                          <td>
                            <Typography className="user_data_typography_2">
                              {" "}
                              Email Id{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight="bold"
                              marginLeft={2}
                              className="user_data_typography_1"
                            >
                              {" "}
                              sabarirahulm25032001@gmail.com
                            </Typography>
                          </td>
                        </tr>
                        <tr className="">
                          <td>
                            <Typography className="user_data_typography_2">
                              {" "}
                              Pan No{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight="bold"
                              marginLeft={2}
                              className="user_data_typography_1"
                            >
                              {" "}
                              9940ABCDEFR70{" "}
                            </Typography>
                          </td>
                        </tr>
                        <tr className="">
                          <td>
                            <Typography className="user_data_typography_2">
                              {" "}
                              Aadhar No{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight="bold"
                              marginLeft={2}
                              className="user_data_typography_1"
                            >
                              {" "}
                              9940 6680 1170{" "}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Box>
                </CardContent>
              </Div>
              )}
            </Grid>
          </Grid>
        
              
        
          

          {/* <CardContent className=" pt-0 mt-20">
            <Typography variant="h3" fontWeight="550" className="mb-0 p-0 m-0">
              Company Info
            </Typography>

            <Box className="user_data_Box">
              <table>
                <tbody>
                  <tr className="user_data_tr">
                    <Div className="user_data_td">
                      <td>
                        <Typography> Contact Person Name </Typography>
                      </td>
                    </Div>
                    <Div className="user_data_td">
                      <td>
                        <Typography
                          fontWeight="bold"
                          style={{ textAlign: "left" }}
                        >
                          {" "}
                          9940ABCDEFR70{" "}
                        </Typography>
                      </td>
                    </Div>
                  </tr>
                  <tr className="user_data_tr">
                    <Div className="user_data_td">
                      <td>
                        <Typography> Aadhar No </Typography>
                      </td>
                    </Div>
                    <Div className="user_data_td">
                      <td>
                        <Typography
                          fontWeight="bold"
                          style={{ textAlign: "right" }}
                        >
                          {" "}
                          9940 6680 1170{" "}
                        </Typography>
                      </td>
                    </Div>
                  </tr>
                  <tr className="user_data_tr">
                    <Div className="user_data_td">
                      <td>
                        <Typography> GST </Typography>
                      </td>
                    </Div>
                    <Div className="user_data_td">
                      <td>
                        <Typography
                          fontWeight="bold"
                          style={{ textAlign: "right" }}
                        >
                          {" "}
                          12345678{" "}
                        </Typography>
                      </td>
                    </Div>
                  </tr>
                  <tr className="user_data_tr">
                    <Div className="user_data_td">
                      <td>
                        <Typography> Address </Typography>
                      </td>
                    </Div>
                    <Div className="user_data_td">
                      <td>
                        <Typography
                          fontWeight="bold"
                          style={{ textAlign: "right" }}
                        >
                          {" "}
                          15/1 Akalya apartments,
                          <br /> Tamil Nadu, Madurai - 625009{" "}
                        </Typography>
                      </td>
                    </Div>
                  </tr>
                </tbody>
              </table>
            </Box>
          </CardContent> */}
        

        <CardContent>

          <Div sx={{ display: "flex", justifyContent: "space-between" }}>
            <Div className="d-flex items-center">
              <h2>Beneficiaries (5)</h2>
            </Div>

            <Div>
              <Grid item lg={3.7} md={6}>
                <Search
                  data={TableData}
                  onSearch={handleSearch}
                  terms={terms}
                />
              </Grid>
            </Div>
          </Div>

          <Div>
            <Table className="common_table" id="tablePopover">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel>Bene.Id</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Created On</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel> Name </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Mobile No </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Bank Name</TableSortLabel>
                  </TableCell>
                  <TableCell>Account No</TableCell>
                  <TableCell align="center">No.of Transaction</TableCell>
                  <TableCell>
                    Status
                  </TableCell>
                  <TableCell sx={{ width: "20px" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <SkeletonTableLoader tablecell="7" row="8" />
                ) : filteredData.length == 0 ? (
                  <NoRecordsFound tablecell="7" />
                ) : (
                  filteredData.map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell
                          className="Link"
                          onClick={handleDrawerToggle}
                        >
                          {item?.id}
                        </TableCell>
                        <TableCell>
                          {item?.createdonDate} {item?.createdTime}{" "}
                        </TableCell>
                        <TableCell
                          className="Link"
                          onClick={handleDrawerToggle}
                        >
                          {item?.name}
                        </TableCell>
                        <TableCell>
                          {item?.mobile.substring(0, 6) +
                            " " +
                            item.mobile.substring(6)}
                        </TableCell>
                        <TableCell>{item?.bank}</TableCell>
                        <TableCell>{item?.acno}</TableCell>
                        <TableCell align="center">{item?.noTrans}</TableCell>
                        <TableCell>
                          {item?.status == 1 ? (
                            <span className="admin_active_status">
                              <span className="active_dot"></span> Active
                            </span>
                          ) : (
                            <span className="admin_inactive_status">
                              <span className="inactive_dot"></span> Inactive
                            </span>
                          )}
                        </TableCell>
                        <TableCell >
                          <IconButton
                            className="table_icon_btn"
                            onClick={(event) => handleClick(event, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
<Menu
                          className="custom_popover"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={selectedIndex === i}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          
                          
                          <MenuItem className="menuItem" onClick={handleView}>
                          <RemoveRedEyeOutlinedIcon />
                            <span className="ml-10" > View </span>
                          </MenuItem>
                          <MenuItem className="menuItem" onClick={handleClose}>
                            <TrashIcon />
                            <span className="ml-10"> Delete </span>
                          </MenuItem>
                        </Menu>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={15}
                  rowsPerPage={5}
                  page={1}
                />
              </TableFooter>
            </Table>
          </Div>
        </CardContent>
      </Card>

      <CurrentUserDetailedView
        handleDrawerClose={handleDrawerClose}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
    </section>
  );
};

export default CurrentUserDetailedList;
