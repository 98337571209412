import React, {useState} from 'react'
import { Box, Card, CardContent, Grid, Typography} from'@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from "prop-types";


// tabs
function CustomTabPanel(props) {
const { children, value, index, ...other } = props;

return (
<div role="tabpanel" hidden={value !==index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}
  {...other}>
  {value === index && (
  <Box sx={{ p: 3 }}>
    <Typography>{children}</Typography>
  </Box>
  )}
</div>
);
}
CustomTabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
return {
id: `simple-tab-${index}`,
"aria-controls": `simple-tabpanel-${index}`,
};
}
const Faq = () => {
// useState
const [value, setValue] = useState(0);
const handleChange = (event, newValue) => {
setValue(newValue);
};



return (
<section>
   <Card className='faq_accord'>
           <CardContent><Box sx={{ width: "100%" }}>
           <Grid className="common_header grid pb-5" container>
            <Grid className="custom_grid p-0">
              <Grid item>
                <div className="d-flex items-center">
                  <h2>FAQ</h2>
                  </div>
              </Grid>
            </Grid>
            </Grid>
        <CustomTabPanel className="faq_ans_box1" value={value} index={0}>
          <Accordion className='shadowbox1'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content">
            <Typography className='feq_question'>What is Nsdl?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='feq_ans'>
                NSDL, or the National Securities Depository Limited, is a pivotal institution in India's financial
                infrastructure. It is a central securities depository, and its primary role is to facilitate the
                holding, trading, and settlement of securities in electronic form. In simpler terms, NSDL is responsible
                for maintaining electronic records of securities, such as stocks and bonds, in dematerialized (demat)
                form, thus eliminating the need for physical certificates.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className='shadowbox1'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content">
            <Typography className='feq_question'>What is the maximum permissible limit of transactions in Indian Bank
              NSDL Internet Banking?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='feq_ans'>
                NSDL, or the National Securities Depository Limited, is a pivotal institution in India's financial
                infrastructure. It is a central securities depository, and its primary role is to facilitate the
                holding, trading, and settlement of securities in electronic form. In simpler terms, NSDL is responsible
                for maintaining electronic records of securities, such as stocks and bonds, in dematerialized (demat)
                form, thus eliminating the need for physical certificates.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className='shadowbox1'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content">
            <Typography className='feq_question'>Is registration of Mobile number required to initiate transactions
              through Net Banking?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='feq_ans'>
                NSDL, or the National Securities Depository Limited, is a pivotal institution in India's financial
                infrastructure. It is a central securities depository, and its primary role is to facilitate the
                holding, trading, and settlement of securities in electronic form. In simpler terms, NSDL is responsible
                for maintaining electronic records of securities, such as stocks and bonds, in dematerialized (demat)
                form, thus eliminating the need for physical certificates.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className='shadowbox2'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content">
            <Typography className='feq_question'>How to register for DOP Internet Banking?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='feq_ans'>
                NSDL, or the National Securities Depository Limited, is a pivotal institution in India's financial
                infrastructure. It is a central securities depository, and its primary role is to facilitate the
                holding, trading, and settlement of securities in electronic form. In simpler terms, NSDL is responsible
                for maintaining electronic records of securities, such as stocks and bonds, in dematerialized (demat)
                form, thus eliminating the need for physical certificates.
              </Typography>
            </AccordionDetails>
          </Accordion>
          </CustomTabPanel>
        <CustomTabPanel value={value} index={1}></CustomTabPanel>
      </Box>
    </CardContent>
  </Card>
  </section>
)
}
export default Faq