import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { TextField, Typography, Grid,CardContent, Autocomplete, Stepper, Step, StepLabel, TablePagination, StepContent} from "@mui/material";
import * as yup from 'yup';
import Div from "@jumbo/shared/Div";
import CREDITS from '../../../../assets/nsdl_logo.png'
import profilebox from '../../../../assets/user_img.jpg'
import BGimg from '../../../../assets/registeruser BG.png';
import {DashLogo} from "../../../../../app/assets/svg/Svg";
import { SucessGif } from "app/components/SucessGif/SucessGif";
import {
 Box,
  Card,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#a19b9b",
            "&.Mui-checked": {
              color: "#000",
            },
          },
        },
      },
    },
  });

  

const RegisterPage = () => {
    const navigate = useNavigate();
    const [process,SetProcess]=useState(1)
    const [selectedValue, setSelectedValue] = useState("salary");
    const steps = [
        'Personal Details',
        'Other Details',
        'Nominee Details',
      ];

    const states = [
        { label: "Tamil Nadu" },
        { label: "Kerala" },
        { label: "Assam" },
      ];
      const Cities = [
        { label: "Madurai" },
        { label: "Chennai" },
        { label: "Theni" },
      ];
   
    function handleUpdate(){
        SetProcess(process+1)
    }
    function handleBack(){
      SetProcess(process-1)
  }
    function handleMove(){
        
          navigate('/saving-user/home');
        
    }
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
      };

    return (
        <Div className='saving_user_register'>
            <Div className='savingsuser-sidebar'>
            <div className='savinguser-dashlogo'>
                        <DashLogo />
                      </div>
             <Div className='saving_user_register_img_div'>
                  <Div>
                     <img src={BGimg} alt='img' className='img-div' />
                  </Div>
                      <div className="credits_logo-last" >
                          <p>Powered by</p>
                          <img width={98} height={40} src={CREDITS} alt="credits" />
                       </div>

                        </Div>
                </Div>
            <Div className='saving_user_register_content_div'>
                <Div className='headerbox-registercontent' sx={{borderLeft:'4px solid #c6c6c656',padding:'22px 0px 0px 25px'}}>
                    <Typography variant='h1' fontWeight={600}>Saving Account Opening Form</Typography>
                </Div>
                <Div sx={{ width: '90%',margin:'0 auto',border:'none !important' }}>
      <Stepper activeStep={process} alternativeLabel sx={{border:'none !important'}}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
                </Div>
                {
                process===1 ? (
                
                    <Div>
                        <div className='saving-user-profile-container'>
                            <div><img width={'105px'} height={'105px'} src={profilebox} alt="profilephoto"/></div>
                            <div className='mt-20'><Typography variant='p' fontWeight={500} color={'#666666'} fontSize={'18px'}>RECENT PROFILE PHOTO</Typography><br/>
                        <Button  className="outline_btn width_fit mt-5" type="submit" variant="contained"size="medium" sx={{height:'40px !important',width:'120px !important'}}>
                         Upload</Button>{"  "}<span>Use a JPEG, JPG, PNG (250 kb)</span></div>
                        
                        </div>
                    <CardContent sx={{paddingRight:'10px !important',paddingLeft:'35px !important'}}>
            <form>
              <Grid container spacing={3} marginBottom={'120px'}>
                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                  <div className="">
                    <Typography className="from_label">
                      First Name <span className="required">*</span>
                    </Typography>
                    <TextField
                      placeholder="Enter First Name"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
               

                  <>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <Typography className="from_label">
                          Last Name{" "}
                          <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Last Name"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label" textTransform={'none !important'}>
                            Date of Birth{" "}
                            <span className="required">*</span>
                          </Typography>
                        </div>
                        <TextField
                          placeholder="Enter Date of Birth"
                          name=""
                          type="date"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                          sx={{fontSize:'12px',fontWeight:'300'}}
                            InputProps={{
                              style: { color: '#969696' }
                            }}
                        />
                      </div>
                    </Grid>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                            Email Id<span className="required">*</span>
                          </Typography>
                        </div>

                        <TextField
                          placeholder="Enter Email Id"
                          name=""
                          type="email"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <Typography className="from_label">
                          Mobile No 
                        </Typography>
                        <TextField
                          placeholder="Enter Mobile No"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                  </>

                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
               
                    <div className="">
                      <Typography className="from_label text-normal">
                        Alternate Mobile No
                      </Typography>
                      <TextField
                        placeholder="Enter Alternate Mobile No"
                        name=""
                        type="number"
                        className="form_new_style"
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                </Grid>

                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        Address Line 1 <span className="required">*</span>
                      </Typography>
                    </div>

                    <TextField
                      placeholder="Enter Address Line 1"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>

                  <>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <Typography className="from_label">
                          Address Line 2
                        </Typography>
                        <TextField
                          placeholder="Enter Address Line 2"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                  </>


                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        State <span className="required">*</span>
                      </Typography>
                    </div>
                    <Autocomplete
                    disablePortal
                    name="bbank"
                    options={states}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select State"
                        className="form_new_style form_selct"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                  </div>
                </Grid>
                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        City <span className="required">*</span>
                      </Typography>
                    </div>
                    <Autocomplete
                    disablePortal
                    name="bbank"
                    options={Cities}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select City"
                        className="form_new_style form_selct"
                        fullWidth
                        {...params}
                      />
                    )}
                    />
                  </div>
                </Grid>
                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                  <div className="">
                    <Typography className="from_label">
                      Pincode <span className="required">*</span>
                    </Typography>
                    <TextField
                      placeholder="Enter Pincode"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
              <Div sx={{display:'flex',justifyContent:"space-between"}}>
                <Div>
              <Grid
          container
          lg={12}
          spacing={2}
          justifyContent={'space-between'}
          sx={{
            width:'976px',
            padding:'20px 15px 5px 5px',
            alignItems:'center',
            position:"fixed",
            right:0,
            bottom:0,
            backgroundColor:'white'
          }}
        ><Grid item>
            <Typography>If Already Apply!<a className='link-savings' href='/savinguser/home'>Login</a></Typography>
        </Grid>
            <Grid container lg={7} justifyContent={'end'} spacing={1.5}>
          <Grid lg={3} item className="custom_grid custom_grid-saving" >
            <Button
              className="outline_btn w-100 admin"
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              onClick={handleBack}
            >
              Cancel
            </Button>
          </Grid>
          <Grid lg={3} item className="custom_grid custom_grid-saving">
            <Button
              className="primary_btn admin"
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              onClick={handleUpdate}
            >
              Next
            </Button>
          </Grid>
          </Grid>
        </Grid>
              </Div>
              </Div>
            </form>
                    </CardContent>
                </Div>
                ): process===2?(
                    <Div>
                    <CardContent sx={{paddingRight:'10px !important',paddingLeft:'35px !important'}} >

            <form>
            <Grid lg={5} item className="custom_grid">
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography className="from_label">
                        Employee Type<span className="required">*</span>
                      </Typography>
                      <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        value={selectedValue}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="salary"
                          className="radio_form"
                          control={<Radio />}
                          label="Salaried"
                          sx={{color:'#666666 !important'}}
                        />
                        <FormControlLabel
                          value="self Employe"
                          className="radio_form ml-30"
                          control={<Radio />}
                          label="Self Employed"
                          sx={{color:'#666666 !important'}}
                        />
                      </RadioGroup>
                    </ThemeProvider>
                  </div>
                </Grid>
              <Grid container spacing={3} marginBottom={'120px'}>
                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                  <div className="">
                    <Typography className="from_label">
                      Marital Status <span className="required">*</span>
                    </Typography>
                    <TextField
                      placeholder="Enter MARITAL STATUS"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
               

                  <>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <Typography className="from_label">
                          Father/Spouse Name{" "}
                          <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Father/Spouse Name"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                            Company Name{" "}
                            <span className="required">*</span>
                          </Typography>
                        </div>
                        <TextField
                          placeholder="Enter Company Name"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                            Company Experience<span className="required">*</span>
                          </Typography>
                        </div>

                        <TextField
                          placeholder="Enter Company Experience"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
               
                    <div className="">
                      <Typography className="from_label text-normal">
                        Location<span className="required">*</span>
                      </Typography>
                      <TextField
                        placeholder="Enter Location"
                        name=""
                        type="text"
                        className="form_new_style"
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                </Grid>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <Typography className="from_label">
                          Income(₹)
                        </Typography>
                        <TextField
                          placeholder="Enter Income"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth                         
                        />
                      </div>
                    </Grid>
                  </>

              </Grid>
              <Div sx={{display:'flex',justifyContent:"space-between"}}>
                <Div>
              <Grid
          container
          lg={12}
          spacing={2}
          justifyContent={'space-between'}
          sx={{
            width:'976px',
            padding:'20px 15px 5px 5px',
            alignItems:'center',
            position:"fixed",
            right:0,
            bottom:0,
            backgroundColor:'white'
          }}
        ><Grid item>
            <Typography>If Already Apply!<a className='link-savings'>Login</a></Typography>
        </Grid>
            <Grid container lg={7} justifyContent={'end'} spacing={1.5} >
          <Grid lg={3} item className="custom_grid custom_grid-saving" >
            <Button
              className="outline_btn w-100 admin"
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              onClick={handleBack}
            >
              Cancel
            </Button>
          </Grid>
          <Grid lg={3} item className="custom_grid custom_grid-saving">
            <Button
              className="primary_btn admin"
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              onClick={handleUpdate}
            >
              Next
            </Button>
          </Grid>
          </Grid>
        </Grid>
              </Div>
              </Div>
            </form>

                    </CardContent>
                </Div>
                ):(<Div>
                    <div className='saving-user-profile-container'>
                            <div><img width={'105px'} height={'105px'} src={profilebox} alt="profilephoto"/></div>
                            <div className='mt-20'><Typography variant='p' fontWeight={500} color={'#666666'} fontSize={'18px'}>RECENT PROFILE PHOTO</Typography><br/>
                        <Button  className="outline_btn width_fit mt-5" type="submit" variant="contained"size="medium" sx={{height:'40px !important',width:'120px !important'}}>
                         Upload</Button>{" "}<span>Use a JPEG, JPG, PNG (250 kb)</span></div>
                        
                        </div>
                    <CardContent sx={{paddingRight:'10px !important',paddingLeft:'35px !important'}}>
            <form>
              <Grid container spacing={3} marginBottom={'120px'}>
                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                  <div className="">
                    <Typography className="from_label">
                      Nominee First Name<span className="required">*</span>
                    </Typography>
                    <TextField
                      placeholder="Enter Nominee First Name"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
               

                  <>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <Typography className="from_label">
                        Nominee Last Name{" "}
                          <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Nominee Last Name"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label "textTransform={'none !important'}>
                          Nominee Date of Birth{" "}
                            <span className="required">*</span>
                          </Typography>
                        </div>
                        <TextField
                          placeholder="Enter Nominee Date of Birth"
                          name=""
                          type="date"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                          sx={{fontSize:'12px',fontWeight:'300'}}
                            InputProps={{
                              style: { color: '#969696' }
                            }}
                        />
                      </div>
                    </Grid>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                          Nominee Email Id<span className="required">*</span>
                          </Typography>
                        </div>

                        <TextField
                          placeholder="Enter Nominee Email Id"
                          name=""
                          type="email"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <Typography className="from_label">
                          Mobile No 
                        </Typography>
                        <TextField
                          placeholder="Enter Mobile No"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                  </>

                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
               
                    <div className="">
                      <Typography className="from_label text-normal">
                        Alternate Mobile No
                      </Typography>
                      <TextField
                        placeholder="Enter Alternate Mobile No"
                        name=""
                        type="number"
                        className="form_new_style"
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                </Grid>

                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        Address Line 1 <span className="required">*</span>
                      </Typography>
                    </div>

                    <TextField
                      placeholder="Enter Address Line 1"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>

                  <>
                    <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                      <div className="">
                        <Typography className="from_label">
                          Address Line 2
                        </Typography>
                        <TextField
                          placeholder="Enter Address Line 2"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                  </>


                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        State <span className="required">*</span>
                      </Typography>
                    </div>
                    <Autocomplete
                    disablePortal
                    name="bbank"
                    options={states}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select State"
                        className="form_new_style form_selct"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                  </div>
                </Grid>
                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        City <span className="required">*</span>
                      </Typography>
                    </div>
                    <Autocomplete
                    disablePortal
                    name="bbank"
                    options={Cities}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select City"
                        className="form_new_style form_selct"
                        fullWidth
                        {...params}
                      />
                    )}
                    />
                  </div>
                </Grid>
                <Grid lg={5.5} item className="custom_grid custom_grid-saving">
                  <div className="">
                    <Typography className="from_label">
                      Pincode <span className="required">*</span>
                    </Typography>
                    <TextField
                      placeholder="Enter Pincode"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
              <Div>
              <Grid
          container
          lg={12}
          spacing={2}
          justifyContent={'space-between'}
          sx={{
            width:'976px',
            padding:'20px 15px 5px 5px',
            alignItems:'center',
            position:"fixed",
            right:0,
            bottom:0,
            backgroundColor:'white'
          }}
        ><Grid item>
            <Typography>If Already Apply!<a className='link-savings'>Login</a></Typography>
        </Grid>
            <Grid container lg={7} justifyContent={'end'} spacing={1.5}>
          <Grid lg={3} item className="custom_grid custom_grid-saving" >
            <Button
              className="outline_btn w-100 admin"
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              onClick={handleBack}
            >
              Cancel
            </Button>
          </Grid>
          <Grid lg={3} item className="custom_grid custom_grid-saving">
            <Button
              className="primary_btn admin"
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              onClick={handleMove}
            >
              Submit
            </Button>
          </Grid>
          </Grid>
        </Grid>
              </Div>
            </form>
                    </CardContent>
                </Div>)}
            </Div>
        </Div>

    );
};

export default RegisterPage;
