import {
    Autocomplete,
    Box,
    Button,
    Drawer,
    FormControlLabel,
    Grid,
    LinearProgress,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Step,
    StepLabel,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    ThemeProvider,
    Typography,
    createTheme,
  } from "@mui/material";
  import React, { useState } from "react";
  import ClearIcon from "@mui/icons-material/Clear";
  import Div from "@jumbo/shared/Div";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import dayjs from "dayjs";
  import { LuUpload } from "react-icons/lu";
  const theme = createTheme({
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#333", // Change the color of the unselected radio button
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: "#666666", // Change the color of the radio label
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
    },
  });


const CreditFac = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [checked, setChecked] = useState(false);
    const [selectedValue, setSelectedValue] = useState("option1");
    const [selectedValueTwo, setSelectedValueTwo] = useState("option1");
    const [value, setValue] = useState(dayjs("2022-04-17"));
    const state = [
      { label: "Tamil Nadu" },
      {
        label: "Maharastra",
      },
      {
        label: "Karnataka",
      },
    ];
    const country = [
      { label: "India" },
      {
        label: "Sri Lanka",
      },
      {
        label: "Malaysia",
      },
    ];
    const role = [
      { label: "Beneficial Owner" },
      {
        label: "Beneficial Owner 1",
      },
      {
        label: "Beneficial Owner 2",
      },
    ];
    const Month = [
      { label: "5" },
      {
        label: "4",
      },
      {
        label: "6",
      },
    ];
    const city = [
      {
        label: "Madurai",
      },
      {
        label: "Chennai",
      },
      {
        label: "Theni",
      },
    ];
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleChangeRadio = (event) => {
      setSelectedValue(event.target.value);
    };
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
      };
      const handleChangeTwo = (event) => {
        setSelectedValueTwo(event.target.value);
      };
      const SelecthandleChange = (event) => {
        setAge(event.target.value);
      };
      const [rowCount, setRowCount] = useState(1);
      const [tableData, setTableData] = useState([]);
    
      const handleRowCountChange = (event) => {
        setRowCount(parseInt(event.target.value, 10));
      };
    
      const handleAddRows = () => {
        const newRow = Array.from({ length: rowCount }, (_, index) => index + 1);
        setTableData([...tableData, newRow]);
      };
      const [age, setAge] = React.useState(10);
  
  return (
    <div className="create_form_width w-100" >
          <Div>
        
          <ThemeProvider theme={theme}>
          <RadioGroup value={selectedValue} onChange={handleChange} row>
        <FormControlLabel   className="radio_form" value="option1" control={<Radio />} label="I/ We do not enjoy any credit facilities with other bank/s" />
        <FormControlLabel    className="radio_form" value="option2" control={<Radio />} label='I/ We enjoy the following "credit facilities" with other bank/s' />
      
      </RadioGroup>
       </ThemeProvider>

        </Div>

        <Div sx={{mt:3}}>
            <div className="d-flex items-center" style={{justifyContent:"flex-end"}}>
                <p>No of rows</p>
                <Select className="ml-10" style={{width:"70px"}}
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={age}
    
    onChange={SelecthandleChange}
  >
    <MenuItem value={10}>2</MenuItem>
    <MenuItem value={20}>4</MenuItem>
    <MenuItem value={30}>6</MenuItem>
  </Select>
</div>
       


        </Div>
        <Div sx={{mt:3}}>
    <table className="w-100 dyanmic_table">
        <thead> 
            <tr>
                <td>S.No</td>
                <td>Bank Name & Branch</td>
                <td>Type of facility</td>
                <td>Amount (Rs. Lacs)</td>
                <td>Authorized Signatories Signature</td>
            </tr>
        </thead>
        <tbody>
        <tr>
            <td>
            1

            </td>
            <td><TextField value={"HDFC Bank"} className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Bank & Branch Name" /></td>
            <td><TextField value={"Business lines"} className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Type of facility" /></td>
            <td><TextField value={"4"} className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Amount (Rs. Lacs)" /></td>
            <td><Button className="upload_btn uploaded" variant="outlined" startIcon={<LuUpload />}>
              Uploaded</Button>
              <span className="uploaded_image">Vimal.png</span>
              </td>
            
          </tr>
          <tr>
            <td>
            2

            </td>
            <td><TextField className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Bank & Branch Name" /></td>
            <td><TextField className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Type of facility" /></td>
            <td><TextField className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Amount (Rs. Lacs)" /></td>
            <td>
              <Button className="upload_btn" variant="outlined" startIcon={<LuUpload />}>Upload Signature</Button>
            </td>
            
          </tr>
        </tbody>

    </table>


  </Div>
        
        </div>
  )
}

export default CreditFac