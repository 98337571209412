import Div from '@jumbo/shared/Div'
import { FormControlLabel, Radio, RadioGroup, TextField, ThemeProvider, Typography, createTheme } from '@mui/material'
import React from 'react'
import { useState } from 'react';
const theme = createTheme ({
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#333", // Change the color of the unselected radio button
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: "#666666", // Change the color of the radio label
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
    },
  });
const CrsDecalartion = () => {
    const [selectedValue, setSelectedValue] = useState("option1");
 
  return (
    <div>
        <div className='create_form_width'>
            <h2 className='crs_head'>To: NSDL Payments Bank Limited</h2>

            <Div sx={{mt:3}} >
            <Typography className="from_label">
       
       Customer Id/ Account No
      </Typography>
      <TextField
        className="form_new_style"
        id="outlined-basic"
        variant="outlined"
        fullWidth
      />


            </Div>
            <Div sx={{mt:3}} >
            <Typography className="from_label">
       Name
      </Typography>
      <TextField
        className="form_new_style"
        id="outlined-basic"
        variant="outlined"
        fullWidth
      />


            </Div>
            <p className='nominee_text' style={{display:"block"}}>* One Input is mandatory</p>

            

        </div>
        <Div>
            <ol className='crs_list'>
                <li className='mb-30'><span className='responsive_list'>(a)</span> <label > Is the account holder a Government Body/ International Organisation/listed on any recognised stock exchange. (If yes, you are listed please specify the name of the stock <br />exchange <input className='list_input' type="text" />   If no proceed to point (b)) <br /> <br />
                <ThemeProvider theme={theme}>
                     <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="yes"
            className="radio_form"
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="Yes"
          />
          <FormControlLabel
            value="No"
            className="radio_form "
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="No"
          />
        
         
        </RadioGroup>
      </ThemeProvider>

                 </label> </li>

                 <li className='mb-20'><span className='responsive_list'>(b)</span> <label >Is the account holder (Entity/Financial Institution) tax resident of any country other than India (If yes, please fill FATCA/CRS self-certification), If no proceed to point (c))
                 <br /> <br />
                <ThemeProvider theme={theme}>
                     <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="yes"
            className="radio_form"
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="Yes"
          />
          <FormControlLabel
            value="No"
            className="radio_form "
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="No"
          />
        
         
        </RadioGroup>
      </ThemeProvider>

                    </label> </li>
                    <li className='mb-30'><span className='responsive_list'>(c)</span> <label > Is the account holder an Indian Financial Institution (If yes, please provide your GIIN <br /> <input className='list_input' type="text" />    If no proceed to point (d)) <br /> <br />
                <ThemeProvider theme={theme}>
                     <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="yes"
            className="radio_form"
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="Yes"
          />
          <FormControlLabel
            value="No"
            className="radio_form "
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="No"
          />
        
         
        </RadioGroup>
      </ThemeProvider>

                 </label> </li>
                 <li className='mb-20'><span className='responsive_list'>(d)</span> <label >
                 Are Substantial owners or controlling persons in the entity or chain of ownership resident for tax purpose in any country outside India or not an Indian Citizen. (If yes, please fill FATCA/ CRS self-certification)
                 <br /> <br />
                <ThemeProvider theme={theme}>
                     <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="yes"
            className="radio_form"
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="Yes"
          />
          <FormControlLabel
            value="No"
            className="radio_form "
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="No"
          />
        
         
        </RadioGroup>
      </ThemeProvider>

                    </label> </li>
                  
               

            </ol>
                
       </Div>

    </div>
  )
}

export default CrsDecalartion