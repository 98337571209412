import {
    Autocomplete,
    Box,
    Button,
    Drawer,
    FormControlLabel,
    Grid,
    LinearProgress,
    Radio,
    RadioGroup,
    Step,
    StepLabel,
    Stepper,
    TextField,
    ThemeProvider,
    Typography,
    createTheme,
    Paper
  } from "@mui/material";
  import React, { useState } from "react";
  import ClearIcon from "@mui/icons-material/Clear";
  import Div from "@jumbo/shared/Div";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import dayjs from "dayjs";
  import * as yup from 'yup';
  import { useFormik } from "formik";
//existCustomer, gender
//role, city, state, country
//dob
// customerId,  name, designation,  maritalStatus, nationality,
// pan, motherName, email, mobile, address, landmark,  pincode, 

const today = new Date();
const eighteenYearsAgo = new Date(today);
eighteenYearsAgo.setFullYear(today.getFullYear() - 18);

const pincodeRegExp = /^[1-9][0-9]{5}$/;
const mobileValidation = /^[6-9]\d{9}$/;
const panValidation = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
  const validationSchema = yup.object({
 /*   customerId: yup
        .string()
        .required('Customer Id is required'),
        
      name: yup
        .string()
        .required('Name is required'),
        
      nationality: yup
        .string()
        .required('Nationality is required'),  

      motherName: yup
        .string()
        .required("Mother's maiden Name is required"),
        */
  
    designation: yup
        .string()
        .required('Designation is required'),
    maritalStatus: yup
        .string()
        .required('Marital Status is required'),
    pan: yup
        .string()
        .matches(panValidation, 'Invalid PAN number(5 alphabets caps, 4 numbers, 1 alphabets caps )')
        .required('Pan is required'),
   
    email: yup
        .string()
        .email('Invalid email address')
        .required('Email is required'),
    mobile: yup
        .string()
        .required('Mobile number is required')
        .matches(mobileValidation, 'Invalid mobile number'),    
    address: yup
        .string()
        .trim()
        .required('Address is required'),
    landmark: yup
        .string()
        .trim(),
    pincode: yup
        .string()
        .matches(pincodeRegExp, 'Invalid PIN code'),
    dob: yup.string()
    // .required('Date of Birth is required')
        .test('is-adult', 'Must be at least 18 years old', function (value) {

          if (value === null) {
            // If dob is not provided, consider it as valid (optional)
            return true;
          }
          if (!value) {
            // If dob is not provided, consider it as valid (optional)
            return true;
          }
    
          const dobDate = new Date(value);
    
          // Check if the date is valid
          if (isNaN(dobDate.getTime())) {
            return false; // Invalid date
          }
    
          return dobDate <= eighteenYearsAgo;
        }),
     

        role: yup.object().shape({
          label: yup.string().required('Authorised signatory role is required'),
        }),
  /*  
    existCustomer: yup.string()
        .required('Existing customer is required'),
    gender: yup.string()
        .required('Gender is required'),

    city: yup.object().shape({
          label: yup.string().required('City is required'),
        }), 
    state: yup.object().shape({
          label: yup.string().required('State is required'),
        }),
    country: yup.object().shape({
          label: yup.string().required('Country is required'),
        }),  
   */
  });
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };
  const theme = createTheme({
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#333", // Change the color of the unselected radio button
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: "#666666", // Change the color of the radio label
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
    },
  });

const AuthorizedForm = () => {

    const [activeStep, setActiveStep] = useState(0);
    const [checked, setChecked] = useState(false);
    const [selectedValue, setSelectedValue] = useState("option1");
    const [selectedValueTwo, setSelectedValueTwo] = useState("option1");
    const [value, setValue] = useState(dayjs());
    const state = [
      { label: "Tamil Nadu" },
      { label: "Maharastra" },
      { label: "Karnataka" },
    ];
    const country = [
      { label: "India" },
      { label: "Sri Lanka" },
      { label: "Malaysia" },
    ];
    const role = [
      { label: "Beneficial Owner" },
      { label: "Beneficial Owner 1" },
      { label: "Beneficial Owner 2" },
    ];
    const Month = [
      { label: "5" },
      { label: "4" },
      { label: "6" },
    ];
    const city = [
      { label: "Madurai" },
      { label: "Chennai" },
      { label: "Theni" },
    ];
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleChangeRadio = (event) => {
      setSelectedValue(event.target.value);
    };
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
      };
      const handleChangeTwo = (event) => {
        setSelectedValueTwo(event.target.value);
      };


      const initialValues = {
        customerId:'',
        name:'',
        designation:'',
        maritalStatus:'',
        nationality:'',
        pan:'',
        motherName:'',
        email:'',
        mobile:'',
        address:'',
        landmark:'',
        pincode:'',
        dob: null,
        //dob:'',
        existCustomer:'',
        gender:'',
        role:null,
        city:null,
        state:null,
        country:null
  }


      const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
          // Handle form submission logic here
          console.log('Form submitted:', values);
          console.log('date', values.dob)
          console.log('date', values.dob.$d)
        },
      });
  {/**
   // customerId,  name, designation,  maritalStatus, nationality,
// pan, motherName, email, mobile,  pincode, 
//address landmark  */}
  return (
    <div className="create_form_width">

<form onSubmit={formik.handleSubmit} noValidate autoComplete='off'> 
        <Div>
        <Typography className="from_label">
      
       Existing Customer
          </Typography>
          <ThemeProvider theme={theme}>
          <RadioGroup 
          name = "existCustomer"
          value={formik.values.existCustomer}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          //value={selectedValue} 
          //onChange={handleChange} 
         
          row>
        <FormControlLabel 
        onClick={()=> !checked}
         className="radio_form" 
         value="yes" 
         control={<Radio  color={selectedValue === 'option1' ? 'primary' : 'default'} />}
         label="Yes" />

        <FormControlLabel
        onClick={()=> !checked}
         className="radio_form"
         value="no" 
         control={<Radio  color={selectedValue === 'option1' ? 'primary' : 'default'} />}
         label="No" />
      
      </RadioGroup>

      {formik.touched.existCustomer && formik.errors.existCustomer && (
  <Div className="error text-danger">{formik.errors.existCustomer}</Div>
)}
       </ThemeProvider>

        </Div>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
         
         Customer Id
          </Typography>
          <TextField
            className="form_new_style"
            variant="outlined"
            name="customerId"
            onChange={formik.handleChange}
            error={formik.touched.customerId && Boolean(formik.errors.customerId)}
            helperText={formik.touched.customerId && formik.errors.customerId}
            fullWidth
          />

        </Div>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
       
        
        Anuthorised Signatory Role
         <span>*</span> 
          </Typography>
          <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  name="role"
                  className="basic-single select_box"
                  classNamePrefix="select"
                  options={role}
                  getOptionLabel={(option) => option.label}
                  PaperComponent={CustomPaper}
                  value={formik.values.role}
                  onChange={(_, newValue) => formik.setFieldValue('role', newValue)} 
                  renderInput={(params) => (
                    <TextField
                      className="mr-10"
                      error={formik.touched.role && Boolean(formik.errors.role)}
                      helperText={formik.touched.role && formik.errors.bbank?.role}
                      fullWidth
                      {...params}
                    />
                  )}
                />
                           
{formik.touched.role && formik.errors.role && (
  <Div className="error text-danger">{formik.errors.role}</Div>
)}

        </Div>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
        Name
          </Typography>
          <TextField
           name="name"
            className="form_new_style"
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant="outlined"
            fullWidth
          />

        </Div>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
        Designation <span>*</span>
          </Typography>
          <TextField
           name="designation"
            className="form_new_style"
            onChange={formik.handleChange}
            error={formik.touched.designation && Boolean(formik.errors.designation)}
            helperText={formik.touched.designation && formik.errors.designation}
            variant="outlined"
            fullWidth
          />

        </Div>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
         Gender
          </Typography>
          <ThemeProvider theme={theme}>
          <RadioGroup
           name = "gender"
           value={formik.values.gender}
           onChange={formik.handleChange}
           onBlur={formik.handleBlur}
           row>

        <FormControlLabel 
        onClick={()=> !checked}
        className="radio_form"
        value="Male"
        control={<Radio  color={selectedValue === 'option1' ? 'primary' : 'default'} />}
        label="Male" />

        <FormControlLabel
        onClick={()=> !checked}
        className="radio_form"
        value="Female"
        control={<Radio  color={selectedValue === 'option1' ? 'primary' : 'default'} />}
        label="Female" />
      
      </RadioGroup>
    
                     {formik.touched.gender && formik.errors.gender && (
  <Div className="error text-danger">{formik.errors.gender}</Div>
)}

       </ThemeProvider>
        

        </Div>
        <Div sx={{mt:3}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography className="from_label">
               Date of Birth
            </Typography>
            <DatePicker
              name="dob"
              className={`w-100 ${formik.touched.dob && formik.errors.dob ? 'Mui-error' : ''}`}
               value={formik.values.dob}
    format="DD/MM/YYYY"
      onChange={(newValue) => {
      setValue(newValue);
      formik.setFieldValue('dob', newValue);
     
    }}
   // onBlur={formik.handleBlur}
    error={formik.touched.dob && Boolean(formik.errors.dob)}
    helperText={formik.touched.dob && formik.errors.dob}
            />
          </LocalizationProvider>
        </Div>
        {formik.touched.dob && formik.errors.dob && (
    <div className="error text-danger">{formik.errors.dob}</div>
  )}

         {/*
        
        <DatePicker
              className="w-100"
              name="dob"
              value={formik.values.dob}
              format="DD/MM/YYYY"
              onChange={(newValue) => formik.setFieldValue('dob', newValue)}
              error={formik.touched.dob && Boolean(formik.errors.dob)}
              helperText={formik.touched.dob && formik.errors.dob}
            />
        
        */}

        <Div sx={{mt:3}}>
        <Typography className="from_label">
           Marital Status <span>*</span>
          </Typography>
          <TextField
           name="maritalStatus"
            className="form_new_style"
            onChange={formik.handleChange}
            error={formik.touched.maritalStatus && Boolean(formik.errors.maritalStatus)}
            helperText={formik.touched.maritalStatus && formik.errors.maritalStatus}
            variant="outlined"
            fullWidth
          /> 
        </Div>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
            Nantionality
          </Typography>
          <TextField
           name="nationality"
            className="form_new_style"
            onChange={formik.handleChange}
            error={formik.touched.nationality && Boolean(formik.errors.nationality)}
            helperText={formik.touched.nationality && formik.errors.nationality}
            variant="outlined"
            fullWidth
          /> 
        </Div>

 {/**
   // customerId,  name, designation,  maritalStatus, nationality,
// pan, motherName, email, mobile,  pincode, 
//address landmark  */}


        <Div sx={{mt:3}}>
        <Typography className="from_label">
          PAN <span>*</span>
          </Typography>
          <TextField
           name="pan"
            className="form_new_style"
            onChange={formik.handleChange}
            error={formik.touched.pan && Boolean(formik.errors.pan)}
            helperText={formik.touched.pan && formik.errors.pan}
            variant="outlined"
            fullWidth
          /> 
          <Typography sx={{fontSize:"15px ", mt:2}} className="lette_space_normal mt-10">***If PAN No. is not available, please fill up ADD-ON Form 60 or 61 and Form 49A</Typography>
        </Div>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
           Mother's Maiden Name
          </Typography>
          <TextField
           name="motherName"
            className="form_new_style"
            onChange={formik.handleChange}
            error={formik.touched.motherName && Boolean(formik.errors.motherName)}
            helperText={formik.touched.motherName && formik.errors.motherName}
            variant="outlined"
            fullWidth
          /> 
        </Div>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
           Email Id<span>*</span>
          </Typography>
          <TextField
           name="email"
            className="form_new_style"
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="outlined"
            fullWidth
          /> 
        </Div>
        <Div sx={{mt:3}}>
        <Typography className="from_label">
           Mobile No <span>*</span>
          </Typography>
          <TextField
           name="mobile"
            className="form_new_style"
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
            variant="outlined"
            fullWidth
          /> 
        </Div>
        <Div sx={{mt:4}}>
        <div  className="year_of_busness">
        <Typography className="lette_space_normal">
        Address
          </Typography>
        
       
        
        <div>

         <Div sx={{ mt: 3 }}>
          <Typography className="from_label">
           Permanent Address <span>*</span>
          </Typography>
          <textarea
            className="w-100"
            name="address"
              onChange={formik.handleChange}
              style={{
                width: '100%',
                borderColor: formik.touched.address && formik.errors.address ? 'red' : 'grey',
                ':hover': {
                  borderColor: formik.touched.address && formik.errors.address ? 'red' : 'grey',
                },
              }}
            rows="5"
          ></textarea>
          {formik.touched.address && formik.errors.address && (
    <div className="error text-danger">{formik.errors.address}</div>
  )}
        </Div>
        <Div sx={{ mt: 3 }}>
          <Typography className="from_label">
           Landmark
          </Typography>
          <textarea
            className="w-100"
            name="landmark"
              onChange={formik.handleChange}
              style={{
                width: '100%',
                borderColor: formik.touched.landmark && formik.errors.landmark ? 'red' : 'grey',
                ':hover': {
                  borderColor: formik.touched.landmark && formik.errors.landmark ? 'red' : 'grey',
                },
              }}
            rows="5"
          ></textarea>
          {formik.touched.landmark && formik.errors.landmark && (
    <div className="error text-danger">{formik.errors.landmark}</div>
  )}
     
        </Div>
        <Div sx={{ mt: 3 }}>
        <Grid container gap={"24px"}>
              <Grid item sm={6}>
                <Typography className="from_label">
                  City
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={city}
                  name="city"
                 // options={RemarksOptions}
                  getOptionLabel={(option) => option.label}
                  PaperComponent={CustomPaper}
                  className="basic-single select_box"
                  classNamePrefix="select"
                  value={formik.values.city}
                  onChange={(_, newValue) => formik.setFieldValue('city', newValue)}
                  renderInput={(params) => (
                    <TextField
                      className="mr-10"
                      fullWidth
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city?.label}
                      {...params}
                    />
                  )}
                />
                              {formik.touched.city && formik.errors.city && (
  <Div className="error text-danger">{formik.errors.city}</Div>
)}
              </Grid>
              <Grid item sm={5.5}>
                <Typography className="from_label">
                  
                  Pin Code
                </Typography>
                
                    <TextField  
                     name="pincode"
                    className="form_new_style"
                    onChange={formik.handleChange}
                    error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                    helperText={formik.touched.pincode && formik.errors.pincode}
                    variant="outlined"
                    fullWidth />
                 
                
              </Grid>
            </Grid> 
        </Div>
        <Div sx={{ mt: 3 }}>
        <Grid container gap={"24px"}>
              <Grid item sm={6}>
                <Typography className="from_label">
                  State
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  name="state"
                  options={state}
                  getOptionLabel={(option) => option.label}
                    PaperComponent={CustomPaper}
                    className="basic-single select_box"
                    classNamePrefix="select"
                    value={formik.values.state}
                    onChange={(_, newValue) => formik.setFieldValue('state', newValue)}
                  renderInput={(params) => (
                    <TextField
                      className="mr-10"
                      fullWidth
                      error={formik.touched.state && Boolean(formik.errors.state)}
                      helperText={formik.touched.state && formik.errors.state?.label}
                      {...params}
                    />
                  )}
                />

{formik.touched.state && formik.errors.state && (
  <Div className="error text-danger">{formik.errors.state}</Div>
)}
              </Grid>
              <Grid item sm={5.5}>
                <Typography className="from_label">
                 Country
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  name="country"
                  options={country}
                  getOptionLabel={(option) => option.label}
                    PaperComponent={CustomPaper}
                    className="basic-single select_box"
                    classNamePrefix="select"
                    value={formik.values.country}
                    onChange={(_, newValue) => formik.setFieldValue('country', newValue)}
                  renderInput={(params) => (
                    <TextField
                      className="mr-10"
                      fullWidth
                      error={formik.touched.country && Boolean(formik.errors.country)}
                      helperText={formik.touched.country && formik.errors.country?.label}
                      {...params}
                    />
                  )}
                />
                {formik.touched.country && formik.errors.country && (
  <Div className="error text-danger">{formik.errors.country}</Div>
)} 
                
              </Grid>

            </Grid> 
           
        </Div>


        </div>
     

       


        </div>

        </Div>

<button type="submit">submit</button>


        </form>

    </div>
  )
}

export default AuthorizedForm