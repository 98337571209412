import React, { Fragment, useEffect, useState } from 'react';
import { FiArrowRight } from "react-icons/fi";
import Div from "@jumbo/shared/Div";
import MenuItem from "@mui/material/MenuItem";
import { CardContent, Card, Typography, Button, IconButton, Select, TextField } from "@mui/material";
import { Form, Formik, useFormik } from "formik";
import BlockIcon from '@mui/icons-material/Block';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { config } from 'app/config/main';
import { ToastContainer, toast } from 'react-toastify';
import ListIcon from '@mui/icons-material/List';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { generatePassword } from 'app/utils/formatHelpers';
import JumboAppTextField from '@jumbo/components/JumboAppTextField/JumboAppTextField';

const UpdateUser = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const token = useSelector(state => state.auth?.accessToken);
    const [roleData, setRoleData] = useState([])
    const [password, setPassword] = useState('');
    const id = window.location.pathname.split('/')[4]
    const [data, setData] = useState();
    const [role, setRole] = useState()

    const loadData = async () => {
        try {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            await fetch(`${config.apiUrl}/users/view/${id}`, options)
                .then(res => res.json())
                .then(res => {
                    console.log(res)
                    setData(res.data[0])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const getRoleData = async () => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        await fetch(`${config.apiUrl}/role/list`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setRoleData(res.data)
            })
    }

    useEffect(() => {
        loadData()
        getRoleData()
    }, [])

    const handleSubmit = async (item) => {
        try {
            const bodyData = {
                name: item.name,
                email: item.email,
                mobileno: item.mobileno,
                password: password,
                role_id: role,
                id: data.id
            }
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(bodyData)
            }
            await fetch(`${config.apiUrl}/users/update`, options)
                .then(res => res.json())
                .then(res => {
                    console.log(res)
                    if (res.status === true) {
                        toast.success('User Updated Successfully')
                        setTimeout(() => { navigate('/settings/manage-users') }, 1000)
                    } else {
                        toast.error(res.message)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleCanel = () => {
        navigate('/settings/manage-users')
    }

    const handlePrevious = () => {
        navigate('/settings/manage-users')
    }

    const handlePassword = () => {
        const genPassword = generatePassword();
        setPassword(genPassword);
    }

    const handleRole = (event) => {
        setRole(event.target.value)
    } 

    useEffect(() => {
        setRole(data?.roleId)
    }, [data])

    console.log(role)
    return (
        <Fragment>
        <Div className="d-flex items-center space-between mb-30">
        <Typography className="page_header" >{"Update User"}</Typography>
        <Button
        startIcon={ <ListIcon />}
          variant="outlined"
          className="link_btn"
          onClick={handlePrevious}
        >
         
          List
        </Button>
      </Div>
            <Div>
                <Card sx={{ width: 650 }}>
                    <CardContent>
                        <Formik
                            validateOnChange={true}
                            enableReinitialize={true}
                            initialValues={{
                                name: data?.name,
                                email: data?.email,
                                mobileno: data?.mobileno,
                                password: password,
                                role_id: data?.roleId
                            }}
                            onSubmit={(formData, { setSubmitting }) => {
                                setSubmitting(true);
                                handleSubmit(formData);
                                setSubmitting(false);
                            }}
                        >
                            {
                                ({ isSubmitting, values, handleChange }) => (
                                    <Form>
                                        <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                                            
                                            <FormControl sx={{ m: 1, width: 500 }} variant="outlined">
                                            <Typography className="from_label">Enter Name</Typography>
                                                <JumboAppTextField
                                                    fullWidth={true}
                                                    type={'text'}
                                                    required={true}
                                                    
                                                    name='name'
                                                    onChange={handleChange}
                                                    value={values?.name}
                                                    defaultValue={'Name'}
                                                />
                                            </FormControl>
                                        </Div>
                                        <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                                            <FormControl sx={{ m: 1, width: 500 }}>
                                            <Typography className="from_label">Select Role</Typography>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                   
                                                    name='roleId'
                                                    value={role}
                                                    required={true}
                                                    onChange={(event) => handleRole(event)}
                                                >
                                                    {
                                                        roleData?.length > 0 && roleData?.map((item) => {
                                                            return (
                                                                <MenuItem value={item?.id}>{item?.name}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Div>
                                        <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                                            <FormControl sx={{ m: 1, width: 500 }} variant="outlined">
                                            <Typography className="from_label">Enter Mobile No</Typography>
                                                <JumboAppTextField
                                                    fullWidth={true}
                                                    type={'text'}
                                                    required={true}
                                                   
                                                    name='mobileno'
                                                    onChange={handleChange}
                                                    value={values?.mobileno}
                                                    defaultValue={'Mobile No'}
                                                />
                                            </FormControl>
                                        </Div>
                                        <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                                            <FormControl sx={{ m: 1, width: 500 }} variant="outlined">
                                            <Typography className="from_label">Enter Email Id</Typography>
                                                <JumboAppTextField
                                                    fullWidth={true}
                                                    type={'text'}
                                                    required={true}
                                                    
                                                    name='email'
                                                    onChange={handleChange}
                                                    value={values?.email}
                                                    defaultValue={'Email'}
                                                />
                                            </FormControl>
                                        </Div>
                                        <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                                            <FormControl sx={{ m: 1, width: 500 }}>
                                            <Typography className="from_label">Enter Password</Typography>
                                                <JumboAppTextField
                                                    fullWidth={true}
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={password}
                                                    name='password'
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowPassword((show) => !show)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                        )
                                                    }}
                                                    label="Password"
                                                />
                                            </FormControl>
                                        </Div>
                                        <Div className=' mb-20' sx={{ display: 'flex' }}>
                                            <p className='note'>Note:</p> <span>If you leave password field as empty, Your existing password will be set automatically.</span>
                                        </Div>
                                        <Div>
                                            <Button variant="outlined"  className="generate_pass"  onClick={handlePassword}>Generate Passowrd</Button>
                                        </Div>
                                        <Div sx={{ display: 'flex', marginTop: '1.5rem', marginLeft: 'auto', justifyContent: 'right' }}>
                                            <Button  className="activity_cancel" variant="outlined" endIcon={<BlockIcon />}  onClick={handleCanel}>Cancel</Button>
                                            <Button  endIcon={<FiArrowRight />}  className="activity_submit" type='submit' >Submit</Button>
                                        </Div>
                                    </Form>
                                )
                            }
                        </Formik>
                    </CardContent>
                </Card>
            </Div>
        </Fragment>
    )
}

export default UpdateUser;