import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, TextField, Typography, Link } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { useFormik } from "formik";
import Div from "@jumbo/shared/Div";
import CREDITS from "../../../../assets/nsdl_logo.png";
import { AdminSVg, AppSvgLogon } from "app/assets/svg/Svg";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const AdminForgetPassword = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Form submitted:", values);
      navigate("/reset-password", { replace: true });
    },
  });

  return (
    <div>
      <div className="session_page ">
        <Grid className="h-full session_grid" container>
          <Grid
            className="session_image_content"
            item
            lg={7}
            style={{ alignSelf: "center" }}
          >
            <div className="session_content">
              <div className="session_img">
                <AdminSVg />
              </div>
            </div>
          </Grid>
          <Grid item lg={5} style={{ alignSelf: "center" }}>
            <div className="session_card flex_container_col justify-between">
              <div className="session_wrapper mb-10">
                <div className="session_form">
                  <div className="session_title mt-20 text-center">
                    <AppSvgLogon />
                  </div>
                  <div className="admin_form" >
                    <form
                      onSubmit={formik.handleSubmit}
                      noValidate
                      autoComplete="off"
                    >
                      <div className="text-center">
                        <h2 className="admin_greetings">Forgot Password? </h2>
                        <Typography textAlign={"center"}>
                          No worries, we’ll send you reset instructions.
                        </Typography>
                      </div>
                      <div >
                        <Div sx={{ mt: 5 }}>
                          <Typography className="login_label">
                            Email Address <span className="required">*</span>
                          </Typography>
                          <TextField
                            name="email"
                            type="email"
                            fullWidth
                            placeholder="Enter Email Address"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                            variant="outlined"
                          />
                        </Div>
                        <LoadingButton
                          className="login_btn"
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3 }}
                          //onClick={handleClick}
                        >
                          Reset Password
                        </LoadingButton>
                        <Typography textAlign={"center"}>
                          <Link
                            className="back_to_login"
                            underline="none"
                            href="/admin"
                          >
                            {" "}
                            Back to Login{" "}
                          </Link>
                        </Typography>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="credits_logo mb-30  text-center">
                <p>Powered by</p>
                <img width={98} height={40} src={CREDITS} alt="credits" />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AdminForgetPassword;
