import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import SkeletonTableLoader from "../../component/skeleton_loader/skeletonTableLoader";
import NoRecordsFound from "../../component/noRecordsFound";
import Search from "../../component/search";
import { TbRotate } from "react-icons/tb";
import Verify from "../Verify/Verify";

const BankAcVerfiyList = () => {
  const TableData = [
    {
      validat: "22/02/2024",
      validtime: "12:40 PM",
      verifiid: "BV000001",
      accono: "180145108007",
      ifsc: "CBR00087",
      name: "Vignesh",
      bank: "Vignesh",
      status: 1,
    },

    {
      validat: "22/02/2024",
      validtime: "12:40 PM",
      verifiid: "BV000001",
      accono: "180145108007",
      ifsc: "CBR00087",
      name: "Vijay",
      bank: "Vijay",
      status: 0,
    },
    {
      validat: "22/02/2024",
      validtime: "12:40 PM",
      verifiid: "BV000001",
      accono: "180145108007",
      ifsc: "CBR00087",
      name: "Vignesh",
      bank: "Vignesh",
      status: 1,
    },
  ];
  const [mobileOpen, setMobileOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(TableData);
  const [loading, setLoading] = useState(false);
  const terms = ["validat", "verifiid", "accono", "ifsc", "name", "bank"];

  const handleSearch = (filteredData) => {
    setFilteredData(filteredData);
  };
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  function handleDrawerClose() {
    setMobileOpen(false);
  }

  return (
    <div>
      <Card className="responsivecard">
        <CardContent>
          <Grid className="common_header grid current_user" container>
            <Grid lg={7} className="custom_grid">
              <Grid item>
                <div className="d-flex items-center">
                  <h2>Bank Accounts (12)</h2>
                </div>
              </Grid>
            </Grid>
            <Grid lg={5} className="custom_grid">
              <Grid container spacing={1}>
                <Grid item lg={7} md={6}>
                  <Search
                    data={TableData}
                    onSearch={handleSearch}
                    terms={terms}
                  />
                </Grid>
                <Grid item lg={5} md={6}>
                  <Button
                    className="table_primary w-100"
                    variant="outlined"
                    onClick={handleDrawerToggle}
                  >
                    Verify Bank Account
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Div>
            <Table className="common_table" id="tablePopover">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel>Verified At</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Verification Id</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    Bank A/C No
                  </TableCell>
                  <TableCell>
                    IFSC
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Name Provided</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Name at Bank</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <SkeletonTableLoader tablecell="7" row="8" />
                ) : filteredData.length == 0 ? (
                  <NoRecordsFound tablecell="7" />
                ) : (
                  filteredData.map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          {item?.validat} {item?.validtime}{" "}
                        </TableCell>
                        <TableCell>{item?.verifiid}</TableCell>
                        <TableCell>{item?.accono}</TableCell>
                        <TableCell>{item?.ifsc}</TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.bank}</TableCell>
                        <TableCell>
                          {item?.status == 1 ? (
                            <span className="admin_active_status">
                              <span className="active_dot"></span> Valid
                            </span>
                          ) : (
                            <>
                              <span className="admin_inactive_status">
                                <span className="inactive_dot"></span> Invalid
                              </span>
                              <Tooltip className="retry_button" title="Retry">
                                <IconButton>
                                  <TbRotate />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={15}
                  rowsPerPage={5}
                  page={1}
                />
              </TableFooter>
            </Table>
          </Div>
        </CardContent>
      </Card>
  <Verify
        handleDrawerClose={handleDrawerClose}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
    </div>
  );
};

export default BankAcVerfiyList;
