import { FiArrowRight } from "react-icons/fi";
import React, { Fragment, useState } from "react";
import Div from "@jumbo/shared/Div";
import { CardContent, Card, Typography, Button } from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import BlockIcon from "@mui/icons-material/Block";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { config } from "app/config/main";
import { ToastContainer, toast } from "react-toastify";
import ListIcon from "@mui/icons-material/List";

const validationSchema = yup.object({
  roleName: yup.string("Enter Role").required("Role is required"),
});

const CreateRole = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth?.accessToken);
  const handleSubmit = async (data) => {
    console.log(data);
    try {
      const bodyData = {
        name: data.roleName,
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      };
      await fetch(`${config.apiUrl}/role/create`, options)
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res.status === true) {
            toast.success("Role Created Successfully");
          } else {
            toast.error(res.message[0]);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCanel = () => {
    navigate("/settings/manage-roles");
  };

  const handlePrevious = () => {
    navigate("/settings/manage-roles");
  };
  return (
    <Fragment>
      <Div className="d-flex items-center space-between mb-30">
        <Typography className="page_header">{"Create User"}</Typography>
        <Button
          startIcon={<ListIcon />}
          variant="outlined"
          className="link_btn"
          onClick={handlePrevious}
        >
          List
        </Button>
      </Div>

      <Div>
        <Card sx={{ width: 650 }}>
          <CardContent>
            <Formik
              validateOnChange={true}
              initialValues={{
                roleName: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                handleSubmit(data);
                setSubmitting(false);
                resetForm({
                  data: {
                    roleName: "",
                  },
                });
              }}
            >
              <Form>
                <Div sx={{ mb: 3, mt: 1, width: 450 }}>
                  <Typography className="from_label">
                    ENTER ROLE NAME
                  </Typography>
                  <JumboTextField fullWidth name="roleName" />
                </Div>
                <Div
                  sx={{
                    display: "flex",
                    marginTop: "1.5rem",
                    marginLeft: "auto",
                    justifyContent: "right",
                  }}
                >
                  <Button
                    endIcon={<BlockIcon />}
                    variant="outlined"
                    className="activity_cancel"
                    onClick={handleCanel}
                  >
                    Cancel
                  </Button>

                  <Button
                   endIcon={<FiArrowRight />}
                   type="submit"
                   className="activity_submit"
                   
                  >
                    Submit
                    
                  </Button>
                </Div>
              </Form>
            </Formik>
          </CardContent>
        </Card>
      </Div>
    </Fragment>
  );
};

export default CreateRole;
