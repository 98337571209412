import React, { useState ,  useContext } from 'react';
import {useNavigate} from "react-router-dom";
import {IconButton, InputAdornment, OutlinedInput, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from 'yup';
import {useFormik} from "formik";
import { AuthenticationContext } from 'app/utils/constants/AuthContext';
import Div from "@jumbo/shared/Div";
import PasswordTooltip from '@jumbo/components/PasswordInfoTooltip/PasswordTooltip';
import { AppSvgLogon, LOGINBGSVG1, LoginBGSVG } from 'app/assets/svg/Svg';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CREDITS from '../../../assets/nsdl_logo.png';

const validationSchema = yup.object({
    pass: yup
    .string('Enter your password')
    .required('Password is required'),
    cpass: yup
    .string('Enter your confirm password')
    .required('Confirm password is required'),
});

const SetNewPassword = () => {
    const navigate = useNavigate();
    const {  login} = useContext(AuthenticationContext)
    const [showPassword, setShowPassword] = useState(false);


    const { 
        handleChange,
        handleSubmit,
        touched,
        errors,} = useFormik({
        validateOnChange: true,
       initialValues: {
        pass: '',
        cpass: '',
       },
         validationSchema: validationSchema,
         onSubmit: (values) => {
        console.log('Form submitted:', values); 
           navigate('/current-user/success-message' ,{replace:true})   
         },
       });

    return (
        <Div className='current_user_session '>
   <div className='h-100'>
      <div className='app_logo_svg '>
         <AppSvgLogon  />
      </div>
      <div className='current_user_session_card'>
         <form className='h-100'  onSubmit={handleSubmit}  noValidate autoComplete="off">
            <div className="current_session_form justify-between h-100">
               <div className='w-100 '>
                  <Typography  sx={{textAlign:"center",mt:1}} className='login_greeting'>Set New Password</Typography>
                  <Div sx={{ mt: 4 }}>
                  <Typography className="login_label d-flex items-center g-10">
                     <span>Password <span className="required">*</span></span> 
                     <PasswordTooltip />
                  </Typography>
                  <OutlinedInput
                  className="password_input"
                  name="pass"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  onChange={handleChange}
                  error={touched.pass && Boolean(errors.pass)}
                  helperText={touched.pass && errors.pass}
                  variant="outlined"
                  placeholder="Enter Password"
                  endAdornment={
                  <InputAdornment position="end">
                     <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                        setShowPassword((show) => !show)
                        }
                        edge="end"
                        >
                        {showPassword ? (
                        <VisibilityOff />
                        ) : (
                        <Visibility />
                        )}
                     </IconButton>
                  </InputAdornment>
                  }
                  />
                  {touched.pass ? ( <small className="error_text">{touched.pass}</small>) : ( ""  )}
               </Div>
               <Div sx={{ mt: 4 }}>
               <Typography className="login_label d-flex items-center g-10">
                  <span>Confirm Password <span className="required">*</span></span> 
                  <PasswordTooltip />
               </Typography>
               <OutlinedInput
               className="password_input"
               name="cpass"
               type={showPassword ? "text" : "password"}
               placeholder="Enter Confirm Password"
               fullWidth
               onChange={handleChange}
               error={touched.cpass && Boolean(errors.cpass)}
               helperText={touched.cpass && errors.cpass}
               variant="outlined"
               endAdornment={
               <InputAdornment position="end">
                  <IconButton
                     aria-label="toggle password visibility"
                     onClick={() =>
                     setShowPassword((show) => !show)
                     }
                     edge="end"
                     >
                     {showPassword ? (
                     <VisibilityOff />
                     ) : (
                     <Visibility />
                     )}
                  </IconButton>
               </InputAdornment>
               }
               />
               {touched.cpass == true ? ( <small className="error_text">{touched.cpass}</small>) : ( ""  )}
            </Div>
      </div>
      <Div  className='w-100'>
      <LoadingButton  className="login_btn"  fullWidth  type="submit"  variant="contained"  size="large" sx={{ mb: 3 }}>
      Set New Password
      </LoadingButton>
      <Typography textAlign={"center"}>
      <Link className="back_to_login"  underline="none" href="/current-user/login" > Back to Login</Link>
      </Typography>
      </Div>
   </div>
   </form>
</div>
<div className="credits_logo mt-25" >
   <p>Powered by</p>
   <img width={98} height={40} src={CREDITS} alt="credits" />
</div>
</div>
<div className='charcter_svg'>
   <LOGINBGSVG1 />
</div>
<div className='mobile_svg'>
   <LoginBGSVG />
</div>
</Div>
        
  
       
    );
};

export default SetNewPassword;
