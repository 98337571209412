import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Typography,
  Link,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { useFormik } from "formik";
import Div from "@jumbo/shared/Div";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CREDITS from "../../../../assets/nsdl_logo.png";
import { AdminSVg, AppSvgLogon } from "app/assets/svg/Svg";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const AdminLogin = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
  } = useFormik({
    validateOnChange: true,
    initialValues: {
      email: "admin@technogenesis.in",
      password: "12345678",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Form submitted:", values);
      handleClick();

      navigate("/admin-dashboard", { replace: true });
    },
  });

  function handleClick() {
    sessionStorage.setItem("portal", "admin");
    sessionStorage.setItem("token", "1111");
    sessionStorage.setItem("refershtoken", "1111");
    sessionStorage.setItem("auth", true);
  }
  return (
    <div>
      <div className="session_page ">
        <Grid className="h-full session_grid" container>
          <Grid
            className="session_image_content"
            item
            lg={7}
            style={{ alignSelf: "center" }}
          >
            <div className="session_content">
              <div className="session_img">
                <AdminSVg />
              </div>
            </div>
          </Grid>
          <Grid item lg={5} style={{ alignSelf: "center" }}>
            <div className="session_card flex_container_col">
              <div className="session_wrapper mb-10">
                <div className="session_form">
                  <div className="session_title text-center">
                    <AppSvgLogon />
                  </div>
                  <div className="admin_form" >
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                      <div className="text-center">
                        <h2 className="admin_greetings">Welcome Back</h2>
                      </div>
                      <div className="">
                        <Div sx={{ mt: 3 }}>
                          <Typography className="login_label">
                            Email Address <span className="required">*</span>
                          </Typography>
                          <TextField
                            fullWidth
                            name="email"
                            placeholder="Enter Email Address"
                            type="email"
                            variant="outlined"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && Boolean(errors.email)}
                            
                          />
                          {touched.email ? (
                            <small className="error_text">{errors.email}</small>
                          ) : (
                            ""
                          )}
                        </Div>
                        <Div sx={{ mt: 3 }}>
                          <Typography className="login_label">
                            Password <span className="required">*</span>
                          </Typography>
                          <OutlinedInput
                            className="password_input"
                            name="password"
                            placeholder="Enter Password"
                            fullWidth={true}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            required={true}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.password && Boolean(errors.password)}
                            helperText={touched.password ? errors.password : ""}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowPassword((show) => !show)
                                  }
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {touched.password ? (
                            <small className="error_text">
                              {errors.password}
                            </small>
                          ) : (
                            ""
                          )}
                          <Div sx={{ padding: 1 }}></Div>
                          <Typography textAlign={"right"}>
                            {" "}
                            <Link
                              className="forget_link"
                              underline="none"
                              href="/forget-password"
                            >
                              {" "}
                              Forgot Password ?
                            </Link>
                          </Typography>
                          <LoadingButton
                            className="login_btn"
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                          >
                            Login Securely
                          </LoadingButton>
                        </Div>
                      </div>
                    </form>
                    <div className="credits_logo  text-center">
                      <p>Powered by</p>
                      <img width={98} height={40} src={CREDITS} alt="credits" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default AdminLogin;
