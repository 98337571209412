import Div from "@jumbo/shared/Div";
import { InputAdornment,  OutlinedInput } from "@mui/material";
import React, { useState , useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import { CiSearch } from "react-icons/ci";
import debounce from 'lodash/debounce';


const Search = ({ data, onSearch, terms }) => {
 
    const [filteredData, setFilteredData] = useState(data);
    const [searchInput, setSearchInput] = useState('');
    const [loading, setLoading] = useState(false);
    
   
   
  
     // Debounce the filter function
     const debouncedFilter = debounce((input) => {
      setLoading(true)
      const lowerCaseInput = input.toLowerCase();
      const filtered = data.filter((item) => {
        // Check if the input matches any of the specified terms
        return terms.some((term) => {
          return item[term] && item[term].toLowerCase().includes(lowerCaseInput);
        });

         // (item) =>
         // item.name.toLowerCase().includes(lowerCaseInput) 
         // Add more conditions as needed for other fields
      });
      
     
      
      setFilteredData(filtered);
      setLoading(false);
      onSearch(filtered, loading);
    }, 3000); 
  
   
      const handleSearch = (event) => {
       //console.log(searchInput) 
        setLoading(true)
        debouncedFilter(searchInput);
       // setSearchInput(event.target.value);
      };
    
      const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
       // console.log(event.target.value)
        setLoading(true)
        debouncedFilter(event.target.value);
      };
      // Update filtered data when searchInput changes
      useEffect(() => {
        debouncedFilter(searchInput);
        return () => {
          // Cleanup the debounce function on component unmount
          debouncedFilter.cancel();
        };
      }, [searchInput, debouncedFilter]);
  
  

    return(


        <Div>
        <OutlinedInput
          className="form_new_style w-100"
          id="outlined-adornment-password"
          placeholder="Search"
           onChange={handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <IconButton edge="start" onClick={handleSearch} >
                <CiSearch/>
              </IconButton>
            </InputAdornment>
          }
        />
      </Div>

    )
}
export default Search;