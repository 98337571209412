import React, { useState } from "react";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Grid,
  Popover,
  Select,
  TableSortLabel,

} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CreateCurrentUser from "./Create-current-user/CreateCurrentUser";
import Search from "app/pages/app-pages/component/search";
import SkeletonTableLoader from "app/pages/app-pages/component/skeleton_loader/skeletonTableLoader";
import NoRecordsFound from "app/pages/app-pages/component/noRecordsFound";
import {
  ExpoIcon,
  FileIcon,
  FilterIcon,
  RefreshIcon,
  TrashIcon,
  UpdateIcon,
} from "app/assets/svg/Svg";
import DyanamicDatePicker from "app/components/DatePicker/DyanamicDatePicker";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
const CurrentUserList = () => {

  const List = [
    {
      userid: "CUS001", 
      company_name: "Alpha - Alpha Enterprise",
      name: "Siva Ramar",
      account_number: "987654321098765",
      mobile_no: "85555 26464",
      place: "Madurai",
      mab: "25,000.00",
      create_on: "10/06/2023",
      time: "08:30 AM",
      entity: 1,
      status: 1,
    },
    {
      userid: "CUS002", 
      company_name: "Beta - Beta Corporation",
      name: "Prakash Raj",
      account_number: "876543210987654",
      mobile_no: "95555 37474",
      place: "Chennai",
      mab: "30,000.00",
      create_on: "12/06/2023",
      time: "08:30 AM",
      entity: 0,
      status: 0,
    },
    {
      userid: "CUS003", 
      company_name: "Gamma - Gamma Industries",
      name: "Mohinder Amarnath",
      account_number: "765432109876543",
      mobile_no: "75555 48484",
      place: "Bangalore",
      mab: "20,000.00",
      create_on: "15/06/2023",
      time: "08:30 AM",
      entity: 1,
      status: 1,
    },
    {
      userid: "CUS004", 
      company_name: "Delta - Delta Technologies",
      name: "Zahir Khan",
      account_number: "654321098765432",
      mobile_no: "65555 59494",
      place: "Hyderabad",
      mab: "28,000.00",
      create_on: "18/06/2023",
      time: "08:30 AM",
      entity: 0,
      status: 0,
    },
    {
      userid: "CUS005", 
      company_name: "Epsilon - Epsilon Innovations",
      name: "Venkatesh Prasad",
      account_number: "543210987654321",
      mobile_no: "85555 70404",
      place: "Mumbai",
      mab: "22,000.00",
      create_on: "20/06/2023",
      time: "08:30 AM",
      entity: 1,
      status: 1,
    },
    {
      userid: "CUS006", 
      company_name: "Zeta - Zeta Solutions",
      name: "Ajay Jadeja",
      account_number: "432109876543210",
      mobile_no: "95555 81414",
      place: "Delhi",
      mab: "35,000.00",
      create_on: "22/06/2023",
      time: "08:30 AM",
      entity: 0,
      status: 1,
    },
    {
      userid: "CUS007", 
      company_name: "Eta - Eta Enterprises",
      name: "Roger Binny",
      account_number: "321098765432109",
      mobile_no: "75555 92424",
      place: "Kolkata",
      mab: "18,000.00",
      create_on: "25/06/2023",
      time: "08:30 AM",
      entity: 1,
      status: 0,
    },
    {
      userid: "CUS008", 
      company_name: "Theta - Theta Group",
      name: "javagal srinath",
      account_number: "210987654321098",
      mobile_no: "65555 03434",
      place: "Pune",
      mab: "26,000.00",
      create_on: "28/06/2023",
      time: "08:30 AM",
      entity: 0,
      status: 1,
    },
    {
      userid: "CUS009", 
      company_name: "Iota - Iota Technologies",
      name: "Yashpal sharma",
      account_number: "109876543210987",
      mobile_no: "85555 14444",
      place: "Jaipur",
      mab: "24,000.00",
      create_on: "30/06/2023",
      time: "08:30 AM",
      entity: 1,
      status: 0,
    },
  ];

  const terms = ["company_name", "account_number", "mobile_no", "place"];
  const navigate = useNavigate();


  // usestate
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [createdraw, setCreatedraw] = useState(false);
  const [updatedraw, setUpdatedraw] = useState(false);
  const [filteredData, setFilteredData] = useState(List);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Status");
  const [method, setMethod] = useState("Method");
  const [fillter, setFillter] = useState(null);
  const openFilter = Boolean(fillter);
  const id = open ? "simple-popover" : undefined;

  // fuctions 
  const handleDrawerToggle = () => {
    navigate('/create-current-user')
  };
  const handleDrawerClose = () => {
    setCreatedraw(false);
  };

  const handleUpdateDrawerToggle = () => {
    setUpdatedraw(false);
    setAnchorEl(null);
    navigate('/current-user/update')
  };
  const handleUpdateDrawerClose = () => {
    setUpdatedraw(false);
    setAnchorEl(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearch = (filteredData, loading) => {
    // Do something with the filtered data
    setLoading(loading);
    setFilteredData(filteredData);
  };
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleClick = (event, i) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(i);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };
 const handleFilter = (event) => {
    setFillter(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFillter(null);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const handleChangeMethod = (event) => {
    setMethod(event.target.value);
  };
const handleView = (entity) => {
  const path = entity==1? '/current-user/view/1' : '/current-user/view/2';
  navigate(path);
}
  

 

  return (
    <section>
      <Card className="responsivecard">
        <CardContent>
          <Grid className="common_header grid" container>
            <Grid lg={4} className="custom_grid">
              <Grid item>
                <div className="d-flex items-center">
                  <h2>Current Users (9)</h2>
                  <Typography
                    className="create_btn"
                    variant={"h2"}
                    mb={5}
                    onClick = {()=>{navigate('/create-current-user')}}
                  >
                    + Add new
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Grid lg={8} className="custom_grid">
              <Grid container spacing={1}>
                <Grid item lg={4} md={6}>
                  <Search data={List} onSearch={handleSearch} terms={terms} />
                </Grid>
                <Grid item lg={4} md={6}>
                  <DyanamicDatePicker />
                </Grid>
                <Grid item lg={0.7}>
                  <Tooltip title="Filter">
                    <IconButton
                      onClick={handleFilter}
                      className="icon_btn "
                      variant="outlined"
                    >
                      <FilterIcon />
                    </IconButton>
                  </Tooltip>
                  {/* filter popover  */}
                  <Popover
                    className="date_popover"
                    id={id}
                    open={openFilter}
                    anchorEl={fillter}
                    onClose={handleFilterClose}
                  >
                    <div className="date_popover_inner">
                      <Select
                        className={status == "Status" ? "select-box mb-20" : 'select-box-select mb-20'}
                        value={status}
                        onChange={handleChange}
                        fullWidth
                      >
                        <MenuItem selected value={"Status"}>
                          Filter by Status
                        </MenuItem>
                        <MenuItem value={0}>Success</MenuItem>
                        <MenuItem value={1}>Pending</MenuItem>
                        <MenuItem value={2}>Failed</MenuItem>
                      </Select>
                      <Select
                        className={method == "Method" ? "select-box mb-20" : 'select-box-select mb-20'}
                        value={method}
                        onChange={handleChangeMethod}
                        fullWidth
                      >
                        <MenuItem selected value={"Method"}>
                          Filter by Type
                        </MenuItem>
                        <MenuItem value={0}>Proprietorship </MenuItem>
                        <MenuItem value={1}>Partnership </MenuItem>
                        
                      </Select>
                      <div>
                        <Button
                          className="datefilter_btn"
                          variant="outlined"
                          onClick={handleFilterClose}
                        >Submit
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </Grid>
                <Grid item lg={0.7}>
                  <Tooltip title="Refresh">
                    <IconButton className="icon_btn" variant="outlined">
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item lg={2.6} md={3.9}>
                  <Button
                    startIcon={<FileIcon />}
                    className="export_btn w-100"
                    variant="outlined"
                  >
                  Export as Excel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        <div>
            <Table className="common_table" id="tablePopover">
              <TableHead>
                <TableRow>
                <TableCell><TableSortLabel>Created On </TableSortLabel></TableCell>
                <TableCell><TableSortLabel>Customer Id </TableSortLabel></TableCell>
                  <TableCell><TableSortLabel>Company Name </TableSortLabel></TableCell>
                  <TableCell><TableSortLabel>Cnt.Person Name </TableSortLabel></TableCell>
                  <TableCell><TableSortLabel>Mobile No</TableSortLabel></TableCell>
                  <TableCell><TableSortLabel>Entity Type</TableSortLabel></TableCell>
                  {/* <TableCell>Account Number</TableCell> */}
                  <TableCell><TableSortLabel>City</TableSortLabel></TableCell>
                 {/**  <TableCell className="amt_head">MAB (₹)</TableCell> */}
                  
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  //Display the skeleton loader while data is loading
                  <SkeletonTableLoader tablecell="9" row="8" />
                ) : // Display the actual table content when data is loaded
                filteredData.length == 0 ? (
                  <NoRecordsFound tablecell="9" />
                ) : (
                  filteredData.map((item, i) => {
                    return (
                      <TableRow key={i}>
                         <TableCell>  
                          {item?.create_on} {item?.time} 
                         </TableCell>
                        <TableCell className="Link" onClick={()=>{handleView(item.entity)}}>{item?.userid}</TableCell>
                        <TableCell className="Link" onClick={()=>{handleView(item.entity)}}>
                        
      {item?.company_name.length > 25 ? (
        <Tooltip title={item?.company_name}>
          <span>{`${item?.company_name.slice(0, 23)}...`}</span>
        </Tooltip>
      ) : (
        <span>{item?.company_name}</span>
      )}  
       

                        </TableCell>
                        <TableCell>
                        {item?.name.length > 15 ? (
        <Tooltip title={item?.name}>
          <span className="cursor">{`${item?.name.slice(0, 13)}...`}</span>
        </Tooltip>
      ) : (
        <span>{item?.name}</span>
      )}  
       

                        </TableCell>
                        <TableCell>{item?.mobile_no}</TableCell>
                        <TableCell>{item?.entity==1?'Proprietorship':'Partnership'}</TableCell>
                        {/* <TableCell>{item?.account_number}</TableCell> */}
                        
                        <TableCell>{item?.place}</TableCell>
                    {/**   <TableCell className="amt_cell">{item?.mab}</TableCell> */} 
                        
                        <TableCell>
                          {item?.status == 1 ? (
                            <span className="admin_active_status">
                              
                              <span className="active_dot"></span> Active
                            </span>
                          ) : (
                            <span className="admin_inactive_status">
                              <span className="inactive_dot"></span> Inactive
                            </span>
                          )}
                        </TableCell>
                        <TableCell >
                          <IconButton
                            className="table_icon_btn"
                            onClick={(event) => handleClick(event, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                        <Menu
                          className="custom_popover"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={selectedIndex === i}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem className="menuItem" onClick={()=>{handleView(item.entity)}}>
                          <RemoveRedEyeOutlinedIcon />
                            <span className="ml-10"> View </span>
                          </MenuItem>
                          <MenuItem
                            className="menuItem"
                            onClick={handleUpdateDrawerToggle}
                          >
                            <UpdateIcon />
                            <span className="ml-10"> Update </span>
                          </MenuItem>
                          <MenuItem className="menuItem" onClick={handleClose}>
                            <ExpoIcon />
                             <span className="ml-10">
                              
                              {item?.status === 1
                                ? "Mark as Inactive"
                                : "Mark as Active"}
                            </span>
                          </MenuItem>
                          <MenuItem className="menuItem" onClick={handleClose}>
                            <TrashIcon />
                            <span className="ml-10"> Delete </span>
                          </MenuItem>
                          
                        </Menu>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
              <TableFooter>
                <TablePagination
                  count={count}
                  page={page}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableFooter>
            </Table>
          </div>
        </CardContent>
      </Card>
      <CreateCurrentUser
        handleDrawerClose={handleDrawerClose}
        createdraw={createdraw}
        handleDrawerToggle={handleDrawerToggle}
      />
     </section>
  );
};

export default CurrentUserList;
