import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Autocomplete,
  Paper,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Verified } from "app/assets/svg/Svg";
import VerifyOtp from "../VerifyOtp/VerifyOtp";
import { useNavigate } from "react-router-dom";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";


const theme = createTheme({
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#333",
          "&.Mui-checked": {
            color: "#000",
          },
        },
      },
    },
  },
});

const UpdateCurrentuserNew = () => {
  const states = [
    { label: "Tamil Nadu" },
    { label: "Kerala" },
    { label: "Assam" },
  ];
  const Cities = [
    { label: "Madurai" },
    { label: "Chennai" },
    { label: "Theni" },
  ];
  const [verify, setVerify] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [pan, setPan] = useState(false);
  const [gst, setGst] = useState(false);
  const [aadhaar, setAadhaar] = useState(false);
  const [selectedValue, setSelectedValue] = useState("partner");
  const navigate = useNavigate();
  const Verification = () => {
    setVerify(!verify);
    setModalOpen(true);
  };
  const PanVaerify = () => {
    setPan(!pan);
  };
  const GSTVaerify = () => {
    setGst(!gst);
  };
  const AadhaarVaerify = () => {
    setAadhaar(!aadhaar);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const goBack = () => {
    navigate("/current-user");
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  
  return (
    <section>
      <Card className="admin">
        <Grid container className="common_header grid "  >
          <Grid lg={12} container className="admin_head" justifyContent={"space-between"} >

          
           <Grid  item lg={6}>
           <h2 className="mt-10 ">Update Current User</h2>
           </Grid>
           <Grid item lg={6} container justifyContent={"flex-end"}>
              <Button
                  startIcon={<ListOutlinedIcon />}
                  className="export_btn width-settings"
                  variant="outlined"
                   onClick={goBack}
                >
                  Back to List
                </Button> 
                </Grid>
                </Grid>
            
        </Grid>
        <CardContent >
          <section className="forms_section">
            <form>
              <Grid container>
                <Grid lg={5} item className="custom_grid">
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography className="from_label">
                        Entity Type <span className="required">*</span>
                      </Typography>
                      <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        value={selectedValue}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="partner"
                          className="radio_form"
                          control={<Radio />}
                          label="Partnership"
                        />
                        <FormControlLabel
                          value="property"
                          className="radio_form ml-30"
                          control={<Radio />}
                          label="Proprietorship"
                        />
                      </RadioGroup>
                    </ThemeProvider>
                  </div>
                </Grid>
                <Grid lg={5} item className="custom_grid">
                 
                </Grid>
                <Grid lg={5} item className="custom_grid">
                  <div className="">
                    <Typography className="from_label">
                      Company Name <span className="required">*</span>
                    </Typography>
                    <TextField
                      placeholder="Enter Company Name"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
               

                {selectedValue === "property" && (
                  <>
                    <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <Typography className="from_label">
                          Contact Person Name{" "}
                          <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Contact Person Name"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                            Business Email Id{" "}
                            <span className="required">*</span>
                          </Typography>
                        </div>
                        <TextField
                          placeholder="Enter Business Email Id"
                          name=""
                          type="email"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                            Mobile No <span className="required">*</span>
                          </Typography>
                          <Typography
                            className="findifce"
                            onClick={Verification}
                          >
                            {verify === true ? (
                              <p className="verified d-flex items-center gap-5">
                                <Verified /> Verified
                              </p>
                            ) : (
                              "Verify"
                            )}
                          </Typography>
                        </div>

                        <TextField
                          placeholder="Enter Mobile No"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <Typography className="from_label">
                          Alterate Mobile No
                        </Typography>
                        <TextField
                          placeholder="Enter Alternate Mobile No"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                  </>
                )}
 {selectedValue === "property" && (
                <Grid lg={5} item className="custom_grid">
               
                    <div className="">
                      <Typography className="from_label text-normal">
                        Years of Gap <span className="required">*</span>
                      </Typography>
                      <TextField
                        placeholder="Enter Years of Gap "
                        name=""
                        type="number"
                        className="form_new_style"
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                </Grid>
                 )}
                <Grid lg={5} item className="custom_grid">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        PAN No <span className="required">*</span>
                      </Typography>
                      <Typography className="findifce" onClick={PanVaerify}>
                        {pan === true ? (
                          <p className="verified d-flex items-center gap-5">
                            <Verified /> Verified
                          </p>
                        ) : (
                          "Verify"
                        )}
                      </Typography>
                    </div>

                    <TextField
                      placeholder="Enter PAN No"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>

                {selectedValue === "partner" && (
                  <>
            <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <Typography className="from_label">
                          Mobile
                          <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Mobile No"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <Typography className="from_label">
                          Email Id
                          <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Email Id"
                          name=""
                          type="email"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                  </>
                  )}

                <Grid lg={5} item className="custom_grid">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        Aadhaar No <span className="required">*</span>
                      </Typography>
                      <Typography className="findifce" onClick={AadhaarVaerify}>
                        {aadhaar === true ? (
                          <p className="verified d-flex items-center gap-5">
                            <Verified /> Verified
                          </p>
                        ) : (
                          "Verify"
                        )}
                      </Typography>
                    </div>

                    <TextField
                      placeholder="Enter Aadhaar No"
                      name=""
                      type="number"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
                <Grid lg={5} item className="custom_grid">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        GST <span className="required">*</span>
                      </Typography>
                      <Typography className="findifce" onClick={GSTVaerify}>
                        {gst === true ? (
                          <p className="verified d-flex items-center gap-5">
                            <Verified /> Verified
                          </p>
                        ) : (
                          "Verify"
                        )}
                      </Typography>
                    </div>
                    <TextField
                      placeholder="Enter GST "
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
                <Grid lg={5} item className="custom_grid">
                  <div className="">
                    <Typography className="from_label">
                      Address <span className="required">*</span>
                    </Typography>
                    <TextField
                      placeholder="Enter Address"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
                <Grid lg={5} item className="custom_grid">
                  <Typography className="from_label">
                    State <span className="required">*</span>
                  </Typography>

                  <Autocomplete
                    disablePortal
                    name="bbank"
                    options={states}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select State"
                        className="form_new_style form_selct"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid lg={5} item className="custom_grid">
                  <Typography className="from_label">
                    City <span className="required">*</span>
                  </Typography>

                  <Autocomplete
                    disablePortal
                    name="bank"
                    options={Cities}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select City"
                        className="form_new_style form_selct"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={5}>
                  <div className="">
                    <Typography className="from_label">
                      Pin Code <span className="required">*</span>
                    </Typography>
                    <TextField
                      placeholder="Enter Pin Code"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
              {selectedValue === "partner" && (
              <section>
                <div className="mt-20">
                <h2 className="create_form_partner_details">Partner I Details</h2>
              <Grid container>
              <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <Typography className="from_label">
                          Contact Person Name{" "}
                          <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Contact Person Name"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                            Mobile No <span className="required">*</span>
                          </Typography>
                          <Typography
                            className="findifce"
                            onClick={Verification}
                          >
                            {verify === true ? (
                              <p className="verified d-flex items-center gap-5">
                                <Verified /> Verified
                              </p>
                            ) : (
                              "Verify"
                            )}
                          </Typography>
                        </div>

                        <TextField
                          placeholder="Enter Mobile No"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <Typography className="from_label">
                          Alterate Mobile No
                        </Typography>
                        <TextField
                          placeholder="Enter Alternate Mobile No"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                             Email Id
                            <span className="required">*</span>
                          </Typography>
                        </div>
                        <TextField
                          placeholder="Enter Email Id"
                          name=""
                          type="email"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5} item className="custom_grid">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        PAN No <span className="required">*</span>
                      </Typography>
                      <Typography className="findifce" onClick={PanVaerify}>
                        {pan === true ? (
                          <p className="verified d-flex items-center gap-5">
                            <Verified /> Verified
                          </p>
                        ) : (
                          "Verify"
                        )}
                      </Typography>
                    </div>

                    <TextField
                      placeholder="Enter PAN No"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
                <Grid lg={5} item className="custom_grid">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        Aadhaar No <span className="required">*</span>
                      </Typography>
                      <Typography className="findifce" onClick={AadhaarVaerify}>
                        {aadhaar === true ? (
                          <p className="verified d-flex items-center gap-5">
                            <Verified /> Verified
                          </p>
                        ) : (
                          "Verify"
                        )}
                      </Typography>
                    </div>

                    <TextField
                      placeholder="Enter Aadhaar No"
                      name=""
                      type="number"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
                   </Grid>
                   
              </div>
              <div className="mt-20">
                <h2 className="create_form_partner_details">Partner II Details</h2>
              <Grid container>
              <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <Typography className="from_label">
                          Contact Person Name{" "}
                          <span className="required">*</span>
                        </Typography>
                        <TextField
                          placeholder="Enter Contact Person Name"
                          name=""
                          type="text"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                            Mobile No <span className="required">*</span>
                          </Typography>
                          <Typography
                            className="findifce"
                            onClick={Verification}
                          >
                            {verify === true ? (
                              <p className="verified d-flex items-center gap-5">
                                <Verified /> Verified
                              </p>
                            ) : (
                              "Verify"
                            )}
                          </Typography>
                        </div>

                        <TextField
                          placeholder="Enter Mobile No"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <Typography className="from_label">
                          Alterate Mobile No
                        </Typography>
                        <TextField
                          placeholder="Enter Alternate Mobile No"
                          name=""
                          type="number"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5} item className="custom_grid">
                      <div className="">
                        <div className="dlfex-justify-between-align-center">
                          <Typography className="from_label">
                             Email Id
                            <span className="required">*</span>
                          </Typography>
                        </div>
                        <TextField
                          placeholder="Enter Email Id"
                          name=""
                          type="email"
                          className="form_new_style"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid lg={5} item className="custom_grid">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        PAN No <span className="required">*</span>
                      </Typography>
                      <Typography className="findifce" onClick={PanVaerify}>
                        {pan === true ? (
                          <p className="verified d-flex items-center gap-5">
                            <Verified /> Verified
                          </p>
                        ) : (
                          "Verify"
                        )}
                      </Typography>
                    </div>

                    <TextField
                      placeholder="Enter PAN No"
                      name=""
                      type="text"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
                <Grid lg={5} item className="custom_grid">
                  <div className="">
                    <div className="dlfex-justify-between-align-center">
                      <Typography className="from_label">
                        Aadhaar No <span className="required">*</span>
                      </Typography>
                      <Typography className="findifce" onClick={AadhaarVaerify}>
                        {aadhaar === true ? (
                          <p className="verified d-flex items-center gap-5">
                            <Verified /> Verified
                          </p>
                        ) : (
                          "Verify"
                        )}
                      </Typography>
                    </div>

                    <TextField
                      placeholder="Enter Aadhaar No"
                      name=""
                      type="number"
                      className="form_new_style"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
                   </Grid>
                   
              </div>
              </section>
              )}
              <Grid className="button_footer" container gap={"10px"}>
                <Grid lg={2.4} item className="custom_grid">
                  <Button
                    className="primary_btn admin"
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                  >
                    Update
                  </Button>
                </Grid>
                <Grid lg={2.4} item className="custom_grid">
                  <Button
                    className="outline_btn w-100 admin"
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    onClick={goBack}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            </form>
          </section>
        </CardContent>
      </Card>
      <VerifyOtp open={isModalOpen} handleClose={handleCloseModal} />
    </section>
  );
};

export default UpdateCurrentuserNew;
