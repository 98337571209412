import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { Card,IconButton,InputAdornment,MenuItem,OutlinedInput,Select,Tooltip, Typography,} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { CiSearch } from "react-icons/ci";
import { IoReceiptOutline } from "react-icons/io5";
import MiniStatement from "./MiniStatement/MiniStatement";
import { Currency, UserSwitch } from "../../../assets/svg/Svg";
import NO_RECORD_FOUND from "../../../assets/no_records_found.png"



const formatAmount = (value) => {
  if (value < 100000) {
    return (value / 1000).toFixed(0) + "k";
  } else if (value < 10000000) {
    return (value / 100000).toFixed(1) + "L";
  } else {
    return (value / 10000000).toFixed(1) + "cr";
  }
};
const Home = () => {
 const navigate = useNavigate()
  const Logo = `https://tse2.mm.bing.net/th?id=OIP.HLCFd2lEK2PDX2wc4BJpMAAAAA&pid=Api&P=0&h=180`;
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const activeColor = "#ffc275";
  const disabledColor = "#ffc275";
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('thisWeek');
  const [mobileOpens, setMobileOpens] = useState(false);

const currentDate1 = new Date();
const currentYear = currentDate1.getFullYear();
const currentMonth1 = currentDate1.getMonth();
const startDate = new Date(currentYear, currentMonth, 1);
const endDate = new Date(currentYear, currentMonth + 1, 0);

const labels = [];
const data = [];

const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 
  'July', 'August', 'September', 'October', 'November', 'December'
];
const currentMonthName = months[currentDate1.getMonth()];
for (let day = 1; day <= 30; day++) {
  const date = new Date(currentYear, currentMonth1, day);
  if (date >= startDate && date <= endDate) {
    labels.push(date.toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }));
    data.push(Math.floor(Math.random() * 100000) + 50000);
  }
}


const dataForOptions = {
  thisWeek: { title: 'Wee', labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'], data: [5000, 4000, 3000, 4000, 1000,2000,3000] },
  thisMonth: { title: 'Month',  labels: Array.from({ length: 30 }, (_, i) => ` ${i + 1}`),  data: Array.from({ length: 30 }, (_, i) => Math.floor(Math.random() * 100000) + 50000)  },
  thisYear: { title: 'Year', labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', "Aug", "Sep", "Oct", "Nov", "Dec"], data: [200000, 220000, 210000, 230000, 240000, 220000, 250000] }
};
 // thisMonth: { title: 'Month',  labels:labels,  data: data   },

  function getWeekLabels() {
    const today = new Date();
    const firstDay = new Date(today.setDate(today.getDate() - today.getDay())); 
    const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 6)); 
    const startDate = formatDate(firstDay);
    const endDate = formatDate(lastDay);
    return [`Days (${startDate} - ${endDate})`];
  }

 
  function formatDate(date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  const getXAxisTitle = (option) => {
    switch (option) {
      case 'thisWeek':
        return getWeekLabels();
      case 'thisMonth':
        return `Month( ${currentMonthName} ${currentYear} )`;;
      case 'thisYear':
        return `Year(${new Date().getFullYear().toString()})`; // Set x-axis title to current year when 'This Year' is selected
      default:
        return '';
    }
  };
  const getCategoryColor = (index) => {
    return index <= currentMonth ? activeColor : disabledColor;
  };
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getBorderRadius = (option) => {
    switch (option) {
      case 'thisWeek':
        return 20; 
      case 'thisMonth':
        return 5; 
      case 'thisYear':
        return 12; 
      default:
        return 20;
    }
  };

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: getBorderRadius(selectedOption),
        colors: {
          backgroundBarColors: ["#ffc275"],
          backgroundBarOpacity: 0.2,
        },
      },
      colors: ["#ffc275"],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: dataForOptions[selectedOption]?.labels || [],
      title: {
        text:  getXAxisTitle(selectedOption),
      },

      labels: {
        style: {
          fontSize: "14px",
          fontWeight: "medium",
          fontFamily: 'Inter, sans-serif'
        },
      },
    },
    yaxis: {
      title: {
        text: "Amount (₹)",
      },

     

      labels: {
        formatter: function (val) {
          return formatAmount(val);
        },
        style: {
          fontSize: "14px",
          fontWeight: "medium",
          fontFamily: 'Inter, sans-serif'
        },
      },
    },
    fill: {
      opacity: 1,

      colors: Array.from({ length: 12 }, (_, i) => getCategoryColor(i)),
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {

        if (selectedOption === 'thisMonth') {
          
         const label = w.config.xaxis.categories[dataPointIndex];
         const currentDate = new Date();
         currentDate.setDate(label);
         const formattedDate = formatDate(currentDate);
          series = series[seriesIndex][dataPointIndex];
          const roundedSeries = Number(series).toFixed(2);
          return `  <div class="custom-tooltip">
          <span class="labels">${formattedDate}</span>
          <div class="border-line">
            <div class="value"><span class="amount">₹ ${roundedSeries} </span></div>
          </div>
        </div>`
      }
      else {
        const label = w.config.xaxis.categories[dataPointIndex];
        series = series[seriesIndex][dataPointIndex];
       const roundedSeries = Number(series).toFixed(2);
       return `  <div class="custom-tooltip">
       <span class="labels">${label}</span>
       <div class="border-line">
         <div class="value"><span class="amount">₹ ${roundedSeries} </span></div>
       </div>
     </div>`
       
      }

      },
      x: {
        show: true,
        formatter: function (val) {
          
          return val;
        }
      },
      y: {
        formatter: function (val) {
          return val.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          });
        }
      },
      style: {
        borderRadius: getBorderRadius(selectedOption),
        backgroundColor: '#FFA500', // Change background color to orange
        color: '#000' // Change text color to black
      }
    },
    noData: {
      text: "No Data Found",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: -20,
      style: {
        color: "black",
        fontSize: '16px',
        fontFamily: 'Inter, sans-serif'
      }
    },
  };
  const series = [
    {
      name: "Amount",
      data: dataForOptions[selectedOption]?.data || []
    },
  ];

 
 
   function handleDrawerToggled() {
    setMobileOpens(!mobileOpens);
    setAnchorEl(null);
  }
  function handleDrawerClosed() {
    setMobileOpens(false);
  }

  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={7} xs={12} spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} spacing={2}>
                <Card sx={{ height: "182px" }}>
                  <div className="accont_info">
                    <p>Account Details</p>
                  </div>
                  <div className="statement_info"> 
                    <Grid container>
                      <Grid sm={6}>
                        <h3>A/C No</h3>
                        <p>18014510700027</p>
                      </Grid>
                      <Grid className="text-end" sm={6}>
                        <h6 className="admin_active_status m-0">
                          {" "}
                          <span className="active_dot"></span> Active
                        </h6>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid sm={6}>
                        <h3>IFSC</h3>
                        <p>PNB002321</p>
                      </Grid>
                      <Grid className="text-end" sm={6}>
                        <Tooltip title="Punjab National Bank">
                          <img
                            className="bank_logo"
                            src={Logo}
                            alt="bank_logo"
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6} spacing={2}>
                <Card className="qucik_access_card" sx={{ height: "182px" }}>
                  <div className="quicAccess_head" style={{padding:'10px'}}>
                    <h5>Quick Access</h5>
                  </div>

                  <div className="quick_acess">
                    <div className="text-center">
                      <div className="icon_bg" onClick={()=>navigate('/fund-transfer')}>
                        <Currency />
                      </div>
                      <p onClick={()=>navigate('/fund-transfer')}>
                        {" "}
                        Fund <br /> Transfer
                      </p>
                    </div>
                    <div className="text-center">
                      <div className="icon_bg" onClick={()=>navigate('/beneficiary')}>
                        <UserSwitch />
                      </div>
                      <p onClick={()=>navigate('/beneficiary')}>
                        Add <br /> Beneficiary
                      </p>
                    </div>
                    <div className="text-center">
                      <div className="icon_bg" onClick={handleDrawerToggled}>
                        <IoReceiptOutline style={{ fontSize: "26px" }} />
                      </div>
                      <p onClick={handleDrawerToggled}>
                        Mini <br /> Statement
                      </p>
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Card sx={{ height: "600px", mt: 2 }}>
                  <div className="chart_head">
                    <Grid container sx={{height:'50px'}}>
                      <Grid item sm={8} >
                        <p className="chart_header m-0">
                          Transaction Analytics
                        </p>
                      </Grid>
                      <Grid item sm={4}>
                        <Select
                          className="select-box-select mb-20"
                          
                          value={selectedOption} onChange={handleSelectChange}
                          fullWidth
                        >
                          <MenuItem selected value="thisWeek">
                            This Week
                          </MenuItem>
                          <MenuItem value="thisMonth">This Month</MenuItem>
                          <MenuItem value="thisYear">This Year</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="chart_sect" style={{height:'500px'}}>
                  {series[0].data.length > 0 ? ( 
                  <ReactApexChart options={options} series={series} type="bar"height={500}/>  ) : (
                    <div className="chart_section_nodata">
                      <img  src={NO_RECORD_FOUND} alt="your-image-alt-text" />
                      <Typography  variant={'h2'} className={'text-center no_records'} sx={{alignItems: 'center'}}> No Records Found </Typography> 
                    </div>
                    )}
                  
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={5} xs={12}>
            <Card className="" sx={{ height: "795px" }}>
              <div className=" p-22 ">
                <div className="recent_transaction_header">
                  <p>Recent Transactions</p>
                  <h6 onClick={()=>{navigate('/transactionlist')}}>See all</h6>
                </div>
                <div className="recent_transaction_header mt-10">
                  <OutlinedInput
                    className="form_new_style w-100"
                    id="outlined-adornment-password"
                    placeholder="Search"
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start">
                          <CiSearch />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
              </div>
              <div className="recent_search_result">
              {/* <div className="chart_section_nodata">
                      <img  src={NO_RECORD_FOUND} alt="your-image-alt-text" />
                      <Typography  variant={'h2'} className={'text-center no_records'} sx={{alignItems: 'center'}}> No Records Found </Typography> 
               </div> */}
                <div>
                  <div className="recent_months_div">
                    <p>September 2023</p>
                  </div>
                  <div className="main_results">
                   
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Sathya Jyothi</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 420.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Gowtham K</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 420.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Vijay Kumar</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 120.50 </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="recent_months_div">
                    <p>August 2023 </p>
                  </div>
                  <div className="main_results">
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Sathya Jyothi</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 420.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Gowtham K</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 420.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Vijay Kumar</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 120.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Vijay Kumar</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 120.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Vijay Kumar</h4>
                          <p>Paid on 22/01/2024, 7:53 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 120.50 </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <MiniStatement
        handleDrawerClosed={handleDrawerClosed}
        mobileOpens={mobileOpens}
        handleDrawerToggled={handleDrawerToggled}
      />
    </div>
  );

 
}

  

export default Home;
