import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { Button, IconButton, InputAdornment, OutlinedInput, TextField, Typography, Grid } from "@mui/material";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as yup from 'yup';
import Div from "@jumbo/shared/Div";
import { ToastServices } from 'app/services/toastServices';
import { AuthenticationContext } from 'app/utils/constants/AuthContext';
import useApp from 'app/hooks/useApp';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AppSvgLogon, LoginBGSVG, LOGINBGSVG1 } from 'app/assets/svg/Svg';
import CREDITS from '../../../assets/nsdl_logo.png'
import checkmark from 'app/assets/check-mark.png';
import Divider from '@mui/material/Divider';
import BGimg from 'app/assets/savinguser BG.png'
import { DashLogo } from 'app/assets/svg/Svg';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const SessionPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const appData = useApp();
    const [showPassword, setShowPassword] = useState(false);
    const { login } = useContext(AuthenticationContext)


    const useofSavingusers1 = ['Swift and Seamless Process', 'No Hidden Fees', 'Instant Verification'];
    const useofSavingusers2 = ['24/7 Accessibility', 'Cutting-Edge Security Measures', 'Mobile Banking Integration'];


    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
    } = useFormik({
        initialValues: {
            email: "admin@technogenesis.in",
            password: "12345678",
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
            return new Promise((reslove, reject) => {
                const item = true;
                try {
                    if (item != null) {

                        sessionStorage.setItem("token", "1111");
                        sessionStorage.setItem("refershtoken", "1111");
                        sessionStorage.setItem("auth", true);
                        sessionStorage.setItem("portal", "user");
                        login();
                        navigate("/curent-user/dashboard", { replace: true });
                        setLoading(false);
                        reslove(item);
                    } else {
                        ToastServices.ErroToast("Test toaster");
                        setLoading(false);
                    }
                } catch (err) {
                    reject(err);
                }
            });
        },
    });

    console.log(appData, "5465456");

    function handleClick() {
        sessionStorage.setItem("portal", "user");
        sessionStorage.setItem("token", "1111");
        sessionStorage.setItem("refershtoken", "1111");
        sessionStorage.setItem("auth", true);
    }

    return (
        <Div className='saving_user_session'>
            <Div className='saving_user_session_content_div'>

                <div className='app_logo_savings'>
                    <DashLogo />
                </div>


                <Div sx={{ paddingLeft: '25px' }}>
                    <Div className='savinuser_heading'>
                        <Typography variant='h3' fontWeight={700} color={'#D16513'}>Smarter Banking Choice</Typography>
                        <Typography variant='h1' fontWeight={600} fontSize={'35px'}>Open an Instant Bank  Account</Typography>
                        <Typography variant='p' fontWeight={400} fontSize={'17px'} color={'#858585'}>Instantly Open Your Bank Account Online
                            - Hassle-Free Banking <br />at Your Fingertips!</Typography>
                    </Div>

                    <Grid container spacing={1} sx={{ marginTop: '35px' }}>
                        <Grid item xs={5}>
                            {useofSavingusers1.map((item, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                    <img src={checkmark} alt={`icon_${index}`} style={{ marginRight: '10px', width: '20px', height: '20px' }} />
                                    <Typography variant='p' fontWeight={500} fontSize={'15px'} color={'#393939'}>
                                        {item}
                                    </Typography>
                                </div>
                            ))}
                        </Grid>
                        <Grid item xs={5}>
                            {useofSavingusers2.map((item, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                    <img src={checkmark} alt={`icon_${index}`} style={{ marginRight: '10px', width: '20px', height: '20px' }} />
                                    <Typography variant='p' fontWeight={500} fontSize={'15px'} color={'#393939'}>
                                        {item}
                                    </Typography>
                                </div>
                            ))}
                        </Grid>
                    </Grid>

                    <Button className='apply_new' variant="contained" color="primary" onClick={() => {navigate('/saving-user/register')}} >
                        Apply Your new instant bank account
                    </Button>

                </Div>
                <Grid container  lg={12} xs={12} justifyContent="flex-end" alignItems="center" sx={{marginTop:'20px'}}>
    <Grid xs={8} item sx={{backgroundColor:'#C1C1C1', height:'0.5px',marginTop:'28px'}}></Grid>
    
    <Grid item xs={4}>
    <div className="credits_logo mt-25" >
        <p>Powered by</p>
        <img width={98} height={40} src={CREDITS} alt="credits" />
     </div>
    </Grid>
</Grid>

            </Div>
            <Div className='saving_user_session_img_div'>
            <Grid container  lg={12} xs={12} justifyContent="flex-end" alignItems="center" sx={{marginTop:'25px'}}>
    
    <Grid item >
    <Typography variant='p' fontWeight={400} fontSize={'17px'} marginRight={'20px'}>If Already Apply!</Typography>

    <Button className='login_session' variant="contained" color="primary" >
      Login </Button>
    </Grid>

    </Grid>
      <Div  sx={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'60px'}}>
         <img src={BGimg} alt='img' width={'525px'} />

      </Div>



            </Div>
        </Div>

    );
};

export default SessionPage;
