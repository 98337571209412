import React from "react";
import { Box, Button, Drawer,  } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LuDownload } from "react-icons/lu";
import { PrintIconSvg } from "app/assets/svg/Svg";
import APPLOGO from "../../../assets/applogo.png";
import CREDITS from "../../../assets/creditis.png";
import TICK from "../../../assets/tick.png";

const TransactionReceipt = ({ handleDrawerToggle, mobileOpen, handleDrawerClose,}) => {
 const dummyArray = [1, 2, 3, 4, 5,6,7,8,9];
  return (
    <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <Box sx={{ width: "530px" }}>
          <div className="sidebar_header">
            <h1>Transaction Receipt</h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>
          <div className="fund_reciptSection">
              <div className="recipt_paper">
                <div className="receipt_header borde_bottom_grey p-10 d-flex items-center space-between">
                  <img height={45} width={160} src={APPLOGO} alt="" />
                  <img height={22} width={120} src={CREDITS} alt="" />
                </div>
                <div className="receipt_header_transaction_status borde_bottom_grey p-10 d-flex items-center justify-center">
                  <img height={50} width={50} src={TICK} alt="" />
                  <div className="ml-10">
                    <h5>₹15,000</h5>
                    <p>Transaction Successful</p>
                  </div>
                </div>
                <table className="w-100 beneficary_details fund_transfer">
                  <tr>
                    <td className="table_label">Transaction Id</td>
                    <td className="table_value">121554856512</td>
                  </tr>
                  <tr>
                    <td className="table_label">Created On</td>
                    <td className="table_value">21/01/2024 04:43 AM</td>
                  </tr>
                  <tr>
                    <td className="table_label">Recipient </td>
                    <td className="table_value text-end">Swamynaathan Iyer</td>
                  </tr>
                  <tr>
                    <td className="table_label">Mobile No</td>
                    <td className="table_value text-end"> 90798 98218</td>
                  </tr>
                  <tr>
                    <td className="table_label">Transaction Amount </td>
                    <td className="table_value text-end">₹ 15,000.00</td>
                  </tr>
                  <tr>
                    <td className="table_label">Convenience Fee </td>
                    <td className="table_value text-end">
                      {" "}
                      <span className="fee">₹ 100.00</span>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="table_label">Total Amount </td>
                    <td className="table_value text-end">₹ 15,100.00</td>
                  </tr>
                  <tr>
                    <td className="table_label">Transaction Type</td>
                    <td className="table_value">NEFT</td>
                  </tr>
                  <tr>
                    <td className="table_label">Remarks</td>
                    <td className="table_value">For Home</td>
                  </tr>
                  
                 
                </table>
                <div className="boder_style">
                {dummyArray.map((item, index) => (<span key={index}></span>))}
                </div>
              </div>
              <div className="d-flex items-center justify-center Share_bttons">
                <Button className="print_btn" variant="outlined" startIcon={<PrintIconSvg />}>
                  Print
                </Button>
                <Button variant="outlined" startIcon={<LuDownload />}>
                  Download
                </Button>
              </div> 
            </div>


          </Box>


      </Drawer>
  )
}

export default TransactionReceipt