import * as React from "react";
import { Box } from "@mui/system";
import { Button, Grid } from "@mui/material";
import DEL_ICON from "../../../assets/del_icon.png";
import Modal from "@mui/material/Modal";
import { IoClose } from "react-icons/io5";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  py: 5,
  px: 4,
};

export default function DeletePopup({ open, handleClose }) {
  return (
    <div>
      <Modal
        className="delete_popup"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-end close_icon_popup">
            <IoClose onClick={handleClose} />
          </div>
          <div className="text-center">
            <img
              src={DEL_ICON}
              width={"70px"}
              height={"70px"}
              alt="delet_pop"
            />
          </div>
          <p
            id="unstyled-modal-description"
            className="modal-description text-center"
          >
            Are you sure do you want to{" "}
            <b>
              Delete <br /> Mamta Lodhi
            </b>{" "}
            from beneficiary list?{" "}
          </p>
          <Grid container>
            <Grid
              alignSelf={"center"}
              item
              lg={6}
              sx={{ padding: "0px 5px 0px 5px" }}
            >
              <Button
                className="popup_cancel "
                fullWidth
                type="submit"
                variant="contained"
                size="small"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>

            <Grid alignSelf={"center"} item lg={6}>
              <Button
                className="primary_btn mt-0 mb-0 "
                fullWidth
                type="submit"
                variant="contained"
                size="small"
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
