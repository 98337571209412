import React, { useEffect } from "react";

import routes, {adminRoutes}  from "./routes";
import useJumboRoutes from "@jumbo/hooks/useJumboRoutes";
 

const AppRoutes = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const appRoutes = useJumboRoutes(routes);
  //  const adminAppRoutes = useJumboRoutes(adminRoutes);
    return (
        <React.Fragment>
           
            { appRoutes  }
            { /* adminAppRoutes */ }
        </React.Fragment>
    );
};
export default AppRoutes;
