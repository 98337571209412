import React, { useState }from "react";
import { useNavigate } from "react-router-dom";
import { Grid, TextField, Typography, Link,OutlinedInput,InputAdornment,
  IconButton, } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import * as yup from 'yup';
import { useFormik } from "formik";
import Div from "@jumbo/shared/Div";
import { AdminSVg, AppSvgLogon } from "app/assets/svg/Svg";
import CREDITS from '../../../../assets/nsdl_logo.png'
import PasswordTooltip from "@jumbo/components/PasswordInfoTooltip/PasswordTooltip";


const validationSchema = yup.object({
  pass: yup
      .string('Enter your password')
      .required('Password is required'),
      
      

 cpass: yup
      .string('Enter your confirm password')
      .required('Confirm password is required')
      
});

const AdminSetPassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
const formik = useFormik({
     validateOnChange: true,
    initialValues: {
     pass: '',
     cpass: '',
    },
      validationSchema: validationSchema,
      onSubmit: (values) => {
  
        console.log('Form submitted:', values); 
        navigate("/success-message", { replace: true });
      },
    });
 return (
    <div>
    <div className="session_page ">
       <Grid className="h-full session_grid" container>
          <Grid
          className="session_image_content"
          item
          lg={7}
          style={{ alignSelf: "center" }}
          >
          <div className="session_content">
             <div className="session_img">
                <AdminSVg />
             </div>
          </div>
       </Grid>
       <Grid item lg={5} style={{ alignSelf: "center" }}>
       <div className="session_card flex_container_col">
          <div className="session_wrapper mb-10">
             <div className="session_form">
                <div >
                   <form className="admin_form" onSubmit={formik.handleSubmit} noValidate autoComplete='off'>
                      <div className="text-center">
                         <AppSvgLogon  />
                         <h2 className="admin_greetings" style={{marginBottom:0}}>Set New Password</h2>
                         
                      </div>
                      <div className="mt-20">
                         <Div sx={{ mt: 2 }}>
                         <Typography className="login_label d-flex items-center g-10"><span>Password <span className="required">*</span></span> <PasswordTooltip /></Typography>
                         <OutlinedInput
                         className="password_input"
                         name="pass"
                         type={showPassword ? "text" : "password"}
                         fullWidth
                         onChange={formik.handleChange}
                         error={formik.touched.pass && Boolean(formik.errors.pass)}
                         helperText={formik.touched.pass && formik.errors.pass}
                         variant="outlined"
                         placeholder="Enter Password"
                         endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowPassword((show) => !show)
                              }
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                         />
                      </Div>
                      <Div sx={{ mt: 3 }}>
                      <Typography className="login_label d-flex items-center g-10"><span>Confirm Password <span className="required">*</span></span> <PasswordTooltip /></Typography>
                      <OutlinedInput
                      className="password_input"
                      name="cpass"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Confirm Password"
                      fullWidth
                      onChange={formik.handleChange}
                      error={formik.touched.cpass && Boolean(formik.errors.cpass)}
                      helperText={formik.touched.cpass && formik.errors.cpass}
                      variant="outlined"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPassword((show) => !show)
                            }
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      />
                </Div>
                <LoadingButton
                className="login_btn"
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                
                >
                Set New Password
                </LoadingButton>
                <Typography textAlign={"center"}>
                <Link
                   className="back_to_login"
                   underline="none"
                   href="/admin"
                   >
                {" "}
                Back to Login{" "}
                </Link>
                </Typography>
             </div>
             </form>
             <div className="credits_logo mt-20 text-center" >
                <p>Powered by</p>
                <img width={98} height={40} src={CREDITS} alt="credits" />
             </div>
          </div>
       </div>
    </div>
 </div>
 </Grid>
 </Grid>
 </div>
 </div>
  );
};

export default AdminSetPassword;
