// import React, { useState } from 'react';
// import Select from 'react-select';

// const options = [
//   {
//     value: '1',
//     label: 'John Doe',
//     accountNumber: '123456789',
//     bankName: 'Bank of Example',
//   },
//   {
//     value: '2',
//     label: 'Jane Smith',
//     accountNumber: '987654321',
//     bankName: 'Another Bank',
//   },
//   // Add more options as needed
// ];


// const Test = () => {
//   const [selectedOption, setSelectedOption] = useState();

//   const handleChange = (selectedOption) => {
//     setSelectedOption(selectedOption);
//   };
//   const formatOptionLabel = ({ label, accountNumber, bankName }) => (
//     <div>
//       <strong>{label}</strong>
     
//     </div>
//   );
//   const Option = (props) => (
//     <div onClick={}>
//       <strong>{props.label}</strong>
//       <br />
//       <span>Account Number: {props.data.accountNumber}</span>
//       <br />
//       <span>Bank Name: {props.data.bankName}</span>
//     </div>
//   );
//   return (

//    <div style={{ width: '300px' }}>
//       <Select
//         value={selectedOption}
//         onChange={handleChange}
//         options={options}
//         isSearchable={true}
//         components={{ Option }}
//         formatOptionLabel={formatOptionLabel}
//         placeholder="Select an account..."
//         getOptionValue={(option) => option.value}
//       />
//       <div>
//         {/* Display selected option details */}
//         {selectedOption && (
//           <div>
//             <strong>Selected:</strong> {selectedOption.label}, 
//             Account Number: {selectedOption.accountNumber}, 
//             Bank Name: {selectedOption.bankName}
//           </div>
//         )}
//       </div>
//     </div>

   
//   )
// }

// export default Test

import React from 'react'

const Test = () => {
  return (
    <div>Comming Soon</div>
  )
}

export default Test