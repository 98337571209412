import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";
import { useContext } from 'react';
import { IconButton, InputAdornment, OutlinedInput, TextField, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import {useFormik} from "formik";
import * as yup from 'yup';
import Div from "@jumbo/shared/Div";
import { ToastServices } from 'app/services/toastServices';
import { AuthenticationContext } from 'app/utils/constants/AuthContext';
import useApp from 'app/hooks/useApp';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AppSvgLogon, LoginBGSVG, LOGINBGSVG1 } from 'app/assets/svg/Svg';
import CREDITS from '../../../assets/nsdl_logo.png'
// important function 
import axios from 'axios';
import { loginAction } from 'app/redux/actions/authAction';
import jwtDecode from 'jwt-decode';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Login1 = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const appData = useApp();
    const [showPassword, setShowPassword] = useState(false);
    const {  login} = useContext(AuthenticationContext)


    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
      } = useFormik({
        initialValues: {
          email: "admin@technogenesis.in",
          password: "12345678",
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
          return new Promise((reslove, reject) => {
            const item = true;
            try {
              if (item != null) {
                
                sessionStorage.setItem("token", "1111");
                sessionStorage.setItem("refershtoken", "1111");
                sessionStorage.setItem("auth", true);
                sessionStorage.setItem("portal", "user");
                login();
                navigate("/curent-user/dashboard", { replace: true });
                setLoading(false);
                reslove(item);
              } else {
                ToastServices.ErroToast("Test toaster");
                setLoading(false);
              }
            } catch (err) {
              reject(err);
            }
          });
        },
      });
    
      console.log(appData, "5465456");

      function handleClick() {
        sessionStorage.setItem("portal", "user");
        sessionStorage.setItem("token", "1111");
        sessionStorage.setItem("refershtoken", "1111");
        sessionStorage.setItem("auth", true);
      }
    
    return (
        <Div className='current_user_session '>
        <div className='h-100'>
           <div className='app_logo_svg '>
              <AppSvgLogon  />
           </div>
           <div className='current_user_session_card'>
              <form className='h-100' onSubmit={handleSubmit} noValidate autoComplete="off">
                 <div className="current_session_form justify-between h-100">
                    <div className='w-100 '>
                       <Typography  sx={{textAlign:"center",mt:1}} className='login_greeting'>Welcome Back</Typography>
                       <Div className='mt_12' sx={{ mt: 4  }}>
                       <Typography className="login_label">
                          Email Address <span className="required">*</span>
                       </Typography>
                       <TextField fullWidth  name="email" placeholder="Enter Email Address"   type="email"  variant="outlined"
                    value={values.email}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       error={touched.email && Boolean(errors.email)}
                       />
                       {touched.email ? ( <small className="error_text">{errors.email}</small>) : ( ""  )}
                    </Div>
                    <Div className='mt_12' sx={{ mt: 3 }}>
                    <Typography className="login_label">
                       Password <span className="required">*</span>
                    </Typography>
                    <OutlinedInput  className="password_input"  name="password"  placeholder="Enter Password" fullWidth={true}
                     id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    required={true}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password ? errors.password : ""}
                    endAdornment={
                    <InputAdornment position="end">
                       <IconButton aria-label="toggle password visibility"  onClick={() => setShowPassword((show) => !show) }
                          edge="end" >
                          {showPassword ? ( <VisibilityOff /> ) : ( <Visibility /> )}
                       </IconButton>
                    </InputAdornment> }
                    />
                    {touched.password ? ( <small className="error_text"> {errors.password} </small>) : ("" )}
                    <Typography className="mt_0" sx={{mt:2}}  textAlign={"right"}>
                    <Link  className="forget_link"  underline="none" href="/current-user/forgot-password" >
                     Forgot Password ?
                    </Link>
                    </Typography>
                 </Div>
           </div>
           <Div  className='w-100'>
           <LoadingButton  className="login_btn"  fullWidth  type="submit"  variant="contained"  size="large" sx={{ mb: 3 }}>
           Login Securely
           </LoadingButton>
           </Div>
        </div>
        </form>
     </div>
     <div className="credits_logo mt-25" >
        <p>Powered by</p>
        <img width={98} height={40} src={CREDITS} alt="credits" />
     </div>
     </div>
     <div className='charcter_svg'>
        <LOGINBGSVG1 />
     </div>
     <div className='mobile_svg'>
        <LoginBGSVG />
     </div>
     </Div>
    );
};

export default Login1;

