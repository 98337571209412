import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Drawer, TextField, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LuDownload } from "react-icons/lu";
import { PrintIconSvg } from "app/assets/svg/Svg";
import APPLOGO from "../../../../assets/applogo.png";
import CREDITS from "../../../../assets/creditis.png";
import TICK from "../../../../assets/tick.png";
import FundTranferForgotpin from "../FundTransferForgotpin.js/FundTransferForgotpin";

const FundTransferVerify = ({ handleDrawerToggle, mobileOpen, handleDrawerClose,}) => {
// useState 
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [otpsection, setOtpsection] = React.useState(true);
  const [verifysection, setVerfysection] = React.useState(true);
  const [counter, setCounter] = useState(30);
  const dummyArray = [1, 2, 3, 4, 5,6,7,8,9];
// UseEffect 
 useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

//   Functions 
  const verify = () => {
    setOtpsection(false);
    setVerfysection(false);
  };
  const handleOtpChange = (e, index) => {
    if (e.target.value.match(/^\d+$/)) {
      const newOtp = [...otp];
      newOtp[index] = e.target.value;
      setOtp(newOtp);
      if (index < 3 && e.target.value) {
        inputRefs[index + 1].current.focus();
      }
    }
    if (e.target.value === "" && index > 0) {
      const newOtp = [...otp];
      newOtp[index] = e.target.value;
      setOtp(newOtp);
      inputRefs[index - 1].current.focus();
    }
    if (e.target.value === "" && index === 0) {
      const newOtp = [...otp];
      newOtp[index] = e.target.value;
      setOtp(newOtp);
    }
  };

  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <Box sx={{ width: "530px" }}>
          <div className="sidebar_header">
            <h1>Fund Transfer</h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>
          {/* Verify form  */}
          {verifysection == true ? (
            <div className="beneficarty_add_details">
              <table className="w-100 beneficary_details">
                <tr>
                  <td className="table_label"> Account Type</td>
                  <td className="table_value">NSDL Payment Bank</td>
                </tr>
                <tr>
                  <td className="table_label">Transfer to</td>
                  <td className="table_value">Rama Krishna Sundramoorthy</td>
                </tr>
                <tr>
                  <td className="table_label">Amount</td>
                  <td className="table_value">₹ 15,000.00</td>
                </tr>
                <tr>
                  <td className="table_label">Convenience Fee</td>
                  <td className="table_value text-end">
                    {" "}
                    <span className="fee">₹ 100.00</span>{" "}
                  </td>
                </tr>
                <tr>
                  <td className="table_label">Total Amount</td>
                  <td className="table_value text-end">₹ 15,100.00</td>
                </tr>
                <tr>
                  <td className="table_label">Transaction Type</td>
                  <td className="table_value">NEFT</td>
                </tr>
                <tr>
                  <td className="table_label">Remarks</td>
                  <td className="table_value">Other</td>
                </tr>
                <tr>
                  <td className="table_label">Other Remarks</td>
                  <td className="table_value">For Work</td>
                </tr>
              </table>
              <div className="verify_otp_verification">
                <p className="verify_otp_verification_title">
                  Verify T-PIN to Fund transfer
                </p>
               <div className="Otp_input_box_div">
                <div className="Otp_input_box_fundtransfer">
                  {otp.map((value, index) => (
                    <div key={index} className="otp_input">
                      <TextField variant="outlined" margin="normal" type="text" inputProps={{maxLength: 0.8,}}
                        value={value}
                        onChange={(e) => handleOtpChange(e, index)}
                        inputRef={inputRefs[index]}
                        sx={{width:'70px'}}/>
                        
                    </div>
                  ))}
                </div>
                 <FundTranferForgotpin handleDrawerClose={handleDrawerClose}/>
                </div>
              <div className="Otp_input_box_button">
                <div className="d-flex items-center confirmation_buttons gap">
                  <Button className="confrm_btn" onClick={verify}>
                    Make Transfer
                  </Button>
                  <Button className="outline_btn" onClick={handleDrawerClose} >
                    Cancel
                  </Button>
                </div>
                </div>
              </div>
            </div>
          ) : (
            // receipt
            <div className="fund_reciptSection">
              <div className="recipt_paper">
                <div className="receipt_header borde_bottom_grey p-20 d-flex items-center space-between">
                  <img height={45} width={160} src={APPLOGO} alt="Logo" />
                  <img height={22} width={120} src={CREDITS} alt="Credits" />
                </div>
                <div className="receipt_header_transaction_status borde_bottom_grey p-20 d-flex items-center justify-center">
                  <img height={50} width={50} src={TICK} alt="Success" />
                  <div className="ml-10">
                    <h5>₹15,000</h5>
                    <p>Payment Successful</p>
                  </div>
                </div>
                <table className="w-100 beneficary_details fund_transfer">
                  <tr>
                    <td className="table_label">Account Type</td>
                    <td className="table_value">NSDL Payment Bank</td>
                  </tr>
                  <tr>
                    <td className="table_label">Transfer to</td>
                    <td className="table_value">Rama Krishna Sundramoorthy</td>
                  </tr>
                  <tr>
                    <td className="table_label">Amount </td>
                    <td className="table_value text-end">₹ 15,000.00</td>
                  </tr>
                  <tr>
                    <td className="table_label">Convenience Fee </td>
                    <td className="table_value text-end">
                      {" "}
                      <span className="fee">₹ 100.00</span>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="table_label">Total Amount </td>
                    <td className="table_value text-end">₹ 15,100.00</td>
                  </tr>
                  <tr>
                    <td className="table_label">Transaction Type</td>
                    <td className="table_value">NEFT</td>
                  </tr>
                  <tr>
                    <td className="table_label">Remarks</td>
                    <td className="table_value">Other</td>
                  </tr>
                  <tr>
                    <td className="table_label">Other Remarks</td>
                    <td className="table_value">For work</td>
                  </tr>
                  <tr>
                    <td className="table_label">Transaction ID</td>
                    <td className="table_value">467988876543190</td>
                  </tr>
                  <tr>
                    <td className="table_label">Time</td>
                    <td className="table_value">07/11/2023 12:23 PM </td>
                  </tr>
                </table>
                <div className="boder_style">
                {dummyArray.map((item, index) => (<span key={index}></span>))}
                </div>
              </div>
              <div className="d-flex items-center justify-center Share_bttons">
                <Button className="print_btn" variant="outlined" startIcon={<PrintIconSvg />}>
                  Print
                </Button>
                <Button variant="outlined" startIcon={<LuDownload />}>
                  Download
                </Button>
              </div> 
            </div>
          )}
        </Box>
      </Drawer>
    </div>
  );
};

export default FundTransferVerify;
