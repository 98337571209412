import React, { Fragment, useState } from 'react';
import { Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Modal, TextField, Typography, capitalize } from "@mui/material";
import Button from "@mui/material/Button";
 
import { useSelector } from 'react-redux';
import { config } from 'app/config/main';
import { toast } from 'react-toastify';
import { logoutAction } from 'app/redux/actions/authAction';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const ConfirmModal = ({value, setOpenModal, data}) => {
    const [open, setOpen] = useState(value)
    const token = useSelector(state => state.auth?.accessToken);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setOpenModal(false)
    }

    const handleSubmit = async () => {
        setLoading(true)
        const bodyData = {
            password: data.oldPassword,
            passwordnew: data.passwordNew,
            passwordconfirm: data.passwordConfirm
        }
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(bodyData)
        }

        await fetch(`${config.apiUrl}/change-password`, options)
        .then(res => res.json())
        .then((res) => {
            setLoading(false)
            console.log(res);
            if(res.status === true) {
                toast.success('Password Changed Successfully!');
                handleClose();
                logoutAction();
                navigate('/')
            } else {
                toast.error(res.message)
                handleClose();
            }
        }, (err) => {
            console.log(err)
        })
    }

    return(
        <Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Password Confirmation
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to change the Password?
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' sx={{
                        textTransform: 'capitalize',
                        ':hover': {
                            backgroundColor: '#E73145',
                            color: '#fff'
                        }
                    }} color='error' onClick={handleClose}>Cancel</Button>
                    <LoadingButton color='success' 
                    loading={loading}
                    sx={{
                        backgroundColor: '#0e9e02',
                        color: '#FFF',
                        textTransform: 'capitalize',
                        ':hover': {
                            backgroundColor: '#0e9e02',
                            color: '#FFF',
                        }
                    }} onClick={handleSubmit}>Submit</LoadingButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default ConfirmModal;