import React from "react";
import Div from "@jumbo/shared/Div";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Drawer,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import "./style.css";
import USER from "../../../assets/user_img.jpg";

const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} />;
};

const CommissionView = ({
  handleDrawerToggle,
  handleDrawerClose,
  mobileOpen,
}) => {
  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpen}
        onClose={handleDrawerClose}
      >
        <Box sx={{ width: "520px" }}>
          <div className="sidebar_header">
            <h1>Commission Details</h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>
          <div className=" items-center userprofile mt-10">
            <div className="user_img_bg">
              <img src={USER} alt="" />
            </div>
            <div className="commission">
              <h3>Commission From</h3>
              <h2>Vignesh M</h2>
            </div>
          </div>

          <div className="sideform_container mt-10" style={{ padding: "0" }}>
            <Div>
              <Table className="common_table my_profile ">
                <TableBody>
                  <TableRow>
                    <TableCell>Transaction Date</TableCell>
                    <TableCell className="bold">12/02/2024</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Transaction Id</TableCell>
                    <TableCell className="bold">3910793817</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Sender Name</TableCell>
                    <TableCell className="bold">Siva Ramar</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Transaction Type</TableCell>
                    <TableCell className="bold">NEFT</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Transaction Amount (₹)</TableCell>
                    <TableCell className="bold">₹ 1,000.00 </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Commission (₹)</TableCell>
                    <TableCell className="bold">₹ 100.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Commission (%)</TableCell>
                    <TableCell className="bold">10%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Amount (₹)</TableCell>
                    <TableCell className="bold">₹ 1,100.00</TableCell>
                  </TableRow>
                  <TableRow style={{ whiteSpace: "normal" }}>
                    <TableCell className="align-top">Remarks </TableCell>
                    <TableCell className="bold">
                      Family Health Insurance 
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Div>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default CommissionView;
