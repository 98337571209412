import Div from '@jumbo/shared/Div'
import { Box, Card, CardContent, Drawer, Grid, TextField, Table, TableBody, TableCell, TableFooter, TableHead, 
  TablePagination, TableRow, Typography, OutlinedInput, InputAdornment } from '@mui/material'
import React, {useState, useEffect} from 'react'
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import { VscFilePdf } from "react-icons/vsc";
import { CiSearch } from "react-icons/ci";
import Search from 'app/pages/app-pages/component/search';
import SkeletonTableLoader from 'app/pages/app-pages/component/skeleton_loader/skeletonTableLoader';
import NoRecordsFound from 'app/pages/app-pages/component/noRecordsFound';





const TransactionsList = () => {
   
    const [selectedValue, setSelectedValue] =useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

     
     

    const handleSelectChange = (event) => {
      setSelectedValue(event.target.value);
    };

  
    const TableData =[
        {
            sno:"01",
            transactionId: "6301957864",
            name:"Siva Ramar",
            remarks: "Friends and Family",
            dateTime:"31/05/23, 14:20",
            amount:"15,000 Cr",
            balance:"2,15,589",
            status: 1
        },
        {
            sno:"02",
            transactionId: "2730175548",
            name:"Vishwas Patel",
            remarks: "Entertainment",
            dateTime:"1/06/23, 09:30",
            amount:"11,000 Cr",
            balance:"2,00,589",
            status: 1
        },
        {
            sno:"03",
            transactionId: "1696086371",
            name:"Navjot Kaur",
            remarks: "Investment",
            dateTime:"1/06/23, 09:30",
            amount:"12,000 Cr",
            balance:"1,89,589",
            status: 1
        },
        {
            sno:"04",
            transactionId: "3954212189",
            name:"mamta Lodhi",
            remarks: "Food",
            dateTime:"03/06/23, 15:00",
            amount:"34,600 Dr",
            balance:"2,01,589",
            status: 0
        },
        {
            sno:"05",
            transactionId: "0500042814",
            name:"Paartho Ghosh",
            remarks: "Entertainment",
            dateTime:"03/06/23, 15:00",
            amount:"12,600 Cr",
            balance:"1,66,989",
            status: 1
        },
        {
            sno:"06",
            transactionId: "7725068610",
            name:"Bhavna Goyal",
            remarks: "Food",
            dateTime:"31/05/23, 14:20",
            amount:"909.12 Dr",
            balance:"1,66,080",
            status: 0
        },
        {
            sno:"07",
            transactionId: "1501341233",
            name:"Jayshri Tiwari",
            remarks: "Entertainment",
            dateTime:"05/06/23, 14:30",
            amount:"765.54 Dr",
            balance:"1,65,315",
            status: 0
        },
        {
            sno:"08",
            transactionId: "3910793817",
            name:"Ramesh Gupta",
            remarks: "Savings",
            dateTime:"02/06/23, 11:30",
            amount:"899 Cr",
            balance:"1,66,214",
            status: 1
        },
        {
            sno:"09",
            transactionId: "1501341233",
            name:"Jayshri Tiwari",
            remarks: "Entertainment",
            dateTime:"05/06/23, 14:30",
            amount:"765.54 Dr",
            balance:"1,65,315",
            status: 0
        },
        {
            sno:"10",
            transactionId: "3910793817",
            name:"Ramesh Gupta",
            remarks: "Savings",
            dateTime:"02/06/23, 11:30",
            amount:"899 Cr",
            balance:"1,66,214",
            status: 1
        },
    ]

    const terms = ['name', 'remarks', 'transactionId'];



    const [age, setAge] = React.useState(30);

    const handleChange = (event) => {
      setAge(event.target.value);
    };
  
    

    const [filteredData, setFilteredData] = useState(TableData);
    const [loading, setLoading] = useState(false);
  
    const handleSearch = (filteredData, loading) => {
      // Do something with the filtered data
      setLoading(loading);
      setFilteredData(filteredData)
    
    };

  return (
    <section>
        <Card className='responsivecard'> 

        <CardContent>
          <div className='common_header d-flex mb-0 mt-0 space-between ' style={{alignItems:"baseline"}}>
            <div className='d-flex items-center'>
            <Typography
                className="page_header"
                variant={"h2"}>Transactions </Typography>
              <div className='ml-20'>
  {/** search customisable */}

  <Search data={TableData} onSearch={handleSearch} terms={terms}/>
              
              {/** search customisable */}   

                {/** 
              <Div sx={{ mt: 1 }} >
              <OutlinedInput
                    className="form_new_style w-100"
                    id="outlined-adornment-password"
                    value={searchInput}
                  onChange={handleSearchChange}
                    placeholder="Search"
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton edge="start" onClick={handleSearch}>
                        <CiSearch />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Div>*/}

              </div>
              

            </div>
            <div className='d-flex items-center' >
              <div>
              <Select className='select-box'
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={age}
   
    onChange={handleChange}
  >
    <MenuItem selected value={10}>Last 30 Days</MenuItem>
    <MenuItem value={20}>Last 60 Days</MenuItem>
    <MenuItem value={30}>Last 90 Days</MenuItem>
  </Select>
              </div>
              <div className='ml-20'>
                <Button startIcon={<VscFilePdf />} className='export_btn' variant='outlined' >Export as PDF</Button>


              </div>
              
            </div>

          </div>
          
 

           
                    <Div>
                        <Table className='common_table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>S.No</TableCell>
                                    <TableCell>Transaction Id</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Remarks</TableCell>
                                    <TableCell>Date & Time</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Balance</TableCell>
                                    
                                    

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
        // Display the skeleton loader while data is loading
        <SkeletonTableLoader tablecell="7" row="8"/>
      ) : (

                                   // Display the actual table content when data is loaded
        (filteredData.length==0) ? 
        ( <NoRecordsFound tablecell="7"/> )
        :
        (
          filteredData.map((item, i)=>{

                                        return(
                                            <TableRow key={i}>
                                    <TableCell>{item?.sno}</TableCell>
                                    <TableCell>{item?.transactionId}</TableCell>
                                    <TableCell>{item?.name}</TableCell>
                                    <TableCell>{item?.remarks}</TableCell>
                                    <TableCell>{item?.dateTime}</TableCell>
                                    <TableCell className={(item?.status===1)?'grow':'loss'}> {item?.amount}</TableCell>
                                    <TableCell>{item?.balance}</TableCell>
                                   

                              </TableRow>
                                        )

                                    })
        ) )
                                }
                            
                            </TableBody>
                            <TableFooter>
                            <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  
                  count={15}
                  rowsPerPage={5}
                  page={1}
                />
                            </TableFooter>
                        </Table>
                    </Div>
                </CardContent>
            
            
            
      </Card>


   


    </section>
    
    
    
   
 
  )
}

export default TransactionsList