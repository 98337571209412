import React, { Fragment, useState } from 'react';
import { Card, CardContent, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import BlockIcon from '@mui/icons-material/Block';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { config } from 'app/config/main';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const EditProfile = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const token = useSelector(state=> state?.auth?.accessToken);
    const userData = useSelector(state => state?.user?.userData);
    const [preData, setPreData] = useState();

    const handleSubmit = async (data) => {
        setLoading(true)
        const bodyData = {
            ...data,
            role_id: userData.acl_role_id
        }
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(bodyData)
        }

        await fetch(`${config.apiUrl}/users/${userData.id}/profile`, options)
        .then(res => res.json())
        .then(res => {
            if(res.status === 200) {
                toast.success('Profile Changed Successfully!')
                navigate('/curent-user/dashboard')
            } else {
                toast.error(res.message)
            }
        })
    }

    const loadProfile = async () => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        await fetch(`${config.apiUrl}/users/${userData.id}/profile`, options)
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(res.status === 200) {
                setPreData(res?.user?.data[0])
            }
        })
    }

    useEffect(()=>{
        loadProfile();
    }, [])

    const handleCancel = () => {
        navigate('/curent-user/dashboard')
    }
    console.log(loading)
    return (
        <Fragment>
            <Typography variant={"h1"} mb={5}>{'Edit Profile'}</Typography>

            <Div>
                <Card sx={{
                    maxWidth: 700
                }}>
                    <CardContent>
                        <Formik
                            validateOnChange={true}
                            enableReinitialize={true}
                            initialValues={{
                                name: preData?.name,
                                email: preData?.email,
                                mobileno: preData?.mobileno
                            }}
                            onSubmit={(data, { setSubmitting }) => {
                                setSubmitting(true);
                                handleSubmit(data);
                                setSubmitting(false);
                            }}
                        >
                            {
                                ({ isSubmitting, values, handleChange }) => (
                                    <Form >
                                        {console.log(values)}
                                        <Div sx={{ mb: 3, mt: 1 }}>
                                            <JumboTextField
                                                fullWidth
                                                name="name"
                                                label="Name"
                                                onChange={handleChange}
                                                value={values?.name}
                                                defaultValue="Name"
                                            />
                                        </Div>
                                        <Div sx={{ mb: 3, mt: 1 }}>
                                            <JumboTextField
                                                fullWidth
                                                type='email'
                                                name="email"
                                                label="Email"
                                                onChange={handleChange}
                                                value={values?.email}
                                                defaultValue={"Email"}
                                            />
                                        </Div>
                                        <Div sx={{ mb: 3, mt: 1 }}>
                                            <JumboTextField
                                                fullWidth
                                                name="mobileno"
                                                label="Mobile No"
                                                onChange={handleChange}
                                                value={values?.mobileno}
                                                defaultValue={"Mobile No"}
                                            />
                                        </Div>
                                        <Div sx={{
                                            display: 'flex',
                                            marginTop: '1.5rem',
                                            justifyContent: 'end'
                                        }} className='profile'>
                                            <Button variant="outlined" sx={{
                                                marginBottom: '1rem',
                                                borderRadius: '24px',
                                                backgroundColor: '#B0BEC5',
                                                color: '#000000DE',
                                                textTransform: 'capitalize',
                                                ':hover': {
                                                    backgroundColor: '#B0BEC5',
                                                    color: '#000000DE',
                                                }
                                            }} onClick={handleCancel}>Cancel <BlockIcon sx={{ marginLeft: '5px', width: '15px', height: '15px' }} /></Button>
                                            <LoadingButton 
                                            type='submit'
                                            variant="contained"
                                            loading={loading}
                                            sx={{
                                                marginBottom: '1rem',
                                                borderRadius: '24px',
                                                backgroundColor: '#e48b1a',
                                                color: '#fff',
                                                marginLeft: '1rem',
                                                textTransform: 'capitalize',
                                                ':hover': {
                                                    backgroundColor: '#e48b1a',
                                                    color: '#fff',
                                                }
                                            }} 
                                            >Update</LoadingButton>
                                        </Div>
                                    </Form>
                                )
                            }
                        </Formik>
                    </CardContent>
                </Card>
            </Div>
        </Fragment>
    )
}

export default EditProfile;