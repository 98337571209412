import { Box, Button, Drawer, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'
import ClearIcon from "@mui/icons-material/Clear";
import Div from '@jumbo/shared/Div';
import { useState } from 'react';
import { Tableuploadder } from 'app/assets/svg/Svg';
const BeneficailOwnership = ({
    handleDrawerToggle,
    createdraw,
    handleDrawerClose,
}) => {
    const [age, setAge] = useState(10);
    const SelecthandleChange = (event) => {
        setAge(event.target.value);
      };
  return (

    

    <Drawer
    className="currentuser_draw bene_ownership"
    variant="temporary"
    anchor={"right"}
    open={createdraw}
    onClose={handleDrawerClose}
  > 
   <Box className="sidenave_box ">
          <div className="sidebar_header">
            <h1>Fill Beneficial Ownership </h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>
           
           <Div  sx={{p:"16px 28px"}}>

           <Div >
            <div className="d-flex items-center" style={{justifyContent:"flex-end"}}>
                <p>No of rows</p>
                <Select className="ml-10" style={{width:"70px"}} labelId="demo-simple-select-label"id="demo-simple-select"value={age}onChange={SelecthandleChange}>
                    <MenuItem value={10}>2</MenuItem>
                    <MenuItem value={20}>4</MenuItem>
                    <MenuItem value={30}>6</MenuItem>
                    </Select>
            </div>
          </Div>
          <Div sx={{mt:3}} className='benefical_div'>
    <table className="w-100 benifical_table">
        <thead> 
            <tr>
                <td rowSpan={2}>S.No</td>
                <td  rowSpan={2} >Full Name of Beneficial Owner/ controlling natural</td>
                <td rowSpan={2}>Date of Birth</td>
                <td rowSpan={2}>Nationality</td>
                <td rowSpan={2} >Address</td>
                <td colSpan={2} className='text-center'>Type of KYC Documents </td>
                
                <td rowSpan={2}>Controlling Ownership Interest (%)</td>
                <td rowSpan={2}>Photograph</td>
            </tr>
            <tr>
                <td>Identity</td>
                <td>Address</td>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td rowSpan={2}>1</td>
            <td rowSpan={2} ><TextField  className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Full Name" /></td>
            <td rowSpan={2} ><TextField  className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Date of Birth" /></td>
            <td rowSpan={2} ><TextField  className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Nationality" /></td>
            <td rowSpan={2} > <textarea placeholder='Enter Address' className="w-100 boder_bottom"  name="" id="" rows="2"></textarea></td>
            <td rowSpan={2} > 
            <TextField  className="table_input" id="standard-basic"  variant="standard" placeholder="Enter PAN Number" />
            <TextField  className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Aadhaar Number" />
            </td>
            <td rowSpan={2} > <textarea placeholder='Enter Address' className="w-100 boder_bottom"  name="" id="" rows="2"></textarea></td>
            <td rowSpan={2} ><TextField  className="table_input" id="standard-basic"  variant="standard" placeholder="Enter Controlling Ownership Interest (%)" /></td>
            <td style={{width:"15%"}}>
                <div className='uploader_card_table'>
                    <div className='uplodar_section'>
                        <Tableuploadder />

                    </div>
                    <div className='uploader_option'>
                        <h3>Upload Photo</h3>
                        <p>Use a JPG, PNG (250 kb)</p>

                    </div>


                </div>

            </td>

            
            </tr>
            
           
          
        </tbody>
        

    </table>
    


  </Div>


           </Div>
           <div className="modal_footer">
                      <div
                        className="d-flex w-100 items-center "
                        style={{ padding: "15px 30px", justifyContent:"flex-end" }}
                      >
                       

                        <div className="d-flex gap">
                          <Button
                            variant="contained"
                            color="primary"
                            className="submit_btn"
                           
                          >
                            Save
                          </Button>
                          <Button
                            className="back_btn"
                           
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </div>






          </Box>
    
    </Drawer>
  )
}

export default BeneficailOwnership