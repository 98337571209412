import React, { useState } from "react";
import {Button,Card,CardContent,FormControlLabel,Radio,RadioGroup,TextField,Typography,Autocomplete,Paper,} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Select from "react-select";
import * as yup from "yup";
import { useFormik } from "formik";
import Div from "@jumbo/shared/Div";
import FundTransferVerify from "../FundTransferVerfication/FundTransferVerify";

// Validations
const validationSchema = yup.object({
  accounttype: yup.string().required("Account Type is required"),
  transferto: yup.object().shape({
    label: yup.string().required("Transfer to is required"),
  }),
  amount: yup
    .number("Amount should be numeric")
    .required("Amount is required")
    .positive("Amount should be positive"),
    transtype: yup.string().required("Transaction type is required"),
});

// themes
const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} />;
};
const theme = createTheme({
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#333",
          "&.Mui-checked": {
            color: "#000",
          },
        },
      },
    },
  },
});

const FundTransferCreate = () => {
  const RemarksOptions = [
    { label: "Friends" },
    { label: "Family" },
    { label: "Other" },
  ];
  // useState
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState("option1");
  const [checked, setChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  /**multi select */
  const options = [
    {
      value: "Bhuvanesh Palanisamy",
      label: "Bhuvanesh Palanisamy",
      details: "NSDL Payment Bank | 501000544346",
    },
    {
      value: "Yuvaraj Thulasimani",
      label: "Yuvaraj Thulasimani",
      details: "HDFC Bank | 501000544346785",
    },
    {
      value: "Rajapandi",
      label: "Rajapandi",
      details: "Axis Bank | 501000544346",
    },
    {
      value: "Ajith Kumar Marimuthu",
      label: "Ajith Kumar Marimuthu",
      details: "Karur Vysya Bank | 501000544346785",
    },
    {
      value: "Brintha Ganeshan",
      label: "Brintha Ganeshan",
      details: "Karur Vysya Bank | 501000544346785",
    },
  ];
  // fuctions
  const initialValues = {
    accounttype: "",
    transferto: null,
    amount: "",
    transtype: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
      console.log("Form submitted:", values);
      handleDrawerToggle();
    },
  });
  function handleDrawerToggle(event) {
    setMobileOpen(!mobileOpen);
    event.preventDefault();
  }
  function handleDrawerClose() {
    setMobileOpen(false);
  }
  const customStyles = {
    control: (styles, { isFocused, isHovered }) => {
      const borderColor =
        formik.touched.transferto && formik.errors.transferto
          ? "red"
          : isFocused
          ? "blue"
          : isHovered
          ? "grey"
          : styles.borderColor;

      return {
        ...styles,
        borderColor,
        "&:hover": {
          borderColor:
            formik.touched.transferto && formik.errors.transferto
              ? "red"
              : "grey",
        },
      };
    },
  };
  const handleAutocompleteChange = (_, newValue) => {
    setSelectedOption(newValue);
  };
  const handleSelect = (selected) => {
    setSelectedOptions(selected);
    console.log(selected);
  };
  const handleSelectClick = (selected, e) => {
    setSelectedOptions(selected);
    console.log(selected, e.target.value);
  };
  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const customFilterOption = (option, rawInput) => {
    const inputValue = rawInput.toLowerCase();
    const label = option.label.toLowerCase();
    const details = option.details ? option.details.toLowerCase() : "";
    const value = option.value ? option.value.toLowerCase() : "";

    return (
      label.includes(inputValue) ||
      details.includes(inputValue) ||
      value.includes(inputValue)
    );
  };

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} className="transfer-select-val">
      <Typography sx={{ fontWeight: 700 }} variant="h4" fontweight="bold">
        {data.label}
      </Typography>
      <span sx={{ fontSize: 1.5 }}>{data.details}</span>
    </div>
  );

  return (
    <div>
      <section className="fund_transfer_create">
        <Card>
          <h2 className="fund_transfer_head">Fund Transfer</h2>
          <CardContent>
            <div className="single_payment">
              <form className="w-90" onSubmit={formik.handleSubmit} noValidate autoComplete="off"
              >
                <Div className="fund_transfer_form pt-0">
                  <ThemeProvider theme={theme}>
                    <Typography className="from_label">
                      Account Type <span className="required">*</span>
                    </Typography>
                    <RadioGroup  name="accounttype"value={formik.values.accounttype} onChange={formik.handleChange} onBlur={formik.handleBlur}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                    >
                      <FormControlLabel onClick={() => !checked}  value="nsdlbank" className="radio_form" control={
                          <Radio color={ selectedValue === "option1" ? "primary" : "default" }  />
                        }
                       label="NSDL Payment Bank"
                      />
                      <FormControlLabel value="others" onClick={() => !checked} className="radio_form ml-30" control={
                          <Radio color={ selectedValue === "option1"  ? "primary"  : "default"}
                          />
                        }
                        label="Other Bank"
                      />
                    </RadioGroup>
                    {formik.touched.accounttype &&  formik.errors.accounttype && (
                        <small className="error text-danger">
                          {formik.errors.accounttype}
                        </small>
                      )}
                  </ThemeProvider>
                </Div>
                {
                  /**************** * Select box with custom options and search bar ************/
                  <Div
                    className="flex-container bordertop fund_transfer_form"
                    sx={{ alignItems: "flex-end" }}
                  >
                    <div>
                      <Typography className="from_label">
                        Transfer to <span className="required">*</span>
                      </Typography>
                      <Select placeholder="Select Transfer To"  fullWidth  styles={customStyles}  name="transferto"  className="basic-single select_box"
                        classNamePrefix="select" options={options} value={selectedOptions} onChange={(selected) => {
                          formik.setFieldValue("transferto", selected);
                          handleSelect(selected);
                        }}  onClick={handleSelectClick} getOptionLabel={(option) => option.label}  getOptionValue={(option) =>
                          `${option.value || ""} ${option.details || ""}` }filterOption={customFilterOption} components={{Option: CustomOption,}}
                        onMenuOpen={handleMenuOpen}onMenuClose={handleMenuClose}menuIsOpen={isMenuOpen}/>
                      {formik.touched.transferto &&formik.errors.transferto && (
                          <small className="error text-danger">
                           Transfer to is required
                          </small>
                        )}
                    </div>
                    <div>
                      <Typography className="from_label bank_detail ml-25">
                        {selectedOptions.details}
                      </Typography>
                    </div>
                  </Div>
                  /********** * Select box with custom options and search bar ********************/
                }
                
                <Div className="bordertop fund_transfer_form">
                  <ThemeProvider theme={theme}>
                    <Typography className="from_label">
                      Transaction Type <span className="required">*</span>{" "}
                    </Typography>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="transtype"value={formik.values.transtype}onChange={formik.handleChange}onBlur={formik.handleBlur}
                    >
                      <FormControlLabel value="rtgs"className="radio_form"control={
                          <Radio color={selectedValue === "option1"? "primary": "default"}/>
                        }
                        label="IMPS"
                      />
                      <FormControlLabel value="neft"className="radio_form ml-25"control={
                          <Radio color={selectedValue === "option1"? "primary": "default"}/>
                        }label="NEFT"
                      />
                      <FormControlLabel value="imps"className="radio_form ml-25"control={<Radio color={selectedValue === "option1"? "primary": "default"}
                          />
                        }label="RTGS"
                      />
                    </RadioGroup>
                    {formik.touched.transtype && formik.errors.transtype && (<small className="error text-danger">{formik.errors.transtype}{" "}</small>
                    )}
                  </ThemeProvider>
                </Div>
                <Div className="flex-container bordertop fund_transfer_form"sx={{ alignItems: "flex-end" }}>
                  <div className=" w-395">
                    <Typography className="from_label">
                      Amount (₹)<span className="required">*</span>
                    </Typography>
                    <TextField name="amount" type="number" onChange={formik.handleChange} error={formik.touched.amount && Boolean(formik.errors.amount)} className="form_new_style amt_input"
                      variant="outlined"fullWidth placeholder="Enter Amount" />
                    {
                    formik.touched.amount && formik.errors.amount && (<small className="error text-danger">{formik.errors.amount}</small>
                    )}
                    <h6 className="fee_text">
                      Convenience Fee : (₹) 100.00 | Total Fund Transfer : <b>₹ 17,100</b> 
                    </h6>
                   </div>
                </Div>
                <Div className="bordertop fund_transfer_form">
                  <div>
                    <Typography className="from_label">
                      {" "}
                      Remarks <span className="required">*</span>
                    </Typography>
                    <Autocomplete disablePortalname="remarks"options={RemarksOptions}getOptionLabel={(option) => option.label}PaperComponent={CustomPaper}
                      className="basic-single select_box"classNamePrefix="select"value={selectedOption}onChange={handleAutocompleteChange}renderInput={(params) => (
                        <TextField placeholder="Select Remarks"className="form_new_style form_selct"fullWidth{...params} />
                      )}
                    />
                  </div>
                  {selectedOption && selectedOption.label === "Other" && (
                    <div className="mt-30 w-395">
                      <Typography className="from_label">
                        Other Remarks <span className="required">*</span>
                      </Typography>
                      <TextField placeholder="Enter Other Remarks" name="amount" className="form_new_style"variant="outlined"fullWidth
                      />
                    </div>
                  )}
                </Div>
                <Div className="bordertop ">
                  <Button className="primary_btn w-390" fullWidth type="submit"variant="contained"size="large"sx={{ mb: 3 }}>
                    Proceed
                  </Button>
                </Div>
              </form>
            </div>
          </CardContent>
        </Card>
      </section>
      <FundTransferVerify handleDrawerClose={handleDrawerClose} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}/>
    </div>
  );
};

export default FundTransferCreate;
