import React from "react";
import Page from "@jumbo/shared/Page";
import Login1 from "app/pages/auth-pages/login1/Login1";
import { adminRoutes, appRoutes } from "./appRoutes";
import ForgotPassword from "app/pages/auth-pages/forgotPassword/forgotPassword";
import ResetPassword from "app/pages/auth-pages/resetPassword/resetPassword";
import SetNewPassword from "app/pages/auth-pages/setNewPassword/setNewPassword";
import AdminForgetPassword from "app/pages/auth-pages/admin-pages/forget-password/AdminForgetPassword";
import AdminResetPassword from "app/pages/auth-pages/admin-pages/reset-password/AdminResetPassword";
import AdminSetPassword from "app/pages/auth-pages/admin-pages/set-password/AdminSetPassword";
import { useState } from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import AdminSuccessMessage from "app/pages/auth-pages/admin-pages/set-password/AdminSuccessMessage";
import AdminLogin from "app/pages/auth-pages/admin-pages/login/AdminLogin";
import SuccessMessage from "app/pages/auth-pages/setNewPassword/successMessage";
import SessionPage from "app/pages/auth-pages/saving-user/Sessionpage";
import SavingsUserHome from "app/pages/auth-pages/saving-user/Home";
import RegisterPage from "app/pages/auth-pages/saving-user/RegisterPage/RegisterPage";
/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    ...appRoutes,
    ...adminRoutes
    
];


const routesForNotAuthenticatedOnly = [
   /* {
        path: "/",
        element: <Page exact component={Login1} layout={"solo-page"} disableSmLogin={true}/>
    }, */
   
   {
        path: "/current-user/login",
        element: <Page  component={  Login1 } layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/current-user/forgot-password",
        element: <Page component={ForgotPassword} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/current-user/reset-password",
        element: <Page component={ResetPassword} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/current-user/set-password",
        element: <Page component={SetNewPassword} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/current-user/success-message",
        element: <Page component={SuccessMessage} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/current-user/reset-password/:token",
        element: <Page component={ResetPassword} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/admin",
        element: <Page component={AdminLogin} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/forget-password",
        element: <Page component={AdminForgetPassword} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/reset-password",
        element: <Page component={AdminResetPassword} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/set-password",
        element: <Page component={AdminSetPassword} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/success-message",
        element: <Page component={AdminSuccessMessage} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/saving-user",
        element: <Page component={SessionPage} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/saving-user/home",
        element: <Page component={SavingsUserHome} layout={"solo-page"} disableSmLogin={true}/>
    },
    {

            path: "/saving-user/register",
            element: <Page component={RegisterPage} layout={"solo-page"} disableSmLogin={true}/>
    }
   
];

const routesForAdmin = [
   ...adminRoutes
];
const routesForCurrent = [
    ...appRoutes
 ];
 

const routes = [
    ...routesForNotAuthenticatedOnly,
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForAdmin,
    ...routesForCurrent
];



export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly, routesForCurrent, routesForAdmin};

 
 