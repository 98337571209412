import Div from '@jumbo/shared/Div'
import { FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

import { SignatureUpload } from 'app/assets/svg/Svg';
import React from 'react'
import { useState } from 'react';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const theme = createTheme ({
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#333", // Change the color of the unselected radio button
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: "#666666", // Change the color of the radio label
          },
          checked: {
            color: "green", // Change the color of the selected radio button
          },
        },
      },
    },
  });
const CutsomerDecl = () => {
    const [selectedValue, setSelectedValue] = useState("option1");
    const [value, setValue] = useState(dayjs("2022-04-17"));
    const [profiles, setProfiles] = useState([
        { date: '', signature: null },
        {  date: '', signature: null },
        { date: '', signature: null },
      ]);

      const isValidImage = (file, maxWidth, maxHeight, allowedFormats) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
    
        return new Promise((resolve) => {
          image.onload = function () {
            const width = this.width;
            const height = this.height;
            const format = file.type.split('/')[1];



    
            const isValid =
              width <= maxWidth &&
              height <= maxHeight &&
              allowedFormats.includes(format.toLowerCase());
    
            resolve(isValid);
          };
        });
      };

      const handleFileChange = async (event, sectionIndex, fileType) => {

        const files = event.target.files;
        const file = files[0];

        const isValid = await isValidImage(file, 300, 300, ['jpeg', 'jpg', 'png']);


        if(isValid){
            const updatedProfiles = [...profiles];
            updatedProfiles[sectionIndex][fileType] = file;
            setProfiles(updatedProfiles);
        }
        else{

            
        }
 
        
      };
    
      const handleInputChange = (event, sectionIndex) => {
        const value = event.target.value;
        const updatedProfiles = [...profiles];
        updatedProfiles[sectionIndex].name = value;
        setProfiles(updatedProfiles);
      };
      const renderProfileSection = (profile, index) => (
        <Grid item   key={index} sm={3.5} > 
        <Div >
           <div className='signatur_upload'>
               <div className='sinature_uploader'>
               <input
           accept="image/*"
           style={{ display: 'none' }}
           id={`signature-uploader-${index}`}
           type="file"
           onChange={(e) => handleFileChange(e, index, 'signature')}/>
            {profile.signature ? (
           <img
           className='signature_image'
             src={URL.createObjectURL(profile.signature)}
             alt={`Signature Preview ${index + 1}`}
            
           />
         ) :  <SignatureUpload />}
                  

               </div>
               <div className='signatur_upload_details'>
               <label htmlFor={`signature-uploader-${index}`}>
         <h2 className='upload_image'>  {profile.signature ? 'Change signature' : 'Upload signature'} </h2>
         <p className='format'> {profile.signature ?  profile.signature.name : 'Use a JPG, PNG (250kb)'}</p>
         </label>
               </div>



           </div>

         </Div>
         <Div sx={{mt:14}}>
         <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography className="from_label">
              Date (DD/MM/ YYYY)
            </Typography>
            <DatePicker
              className="w-100"
              value={value}
              format="DD/MM/YYYY"
              onChange={(newValue) => setValue(newValue)}
            />
          </LocalizationProvider>
       
         </Div>
        
        </Grid>
     );
  return (
    <div>
        <p className='customer_decl_head'>(I) Under penalty of perjury, I/We certify that:</p>
        <Div>
            <ol className='customer_del_list'>
                <li className='mb-20'>The applicant is (i) an applicant taxable as a US person under the laws of the United States of America (“U.S.”) or any state or political subdivision thereof or therein, including the District of Columbia or any other states of the U.S., (ii) an estate, the income of which is subject to U.S. Federal Income tax regardless of the source thereof, or (This clause is applicable only if the account holder is identified as a U.S. person)</li>
                <li>
                The applicant is taxable as a tax resident under the laws of the country outside India (This clause is applicable only if the account holder is a tax resident outside of India) II) I/We understand that the Bank is relying on this information for the purpose of determining the status of the applicant in compliance with FATCA/CRS. The Bank is not able to offer any tax advice on FATCA or CRS or its impact on the applicant. I/We should seek advice from professional tax advisor for any tax questions (III) I/We agree to submit a new form within 30 days if any information or certification on this form becomes incorrect (IV)I/We agree that as may be required by regulatory authorities, the Bank may also be required to report reportable details to CBDT or close or suspend my account (V) I/We certify that I/we provide the information on this form and to the best of my/our knowledge and belief the certification is true, correct, and complete including the taxpayer identification number of the applicant.
                </li>
            </ol>


        </Div>
        <Div sx={{mt:4}}>
            <h2 className='declration_head'>PEP Declaration</h2>
            <p className='decl_sub_head'>I / We understand the definition of ‘Politically Exposed Persons’ (PEPs) as individuals who are or have been entrusted with prominent public functions including foreign country,e.g., Heads of States/Governments, senior politicians, senior government/judicial/military officers, senior executives of state-owned corporations, important political party officials, etc</p>

        </Div>
        <Div className='decl_radio' sx={{mt:1}}>
            <p >Are any of the Authorised Signatories or Beneficial Owners Politically Exposed Person (PEP) ?</p>
            <div>
            <ThemeProvider theme={theme}>
                     <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="yes"
            className="radio_form"
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="Yes"
          />
          <FormControlLabel
            value="No"
            className="radio_form "
            control={
              <Radio
                color={
                  selectedValue === "option2"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="No"
          />
        
         
        </RadioGroup>
      </ThemeProvider>
            </div>
            <p>(Please fill separate PEP declaration form in case applicant/s are PEP)</p>

        </Div>
        <Div sx={{mt:3}}>
        <h2 className='declration_head'>Name of the Entity</h2>
        <Grid sx={{mt:4}} container gap={"20px"}>
    {profiles.map((profile, index) => renderProfileSection(profile, index))}

    </Grid>
        </Div>

    </div>
  )
}

export default CutsomerDecl