import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardContent, IconButton, TextField, Typography, InputLabel, Stepper, Step, StepLabel, TablePagination, StepContent } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import Div from "@jumbo/shared/Div";
import FormControl from '@mui/material/FormControl';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from '@mui/x-date-pickers';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import CachedIcon from '@mui/icons-material/Cached';
import EastIcon from '@mui/icons-material/East';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useSelector } from 'react-redux';
import { config } from 'app/config/main';
import moment from 'moment';
import Link from "@mui/material/Link";
import { dayFormatter } from 'app/utils/formatHelpers';
import { FiArrowRight } from "react-icons/fi";
import { GrPowerReset } from "react-icons/gr";
const ActivityLog = () => {
    const [type, setType] = useState([]);
    const [typeId, setTypeId] = useState();
    const [page, setPage] = useState(0);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [activityTypes, setActivityTypes] = useState([]);
    const [data, setData] = useState();
    const [count, setCount] = useState();

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const token = useSelector(state => state.auth?.accessToken);

    const handleChange = (event) => {
        console.log(event.target.value)
        const {
            target: { value },
        } = event;
        setType(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const loadTodayData = async () => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        await fetch(`${config.apiUrl}/activity/log?limit=10&offset=1`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setData(res.data)
                setCount(res.count)
            })
    }
    const loadActivityTypes = async () => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        await fetch(`${config.apiUrl}/activity/type`, options)
            .then(res => res.json())
            .then((res) => {
                // console.log(res.data)
                setActivityTypes(res.data);
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        loadTodayData();
        loadActivityTypes();
    }, [])

    
    const StepIconRoot = styled('div')(() => ({
        backgroundColor: '#90A4AE',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    }));

    const StepIcon = () => {
        return (
            <StepIconRoot>
                <PersonOutlineIcon />
            </StepIconRoot>
        )
    }

    const handleSubmit = async () => {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        await fetch(`${config.apiUrl}/activity/log?toDate=${moment(toDate).format('yyyy-MM-DD')}&fromDate=${moment(fromDate).format('yyyy-MM-DD')}&limit=10&offset=1`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setData(res.data)
            })
    }

    const handleReset = () => {
        console.log('reset')
        setFromDate(null);
        setToDate(null);
        setType([]);
    }
    
    return (
        <Fragment>
          
            
            <Div>
                <Card className="responsivecard" sx={{p:"24px"}}>
                <Typography className='page_header' >{`${count} Activity Log `}</Typography>
                    
                        <Div>
                           <p className='activity_log_small'>Filter By</p>
                        </Div>
                        <Div sx={{
                            display: 'flex',
                            alignItems:"flex-end"
                        }}>
                            <Div>
                            <Typography className="from_label">From Date <span className='required'>*</span></Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        format='DD/MM/YYY'
                                        value={fromDate}
                                        onChange={(date) => setFromDate(date)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Div>
                            <Div sx={{ marginLeft: '1.5rem' }}>
                            <Typography className="from_label">To Date <span className='required'>*</span></Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        format='DD/MM/YYY'
                                        value={toDate}
                                        onChange={(date) => setToDate(date)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Div>
                            <Div sx={{ marginLeft: '1.5rem' }}>
                            <Typography className="from_label">Activity Type <span className='required'>*</span></Typography>
                                <Select
                                    multiple
                                    value={type}
                                    onChange={handleChange}
                                    // input={<OutlinedInput label="Select Activity Type" />}
                                    // label="Select Activity Type"
                                    renderValue={(selected) => selected.join(', ')}
                                    sx={{ minWidth: '14rem', color: '#475259', maxWidth: '14rem' }}
                                >
                                    {
                                        activityTypes?.map((item, index) => (
                                            <MenuItem key={index} value={item.activity_type_name}>
                                                <Checkbox checked={type.indexOf(item.activity_type_name) > -1} />
                                                <ListItemText primary={item?.activity_type_name} />
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </Div>
                            <Div >
                                <Button endIcon={<FiArrowRight />} className='activity_submit' onClick={handleSubmit}>Submit</Button>
                            </Div>
                            <Div >
                                <Button startIcon={<GrPowerReset />} className='activity_cancel' variant="outlined"  onClick={handleReset}>Reset</Button>

                            </Div>
                        </Div>
                  
                </Card>
                

             

            </Div>
            <Card sx={{p:"24px", mt:"40px"}}>
            <Div sx={{
                   
                }} className='activity'>
                    <TablePagination
                        count={count}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Div>

            <Div sx={{
                marginTop: '2rem'
            }}>
                {
                    data && data?.map((item) => {
                        return (
                            <div>
                                <Div >
                                    <p className='activity-p'>{dayFormatter(item.date)}</p>
                                </Div>
                                <Div sx={{
                                    marginTop: '2rem'
                                }}>
                                    <Stepper className='activity_step' activeStep={-1} orientation='vertical' >
                                        {
                                            item?.list?.map((i) => (
                                                <Step expanded={true}>
                                                    <StepLabel StepIconComponent={StepIcon}>
                                                        <div>
                                                            <p className='mb-0'>{moment(i?.created_at).format('MMM DD, hh:mm A')}</p>
                                                        </div>
                                                    </StepLabel>
                                                    <StepContent>
                                                        <Card className='activity_card_content'  >
                                                           
                                                                <Link className='title' underline={"none"} href="#/">{i?.activity_type_id?.activity_type_name}</Link>
                                                                <p>{i?.description}</p>
                                                           
                                                        </Card>
                                                    </StepContent>
                                                </Step>
                                            ))
                                        }
                                    </Stepper>
                                </Div>
                            </div>
                        )
                    })
                }

            </Div>

            </Card>



           
        </Fragment>
    )
}

export default ActivityLog;