import React from "react";
import LockResetIcon from '@mui/icons-material/LockReset';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { getModulePermission } from "app/utils/menuHelpers";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import WebhookIcon from '@mui/icons-material/Webhook';
import HomeIcon from '@mui/icons-material/Home';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { RiBankLine, RiHomeLine } from "react-icons/ri";
import { PiNotepad, PiUsers } from "react-icons/pi";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { PiNote } from "react-icons/pi";
import { PiHandCoins } from "react-icons/pi";
import { PiReceiptLight } from "react-icons/pi";
import { FiSettings } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { GoQuestion } from "react-icons/go";
let portal = sessionStorage.getItem('portal')
let USER_MENU = [
    {
      
        type: 'section',
    }
];

portal = null ?? 'admin';

if (portal === 'user') {
    await getModulePermission().then(res => { USER_MENU = res })
}

const ADMIN_MENU = [
    // {
    //     uri: "/settings/manage-roles",
    //     label: 'Manage Roles',
    //     type: "nav-item",
    //     icon: <ListAltIcon style={{fontSize :"24px"}} />
    // },
    // {
    //     uri: "/settings/manage-users",
    //     label: 'Manage Users',
    //     type: "nav-item",
    //     icon: <AccountCircleIcon style={{fontSize :"24px"}} />
    // },
    // {
    //     uri: "/settings/roles-permissions",
    //     label: 'Roles & Permissions',
    //     type: "nav-item",
    //     icon: <LockPersonIcon style={{fontSize :"24px"}} />
    // },
    // {
    //     uri: "/settings/activity-log",
    //     label: 'Activity Log',
    //     type: "nav-item",
    //     icon: <EditCalendarIcon style={{fontSize :"24px"}} />
    // },
    // {
    //     uri: "/change-password",
    //     label: 'Change Password',
    //     type: "nav-item",
    //     icon: <LockResetIcon style={{fontSize :"24px"}} />
    // },
    // {
    //     uri: "/settings/manage-users-with-react-hook",
    //     label: 'Manage Users with React Hook Form',
    //     type: "nav-item",
    //     icon: <WebhookIcon style={{fontSize :"24px"}} />
    // },
]

const menus = [
    {
        label: '-',
        type: 'section',
        children: [
            {
                uri: "/curent-user/dashboard",
                label: 'Dashboard',
                type: "nav-item",
                icon: <RiHomeLine style={{fontSize :"24px"}} />
            },
            {
                uri: "/beneficiary",
                label: 'Beneficiary',
                type: "nav-item",
                icon: <PiUsers style={{fontSize :"24px"}} />
            },
            {
                uri: "/bank-account-verification",
                label: 'Bank A/C Verification',
                type: "nav-item",
                icon: <RiBankLine style={{fontSize :"24px"}} />
            },
            

            {
                uri: "/fund-transfer",
                label: 'Fund Transfer',
                type: "nav-item",
                icon: <LiaRupeeSignSolid style={{fontSize :"24px"}} />
            },
            {
                uri: "/transactionlist",
                label: 'Transactions',

                type: "nav-item",
                icon: <PiNote style={{fontSize :"24px"}} />
            },
            {
                uri: "/faq",
                label: 'FAQ',

                type: "nav-item",
                icon: <GoQuestion style={{ fontSize: "24px" }} />
            },
            {
                uri: "/terms-and-conditions",
                label: 'Terms & Conditions',

                type: "nav-item",
                icon: <PiNotepad style={{ fontSize: "24px" }} />
            },
            // {
            //     uri: "",
            //     label: 'Settings',

            //     type: "nav-item",
            //     icon: <IoSettingsOutline  style={{fontSize :"24px"}} />
            // },

        ]
    },
    {
        label: '-',
        type: 'section',
        children: portal === "admin" ? ADMIN_MENU : USER_MENU
    }
];

export default menus;
