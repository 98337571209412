import React, { Fragment, useState } from "react";
import Div from "@jumbo/shared/Div";
import {
  CardContent,
  Card,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  Pagination,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from "@mui/material";
import SearchGlobal from "app/shared/SearchGlobal/SearchGlobal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { config } from "app/config/main";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { hasActionPermissionList } from "app/utils/menuHelpers";
import { CiSearch } from "react-icons/ci";
import { IoMdEye } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import debounce from "lodash/debounce";
import SkeletonRolesList from "app/pages/skeleton/SkeletonRolesList";
import NoRecordsFound from "app/pages/app-pages/component/noRecordsFound";
import SkeletonTableLoader from "../component/skeleton_loader/skeletonTableLoader";
import Search from "../component/search";
import { ToastServices } from "app/services/toastServices";
import BackdropLoader from "../component/loader";
import axiosInstance from "app/services/auth/axiosInstance";
const ManageRoles = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = React.useState(0);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [current, setCurrent] = useState();
  const [createAccess, setCreate] = useState(false);
  const [updateAccess, setUpdate] = useState(false);
  const [deleteAccess, setDelete] = useState(false);
  const [statusAccess, setStatus] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [loader, setLoader] =useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalItems, setTotalItems] = useState(0);

  const portal = useSelector((state) => state?.user?.userData?.portal);

  

  const token = useSelector((state) => state.auth?.accessToken);

  const getRoleData = async (page, limit, search) => {
    setLoader(true)
    const response = await axiosInstance.get(`/role/list`)
    console.log( "/role/list",response.data.data)
    setLoader(false)
    setData(response.data.data)
    setCount(response.data.count);
    setTotalItems(response?.data.totalPages)
    // const options = {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // };
    // setLoader(true)
    // await fetch(`${config.apiUrl}/role/list`, options)
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setLoader(false)
    //     console.log(res);
    //     setData(res.data);
    //     setCount(res.count);
    //     setTotalItems(res?.totalPages)
    //   });
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setCurrent(item);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const TableData = [
    {
      sno: "01",
      role: "Project Manger",
      status: 1,
    },
    {
      sno: "02",
      role: "Project Co-ordinator",
      status: 0,
    },
    {
      sno: "03",
      role: "Project Leader",
      status: 1,
    },
    {
      sno: "04",
      role: "Software Developer",
      status: 0,
    },
    {
      sno: "05",
      role: "UI Designer",
      status: 1,
    },
    {
      sno: "06",
      role: "UX Designer",
      status: 0,
    },
    {
      sno: "07",
      role: "Automation Tester",
      status: 1,
    },
    {
      sno: "08",
      role: "Manual Tester",
      status: 0,
    },
  ];
  const handleCreate = () => {
    navigate("/settings/manage-roles/create");
  };

  const handleUpdate = () => {
    setAnchorEl(null);
    navigate(`/settings/manage-roles/update/${current.id}`);
  };

  const handleDelete = async () => {
    try {
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      await fetch(`${config.apiUrl}/role/delete/${current.id}`, options)
        .then((res) => res.json())
        .then(
          (res) => {
            console.log(res);
            if (res.status === true) {
              toast.success("Role Deleted Successfully");
              getRoleData();
            } else {
              toast.error(res.message);
            }
            setAnchorEl(null);

            setModalOpen(true);
          },
          (err) => {
            console.log(err);
          }
        );
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatus = async () => {

    try {
      const bodyData = {
        id: current.id,
        status: current.status === 1 ? 0 : 1,
      };
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      };
      setLoader(true)
      await fetch(`${config.apiUrl}/role/status`, options)
        .then((res) => res.json())
        .then(
          (res) => {
            console.log(res);
            if (res.status === true) {
              setLoader(false)
              ToastServices.SucessToast("Role status Updated Successfully");

              getRoleData();
            } else {
              toast.error(res.message);
              setLoader(false)
            }
            setAnchorEl(null);
          },
          (err) => {
            console.log(err);
          }
        );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    getRoleData(currentPage, itemsPerPage, searchQuery);

  },[currentPage, itemsPerPage])

  useEffect(() => {
    if (portal === "admin") {
      setCreate(true);
      setUpdate(true);
      setDelete(true);
      setStatus(true);
    } else {
      hasActionPermissionList("role_create").then((res) => {
        setCreate(res);
      });
      hasActionPermissionList("role_update").then((res) => {
        setUpdate(res);
      });
      hasActionPermissionList("role_delete").then((res) => {
        setDelete(res);
      });
      hasActionPermissionList("role_status").then((res) => {
        setStatus(res);
      });
    }

  }, []);

  const terms = ["role"];

  const [filteredData, setFilteredData] = useState(TableData);
  const [loading, setLoading] = useState(false);

  const handleSearch = (filteredData, loading) => {
    // Do something with the filtered data
    setLoading(loading);
    setFilteredData(filteredData);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };
  const handlePaginationChange = (event, page) => {
    setCurrentPage(page);
  };
  return (
    <div>
     
      <Card className="responsivecard">
        <CardContent>
          <div
            className="page_header_option space-between mb-30"
            style={{ alignItems: "baseline" }}
          >
            <div className="d-flex items-center space-between w-100">
              <div className="d-flex items-center ">
              <Typography
                className="page_header"
                variant={"h2"}
              >{`${8} Manage Roles `}</Typography>
              <Typography
                className="create_btn"
                variant={"h2"}
                onClick={handleCreate}
              >
                + Add new
              </Typography>
              </div>
              
              {/* <div className="ml-20">
                <Search
                  data={TableData}
                  onSearch={handleSearch}
                  terms={terms}
                />
              </div> */}
            </div>
          </div>

          <Div>
            <Table className="common_table" id="tablePopover">
              <TableHead>
                <TableRow>
                  <TableCell>Roles</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                   data.map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>
                          {item?.status == 1 ? (
                            <span className="admin_active_status">
                              {" "}
                              <span className="active_dot"></span> Active
                            </span>
                          ) : (
                            <span className="admin_inactive_status">
                              <span className="inactive_dot"></span> Inactive
                            </span>
                          )}
                        </TableCell>
                        {(updateAccess === true ||
                          deleteAccess === true ||
                          statusAccess === true) && (
                          <TableCell>
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => handleClick(e, item)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="fade-menu"
                              className="custom_popover"
                              MenuListProps={{
                                "aria-labelledby": "fade-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              TransitionComponent={Fade}
                            >
                              {updateAccess === true && (
                                <MenuItem onClick={(e) => handleUpdate()}>
                                  Update
                                </MenuItem>
                              )}
                              {statusAccess === true && (
                                <MenuItem onClick={() => handleStatus()}>
                                  {current?.status === 1
                                    ? "Mark as Inactive"
                                    : "Mark as Active"}
                                </MenuItem>
                              )}
                              {deleteAccess === true && (
                                <MenuItem onClick={() => handleDelete()}>
                                  Delete
                                </MenuItem>
                              )}
                            </Menu>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                }
               
              </TableBody>
              {/* <TableFooter>
              <div className="pagination_containner">
            <div className="count_page" >
              <span>Rows per page :</span>
            <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
            <option value="5">5 </option>
            <option value="10">10</option>
            <option value="20">20 </option>
            <option value="30">30 </option>
          </select>
            </div>
         
          <Stack spacing={2}>
            <Pagination
              count={totalItems}
              page={currentPage}
              onChange={handlePaginationChange}
            />
          </Stack>
          </div>
              </TableFooter> */}
            </Table>
          </Div>
        </CardContent>
      </Card>
      {
        loader == true ? <>  <BackdropLoader  /> </> : ""
      }
      
    </div>
  );
};

export default ManageRoles;
