import React from "react";
import Page from "@jumbo/shared/Page";
import PermissionList from "../pages/app-pages/permissionList";
import ManageRoles from "../pages/app-pages/manageRoles/ManageRoles";
import ManageUsers from "../pages/app-pages/manageUsers/ManageUsers";
import RolesList from "app/pages/app-pages/rolesList/RolesList";
import ChangePassword from "app/pages/app-pages/changePassword/ChangePassword";
import ActivityLog from "app/pages/app-pages/activityLog/ActivityLog";
import NotificationList from "app/pages/app-pages/notificationList/NotificationList";
import CreateRole from "app/pages/app-pages/manageRoles/CreateRole/CreateRole";
import UpdateRole from "app/pages/app-pages/manageRoles/UpdateRole/UpdateRole";
import CreateUser from "app/pages/app-pages/manageUsers/CreateUser/CreateUser";
import UpdateUser from "app/pages/app-pages/manageUsers/UpdateUser/UpdateUser";
import EditProfile from "app/pages/app-pages/editProfile/EditProfile";
import ManageUsersWithHook from "app/pages/app-pages/manageUsersWithHookForm/ManageUsersWithHook";
import CreateUserWithHook from "app/pages/app-pages/manageUsersWithHookForm/CreateUser/CreateUserWithHook";
import UpdateUserWithHook from "app/pages/app-pages/manageUsersWithHookForm/UpdateUser/UpdateUsersWithHook";
import BeneficiariesList from "app/pages/app-pages/Beneficiaries/BeneficiarieList/BeneficiariesList";
import TransactionList from "app/pages/app-pages/transactions/transactionList/transactionList";
import Faq from "app/pages/app-pages/Profile/faq";
import TermsConditions from "app/pages/app-pages/Profile/termsConditions";
import Test from "app/pages/admin-pages/Test";
import FindIfsc from "app/pages/app-pages/Beneficiaries/BaneficiaryCreate/findIfsc";
import CurrentUserList from "app/pages/admin-pages/CurrentUser/CurrentUserList";
import HomeSkeleton from "app/pages/skeleton/dashboard_skeleton";
import BackdropLoader from "app/pages/app-pages/component/loader";
import AdminDashboard from "app/pages/admin-pages/dashboard/dashboard";
import TransactionsList from "app/pages/admin-pages/transactions/TransactionsList";
import NotFound from "app/pages/auth-pages/error-pages/notFound";
import FundTransferCreate from "app/pages/app-pages/FundTransfer/FundTranserCreate/FundTransferCreate";
import ManageCommission from "app/pages/app-pages/Commission/ManageCommission";
import ManageSavingsUser from "app/pages/app-pages/savingsUser/ManageSavingsUser";
import ManageTransactions from "app/pages/admin-pages/Transaction/ManageTransaction";
import CreateCurrentForm from "app/pages/admin-pages/CurrentUser/CreateCurrentForm/CreateCurrentForm";
import BankAcVerfiyList from "app/pages/app-pages/BankAccountVerify/List/BankAcVerfiyList";
import CurrentUserDetailedList from "app/pages/admin-pages/CurrentUser/CurrentUserDetailedList";
import BeneficiaryViewPage from "app/pages/app-pages/Beneficiaries/BeneficiaryView/BeneficiaryViewPage";
import NotificationListAdmin from "app/pages/admin-pages/Notifications/NotificationList";
import AdminChangePassword from "app/pages/admin-pages/changePassword/changePassword" 
import CreateCommissionnew from "app/pages/app-pages/settings/CommissionCreatednew";
import Home from "app/pages/app-pages/home/Home";
import BeneTransactionList from "app/pages/admin-pages/CurrentUser/benetransactionList";
import UpdateCurrentuserNew from "app/pages/admin-pages/CurrentUser/Update-current-user/UpdateCurrentuserNew";
import savingsUserHome from "app/pages/auth-pages/saving-user/Home";
 

const appRoutes = [
    {
        path: "/",
        element: <Page component={Home} />
    },
    {
        path: "/curent-user/dashboard",
        element: <Page component={Home} />
    },
    {
        path: "/beneficiary",
        element: <Page component={BeneficiariesList} />
    },
    {
        path: "/bank-account-verification",
        element: <Page component={BankAcVerfiyList} />
    },
    {
        path: "/fund-transfer",
        element: <Page component={FundTransferCreate} />
    },
    {
        path: "/transactionlist",
        element: <Page component={TransactionList} />
    },
    {
        path: "/faq",
        element: <Page component={Faq} />
    },
    {
        path: "/terms-and-conditions",
        element: <Page component={TermsConditions} />
    },
    {
        path: "/beneficiary/view/overalltransactions",
        element: <Page component={BeneficiaryViewPage} />
    },
    {
        path: "/ifsc",
        element: <Page component={FindIfsc} />
    },
    {
        path: 'settings/roles-permissions',
        element: <Page component={RolesList} />
    },
    {
        path: 'settings/roles-permissions/permission-list/:id',
        element: <Page component={PermissionList} />
    },
    {
        path: 'settings/manage-roles',
        element: <Page component={ManageRoles} />
    },
    {
        path: 'settings/manage-roles/create',
        element: <Page component={CreateRole} />
    },
    {
        path: 'settings/manage-roles/update/:id',
        element: <Page component={UpdateRole} />
    },
    {
        path: 'settings/manage-users',
        element: <Page component={ManageUsers} />
    },
    {
        path: 'settings/manage-users/create',
        element: <Page component={CreateUser} />
    },
    {
        path: 'settings/manage-users/update/:id',
        element: <Page component={UpdateUser} />
    },
    {
        path: "/change-password",
        element: <Page component={ChangePassword} />
    },
    {
        path: "settings/activity-log",
        element: <Page component={ActivityLog} />
    },
    {
        path: "notification-details",
        element: <Page component={NotificationList} />
    },  
    {
        path: "change-profile",
        element: <Page component={EditProfile} />
    },
    {
        path: 'settings/manage-users-with-react-hook',
        element: <Page component={ManageUsersWithHook} />
    },
    {
        path: 'settings/manage-users-with-react-hook/create',
        element: <Page component={CreateUserWithHook} />
    },
    {
        path: 'settings/manage-users-with-react-hook/update/:id',
        element: <Page component={UpdateUserWithHook} />
    },
    {
        path: 'home/skeleton',
        element: <Page component={HomeSkeleton} />
    },
    {
        path: "/loader",
        element: <Page component={BackdropLoader} />
    },
    {
        path: "*",
        element: <Page component={NotFound}  />
    },
  
];

const adminRoutes = [

    // {
    //     path: "/loader",
    //     element: <Page component={BackdropLoader} />
    // },
    {
        path: "/dashboard-demo",
        element: <Page component={Test} layout={"admin-layout"} />
    },
    {
        path: "/admin-dashboard",
        element: <Page component={AdminDashboard} layout={"admin-layout"}/>
    },
    {
        path: "/current-user",
        element: <Page component={CurrentUserList} layout={"admin-layout"} />
    },
    {
        path: "/current-user/update",
        element: <Page component={UpdateCurrentuserNew} layout={"admin-layout"} />
    },
    {
        path: "/current-user/view/:id",
        element: <Page component={CurrentUserDetailedList} layout={"admin-layout"} />
    },
    {
        path: "/savings-user",
        element : <Page component={ManageSavingsUser} layout={"admin-layout"} />
    },   
    {
        path: "/transactions",
        element : <Page component={ManageTransactions} layout={"admin-layout"} />
    },
    {
        path: "/commissions",
        element : <Page component={ManageCommission} layout={"admin-layout"} />
    },
    {
        path: "settings/commissions/update",
        element : <Page component={CreateCommissionnew} layout={"admin-layout"} />
    },
    
    {
        path:"/settings/change-password",
        element : <Page component={AdminChangePassword} layout={"admin-layout"} />
    },
     
    {
        path:"*",
        element : <Page component={TransactionsList} layout={"admin-layout"} />
    },
    {
        path: "*",
        element: <Page component={NotFound}   layout={"admin-layout"} />
    },
    {
        path: "/notifications",
        element: <Page component={NotificationListAdmin}   layout={"admin-layout"} />
    },
     {

        path: "/create-current-user",
        element: <Page component={CreateCurrentForm}   layout={"admin-layout"} />
    },
    {
        path: "/Notifications",
        element: <Page component={NotificationListAdmin}   layout={"admin-layout"} />
    },
    {
        path: "/beneficiary/overalltransactions",
        element: <Page component={BeneTransactionList}  layout={"admin-layout"}/>
    },

   
]


const savingsUserRoutes = [
    {
        path:"/savinguser/home",
        element: <Page component={savingsUserHome}/>
    }
]

export  {appRoutes, adminRoutes, savingsUserRoutes};
