import {LAYOUT_CONTAINER_STYLES} from "@jumbo/utils/constants/layout";
import {mainTheme} from "../themes/main/default";
import {headerTheme} from "../themes/header/default";
import {sidebarTheme} from "../themes/sidebar/default";
import {footerTheme} from "../themes/footer/default";
import LAYOUT_NAMES from "../layouts/layouts";
import {createJumboTheme} from "@jumbo/utils";
import jwtAuthAxios from "../services/auth/jwtAuth";
import authServices from "../services/auth-services";

 const portal =sessionStorage.getItem("portal" )

const config = {

    defaultLayout:  portal == 'admin' ? LAYOUT_NAMES.ADMIN_LAYOUT :  LAYOUT_NAMES.VERTICAL_DEFAULT  ,
    containerStyle: LAYOUT_CONTAINER_STYLES.FLUID,

    authSetting: {
        axiosObject: jwtAuthAxios,
        fallbackPath: portal == 'admin' ? "/admin" : "/current-user/login",
        getAuthUserService: authServices.getCurrentUser,
        redirectNotAuthenticatedPath:  portal == 'admin' ? "/admin" : "/current-user/login"
    },

    theme: createJumboTheme(mainTheme, headerTheme, sidebarTheme, footerTheme),

    apiUrl: 'http://192.168.43.41:4000'
  //  apiUrl: 'http://[::1]:4000'
    
};

export {config};
