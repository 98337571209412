import Div from '@jumbo/shared/Div'
import { Button, FormControlLabel, Grid, Radio, RadioGroup, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { SignatureUpload, Uploader } from 'app/assets/svg/Svg';
import { ToastServices } from 'app/services/toastServices';
import React from 'react'
import { useState } from 'react';

const Declaration = () => {
    const [selectedValue, setSelectedValue] = useState("option1");
    const theme = createTheme({
        components: {
          MuiRadio: {
            styleOverrides: {
              root: {
                color: "#333", // Change the color of the unselected radio button
              },
              checked: {
                color: "green", // Change the color of the selected radio button
              },
            },
          },
          MuiFormControlLabel: {
            styleOverrides: {
              label: {
                color: "#666666", // Change the color of the radio label
              },
              checked: {
                color: "green", // Change the color of the selected radio button
              },
            },
          },
        },
      });

    //   const [imageArray, setImageArray] = useState([null, null, null]);

    //   const handleFileChange = (event, index) => {
    //     const files = event.target.files;
    //     const updatedArray = [...imageArray];
    //     updatedArray[index] = files[0];
    //     setImageArray(updatedArray);
    //   };
    
    //   const handleDeleteImage = (index) => {
    //     const updatedArray = [...imageArray];
    //     updatedArray[index] = null;
    //     setImageArray(updatedArray);
    //   };


    //   const renderImageDiv = (file, index) => (

    //     <>
      
    //     <Grid key={index} item sm={3.5}>
    //         <div className='image_uploader'>
                
    //             <div className='uploader'>
    //                {
    //                 file ?   <img className='imag_prvew' src={URL.createObjectURL(file)} alt={`Preview ${index + 1}`} />  : <Uploader />
    //                } 

    //             </div>
    //             <div className='uploader_details'>
    //                 {
    //                     file ?   <div>
    //                     <input
    //                       accept="image/*"
    //                       style={{ display: 'none' }}
    //                       id={`image-uploader-input-${index}`}
    //                       type="file"
    //                       onChange={(e) => handleFileChange(e, index)}
    //                     />
    //                     <label htmlFor={`image-uploader-input-${index}`}>
    //                       <h2 className='upload_image' >
    //                         Change Image
    //                       </h2>
    //                     </label>
    //                     <h3
    //                      className='format'
                          
                          
    //                     >
    //                       {file.name}
    //                     </h3>
    //                   </div> : 
    //                     <>
    //                     <input
    //                       accept="image/*"
    //                       style={{ display: 'none' }}
    //                       id={`image-uploader-input-${index}`}
    //                       type="file"
    //                       onChange={(e) => handleFileChange(e, index)}
    //                     />
    //                     <label htmlFor={`image-uploader-input-${index}`}>
    //                       <h2 className='upload_image'>
    //                         Upload Photo
    //                       </h2>
    //                       <p className='format'>Use a JPG, PNG (250kb)</p>
    //                     </label>
    //                   </>
    //                 }
                

    //             </div>

    //         </div>



    //     </Grid>
       




    //     {/* <div key={index} style={{ marginTop: '20px', textAlign: 'center' }}>
    //       {file ? (
    //         <>





    //           <img src={URL.createObjectURL(file)} alt={`Preview ${index + 1}`} style={{ maxWidth: '100%' }} />
    //           <div>
    //             <input
    //               accept="image/*"
    //               style={{ display: 'none' }}
    //               id={`image-uploader-input-${index}`}
    //               type="file"
    //               onChange={(e) => handleFileChange(e, index)}
    //             />
    //             <label htmlFor={`image-uploader-input-${index}`}>
    //               <Button variant="contained" component="span">
    //                 Change Image
    //               </Button>
    //             </label>
    //             <Button
    //               variant="outlined"
    //               color="secondary"
    //               onClick={() => handleDeleteImage(index)}
    //               style={{ marginLeft: '10px' }}
    //             >
    //               Delete Image
    //             </Button>
    //           </div>
    //         </>
    //       ) : (
    //         <>
    //           <input
    //             accept="image/*"
    //             style={{ display: 'none' }}
    //             id={`image-uploader-input-${index}`}
    //             type="file"
    //             onChange={(e) => handleFileChange(e, index)}
    //           />
    //           <label htmlFor={`image-uploader-input-${index}`}>
    //             <Button variant="contained" component="span">
    //               Add Image
    //             </Button>
    //           </label>
    //         </>
    //       )}
    //     </div> */}
    //     </>

    //   );
    const [profiles, setProfiles] = useState([
        { profilePic: null, name: '', signature: null },
        { profilePic: null, name: '', signature: null },
        { profilePic: null, name: '', signature: null },
      ]);

      const isValidImage = (file, maxWidth, maxHeight, allowedFormats) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
    
        return new Promise((resolve) => {
          image.onload = function () {
            const width = this.width;
            const height = this.height;
            const format = file.type.split('/')[1];



    
            const isValid =
              width <= maxWidth &&
              height <= maxHeight &&
              allowedFormats.includes(format.toLowerCase());
    
            resolve(isValid);
          };
        });
      };

      const handleFileChange = async (event, sectionIndex, fileType) => {

        const files = event.target.files;
        const file = files[0];

        const isValid = await isValidImage(file, 300, 300, ['jpeg', 'jpg', 'png']);


        if(isValid){
            const updatedProfiles = [...profiles];
            updatedProfiles[sectionIndex][fileType] = file;
            setProfiles(updatedProfiles);
        }
        else{

          ToastServices.ErroToast("Image dimenssion must be 300 X 300")

            
        }
 
        
      };
    
      const handleInputChange = (event, sectionIndex) => {
        const value = event.target.value;
        const updatedProfiles = [...profiles];
        updatedProfiles[sectionIndex].name = value;
        setProfiles(updatedProfiles);
      };
      
      const handleSubmit = () => { 
       
        profiles.forEach((profile, index) => {
          console.log(`Profile ${index + 1}:`);
          console.log('Name:', profile.name);
          console.log('Profile Picture:', profile.profilePic);
          console.log('Signature:', profile.signature);
          console.log('---');
        });

        console.log(profiles , ">>>>>>>>>>>>>>")
    
       
      };
      const renderProfileSection = (profile, index) => (
         <Grid item   key={index} sm={3.5} > 
         <div>
         <div className='image_uploader'>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id={`profile-pic-uploader-${index}`}
            type="file"
            onChange={(e) => handleFileChange(e, index, 'profilePic')}

          />
          <div className='uploader'>
          {profile.profilePic ? (
            <img
            className='imag_prvew'
              src={URL.createObjectURL(profile.profilePic)}
              alt={`Profile Preview ${index + 1}`}
              
            />
          ) :  <Uploader />}
          </div>
          </div>
          <div className='uploader_details'>
          <label htmlFor={`profile-pic-uploader-${index}`}>
          <h2 className='upload_image'>  {profile.profilePic ? 'Change Photo' : 'Upload Photo'} </h2>
          <p className='format'> {profile.profilePic ?  profile.profilePic.name : 'Use a JPG, PNG (250kb)'}</p>
          </label>

          </div>
         </div>
          <Div sx={{mt:4}}>
          <Typography className="from_label">
          Enter Name
          </Typography>
          <TextField
            className='m-0'
            variant="outlined"
            margin="normal"
            fullWidth
            value={profile.name}
            onChange={(e) => handleInputChange(e, index)}
          />
          </Div>
          <Div sx={{mt:4}}>
            <div className='signatur_upload'>
                <div className='sinature_uploader'>
                <input
            accept="image/*"
            style={{ display: 'none' }}
            id={`signature-uploader-${index}`}
            type="file"
            onChange={(e) => handleFileChange(e, index, 'signature')}/>
             {profile.signature ? (
            <img
            className='signature_image'
              src={URL.createObjectURL(profile.signature)}
              alt={`Signature Preview ${index + 1}`}
             
            />
          ) :  <SignatureUpload />}
                   

                </div>
                <div className='signatur_upload_details'>
                <label htmlFor={`signature-uploader-${index}`}>
          <h2 className='upload_image'>  {profile.signature ? 'Change signature' : 'Upload signature'} </h2>
          <p className='format'> {profile.signature ?  profile.signature.name : 'Use a JPG, PNG (250kb)'}</p>
          </label>
                </div>



            </div>

          </Div>
          


         

          
          
         
    
          
    
          
         
         
        </Grid>
      );
      
  return (
    <div>
        <h1 className='decleation_head'>Operating Instructions</h1>

        <Div sx={{mt:3}}>
            <ol className='decleation_list'>
                <li>I/We wish to avail the banking facilities/products from NSDL Payments Bank Limited (NSDL Payments Bank) and have read, understood and agree to the Terms and Conditions displayed on the website of NSDL Payments Bank i.e. www.nsdlbank.com, which may be amended by NSDL Payments Bank from time to time and hosted and notified on the website of NSDL Payments Bank.</li>
                <li>I/We have read understood and agree to the charges/cost mentioned in the extant Schedule of Charges. This Schedule of Charges is displayed on www.nsdlbank.com</li>
                <li>I/We agree to abide by and be bound by applicable rules/regulations/instruction/guidelines issued by the Reserve Bank of India, and under the FEMA Regulations, 2000 governing EEFC Accounts, the Foreign Exchange Management Act, 1999 and Foreign Account Tax Compliance Act, 2010 (to the extent applicable to India) and the Common Reporting Standards (CRS), in force from time to time. I/We have declared our status as per the rules applicable under section 285BA of the Income Tax Act, 1991 (the Act) as notified by Central Board of Direct Taxes (CBDT) in this regard.</li>
                <li>.I/We authorize NSDL Payments Bank to conduct my/our credit history verification with CIBIL or any other credit rating agency and acknowledge that NSDL Payments Bank shall have the right and authority to carry out investigations from the information available in public domain for confirming the information provided by me/us to NSDL Payments Bank.</li>
                <li>
                I/We agree to furnish and intimate to NSDL Payments Bank any other particulars that we are called upon to provide on account of any change in law/statutory requirements either in India or abroad. I/We authorize NSDL Payments Bank to exchange, share or part with all the information provided herein with financial institutions/agencies/statutory bodies/other such persons, as may be required by NSDL Payments Bank. I/We shall not hold NSDL Payments Bank or its agents/representatives liable for using/sharing such information.
                </li>
                <li>
                I/We hereby declare that the information provided herein as well as in the documentary evidence provided by me/us to NSDL Payments Bank (the “Customer Information”) is true, correct and complete in all aspects to the best of my/our knowledge and that I/We have not withheld any Customer Information that may affect the assessment/categorization of the account as a Reportable account or otherwise. I/We further agree that any false/misleading Customer Information given by me/us or suppression of any material fact will render my/our account liable for closure and the bank shall have the right to initiate any action, under law or otherwise.
                </li>
                <li>
                If any of the information provided here is incorrect, I/We hereby agree to indemnify and keep indemnified NSDL Payments Bank, affiliates and their successors or assignss
                </li>
                <li>I/We agree and understand that NSDL Payments Bank reserves the right to reject my/our account opening application form/request without assigning any reason thereof and without being liable to me/us in any manner whatsoever</li>
                <li>I, being the Sole Proprietor of the Sole Proprietorship Concern (as mentioned above) hereby agree and consent to avail the Current Account of NSDL Payments Bank and further agree to absolutely abide by all the terms and conditions in respect thereof, as may be notified by NSDL Payments Bank from time to time</li>
                <li>
                All fees/charges to be paid shall be exclusive of goods and services tax (GST), as may be applicable
                </li>
                <li>
                NSDL Payments Bank will determine if I/We are related party based on documents available or submitted for this purpose
                </li>
                <li>
                NSDL Payments Bank will determine the location of service provided which shall be binding on me/us.
                </li>
                <li>
                I/We shall provide the Bank with the details of exemption or lower rate of tax, if any supported by relevant documents prior to availment of services.
                </li>
                <li>
                I am fully aware that the bank sends SMS alerts on all account/card related transactions promptly on the mobile number shared at the time of account op
                </li>
                <li>
                I/We am/are fully aware that in case an Account is opened on the basis of Aadhaar Enrolment Number provided by me/us then I/We am/are obliged to submit the final Aadhaar number to the Bank within 60 days of opening of t
                </li>


            </ol>


        </Div>
        <Div sx={{ mt: 3 }}>
      <ThemeProvider theme={theme}>
        <Typography className="from_label">
        Would you like NSDL Payments Bank to contact you and tell you about new products, features and offers?
        </Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="Yes"
            className="radio_form"
            control={
              <Radio
                color={
                  selectedValue === "option1"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="Yes"
          />
          <FormControlLabel
            value="No"
            className="radio_form "
            control={
              <Radio
                color={
                  selectedValue === "option1"
                    ? "primary"
                    : "default"
                }
              />
            }
            label="No"
          />
        </RadioGroup>
      </ThemeProvider>
       </Div>
      
   
       


       {/* <Grid container gap={"20px"}>
       {imageArray.map((file, index) => renderImageDiv(file, index))}
       </Grid > */}



    <Grid sx={{mt:4}} container gap={"20px"}>
    {profiles.map((profile, index) => renderProfileSection(profile, index))}

    </Grid>
  
  






      




        
    </div>
  )
}

export default Declaration