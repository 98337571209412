import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { MdOutlineFileUpload } from "react-icons/md";
const DocumentsUpload = () => {
  return (
    <div>
        <div className='create_form_width'>
        <Typography className="from_label">
            Upload below documents (max 250kb)
            </Typography>
            <Grid container gap={"24px"}>
                <Grid item sm={6}>
                <Button className='documnets_upload' variant="outlined">Aadhar Card</Button>
                   
                </Grid>
                <Grid item sm={4}>
                <Button className='documents_file_upload' variant="outlined" startIcon={<MdOutlineFileUpload />}>Upload Aadhar Card  </Button>
                <span className='upload_format'>Use a JPG, PNG (250 kb)</span>
                   
                </Grid>
                <Grid item sm={6}>
                <Button className='documnets_upload' variant="outlined">PAN Card</Button>
                   
                </Grid>
                <Grid item sm={4}>
                <Button className='documents_file_upload uploaded' variant="outlined" startIcon={<MdOutlineFileUpload />}>Uploaded  </Button>
                <span className='upload_format'>document.pdf</span>
                   
                </Grid>
                <Grid item sm={6}>
                <Button className='documnets_upload' variant="outlined">Account Opening Form</Button>
                   
                </Grid>
                <Grid item sm={4}>
                <Button className='documents_file_upload uploaded' variant="outlined" startIcon={<MdOutlineFileUpload />}>Uploaded  </Button>
                <span className='upload_format'>document.pdf</span>
                   
                </Grid>

            </Grid>
        </div>

    </div>
  )
}

export default DocumentsUpload