import React from 'react';
import { useNavigate} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import { AppSvgLogon, LOGINBGSVG1, LoginBGSVG } from 'app/assets/svg/Svg';
import CREDITS from '../../../assets/nsdl_logo.png'
import { SucessGifLogin } from 'app/components/SucessGif/SucessGif';

const SuccessMessage = () => {
    const navigate = useNavigate();

    function handleClick(event) {
      event.preventDefault()
      navigate("/current-user/login", { replace: true });
      } 

    return (
<Div className='current_user_session '>
   <div className='h-100'>
      <div className='app_logo_svg '>
         <AppSvgLogon  />
      </div>
      <div className='current_user_session_card'>
         <div className="current_session_form justify-between h-100">
            <div className='w-100 '>
               <Div sx={{ mt: 4, textAlign:"center" } }>
                  <div className='login_gif'><SucessGifLogin /></div>
                   <p className="sucess_greeting text-center">
                  You have successfully reset your password. Please use
                  your new password when logging in.
               </p>
            </Div>
         </div>
         <Div  className='w-100'>
            <LoadingButton  className="login_btn"  fullWidth  type="submit"  variant="contained"  size="large" sx={{ mb: 3 }} onClick={handleClick}>
            Go to Login
            </LoadingButton>
         </Div>
      </div>
   </div>
   <div className="credits_logo mt-25" >
      <p>Powered by</p>
      <img width={98} height={40} src={CREDITS} alt="credits" />
   </div>
</div>
<div className='charcter_svg'>
   <LOGINBGSVG1 />
</div>
<div className='mobile_svg'>
   <LoginBGSVG />
</div>
</Div>
);
};

export default SuccessMessage;
