import "./style.css"; 
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Paper,
  TextField,
  Typography,
  Grid,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import USER from '../../../assets/user_img.jpg'
import {Card} from "@mui/material";
import { useNavigate } from "react-router-dom";



const CustomPaper = (props) => {
  return <Paper elevation={8} {...props} />;
};

const CurrentUserDetailedView = ({ handleDrawerToggle, handleDrawerClose, mobileOpen }) => {
  const navigate=useNavigate();
    return (
        <div>
          <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <Box className='sidenave_box' sx={{ width: "520px" }}>
          <div className="sidebar_header">
            <h1>View Beneficiary</h1>
            <ClearIcon sx={{ cursor: "pointer" }} onClick={handleDrawerClose} />
          </div>

            <div className="beneficarty_add_details">
              <table className="w-100 beneficary_details">
                <tr>
                  <td className="table_label">Beneficiary Name</td>
                  <td className="table_value">Siva Ramar</td>
                </tr>
                <tr>
                  <td className="table_label">Beneficiary Bank</td>
                  <td className="table_value">HDFC Bank</td>
                </tr>
                <tr>
                  <td className="table_label">Account Number</td>
                  <td className="table_value">5010007865490</td>
                </tr>
                <tr>
                  <td className="table_label">IFSC Code</td>
                  <td className="table_value">HDFC0987182</td>
                </tr>
                </table>
                     
                <Grid item lg={5} xs={12}>
                <Card className="bene" sx={{ height: "710px",borderRadius:'0 !important'    }}>
                  <Div className=" p-22 " sx={{padding:'5px 2px 5px 22px !important'}}>
                    <div className="recent_transaction_header">
                      <p>Recent 10 Transactions</p>
                      <a className='result_card bene'  onClick={()=>{navigate('/beneficiary/overalltransactions')}}><h6>View all</h6></a>
                    </div>
                  </Div>
                  <div className="recent_search_result">
                <div>
                  <Div className="recent_months_div">
                    <p>September 2023</p>
                  </Div>
                  <div className="main_results">
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Sathya Jyothi</h4>
                          <p>Paid on 22/01/2024, 7:53:34 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 420.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Gowtham K</h4>
                          <p>Paid on 22/01/2024, 7:53:34 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 420.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Vijay Kumar</h4>
                          <p>Paid on 22/01/2024, 7:53:34 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 120.50 </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="recent_months_div">
                    <p>August 2023 </p>
                  </div>
                  <div className="main_results">
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Sathya Jyothi</h4>
                          <p>Paid on 22/01/2024, 7:53:34 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 420.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Gowtham K</h4>
                          <p>Paid on 22/01/2024, 7:53:34 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 420.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Vijay Kumar</h4>
                          <p>Paid on 22/01/2024, 7:53:34 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 120.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Vijay Kumar</h4>
                          <p>Paid on 22/01/2024, 7:53:34 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 120.50 </p>
                    </div>
                    <div className="result_card space-between d-flex items-start">
                      <div className="d-flex items-center">
                        <div className=" resultcard_details">
                          <h4>Vijay Kumar</h4>
                          <p>Paid on 22/01/2024, 7:53:34 PM</p>
                        </div>
                      </div>
                      <p className="reasult_mony ">₹ 120.50 </p>
                    </div>
                  </div>
                </div>
              </div>
    
               </Card>
              </Grid>

            </div>
         </Box>
      </Drawer>
        </div>
      );
    };

export default CurrentUserDetailedView;
