import React, { useState, useEffect } from "react";
import Div from "@jumbo/shared/Div";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
  Button,
  FormControl,
  Popover,
  Select,
  MenuItem,
  TableSortLabel,
} from "@mui/material";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import "./style.css";
import Search from "app/pages/app-pages/component/search";
import CommissionView from "./CommissionView";
import SkeletonTableLoader from "app/pages/app-pages/component/skeleton_loader/skeletonTableLoader";
import NoRecordsFound from "app/pages/app-pages/component/noRecordsFound";
import DyanamicDatePicker from "app/components/DatePicker/DyanamicDatePicker";
import { FileIcon, RefreshIcon } from "app/assets/svg/Svg";
import { FilterIcon } from "app/assets/svg/Svg";

const ManageCommission = () => {
  const navigate = useNavigate();

  const TableData = [
    {
      sno: "01",
      transactionId: "6301957864",
      name: "Siva Ramar",
      remarks: "Friends and Family",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "1,000.00",
      commission: "100.00",
      balance: "1,100.00",
      sender: "Jayshri Tiwari",
      mobile: "78945 61230",
      percentage: 10,
      method: "UPI",
      email: "siva@gmail.com",
    },
    {
      sno: "02",
      transactionId: "2730175548",
      name: "Vishwas Patel",
      remarks: "Entertainment",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "2,000.00",
      commission: "100.00",
      balance: "2,100.00",
      sender: "Jayshri Tiwari",
      mobile: "78945 61230",
      percentage: 5,
      method: "Card",
      email: "Patel@gmail.com",
    },
    {
      sno: "03",
      transactionId: "1696086371",
      name: "Navjot Kaur",
      remarks: "Investment",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "1,000.00",
      commission: "200.00",
      balance: "1,200.00",
      sender: "Siva Ramar",
      mobile: "78945 61230",
      percentage: 10,
      method: "UPI",
      email: "navjot@gmail.com",
    },
    {
      sno: "04",
      transactionId: "3954212189",
      name: "Mamta Lodhi",
      remarks: "Food",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "2,000.00",
      commission: "100.00",
      balance: "2,100.00",
      sender: "Vishwas Patel",
      mobile: "78945 61230",
      percentage: 5,
      method: "UPI",
      email: "mamtalodhi@gmail.com",
    },
    {
      sno: "05",
      transactionId: "0500042814",
      name: "Paartho Ghosh",
      remarks: "Entertainment",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "1,000.00",
      commission: "200.00",
      balance: "1,200.00",
      sender: "Navjot Kaur",
      mobile: "78945 61230",
      percentage: 10,
      method: "UPI",
      email: "ghosh@gmail.com",
    },
    {
      sno: "06",
      transactionId: "7725068610",
      name: "Bhavna Goyal",
      remarks: "Food",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "2,000.00",
      commission: "100.00",
      balance: "2,100.00",
      sender: "Jayshri Tiwari",
      mobile: "78945 61230",
      percentage: 5,
      method: "Card",
      email: "bhavnagoyal@gmail.com",
    },
    {
      sno: "07",
      transactionId: "1501341233",
      name: "Jayshri Tiwari",
      remarks: "Entertainment",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "2,000.00",
      commission: "100.00",
      balance: "2,100.00",
      sender: "mamta Lodhi",
      mobile: "78945 61230",
      percentage: 5,
      method: "Card",
      email: "jayshri@gmail.com",
    },
    {
      sno: "08",
      transactionId: "3910793817",
      name: "Ramesh Gupta",
      remarks: "Savings",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "1,000.00",
      commission: "200.00",
      balance: "1,200.00",
      sender: "Paartho Ghosh",
      mobile: "78945 61230",
      percentage: 10,
      method: "UPI",
      email: "paartho@gmail.com",
    },
    {
      sno: "09",
      transactionId: "1501341233",
      name: "Jayshri Tiwari",
      remarks: "Entertainment",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "2,000.00",
      commission: "100.00",
      balance: "2,100.00",
      sender: "Ramesh Gupta pandey sharma",
      mobile: "78945 61230",
      percentage: 5,
      method: "UPI",
      email: "tiwari@gmail.com",
    },
    {
      sno: "10",
      transactionId: "3910793817",
      name: "Ramesh Gupta pandey sharma",
      remarks: "Savings for the gold investments",
      create_on: "30/06/2023",
      time: "08:30 AM",
      amount: "1,000.00",
      commission: "200.00",
      balance: "1,200.00",
      sender: "Jayshri Tiwari",
      mobile: "78945 61230",
      percentage: 10,
      method: "UPI",
      email: "rameshGupta@gmail.com",
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [type, setType] = useState("Type");
  const [method, setMethod] = useState("Method");
  const [filteredData, setFilteredData] = useState(TableData);
  const [loading, setLoading] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const terms = ["name", "remarks", "transactionId"];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDrawerToggle() {
    setMobileOpen(true);
  }

  function handleDrawerClose() {
    setMobileOpen(false);
  }

  const handleSearch = (filteredData, loading) => {
    setLoading(loading);
    setFilteredData(filteredData);
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <section>
      <Card className="responsivecard">
        <CardContent>
          <div
            className="common_header d-flex mb-0 mt-0 space-between "
            style={{ alignItems: "baseline" }}
          >
            <div className="d-flex items-center">
              <h2> Commission (112) </h2>
            </div>

            <div className="d-flex items-center">
              <div className="ml-20 ">
                {" "}
                <Search
                  data={TableData}
                  onSearch={handleSearch}
                  terms={terms}
                />{" "}
              </div>

              <div className="ml-20 mr-20">
                <DyanamicDatePicker />
              </div>

              <div>
                {/** filter  */}
                <div className="marginsettings">
                  <Popover
                    className="date_popover"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                  >
                    <div className="date_popover_inner">
                      <Select
                        className={type == "Type" ? "select-box mb-20" : 'select-box-select mb-20'}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        onChange={handleChange}
                        fullWidth
                      >
                        <MenuItem selected value={"Type"}>
                          Filter by Transaction Type
                        </MenuItem>
                        <MenuItem value={0}>NEFT</MenuItem>
                        <MenuItem value={1}>RTGS</MenuItem>
                        <MenuItem value={2}>IMTS</MenuItem>
                      </Select>

                      <div>
                        <Button className="datefilter_btn" variant="outlined">
                          {" "}
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Popover>
                  <FormControl className="" variant="outlined">
                    <Tooltip title="Filter">
                      <IconButton
                        onClick={handleClick}
                        className="icon_btn "
                        variant="outlined"
                      >
                        <FilterIcon />
                      </IconButton>
                    </Tooltip>
                  </FormControl>
                </div>
                {/** filter  */}
              </div>

              <div>
                <Tooltip title="SVG icon Refresh">
                  <IconButton className="ml-10 icon_btn" variant="outlined">
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="ml-20">
                <Button
                  startIcon={<FileIcon />}
                  className="export_btn width-settings"
                  variant="outlined"
                >
                  {" "}
                  Export as Excel
                </Button>
              </div>
            </div>
          </div>

          <Div>
            <Table className="common_table">
              <TableHead>
                <TableRow>
                  <TableCell> <TableSortLabel>Created On </TableSortLabel></TableCell>
                  <TableCell> Txn.Id </TableCell>
                  <TableCell> <TableSortLabel>Commission From </TableSortLabel></TableCell>
                  <TableCell> <TableSortLabel>Mobile No </TableSortLabel></TableCell>
                  <TableCell> <TableSortLabel>Sender Name </TableSortLabel></TableCell>
                  <TableCell className="amt_head"> Txn.Amount (₹)  </TableCell>
                  <TableCell className="amt_head"> Commission (%) </TableCell>
                  <TableCell className="amt_head"> Commission (₹) </TableCell>
                  <TableCell className="amt_head"> Total Amount (₹) </TableCell>
                  <TableCell align="center"> Action </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  // Display the skeleton loader while data is loading
                  <SkeletonTableLoader tablecell="10" row="8" />
                ) : // Display the actual table content when data is loaded
                filteredData.length == 0 ? (
                  <NoRecordsFound tablecell="7" />
                ) : (
                  filteredData.map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{item?.create_on} {item?.time} </TableCell>
                        <TableCell className="Link"  onClick={handleDrawerToggle}>{item?.transactionId}</TableCell>
                        <TableCell> 
                          {item?.sender.length > 15 ? (
                            <Tooltip title={item?.sender}>
                              <span>{`${item?.sender.slice(0, 13)}...`}</span>
                          </Tooltip>
                          ) : (
                            <span>{item?.sender}</span>
                          )} 
                         </TableCell>
                        <TableCell>{item?.mobile}</TableCell>
                        <TableCell >
                        {item?.name.length > 15 ? (
                            <Tooltip title={item?.name}>
                              <span>{`${item?.name.slice(0, 13)}...`}</span>
                          </Tooltip>
                          ) : (
                            <span>{item?.name}</span>
                          )} 
                        </TableCell>
                        <TableCell className="amt_cell">
                          {item?.amount}
                        </TableCell>

                        <TableCell className="amt_cell ">
                          {item?.percentage}
                        </TableCell>
                        <TableCell className="amt_cell ">
                          {item?.commission}
                        </TableCell>
                        <TableCell className="amt_cell">
                          {item?.balance}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label=" View "
                            className="icon"
                            onClick={handleDrawerToggle}
                          >
                            <RemoveRedEyeRoundedIcon className="icon" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={15}
                  rowsPerPage={5}
                  page={1}
                />
              </TableFooter>
            </Table>
          </Div>
        </CardContent>
      </Card>

      <CommissionView
        handleDrawerClose={handleDrawerClose}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
    </section>
  );
};

export default ManageCommission;
