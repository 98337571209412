import React , {useState} from 'react';
import ReactApexChart from 'react-apexcharts';

const MonthLineChart = () => {
  const [chart, setChart] = useState(0);
  const formatAmount = (value) => {
    if (value < 100000) {
      return (value / 1000).toFixed(0) + "k";
    } else if (value < 10000000) {
      return (value / 100000).toFixed(1) + "L";
    } else {
      return (value / 10000000).toFixed(1) + "cr";
    }
  };
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const currentYear = currentDate.getFullYear();
  const currentMonthName = months[currentDate.getMonth()];
  
  console.log(currentMonthName);
  const daysInMonth = new Date(currentDate.getFullYear(), currentMonth + 1, 0).getDate();
  console.log(currentMonth, daysInMonth)
  const activeColor = "#FFC275";
  const disabledColor = "#ACE9F0";
  const getCategoryColor = (index) => {
    return index <= daysInMonth ? activeColor : disabledColor;
  };
  const handleChangeChart = (e) => {
    setChart(e.target.value);
  };

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 5,
        colors: {
            backgroundBarColors: ["#FFC275"],
          backgroundBarOpacity: 0.2,
        },
      },
      colors: ["#7CDFD3"],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString()),
      title: {
        text: `Month( ${currentMonthName} ${currentYear} )`,
        style: {
          fontSize: "16px", // Change the font size of the y-axis title
          fontWeight: "500", // Change the font weight of the y-axis title
          fontFamily: "Inter", // Change the font family of the y-axis title
        },
      },
      labels: {
        style: {
          fontSize: "12px", // Change the font size of the y-axis labels
          fontWeight: "500", // Change the font weight of the y-axis labels
          fontFamily: "Inter", // Change the font family of the y-axis labels
        },
      },
    },
    yaxis: {
        title: {
            text: "Amount (₹)",
            style: {
                fontSize: "16px", // Change the font size of the y-axis title
                fontWeight: "500", // Change the font weight of the y-axis title
                fontFamily: "Inter", // Change the font family of the y-axis title
              },
          },
          min: 28000,
          max: 300000,
          labels: {
            formatter: function (val) {
              return formatAmount(val);
            },
            style: {
              fontSize: "12px", // Change the font size of the y-axis labels
              fontWeight: "500", // Change the font weight of the y-axis labels
              fontFamily: "Inter", // Change the font family of the y-axis labels
            },
          },
    },
    fill: {
      opacity: 1,

      colors: Array.from({ length: 12 }, (_, i) => getCategoryColor(i)),
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {

        const label = w.config.xaxis.categories[dataPointIndex];
        series = series[seriesIndex][dataPointIndex];
       const roundedSeries = Number(series).toFixed(2);
       return `  <div class="custom-tooltip">
       <span class="labels">${label + "/3/2024"}</span> 
       <div class="border-line">
         <div class="value"><span class="amount">₹ ${roundedSeries} </span></div>
       </div>
     </div>`
      }
  },}
  const series = [
    {
      name: "",
      data: [300000, 150000, 120000, 180000, 200000, 160000, 220000, 150000, 120000, 150000, 120000, 110000,300000, 150000, 120000, 180000, 200000, 160000, 220000, 150000, 120000, 150000, 120000, 110000,300000, 150000, 120000, 180000, 200000, 160000, 220000],
    },
  ];

  return (
    <ReactApexChart
                      options={options}
                      series={series}
                      type="bar"
                      height={500}
                    />
  );
};

export default MonthLineChart; 