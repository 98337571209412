import React, { Fragment, useState } from "react";
import {
  Card,
  CardContent,
  IconButton,
  TextField,
  Typography,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import "./style.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { CommissionImg } from "app/assets/svg/Svg";
import { ToastServices } from "app/services/toastServices";

const CreateCommissionnew = () => {
  const [cards, setCards] = useState([]);
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateData = () => {
    ToastServices.SucessToast('Set Commission Updated SucessFully')
  };
  
  const handleDeleteCard = (index) => {
    const updatedCards = [...cards];
    updatedCards.splice(index, 1);
    setCards(updatedCards);
  };

  const handleAddCard = () => {
    const newCard = {
      // Add properties for your new card as needed
    };
    setCards([...cards, newCard]);
  };


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
 

  const CommissionCard = (index) => {
    return (
      <>
        <Typography className="mt-20" variant="h5" fontWeight="500">
          Slab 1
        </Typography>
        <Card key={index} className="mt-20 dynamic_card">
          <CardContent className="">
            <Grid
              container
              className="mr_10"
              alignItems="flex-end"
              justifyContent="right"
            >
              <IconButton onClick={handleDeleteCard} aria-label="delete">
                <ClearOutlinedIcon className="close-icon" />
              </IconButton>
            </Grid>

            <Div sx={{ display: "flex", alignItems: "flex-end" }}>
              <Div>
                <Typography className="from_label">
                  From Amount(₹)<span className="text-danger"> * </span>{" "}
                </Typography>
                <TextField
                  name="from"
                  placeholder="Enter From Amount"
                  className="form_new_style ml-20"
                  id="textin"
                  variant="outlined"
                  fullWidth
                />
              </Div>
              <Div className="ml-10">
                <Typography className="from_label ">
                  To Amount(₹)<span className="text-danger"> * </span>
                </Typography>
                <TextField
                  name="from"
                  placeholder="Enter To Amount"
                  className="form_new_style ml-20"
                  id="textin"
                  variant="outlined"
                  fullWidth
                />
              </Div>
              <Div className="ml-10">
                <Typography className="from_label">
                  Commission(%)<span className="text-danger"> * </span>
                </Typography>

                <TextField
                  name="commission"
                  placeholder="Enter Commission Percentage"
                  className="form_new_style ml-20 "
                  id="textin"
                  variant="outlined"
                  fullWidth
                />
              </Div>
            </Div>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <Fragment>
      <Div>
        <Card className="responsivecard">
          <CardContent>
            <Grid container>
              <Grid item>
                <Typography className="page_header">{`Update Set Commission`}</Typography>
                {/* Feature added */}
                <Tabs
                  sx={{ width: "750px", marginTop: "20px" }}
                  value={value}
                  onChange={handleChange}
                  disableRipple
                  className="tabs_section pin"
                  aria-label="basic tabs example"
                >
                  <Tab
                    disableRipple
                    className="tab_title"
                    label="IMPS"
                    value={1}
                    {...a11yProps(0)}
                  />
                  <Tab
                    disableRipple
                    className="tab_title ml-50"
                    label="NEFT"
                    value={2}
                    {...a11yProps(1)}
                  />
                  <Tab
                    disableRipple
                    className="tab_title ml-50"
                    label="RTGS"
                    value={3}
                    {...a11yProps(1)}
                  />
                </Tabs>

                {/* Feature added */}

                <CommissionCard key={1} />

                {cards.map((card, index) => (
                  <>
                    <CommissionCard key={index} />
                  </>
                ))}

                <Grid container className="mt-20">
                  <Typography
                    className="text_btn ml-5"
                    variant={"h2"}
                    onClick={handleAddCard}
                  >
                    + Add more
                  </Typography>
                </Grid>

                <Grid container className="mt-20 w-70" justifyContent="right">
                  <Grid item>
                    <Button className="primary_btn width_fit" fullWidth
                     type="submit" variant="contained" size="large" onClick={updateData}
                      // endIcon={<FiArrowRight />}
                    >
                      {" "}
                      Update{" "}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommissionImg />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Div>
    </Fragment>
  );
};

export default CreateCommissionnew;
