import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import Div from "@jumbo/shared/Div";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CurrentContext } from "../../Context/CurrentUserContext";
import { useContext } from "react";

const steps = [
  "Organisation Details",
  "Customer Profile",
  "Nominee",
  "Application",
  "Authorised Signatory ",
  "Credit Facilities",
  "Declaration",
  "Annexure 5",
  "Standing Instruction Request",
  "FATCA /CRS Declaration",
  "Customer Declaration",
];

const theme = createTheme({
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#333", // Change the color of the unselected radio button
        },
        checked: {
          color: "green", // Change the color of the selected radio button
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: "#666666", // Change the color of the radio label
        },
        checked: {
          color: "green", // Change the color of the selected radio button
        },
      },
    },
  },
});

const options = [
  { label: "Option 1", value: "option1" },
  { label: "Option 2", value: "option2" },
  { label: "Option 3", value: "option3" },
];

const OrganisationDetails = () => {
  const {formik} = useContext(CurrentContext)
  // const formik = useFormik({
  //      initialValues: {
  //     nameOftheOrg: "",
  //     accountTitle: "",
  //     accountType: "",
  //     pan: "",
  //     dateOfReg: null,
  //     registrationNo: "",
  //     cityOfIncroporation: "",
  //     annualTurnover:"",

  //     selectedOption: null,
  //   },
  //   validationSchema: Yup.object({
  //     nameOftheOrg: Yup.string().required("Name of org is required"),
  //     accountTitle: Yup.string().required("Account title is required"),
  //     accountType: Yup.string().required("Account Type is required"),
  //     pan: Yup.string().required("Account Type is required"),
  //     dateOfReg: Yup.date().required("Date of Birth is required"),
  //     registrationNo: Yup.string().required("registrationNo is required"),
  //     cityOfIncroporation: Yup.string().required(
  //       "cityOfIncroporation is required"
  //     ),
  //     annualTurnover: Yup.string().required("Account Type is required"),
  //     // selectedOption: Yup.object().nullable().required('Option is required'),
   
  //   }),
  //   onSubmit: (values) => {
  //     // Handle form submission logic here
  //     console.log("Form submitted with values:", values);
  //   },
  // });

  const [activeStep, setActiveStep] = useState(0);
  const [checked, setChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState("option1");
  const [value, setValue] = useState(dayjs("2022-04-17"));
  const enitity = [
    { label: "Proprietorship" },
    {
      label: "Proprietorship 1",
    },
    {
      label: "Proprietorship 2",
    },
  ];
  const NatureofBusiness = [
    { label: "Others" },
    {
      label: "Others 1",
    },
    {
      label: "Others 2",
    },
  ];
  const Year = [
    { label: "5" },
    {
      label: "4",
    },
    {
      label: "6",
    },
  ];
  const Month = [
    { label: "5" },
    {
      label: "4",
    },
    {
      label: "6",
    },
  ];
  const city = [
    {
      label: "Madurai",
    },
    {
      label: "Chennai",
    },
    {
      label: "Theni",
    },
  ];
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isLastStep = activeStep === steps.length - 1;

  const handleStepClick = (stepIndex) => {
    if (stepIndex < activeStep) {
      setActiveStep(stepIndex);
    }
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="create_form_width">
          <Div>
            <Typography className="from_label">
              Name of the Organization <span>*</span>
            </Typography>
            <TextField
              className="form_new_style"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              name="nameOftheOrg"
              {...formik.getFieldProps("nameOftheOrg")}
              error={
                formik.touched.nameOftheOrg &&
                Boolean(formik.errors.nameOftheOrg)
              }
              helperText={
                formik.touched.nameOftheOrg && formik.errors.nameOftheOrg
              }
            />
          </Div>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">
              Acoount Title <span>*</span>
            </Typography>
            <TextField
              className="form_new_style"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              name="accountTitle"
              {...formik.getFieldProps("accountTitle")}
              error={
                formik.touched.accountTitle &&
                Boolean(formik.errors.accountTitle)
              }
              helperText={
                formik.touched.accountTitle && formik.errors.accountTitle
              }
            />
          </Div>
          <Div sx={{ mt: 3 }}>
            <FormControl>
              <Div sx={{ mt: 3 }}>
                <ThemeProvider theme={theme}>
                  <Typography className="from_label">Account Type</Typography>

                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      name="accountType"
                      value={formik.values.accountType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <FormControlLabel
                        value="operative_current_account"
                        control={<Radio />}
                        label="Operative Current Account"
                      />
                      <FormControlLabel
                        value="earnest_money_deposit"
                        control={<Radio />}
                        label="Earnest Money Deposit (EMD)"
                      />
                      <FormControlLabel
                        value="nodal_account"
                        control={<Radio />}
                        label="NODAL Account"
                      />
                      <FormControlLabel
                        value="poa_account"
                        control={<Radio />}
                        label="POA Account"
                      />
                    </RadioGroup>
                    {formik.touched.accountType &&
                      formik.errors.accountType && (
                        <div style={{ color: "red" }}>
                          {formik.errors.accountType}
                        </div>
                      )}
                  </FormControl>
                </ThemeProvider>
              </Div>
            </FormControl>
          </Div>

          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">
              PAN <span>*</span>
            </Typography>
            <TextField
              className="form_new_style"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              name="pan"
              {...formik.getFieldProps("pan")}
              error={formik.touched.pan && Boolean(formik.errors.pan)}
              helperText={formik.touched.pan && formik.errors.pan}
            />
          </Div>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">
              
              Date of incorporation / Registration
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="w-100"
                value={formik.values.dateOfReg}
                onChange={(value) => formik.setFieldValue("dateOfReg", value)}
                format="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      formik.touched.dateOfReg && formik.errors.dateOfReg
                    }
                    value={
                      formik.values.dateOfReg
                        ? dayjs(formik.values.dateOfReg).format("DD/MM/YYYY")
                        : ""
                    }
                    onChange={(event) => {
                      formik.setFieldValue(
                        "dateOfReg",
                        event.target.value
                          ? dayjs(event.target.value, "DD/MM/YYYY").toDate()
                          : null
                      );
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Div>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">
               Registration / CIN No<span>*</span>
            </Typography>
            <TextField
              className="form_new_style"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              {...formik.getFieldProps("registrationNo")}
              name="registrationNo"
              error={
                formik.touched.registrationNo &&
                Boolean(formik.errors.registrationNo)
              }
              helperText={
                formik.touched.registrationNo && formik.errors.registrationNo
              }
            />
          </Div>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">
              City of Incorporation / Registration<span>*</span>
            </Typography>
            <TextField
              className="form_new_style"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              name="cityOfIncroporation"
              {...formik.getFieldProps("cityOfIncroporation")}
              error={
                formik.touched.cityOfIncroporation &&
                Boolean(formik.errors.cityOfIncroporation)
              }
              helperText={
                formik.touched.cityOfIncroporation &&
                formik.errors.cityOfIncroporation
              }
            />
          </Div>
          <Div sx={{ mt: 3 }}>
            <FormControl>
              <Div sx={{ mt: 3 }}>
                <ThemeProvider theme={theme}>
                  <Typography className="from_label">
                    Annual Turnover <span>*</span>
                  </Typography>

                  <FormControl>
                    <RadioGroup
                      row
                      name="annualTurnover"
                      value={formik.values.annualTurnover}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <FormControlLabel
                        value="<5cr"
                        control={<Radio />}
                        label="<5cr"
                      />
                      <FormControlLabel
                        value="5-25cr"
                        control={<Radio />}
                        label="5-25cr"
                      />
                      <FormControlLabel
                        value=">25-50cr"
                        control={<Radio />}
                        label=">25-50cr"
                      />
                      <FormControlLabel
                        value=">50-100cr"
                        control={<Radio />}
                        label=">50-100cr"
                      />
                      <FormControlLabel
                        value=">100-150cr"
                        control={<Radio />}
                        label=">100-150cr"
                      />
                      <FormControlLabel
                        value=">150-250cr"
                        control={<Radio />}
                        label=">150-250cr"
                      />
                      <FormControlLabel
                        value=">250-500cr"
                        control={<Radio />}
                        label=">250-500cr"
                      />
                      <FormControlLabel
                        value=">500-750cr"
                        control={<Radio />}
                        label=">500-750cr"
                      />
                      <FormControlLabel
                        value=">750cr"
                        control={<Radio />}
                        label=">750cr"
                      />
                    </RadioGroup>
                    {formik.touched.annualTurnover &&
                      formik.errors.annualTurnover && (
                        <div style={{ color: "red" }}>
                          {formik.errors.annualTurnover}
                        </div>
                      )}
                  </FormControl>
                </ThemeProvider>
              </Div>
            </FormControl>
          </Div>
          <Div sx={{ mt: 3 }}>
            <Typography className="from_label">
             City of Incorpration / Registration<span>*</span>
            </Typography>
            <Autocomplete
        options={options}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value?.value}
        value={options.find((opt) => opt.value === formik.values.selectedOption) || null}
        onChange={(_, value) => formik.setFieldValue('selectedOption', value?.value || null)}
        renderInput={(params) => (
          <TextField
            {...params}
          
            helperText={formik.touched.selectedOption && formik.errors.selectedOption}
          />
        )}
      />

      {/* Display form errors */}
      {formik.touched.selectedOption && formik.errors.selectedOption ? (
        <div style={{ color: 'red' }}>{formik.errors.selectedOption}</div>
      ) : null}
          

      {/* Display form errors */}
      {formik.touched.selectedOption && formik.errors.selectedOption ? (
        <div style={{ color: 'red' }}>{formik.errors.selectedOption}</div>
      ) : null}
        

      
      
          </Div>
        </div>
      </form>
   
    </>
  );
};

export default OrganisationDetails;
