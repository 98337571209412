import React, { useState } from 'react';
import {useNavigate, NavLink } from 'react-router-dom';
import {
AppBar,
Toolbar,
IconButton,
Drawer,
MenuItem,
Avatar,
Menu,
useMediaQuery,
Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { RiHomeLine } from "react-icons/ri";
import { PiNote, PiPiggyBank, PiBuildings } from "react-icons/pi";
import Div from '@jumbo/shared/Div';
import CREDITS from "../../../assets/creditis.png"
import { IoSettingsOutline } from "react-icons/io5";
import { LiaRupeeSignSolid } from 'react-icons/lia';
import { SlLock } from 'react-icons/sl';
import { GrMoney } from 'react-icons/gr';
import {DashLogo} from "../../../assets/svg/Svg";
import NotificationsDropdownAdmin from '../Notifications/NotificationsDropdownAdmin';
import { logoutAction } from 'app/redux/actions/authAction';




const AdminHeader = () => {
const navigate = useNavigate()
const isMobile = useMediaQuery('(max-width:1300px)');
const [isDrawerOpen, setIsDrawerOpen] = useState(false);
const [anchorEl, setAnchorEl] = React.useState(null);
const open = Boolean(anchorEl);


// drawer
const drawerContent = (

<Div style={{width:"280px", height:"100%"}}>
  <div className='admin_sidenav_container'>
    <div className='sidebar_content'>
      <div className='sidebar_content_top'>
        <div className='admin_responsive_logo'>
          <DashLogo />
        </div>
        <div className='admin_responsive_links'>
          <Divider component="li" />

          <ul>
            <li>
              <NavLink to={'/admin-dashboard'}>
                <RiHomeLine style={{fontSize:"24px"}} /> Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to={'/savings-user'}>
                <PiPiggyBank style={{fontSize:"24px"}} /> Saving User
              </NavLink>
            </li>
            <li>
              <NavLink to={'/current-user'}>
                <PiBuildings style={{fontSize:"24px"}} /> Current Users
              </NavLink>
            </li>
            <li>
              <NavLink to={'/transactions'}>
                <PiPiggyBank style={{fontSize:"24px"}} /> Transactions
              </NavLink>
            </li>
            <li>
              <NavLink to={'/transactions'}>
                <PiNote style={{fontSize:"24px"}} /> Transactions
              </NavLink>
            </li>
            <li>
              <NavLink to={'/commissions'}>
                <LiaRupeeSignSolid style={{fontSize:"24px"}} /> Commissions
              </NavLink>
            </li>
            <Divider component="li" />
            <h6 className="devider_head">Settings</h6>
            <li >
              <NavLink to={'/settings/commissions/update'}>
                <GrMoney style={{fontSize:"24px"}} /> Set Commissions
              </NavLink>

            </li>
            <li >
              <NavLink to={'/settings/change-passwors'}>
                <SlLock style={{fontSize:"24px"}} /> Change Password
              </NavLink>
             
            </li>

          </ul>
        </div>
      </div>

      <div className='credits_admin_logo'>

        <img height={30} width={160} src={CREDITS} alt="credits" />

      </div>


    </div>

  </div>

</Div>




);
// function
const toggleDrawer = () => {
setIsDrawerOpen(!isDrawerOpen);
};
const handleClick = (event) => {
setAnchorEl(event.currentTarget);
};
const handleClose = () => {
setAnchorEl(null);
};

const Logout = () =>{
setAnchorEl(null);
logoutAction()
navigate('/admin')
}




return (
<>
 <AppBar position="static" className='admin_navbar'>
    <Toolbar className='h-100' sx={{height:"10%"}}>
      {isMobile && (
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
      )}
       <div className='d-flex items-center space-between w-100 h-full'>
        <div className='d-flex  items-center h-full'>
          <div className='d-flex items-center h-full'>
            <DashLogo />
          </div>
          <div className={isMobile==true ? 'admin_nav_menus d-none h-full' : "admin_nav_menus h-full" }>
            <ul className='ml-0, pl-0 pr-0 h-full'>
              <li className='h-full'>
                <NavLink to={'/admin-dashboard'}>
                  <RiHomeLine style={{fontSize:"24px"}} /> Dashboard
                </NavLink> 
              </li>
              <li className='h-full'>
                <NavLink to={'/savings-user'}>
                  <PiPiggyBank style={{fontSize:"24px"}} /> Saving Users
                </NavLink>
              </li>
              <li className='h-full'>
                <NavLink to={'/current-user'}>
                  <PiBuildings style={{fontSize:"24px"}} /> Current Users
                </NavLink>
              </li>
              <li className='h-full'>
                <NavLink to={'/transactions'}>
                  <PiNote style={{fontSize:"24px"}} /> Transactions
                </NavLink>
              </li>
              <li className='h-full'>
                <NavLink to={'/commissions'}>
                  <LiaRupeeSignSolid style={{fontSize:"24px"}} /> Commissions
                </NavLink>
              </li>
              <li className={'dropdown_hover h-full '}>
                          <NavLink className={'settings'}>
                <IoSettingsOutline style={{fontSize:"24px"}} /> Settings</NavLink>
                <div className='drpdownlinks'>
                  <p className="m-0">
                    <NavLink to={'/settings/commissions/update'}>Set Commissions </NavLink>
                  </p>
                  <p className="m-0">
                    <NavLink to={'/settings/change-password'}>Change password </NavLink>
                  </p>
                  </div>
              </li>
            </ul>
       </div> 
   </div>
   <div className='d-flex items-center' style={{ whiteSpace: 'nowrap' }}>
          <NotificationsDropdownAdmin />
          <div className='d-flex items-center' style={{ whiteSpace: 'nowrap' }} onClick={handleClick}>
            <Avatar aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' :
              undefined} alt="Account"
              src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" />
            <h2 className='ml-10 profile_name cursor-pointer'>Admin</h2>
            <KeyboardArrowDownIcon className='ml-10' />
          </div>
          <Menu disableRipple PaperProps={{style: { width: 100,},  
 }}  id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby'
            : 'basic-button' , }}>
           <MenuItem classname="logout_admin" disableRipple  sx={{ my: 0, color: "#e41919" , fontSize: "15px" , }} onClick={Logout}>
             Logout</MenuItem>
          </Menu>
        </div>
      </div>
</Toolbar>

  </AppBar>
  {isMobile && (
  <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer} style={{width:"400px"}}>
    {drawerContent}
  </Drawer>
  )}



</>
)
}

export default AdminHeader